import React from 'react';
import { Box, Typography, Tooltip, styled } from '@mui/material';
import { FiberManualRecord } from '@mui/icons-material'; // Cambiato con l'icona piena

// Creiamo un LED stilizzato per aggiungere effetti animati
const StyledLED = styled(FiberManualRecord)(({ status }) => ({
    color: (status === "1") ? 'green' : 'transparent',
    fontSize: '2rem',
    marginRight: '8px',
    transition: 'transform 0.3s ease', // Animazione fluida
    transform: status === "1" ? 'scale(1.2)' : 'scale(1)', // Cambiamento di dimensione
}));

const TrattaKmVuotoLED = ({ status }) => (
    <Tooltip title={status ? 'Questo record è attivo' : 'Questo record è inattivo'} arrow>
        <Box display="flex" alignItems="center">
            {status === "1" && ( // Mostra la luna solo se è attivo
                <StyledLED status={status} />
            )}
            <Typography variant="body2" sx={{ fontWeight: (status === "1") ? 'bold' : 'normal' }}>
                {(status === "1") ? 'Si' : '-'}
            </Typography>
        </Box>
    </Tooltip>
);

export default TrattaKmVuotoLED;
