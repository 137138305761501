

// import { GruppiList } from '../gruppi/gruppiList';
// import { GruppiEdit } from '../gruppi/gruppiEdit';
import GruppiShow from '../gruppi/gruppiShow';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    // list: GruppiList,
    // edit: GruppiEdit,
    show: GruppiShow,
    icon: AssignmentIndIcon
};