import React, { useState } from 'react';
import { useCreate, useNotify } from 'react-admin';
import { TextField, Button } from '@mui/material';
import Box from '@mui/material/Box';
const CreateNoteForm = ({ tiporecordId, recordId, userId, refresh }) => {
    const [descrizione, setDescrizione] = useState('');
    const notify = useNotify();
    const [create, { isLoading, error }] = useCreate();

    const handleClick = () => {
        const data = {
            tiporecordId: tiporecordId,
            recordId: recordId,
            descrizione: descrizione,
            userId: userId
        };

        create(
            'note',
            { data },
            {
                onSuccess: ({ data }) => {
                    refresh();
                    notify('Nota creata con successo');
                    setDescrizione(''); // Reset della descrizione dopo la creazione con successo
                },
                onFailure: (error) => {
                    notify(error.message, 'warning');
                }
            }
        );
    };

    if (error) {
        return <p>Attenzione. Problemi d'accesso alla tabella delle note. Riavvia la pagina.</p>; // Considera una gestione degli errori più sofisticata
    }

    return (
        <Box >
            <TextField
                id="descrizione"
                label="Descrizione"
                variant="outlined"
                fullWidth
                multiline // Abilita il supporto per più righe
                rows={4} // Numero di righe che il campo deve mostrare
                cols={60}
                value={descrizione}
                onChange={(e) => setDescrizione(e.target.value)}
                disabled={isLoading}
            />
            <Button
                onClick={handleClick}
                disabled={isLoading || !descrizione}
                variant="contained"
                color="primary"
                style={{ marginTop: '10px' }}
            >
                Crea
            </Button>
        </Box>
    );
};

export default CreateNoteForm;
