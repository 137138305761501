import * as React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Title } from 'react-admin';
import SettingsIcon from '@mui/icons-material/Settings'; // Icona per le impostazioni
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';

function SettingsPage() {
    return (
        <Card sx={{ mt: 10, textAlign: 'center', backgroundColor: '#f3f6f9' }}>
            <Title title="Impostazioni" />
            <CardContent>
                <SettingsIcon sx={{ color: blue[500] }} />
                <Typography variant="h4" sx={{ margin: '20px 0', color: '#01b5d0' }}>
                    Impostazioni
                </Typography>
                <Typography variant="subtitle1">
                    Personalizza la tua esperienza di utilizzo.
                </Typography>
                {/* Qui puoi aggiungere i componenti per le impostazioni, come switch, checkbox, etc. */}
            </CardContent>
        </Card>
    );
}

export default SettingsPage;
