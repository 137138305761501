import { useGetOne, Loading } from 'react-admin';
import { List, ListItem, ListItemText } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { listItemLiv01Zero, listItemDefault } from '../shared/Styles/listStyles';

const GetDescrizioneVoce = ({ id }) => {
    const { data: record, isLoading, error } = useGetOne('menuprog', { id: id });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return <>
        {/* {record.id}
        <br></br> */}
        {(record.liv01 == 0) ? <List>
            <ListItem>
                <ListItemText style={listItemDefault} primary={record.descrizione} />
            </ListItem>
        </List> : <List>
            <ListItem>
                <KeyboardArrowDownIcon />
                <ListItemText style={listItemLiv01Zero} primary={record.descrizione} />
            </ListItem>
        </List>}
    </>;
};

export default GetDescrizioneVoce;