import React from 'react';
// import Typography from '@mui/material/Typography';
// import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Card, CardContent } from '@mui/material';
import { Title } from 'react-admin';
import Plot from 'react-plotly.js';
import StatusPieChartBooking0Status from './StatusPieChartBooking0Status';

function StatisticheBoard() {



    var data = [
        {
            "country": "AD",
            "hot dog": 31,
            "hot dogColor": "hsl(277, 70%, 50%)",
            "burger": 24,
            "burgerColor": "hsl(164, 70%, 50%)",
            "sandwich": 95,
            "sandwichColor": "hsl(239, 70%, 50%)",
            "kebab": 118,
            "kebabColor": "hsl(62, 70%, 50%)",
            "fries": 126,
            "friesColor": "hsl(357, 70%, 50%)",
            "donut": 128,
            "donutColor": "hsl(288, 70%, 50%)"
        },
        {
            "country": "AE",
            "hot dog": 136,
            "hot dogColor": "hsl(60, 70%, 50%)",
            "burger": 73,
            "burgerColor": "hsl(3, 70%, 50%)",
            "sandwich": 146,
            "sandwichColor": "hsl(74, 70%, 50%)",
            "kebab": 13,
            "kebabColor": "hsl(294, 70%, 50%)",
            "fries": 94,
            "friesColor": "hsl(144, 70%, 50%)",
            "donut": 15,
            "donutColor": "hsl(285, 70%, 50%)"
        },
        {
            "country": "AF",
            "hot dog": 68,
            "hot dogColor": "hsl(251, 70%, 50%)",
            "burger": 69,
            "burgerColor": "hsl(50, 70%, 50%)",
            "sandwich": 14,
            "sandwichColor": "hsl(342, 70%, 50%)",
            "kebab": 178,
            "kebabColor": "hsl(75, 70%, 50%)",
            "fries": 127,
            "friesColor": "hsl(357, 70%, 50%)",
            "donut": 19,
            "donutColor": "hsl(336, 70%, 50%)"
        },
        {
            "country": "AG",
            "hot dog": 46,
            "hot dogColor": "hsl(336, 70%, 50%)",
            "burger": 188,
            "burgerColor": "hsl(272, 70%, 50%)",
            "sandwich": 138,
            "sandwichColor": "hsl(220, 70%, 50%)",
            "kebab": 71,
            "kebabColor": "hsl(247, 70%, 50%)",
            "fries": 31,
            "friesColor": "hsl(266, 70%, 50%)",
            "donut": 189,
            "donutColor": "hsl(168, 70%, 50%)"
        },
        {
            "country": "AI",
            "hot dog": 64,
            "hot dogColor": "hsl(157, 70%, 50%)",
            "burger": 155,
            "burgerColor": "hsl(64, 70%, 50%)",
            "sandwich": 27,
            "sandwichColor": "hsl(217, 70%, 50%)",
            "kebab": 133,
            "kebabColor": "hsl(201, 70%, 50%)",
            "fries": 145,
            "friesColor": "hsl(313, 70%, 50%)",
            "donut": 88,
            "donutColor": "hsl(170, 70%, 50%)"
        },
        {
            "country": "AL",
            "hot dog": 15,
            "hot dogColor": "hsl(221, 70%, 50%)",
            "burger": 54,
            "burgerColor": "hsl(296, 70%, 50%)",
            "sandwich": 52,
            "sandwichColor": "hsl(191, 70%, 50%)",
            "kebab": 52,
            "kebabColor": "hsl(166, 70%, 50%)",
            "fries": 159,
            "friesColor": "hsl(218, 70%, 50%)",
            "donut": 200,
            "donutColor": "hsl(6, 70%, 50%)"
        },
        {
            "country": "AM",
            "hot dog": 109,
            "hot dogColor": "hsl(115, 70%, 50%)",
            "burger": 15,
            "burgerColor": "hsl(275, 70%, 50%)",
            "sandwich": 150,
            "sandwichColor": "hsl(88, 70%, 50%)",
            "kebab": 83,
            "kebabColor": "hsl(165, 70%, 50%)",
            "fries": 92,
            "friesColor": "hsl(316, 70%, 50%)",
            "donut": 44,
            "donutColor": "hsl(103, 70%, 50%)"
        }
    ];

    return (
        <div>

            {/* <Container component="main" maxWidth="md" sx={{ mt: 10 }}> */}
            <Card sx={{ mt: 10 }}>
                <Title title="STATISTICHE" />
                <CardContent>
                    Pagina delle statistiche.
                </CardContent>
            </Card>

            {/* <ResponsiveBar
                data={data}
                keys={[
                    'hot dog',
                    'burger',
                    'sandwich',
                    'kebab',
                    'fries',
                    'donut'
                ]}
                indexBy="country"
                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'nivo' }}
                defs={[
                    {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: '#38bcb2',
                        size: 4,
                        padding: 1,
                        stagger: true
                    },
                    {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: '#eed312',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10
                    }
                ]}
                fill={[
                    {
                        match: {
                            id: 'fries'
                        },
                        id: 'dots'
                    },
                    {
                        match: {
                            id: 'sandwich'
                        },
                        id: 'lines'
                    }
                ]}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'country',
                    legendPosition: 'middle',
                    legendOffset: 32,
                    truncateTickAt: 0
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'food',
                    legendPosition: 'middle',
                    legendOffset: -40,
                    truncateTickAt: 0
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={e => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
            /> */}


            {/* {<Box sx={{ mt: 5, display: 'flex', justifyContent: 'center' }}>
                <Paper elevation={3} sx={{ p: 3, maxWidth: 600, margin: 'auto' }}>
                    <Plot
                        data={[
                            {
                                x: [1, 2, 3],
                                y: [2, 6, 3],
                                type: 'scatter',
                                mode: 'lines+markers',
                                marker: { color: 'red' },
                            },
                            { type: 'bar', x: [1, 2, 3], y: [2, 5, 3] },
                        ]}
                        layout={{ title: 'Un Grafico Semplice', margin: { l: 40, r: 40, t: 80, b: 40 } }}
                        useResizeHandler={true}
                        style={{ width: "100%", height: "100%" }}
                    />
                </Paper>
            </Box>}

            <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center' }}>
                <Paper elevation={3} sx={{ p: 3, maxWidth: 800, margin: 'auto' }}>
                    <Plot
                        data={[{
                            values: [19, 26, 55],
                            labels: ['Residential', 'Non-Residential', 'Utility'],
                            type: 'pie'
                        }]}
                        layout={{
                            title: 'Distribuzione delle Vendite per Categoria',
                            margin: { l: 40, r: 40, t: 80, b: 40 }
                        }}
                        // useResizeHandler={true}
                        style={{ width: "100%", height: "100%" }}
                    />
                </Paper>
            </Box> */}

            {/* </Container> */}

            <StatusPieChartBooking0Status></StatusPieChartBooking0Status>

        </div>
    );
}

export default StatisticheBoard;
