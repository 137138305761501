// EditClientDialog.js
import * as React from 'react';
import { useState } from 'react';
import {
    useUpdate,
    useNotify,
    useRefresh,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    required
} from 'react-admin';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Person as PersonIcon } from '@mui/icons-material';

export const EditClientDialog = ({ open, onClose, record }) => {
    const [loading, setLoading] = useState(false);
    const [update] = useUpdate();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            await update(
                'booking-0',
                {
                    id: record.id,
                    data: {
                        bk0_clientecod: values.bk0_clientecod,
                        bk0_servizio: values.bk0_servizio,
                        bk0_richiestatipo: values.bk0_richiestatipo
                    },
                }
            );
            notify('Informazioni cliente aggiornate con successo', { type: 'success' });
            refresh();
            onClose();
        } catch (error) {
            console.error('Error updating client:', error);
            notify('Errore durante l\'aggiornamento', { type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    if (!record) return null;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: { maxHeight: '90vh', overflowY: 'auto' }
            }}
        >
            <DialogTitle sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                backgroundColor: '#f5f5f5',
                borderBottom: '1px solid #ddd'
            }}>
                <PersonIcon color="primary" />
                Modifica Informazioni Cliente
            </DialogTitle>
            <DialogContent sx={{ pt: 2 }}>
                <SimpleForm
                    record={record}
                    onSubmit={handleSubmit}
                    toolbar={null}
                >
                    <ReferenceInput
                        source="bk0_clientecod"
                        reference="anagen"
                    >
                        <SelectInput
                            fullWidth
                            label="Cliente"
                            validate={[required()]}
                            optionText={(record) => `${record.gnfind} (${record.gncodipc})`}
                            optionValue="gncodipc"
                            sx={{ minWidth: 250 }}
                        />
                    </ReferenceInput>

                    <ReferenceInput
                        source="bk0_servizio"
                        reference="servizi"
                    >
                        <SelectInput
                            fullWidth
                            label="Tipo Servizio"
                            validate={[required()]}
                            optionText="ss_des"
                            sx={{ minWidth: 200 }}
                        />
                    </ReferenceInput>

                    <ReferenceInput
                        source="bk0_richiestatipo"
                        reference="tabellealfa"
                    >
                        <SelectInput
                            fullWidth
                            label="Tipo Richiesta"
                            validate={[required()]}
                            optionText="tbdes"
                            sx={{ minWidth: 200 }}
                        />
                    </ReferenceInput>
                </SimpleForm>
            </DialogContent>
            <DialogActions sx={{
                padding: 2,
                borderTop: '1px solid #ddd',
                backgroundColor: '#f5f5f5'
            }}>
                <Button
                    onClick={onClose}
                    disabled={loading}
                >
                    Annulla
                </Button>
                <LoadingButton
                    loading={loading}
                    onClick={() => {
                        const form = document.querySelector('form');
                        if (form) {
                            form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
                        }
                    }}
                    variant="contained"
                    color="primary"
                >
                    Salva
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default EditClientDialog;