import React from 'react';
import { useGetList, Loading, NumberField } from 'react-admin';
import { Card, CardContent, CardActions, Typography, Avatar, Grid, Box } from '@mui/material';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import EditCostiUffciali from './EditCostiUffciali';

const CostiUfficialiList = ({ tiporecordId, recordId, emailUser }) => {
    const { data, isLoading, error } = useGetList(
        'automezzi-costi',
        {
            pagination: false,
            filter: {
                automezziId: recordId
            }
        }
    );

    if (isLoading) return <Loading />;
    if (error) return <Box display="flex" justifyContent="center" alignItems="center"><ErrorOutlineIcon color="error" fontSize="large" /><Typography variant="h6" color="error"> ERROR</Typography></Box>;

    return (
        <Box p={3}>
            <Grid container spacing={3}>
                {data.map((costo) => (
                    <Grid item /* xs={12} md={6} lg={4} */ key={costo.id}>
                        <Card>
                            <CardContent>
                                <Box display="flex" alignItems="center" mb={2}>
                                    <Avatar sx={{ bgcolor: 'cyan.500', mr: 2 }}>
                                        <DirectionsBusIcon />
                                    </Avatar>
                                    <Typography variant="h6">
                                        {costo.amtarga}
                                    </Typography>
                                </Box>
                                <Typography variant="body1" color="textPrimary">
                                    <strong>Anno:</strong> {costo.anno}
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                    <strong>Automezzi ID:</strong> {costo.automezziId}
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                    <strong>Prezzo Vendita Effettivo:</strong>
                                    <NumberField record={{ prezzoVenditaEff: parseFloat(costo.prezzoVenditaEff) }} source="prezzoVenditaEff" options={{ style: "currency", currency: "EUR" }} />
                                </Typography>
                                <Typography variant="body1" color="textSecondary">
                                    <strong>Data Creazione:</strong> {new Date(costo.createdAt).toLocaleDateString()} {new Date(costo.createdAt).toLocaleTimeString()}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <strong>Costi Fissi Previsti:</strong>
                                    <NumberField record={{ costiFissiPrev: parseFloat(costo.costiFissiPrev) }} source="costiFissiPrev" options={{ style: "currency", currency: "EUR" }} />
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <strong>Costi Fissi Effettivi:</strong>
                                    <NumberField record={{ costiFissiEff: parseFloat(costo.costiFissiEff) }} source="costiFissiEff" options={{ style: "currency", currency: "EUR" }} />
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <strong>Costi Variabili Previsti:</strong>
                                    <NumberField record={{ costiVarPrev: parseFloat(costo.costiVarPrev) }} source="costiVarPrev" options={{ style: "currency", currency: "EUR" }} />
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <strong>Costi Variabili Effettivi:</strong>
                                    <NumberField record={{ costiVarEff: parseFloat(costo.costiVarEff) }} source="costiVarEff" options={{ style: "currency", currency: "EUR" }} />
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <strong>Costi Generali Previsti:</strong>
                                    <NumberField record={{ costiGenPrev: parseFloat(costo.costiGenPrev) }} source="costiGenPrev" options={{ style: "currency", currency: "EUR" }} />
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <strong>Costi Generali Effettivi:</strong>
                                    <NumberField record={{ costiGenEff: parseFloat(costo.costiGenEff) }} source="costiGenEff" options={{ style: "currency", currency: "EUR" }} />
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <strong>Costi Complessivi Previsti:</strong>
                                    <NumberField record={{ costiComplPrev: parseFloat(costo.costiComplPrev) }} source="costiComplPrev" options={{ style: "currency", currency: "EUR" }} />
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <strong>Costi Complessivi Effettivi:</strong>
                                    <NumberField record={{ costiComplEff: parseFloat(costo.costiComplEff) }} source="costiComplEff" options={{ style: "currency", currency: "EUR" }} />
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <strong>Km Previsti:</strong> {costo.kmPrev}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <strong>Km Effettivi:</strong> {costo.kmEff}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <strong>Ricarico Stabilito Previsto:</strong>
                                    <NumberField record={{ ricaricoStabPrev: parseFloat(costo.ricaricoStabPrev) }} source="ricaricoStabPrev" options={{ style: "currency", currency: "EUR" }} />
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <strong>Ricarico Stabilito Effettivo:</strong>
                                    <NumberField record={{ ricaricoStabEff: parseFloat(costo.ricaricoStabEff) }} source="ricaricoStabEff" options={{ style: "currency", currency: "EUR" }} />
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <strong>Ricarico Stabilito % Previsto:</strong> {costo.ricaricoStabPercPrev}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <strong>Ricarico Stabilito % Effettivo:</strong> {costo.ricaricoStabPercEff}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <EditCostiUffciali record={costo} />
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default CostiUfficialiList;
