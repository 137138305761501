import * as React from 'react';
import { useGetOne } from 'react-admin';
import {
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Alert,
    Box,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import Simulatore from '../../tariffea/simulatore/simulatore';

const SelectedTariffBInfo = ({ tariff, totals }) => {
    const { data: tariffBData, isLoading, error } = useGetOne(
        'tariffe-b',
        { id: tariff.id }
    );

    if (isLoading) {
        return <CircularProgress size={20} />;
    }

    if (error) {
        return <Alert severity="error">Errore nel caricamento della tariffa</Alert>;
    }

    if (!tariffBData) {
        return <Alert severity="info">Nessuna tariffa selezionata</Alert>;
    }

    // Calcolo dei costi
    const calculateCosts = () => {
        if (!totals) return null;

        const kmCost = totals.kilometers * tariffBData.tfb_ckm;
        const hoursCost = (totals.totalTimeInSeconds / 3600) * tariffBData.tfb_chh;
        const totalCost = kmCost + hoursCost;

        return {
            kmCost,
            hoursCost,
            totalCost
        };
    };

    const costs = calculateCosts();

    return (
        <Card variant="outlined">
            <CardContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography variant="h6" color="primary">
                        Tariffa B Selezionata
                    </Typography>

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                            ID: {tariff.id}<br></br>
                            Descrizione: {tariff.tfb_des}<br></br>
                            €/Km: {tariff.tfb_ckm}<br></br>
                            €/Ora: {tariff.tfb_chh}<br></br>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                            Suppl. Nott.: {tariff.tfb_snotturno}<br></br>
                            €/Km Vuoto: {tariff.tfb_vkm}<br></br>
                            €/Ora Vuoto: {tariff.tfb_vhh}<br></br>
                        </Box>
                    </Box>


                    <Divider />

                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
                        <Typography>
                            €/Km: {tariffBData.tfb_ckm}
                        </Typography>
                        <Typography>
                            €/Ora: {tariffBData.tfb_chh}
                        </Typography>
                    </Box>

                    {costs && (
                        <>
                            <Divider />
                            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
                                <Typography>
                                    Costo Km: €{costs.kmCost.toFixed(2)}
                                </Typography>
                                <Typography>
                                    Costo Ore: €{costs.hoursCost.toFixed(2)}
                                </Typography>
                                <Typography variant="h6" color="primary" sx={{ gridColumn: 'span 2' }}>
                                    Totale: €{costs.totalCost.toFixed(2)}
                                </Typography>
                            </Box>
                        </>
                    )}

                    {/* Simulatore */}
                    <Simulatore
                        record={tariff}
                        noSimulatore={true}
                        kmTotali={totals?.kilometers || 0}
                        tempoTotaleInSecondi={totals?.totalTimeInSeconds || 0}
                    />

                    {/* Sezione Debug */}
                    <Accordion sx={{ mt: 2, backgroundColor: '#f5f5f5' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="debug-panel-content"
                            id="debug-panel-header"
                        >
                            <Typography color="textSecondary">Debug Info</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{ fontFamily: 'monospace', whiteSpace: 'pre-wrap' }}>
                                <Typography variant="body2" color="textSecondary">
                                    Tariff B Data:
                                </Typography>
                                <pre>
                                    {JSON.stringify(tariffBData, null, 2)}
                                </pre>

                                <Divider sx={{ my: 1 }} />

                                <Typography variant="body2" color="textSecondary">
                                    Totals Data:
                                </Typography>
                                <pre>
                                    {JSON.stringify(totals, null, 2)}
                                </pre>

                                <Divider sx={{ my: 1 }} />

                                <Typography variant="body2" color="textSecondary">
                                    Calculated Costs:
                                </Typography>
                                <pre>
                                    {JSON.stringify(costs, null, 2)}
                                </pre>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </CardContent>
        </Card>
    );
};

export default SelectedTariffBInfo;