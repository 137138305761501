import React, { useState, useEffect } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { useInput } from 'react-admin';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import config from '../../../config/config.json';

/**
 * AutocompleteComponent
 * 
 * Un componente React che fornisce funzionalità di autocompletamento per la ricerca di luoghi
 * utilizzando l'API di Google Maps Places, integrato con react-admin.
 *
 * @component
 * @param {Object} props - Le proprietà del componente.
 * @param {string} props.source - Il nome del campo nel form di react-admin.
 * @param {string} [props.label="Cerca luogo"] - L'etichetta da visualizzare per il campo di input.
 * @param {string} [props.placeholder="Inserisci un luogo"] - Il testo placeholder per il campo di input.
 * @param {Object} [props.style] - Stili aggiuntivi da applicare al contenitore principale.
 *
 * @example
 * // Uso base
 * <AutocompleteComponent source="nome" />
 *
 * @example
 * // Uso con proprietà personalizzate
 * <AutocompleteComponent
 *   source="indirizzo"
 *   label="Indirizzo completo"
 *   placeholder="Inserisci un indirizzo"
 *   style={{ width: '500px' }}
 * />
 */
const AutocompleteComponent = ({ source, label = "Cerca luogo", placeholder = "Inserisci un luogo", style = {} }) => {
    const {
        field,
        fieldState: { isTouched, invalid, error },
        formState: { isSubmitted }
    } = useInput({ source });

    const [inputValue, setInputValue] = useState(field.value || '');
    const [suggestions, setSuggestions] = useState([]);
    const jwt = localStorage.getItem("feathers-jwt");

    useEffect(() => {
        // Popola l'input con il valore esistente quando il componente viene montato
        if (field.value && field.value !== inputValue) {
            setInputValue(field.value);
            fetchSuggestions(field.value);
        }
    }, [field.value]);

    /**
     * Funzione per recuperare i suggerimenti dall'API di Google Maps.
     * Utilizza debounce per limitare le chiamate API.
     *
     * @param {string} value - Il valore di input corrente.
     */
    const fetchSuggestions = debounce(async (value) => {
        if (value.length < 3) {
            setSuggestions([]);
            return;
        }

        const configBackend = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${config.production.host}/google-maps-proxy?path=/place/autocomplete/json?input=${encodeURIComponent(value)}&types=geocode`,
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        };

        try {
            const response = await axios.request(configBackend);
            setSuggestions(response.data.predictions);
        } catch (error) {
            console.error(error);
        }
    }, 500);

    /**
     * Gestisce il cambiamento del valore nell'input.
     *
     * @param {Object} event - L'evento di cambio dell'input.
     */
    const handleInputChange = (event) => {
        const newValue = event.target.value;
        setInputValue(newValue);
        field.onChange(newValue);
        fetchSuggestions(newValue);
    };

    /**
     * Gestisce la selezione di un suggerimento.
     *
     * @param {Object} suggestion - Il suggerimento selezionato.
     */
    const handleSuggestionClick = (suggestion) => {
        setInputValue(suggestion.description);
        field.onChange(suggestion.description);
        setSuggestions([]);
    };

    return (
        <div style={{ width: '400px', margin: '0 auto', position: 'relative', ...style }}>
            <TextField
                value={inputValue}
                onChange={handleInputChange}
                label={label}
                variant="outlined"
                fullWidth
                placeholder={placeholder}
                error={isTouched && invalid}
                helperText={isTouched && invalid ? error : ''}
            />
            {suggestions.length > 0 && (
                <Paper style={{ position: 'absolute', width: '100%', zIndex: 1 }}>
                    <List>
                        {suggestions.map((suggestion) => (
                            <ListItem
                                button
                                key={suggestion.place_id}
                                onClick={() => handleSuggestionClick(suggestion)}
                            >
                                {suggestion.description}
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            )}
        </div>
    );
};

export default AutocompleteComponent;