
import * as React from "react";
import { useState } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    required,
    localStorageStore,
    SaveButton,
    Toolbar,
    BooleanInput,
    DateInput,
    NumberInput,
    useRedirect,
} from 'react-admin';

import { styled } from '@mui/material/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button as MuiButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import PeopleIcon from '@mui/icons-material/People';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";
import axiosInstance from "../../utils/axios"; // TODO: Necessario per l'upload

// Importo il css per i Bottoni
import { saveButtonCss } from '../shared/Styles/buttonStyles';
import { formCss } from "../shared/Styles/formCss";

// Importa validators
import { validateNome, validateCognome } from '../../utils/validators/formValidators';

import CustomAutocompleInputTipoUser from "../shared/FormFields/CustomAutocompleInputTipoUser";

/* STILI PER LA DIALOG DEL CREATEUSERVER */
// Stili personalizzati per la DialogTitle
const StyledDialogTitle = styled(DialogTitle)(({ theme, iserror }) => ({
    backgroundColor: iserror === 'true' ? theme.palette.error.main : theme.palette.success.main,
    color: theme.palette.common.white,
}));

// Stili personalizzati per la DialogContent
const StyledDialogContent = styled(DialogContent)(({ theme, iserror }) => ({
    backgroundColor: iserror === 'true' ? theme.palette.error.light : theme.palette.success.light,
    color: iserror === 'true' ? theme.palette.error.contrastText : theme.palette.success.contrastText,
}));


// Init store
const storeGenerale = localStorageStore();

/* CSS - BASE - FORM - START */
const styles = {

    TextInput: {
        // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        // background: 'linear-gradient(45deg, #01b5d0 10%, #ffffff 90%)',
        border: 0,
        borderRadius: 1,
        // boxShadow: '0px 0px 3px 2px #01b5d0',
        color: 'green',
        height: 50,
        padding: '0 0px',
    },
};


const useStyles = makeStyles(styles);
/* CSS - BASE - FORM - END */


const listFiltersAnagen = {
    // title: textFilter(),
    'idx': {
        input: ({ source }) => <NumberInput source="id" />,
        operators: [
            { value: 'eq', label: 'Uguale' },
            { value: 'neq', label: 'Maggiore' },
        ],
    },
    'ragione sociale': {
        input: ({ source }) => <TextInput label="La ragione sociale" source={source} />,
        operators: [
            { value: 'eq', label: 'Uguale' },
            { value: 'in', label: 'Include' },
        ],
    },
};

/* CODICE PER LA GESTIONE DELLA VALIDAZIONE - inizio */

const requiredField = [required('Questo campo è obbligatorio')];

// Funzione di validazione per email
const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value) ? undefined : 'Email non valida';
};

// Funzione di validazione per la Provincia di nascita
const validateProvince = (value) => {
    const provinceRegex = /^[A-Z]{2}$/i; // Due lettere, case insensitive
    return provinceRegex.test(value) ? undefined : 'Provincia non valida, servono due lettere';
};

// Funzione di validazione per la Nazione di nascita
const validateNation = (value) => {
    const nationRegex = /^[A-Z]{2}$/i; // Due lettere, case insensitive
    return nationRegex.test(value) ? undefined : 'Nazione non valida, servono due lettere';
};



// Funzione di validazione per codice fiscale
const validateCodiceFiscale = (value) => {
    const cfRegex = /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/i;
    return cfRegex.test(value) ? undefined : 'Codice Fiscale non valido';
};

// Funzione di validazione per il numero di telefono
const validatePhoneNumber = (value) => {
    const phoneRegex = /^[0-9]+$/;
    const minLength = 8;
    const maxLength = 15;
    if (!phoneRegex.test(value)) {
        return 'Il numero di telefono deve contenere solo cifre';
    }
    if (value.length < minLength || value.length > maxLength) {
        return `Il numero di telefono deve essere tra ${minLength} e ${maxLength} cifre`;
    }
    return undefined;
};

// Validazione
const validateOperatoreCreation = (values) => {
    const errors = {};
    if (!values.nome) {
        errors.nome = 'Il nome è necessario';
    }
    if (!values.cognome) {
        errors.cognome = 'Il cognome è necessario';
    }
    /* if (!values.age) {
        // You can return translation keys
        errors.age = 'ra.validation.required';
    } else if (values.age < 18) {
        // Or an object if the translation messages need parameters
        errors.age = {
            message: 'ra.validation.minValue',
            args: { min: 18 }
        };
    } */
    return errors
};

const UserCreateToolbar = () => (
    <Toolbar>
        <UserSaveButton />
    </Toolbar>
);

const UserSaveButton = () => {
    const redirect = useRedirect();
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [isError, setIsError] = useState(false);

    const handleCloseDialog = () => {
        setOpenDialog(false);
        if (!isError) {
            redirect('/anagen');
        }
    };

    const onSuccess = (data) => {
        // console.log("Success callback triggered");
        setDialogContent(`Utente "${data.gnpfnome} ${data.gnpfcognome}" creato con successo!`);
        setIsError(false);
        setOpenDialog(true);
    };

    const onError = (error) => {
        let errorMessage = "Si è verificato un errore durante la creazione dell'utente. Motivo: " + error.message;
        if (error.errors && error.errors.length > 0) {
            const firstError = error.errors[0];
            errorMessage = `Non è stato possibile creare l'utente. Motivo dal ${firstError.origin}: ${firstError.message} per ${firstError.value}`;
        }

        setDialogContent(errorMessage);
        setIsError(true);
        setOpenDialog(true);
    };

    return (
        <>
            <SaveButton
                label="Crea nuovo utente"
                sx={saveButtonCss}
                type="button"
                mutationOptions={{ onSuccess, onError }}
            />
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <StyledDialogTitle iserror={isError.toString()}>
                    {isError ? 'Errore' : 'Successo'}
                </StyledDialogTitle>
                <StyledDialogContent iserror={isError.toString()}>
                    {dialogContent}
                </StyledDialogContent>
                <DialogActions>
                    <MuiButton onClick={handleCloseDialog} color={isError ? "error" : "success"}>
                        Chiudi
                    </MuiButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export const AnagenCreate = (props) => {

    const classes = useStyles();

    // Recupera il valore di urlParam dallo store
    const urlParam = storeGenerale.getItem('profile.urlParam');
    const urlParamId = storeGenerale.getItem('profile.urlParam.id');

    React.useEffect(() => {
        // Pulisci il valore dallo store dopo averlo utilizzato
        return () => {
            storeGenerale.removeItem('profile.urlParam');
            storeGenerale.removeItem('profile.urlParam.id');
        };
    }, []);

    return (
        <Create title={<>
            <Button sx={{ color: "white" }}>
                <PeopleIcon sx={{ color: "white" }}></PeopleIcon>
                &nbsp;Aggiungi Nuova Anagrafica
            </Button>
        </>}  {...props}>
            <SimpleForm sx={formCss} toolbar={<UserCreateToolbar />} >
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 2, width: '30ch' },
                    }}
                >
                    <div>

                        <Box display="flex" alignItems="center" >
                            <CustomAutocompleInputTipoUser source="gncdtan" reference="tabellealfa" optionProperty="tbdes" label="Seleziona Tipologia Anagrafica" />

                            <Typography variant="body1" style={{ marginRight: 8 }}>Codice tipologia anagrafica : <strong>{urlParam}</strong>
                                <TextInput
                                    source="gncdtan"
                                    defaultValue={urlParamId}
                                    disabled
                                    className={classes.TextInput}
                                    variant="outlined"
                                />
                            </Typography>
                        </Box>
                    </div>
                    <div>
                        <Box display="flex" alignItems="center" >
                            <Typography variant="body1" style={{ marginRight: 8 }}>Ricerca nome: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Ricerca" source="gnfind" validate={[required("Campo obbligatorio")]} />}
                        </Box>
                    </div>
                    <Divider variant="middle" />
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Nome: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Nome" source="gnpfnome" validate={validateNome} />}

                            <Typography variant="body1" style={{ marginRight: 8 }}>Cognome: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Cognome" source="gnpfcognome" validate={validateCognome} />}
                        </Box>
                    </div>
                    <Divider variant="middle" />
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Partita Iva: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="P.Iva" source="gncdiva" validate={[required("Campo obbligatorio")]} />}
                            <Typography variant="body1" style={{ marginRight: 8 }}>Codice Fiscale: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Codice fiscale" source="gncdfisc" validate={[required("Campo obbligatorio"), validateCodiceFiscale]} />}
                        </Box>
                    </div>
                    <Divider variant="middle" />
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Ragione sociale: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Rag.Soc." source="gnragnom" validate={[required("Campo obbligatorio")]} placeholder="Nome" />}
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Rag.Soc2" source="gnrag2" />}
                        </Box>
                    </div>
                    <Divider variant="middle" />
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Data di nascita: </Typography>
                            {<DateInput color="success" className={classes.TextInput} variant="outlined" label="Data di nascita" source="gnpfdtna" placeholder="" validate={[required("Campo obbligatorio")]} />}
                            <Typography variant="body1" style={{ marginRight: 8 }}>Comune di nascita: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Comune di nascita" source="gnpfcuna" placeholder="" validate={[required("Campo obbligatorio")]} />}
                        </Box>
                    </div>
                    <Divider variant="middle" />
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Indirizzo: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Indirizzo" source="gnind" placeholder="" validate={[required("Campo obbligatorio")]} />}
                            <Typography variant="body1" style={{ marginRight: 8 }}>CAP: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="CAP" source="gncap" placeholder="" validate={[required("Campo obbligatorio")]} />}
                        </Box>
                    </div>
                    <Divider variant="middle" />
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Comune: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Comune" source="gncomune" placeholder="" validate={[required("Campo obbligatorio")]} />}
                            <Typography variant="body1" style={{ marginRight: 8 }}>Frazione: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Frazione" source="gnfrazione" placeholder="" />}
                        </Box>
                    </div>
                    <Divider variant="middle" />
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Provincia: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Provincia" source="gnprov" placeholder="" validate={[required("Campo obbligatorio"), validateProvince]} />}
                            <Typography variant="body1" style={{ marginRight: 8 }}>Nazione: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Nazione" source="gnnaz" placeholder="" validate={[required("Campo obbligatorio"), validateNation]} />}
                        </Box>
                    </div>
                    <Divider variant="middle" />
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Provincia di nascita: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Provincia di nascita" min source="gnpfprna" placeholder="" validate={[required("Campo obbligatorio"), validateProvince]} />}
                            <Typography variant="body1" style={{ marginRight: 8 }}>Nazione di nascita: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Nazione di nascita" source="gnpfnzna" placeholder="" validate={[required("Campo obbligatorio"), validateNation]} />}
                        </Box>
                    </div>
                    <Divider variant="middle" />
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Email: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Email" source="gnemail" placeholder="" validate={[required("Campo obbligatorio"), validateEmail]} />}
                            <Typography variant="body1" style={{ marginRight: 8 }}>Website: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Url Website" source="gnhomep" placeholder="" />}
                        </Box>
                    </div>
                    <Divider variant="middle" />
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Telefono 1: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Telefono" source="gntel" placeholder="" validate={[required("Campo obbligatorio"), validatePhoneNumber]} />}
                            <Typography variant="body1" style={{ marginRight: 8 }}>Telefono 2: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Cellulare" source="gncel" placeholder="" validate={[validatePhoneNumber]} />}
                            <Typography variant="body1" style={{ marginRight: 8 }}>Fax: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Fax" source="gnfax" placeholder="" />}
                        </Box>
                    </div>
                    <Divider variant="middle" />
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Attivo: </Typography>
                            {<BooleanInput defaultValue={true} color="success" className={classes.TextInput} variant="outlined" label="Att" source="flAttivo" />}
                            <Typography variant="body1" style={{ marginRight: 8 }}>Visibile: </Typography>
                            {<BooleanInput defaultValue={true} color="success" className={classes.TextInput} variant="outlined" label="Vis" source="fiVisibile" />}
                            <Typography variant="body1" style={{ marginRight: 8 }}>Annullato: </Typography>
                            {<BooleanInput defaultValue={false} color="success" className={classes.TextInput} variant="outlined" label="Ann" source="flAnnullato" />}
                        </Box>
                    </div>

                </Box>
            </SimpleForm>
        </Create>
    );
}