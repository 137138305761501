import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const CustomLoading = () => (
    <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        sx={{
            backgroundColor: '#f0f4f8',
            color: '#1a237e',
        }}
    >
        <CircularProgress
            size={80}
            thickness={5}
            sx={{ color: '#1a237e', mb: 3 }}
        />
        <Typography
            variant="h6"
            sx={{ fontWeight: 'bold', mb: 1 }}
        >
            Caricamento in corso...
        </Typography>
        <Typography
            variant="body2"
            sx={{ color: '#5c6bc0' }}
        >
            Attendere.
        </Typography>
    </Box>
);

export default CustomLoading;
