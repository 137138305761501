// src/AutomezziList.js
import React from "react";
import {
    List,
    TextField,
    EditButton,
    ShowButton,
    DatagridConfigurable,
    SelectColumnsButton,
    TopToolbar,
    ExportButton,
    WrapperField,
    CreateButton,
    useListContext,
    FilterList,
    FilterListItem,
    TextInput,
    SavedQueriesList
} from 'react-admin';

import {
    Card,
    CardContent,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Typography,
    Box
} from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PeopleIcon from '@mui/icons-material/People';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";

// Importo il css per i Bottoni
import { editButtonCss, showButtonCss, exportButtonCss, columnsButtonCss } from '../shared/Styles/buttonStyles';
import { listCss } from '../shared/Styles/listStyles';
import { ToBoolean } from '../shared/ToBoolean';

// Importo componenti input custom
import CustomTextInput from '../shared/FormFields/CustomTextInput';
import CustomNumberInput from '../shared/FormFields/CustomNumberInput';

// Filterlist dinamica
import FilterListDinamico from "../shared/CustomFields/FilterListDinamico";

// Campi custom 
import GetValoreCampo from '../shared/FormFields/GetValoreCampo';

/**
 * Componenti Personalizzati
 */

// Azioni della lista
const ListActions = () => (
    <Box width="100%">
        <TopToolbar>
            <CreateButton label="Crea" sx={exportButtonCss} />
            <ExportButton label="Esporta" sx={exportButtonCss} />
            <SelectColumnsButton sx={columnsButtonCss} />
        </TopToolbar>
    </Box>
);

const AutomezziFilters = [
    <CustomNumberInput source="amid" label="amid" alwaysOn ></CustomNumberInput>,
    <CustomTextInput source="amtarga" label="Targa" alwaysOn ></CustomTextInput>,
    <CustomNumberInput source="ampaxnrdw_s1" label="Posti a sedere" alwaysOn></CustomNumberInput>,
    <CustomNumberInput source="ampaxnrup_s2" label="Posti in piedi" alwaysOn></CustomNumberInput>,
    <CustomNumberInput source="ampaxnrdw_ass" label="Posti Assistente" alwaysOn></CustomNumberInput>,
    <CustomNumberInput source="ampaxnrtot" label="Totali" alwaysOn></CustomNumberInput>,
    <CustomNumberInput source="ampaxnrdw_tot" label="Totali a sedere" alwaysOn></CustomNumberInput>,
    <SavedQueriesList />
];

// Header dei filtri
const FilterHeader = ({ source }) => {
    const { setFilters, displayedFilters, filterValues } = useListContext();

    return (
        <TextInput
            label={`Filter by ${source}`}
            source={source}
            alwaysOn
            onChange={(event) => setFilters({ ...filterValues, [source]: event.target.value }, displayedFilters)}
        />
    );
};

/**
 * Sidebar dei Filtri con Accordion
 */
const AutomezziFilterSidebar = () => (
    <Card sx={{ order: -1, mr: 2, mt: 2, width: 300 }}>
        <CardContent>
            <SavedQueriesList label="Salva Filtro" icon={<AssignmentIcon />} />

            {/* Gruppo Status */}
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Status</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FilterList label="Status" icon={<AssignmentIcon />}>
                        <FilterListItem label="Attivi" value={{ flAttivo: 1 }} />
                        <FilterListItem label="Visibili" value={{ fiVisibile: 1 }} />
                        <FilterListItem label="Annullati" value={{ flAnnullato: 1 }} />
                    </FilterList>
                </AccordionDetails>
            </Accordion>

            {/* Gruppo Aereazione */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Aereazione</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FilterListDinamico service="view-tabellaalfa-aer" label="Aereazione" source="amtaereazione" />
                </AccordionDetails>
            </Accordion>

            {/* Gruppo Marca */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Marca</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FilterListDinamico service="view-tabellaalfa-mar" label="Marca" source="amtmarca_d1" />
                </AccordionDetails>
            </Accordion>
        </CardContent>
    </Card>
);

/**
 * Componente Principale della Lista
 */
export const AutomezziList = props => {
    return (
        <List
            title={<>&nbsp;Anagrafiche Automezzi&nbsp;</>}
            sx={listCss}
            perPage={50}
            actions={<ListActions />}
            filters={AutomezziFilters}
            aside={<AutomezziFilterSidebar />}
        >
            <DatagridConfigurable
                sx={listCss}
                omit={['note', 'gntypepc', 'gncodipc']}
                bulkActionButtons={false}
            >
                <TextField label="amid" source="amid" />
                <TextField label="Targa" source="amtarga" header={<FilterHeader source="amtarga" />} />
                <TextField label="Posti a sedere" source="ampaxnrdw_s1" />
                <TextField label="Posti assistente" source="ampaxnrdw_ass" />
                <TextField label="Posti in piedi" source="ampaxnrup_s2" />
                <TextField label="Posti Totali" source="ampaxnrtot" />
                <TextField label="Posti a sedere totali" source="ampaxnrdw_tot" />

                <GetValoreCampo label="Gruppo" source="amtgruppo" reference="tabellealfa" />
                <GetValoreCampo label="Marca" source="amtmarca_d1" reference="tabellealfa" />
                <WrapperField label="Organizzazione">
                    <GetValoreCampo label="Organizzazione" source="amtintest_c2" reference="tabellealfa" />
                </WrapperField>
                <WrapperField label="Categoria">
                    <GetValoreCampo label="Categoria" source="amtcategoria_j" reference="tabellealfa" />
                </WrapperField>
                <ToBoolean label="Attivo" source="flAttivo" />
                <ToBoolean label="Visibile" source="fiVisibile" />
                <ToBoolean label="Annullato" source="flAnnullato" />
                <EditButton sx={editButtonCss} label="Modifica" />
                <ShowButton sx={showButtonCss} label="Dettaglio" />
                <WrapperField label="Elimina">
                    <ConfirmDialogDeleteButtonCustom
                        titolo={"Cancella Automezzo"}
                        messaggio={"Sei sicuro di voler cancellare questo Automezzo?"}
                        servizio={"automezzi"}
                    />
                </WrapperField>
            </DatagridConfigurable>
        </List>
    );
}
