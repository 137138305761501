

/*  */
const required = (message = 'Parametro necessario.') =>
    value => value ? undefined : message;
const maxLength = (max, message = 'Caratteri massimi consentiti: ' + max) =>
    value => value && value.length > max ? message : undefined;
const minValue = (min, message = 'Caratteri minimi consentiti: ' + min) =>
    value => value && value.length < min ? message : undefined;
/*  */

export const validateNome = [required(), maxLength(100)];
export const validateCognome = [required(), maxLength(100)];
export const validateRicercaNome = [required(), maxLength(100)];



export const validateCreate = (values) => {
    const errors = {};
    if (!values.firstName) {
        errors.firstName = 'The firstName is required';
    }
    if (!values.age) {
        // You can return translation keys
        errors.age = 'ra.validation.required';
    } else if (values.age < 18) {
        // Or an object if the translation messages need parameters
        errors.age = {
            message: 'ra.validation.minValue',
            args: { min: 18 }
        };
    }
    // You can add a message for a whole ArrayInput
    if (!values.children || !values.children.length) {
        errors.children = 'ra.validation.required';
    } else {
        // Or target each child of an ArrayInput by returning an array of error objects
        errors.children = values.children.map(child => {
            const childErrors = {};
            if (!child || !child.firstName) {
                childErrors.firstName = 'The firstName is required';
            }
            if (!child || !child.age) {
                childErrors.age = 'ra.validation.required'; // Translation keys are supported here too
            }
            return childErrors;
        });
    }
    return errors
};
