
import { Edit, SimpleForm, TextInput, DateInput, NumberInput, TopToolbar, ShowButton, Toolbar, SaveButton } from 'react-admin';

// Import CSS
import { editButtonCss, saveButtonCss, showButtonCss, exportButtonCss, columnsButtonCss, deleteButtonCss, filterButtonCss } from '../shared/Styles/buttonStyles';
import { formCss } from "../shared/Styles/formCss";

const ShowTopToolbarDettaglio = () => (
    <TopToolbar>
        <ShowButton sx={showButtonCss} label="Dettaglio" />
    </TopToolbar>
);

const ShowActionsForEdit = () => (
    <ShowTopToolbarDettaglio></ShowTopToolbarDettaglio>
);

const MyToolbarEdit = () => (
    <Toolbar>
        <SaveButton sx={saveButtonCss} label="Salva parametri" alwaysEnable />
    </Toolbar>
);


export const ServpaxEdit = props => (
    <Edit
     actions={<ShowActionsForEdit />} 
    {...props}>
        <SimpleForm sx={formCss}  toolbar={<MyToolbarEdit />}>
            <NumberInput source="id" disabled />
            <TextInput source="descrizione"  />
            <TextInput source="note"  />
            <NumberInput source="flAttivo"  />
            <NumberInput source="flVisibile"  />
            <NumberInput source="flAnnullato"  />
            <NumberInput source="ordinamento"  />
            <DateInput source="createdAt"  />
            <DateInput source="updatedAt"  />
        </SimpleForm>
    </Edit>
);
