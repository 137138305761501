import * as React from "react";
import {
    List,
    EditButton,
    TextInput,
    Create,
    SimpleForm,
    TextField,
    Edit,
    ReferenceField,
    FunctionField,
    DateField,
    SimpleShowLayout,
    Show,
    DateInput,
    ShowButton,
    NumberInput,
    ReferenceManyField,
    useRecordContext,
    Loading,
    NumberField,
    DateTimeInput,
    TopToolbar,
    ExportButton,
    CreateButton,
    useListContext,
    Datagrid
} from 'react-admin';

import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import MyUrlField from '../MyUrlField';

// Importo il css per i Bottoni
import { editButtonCss, saveButtonCss, showButtonCss, exportButtonCss, columnsButtonCss, filterButtonCss } from '../shared/Styles/buttonStyles';
import { listCss } from '../shared/Styles/listStyles';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

/* Import necessari per google maps */
import {
    Box,
    ButtonGroup,
    HStack,
    IconButton,
    Input,
    Text,
} from '@chakra-ui/react'
// import { FaLocationArrow, FaTimes } from 'react-icons/fa'
import BookIcon from '@mui/icons-material/Book';
import ContentFilter from '@mui/icons-material/FilterList';

import axios from 'axios';

import {
    useJsApiLoader,
    GoogleMap,
    Marker,
    Autocomplete,
    DirectionsRenderer,
} from '@react-google-maps/api'
import { useRef, useState, useEffect } from 'react';
import GetValoreCampo from "../shared/FormFields/GetValoreCampo";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ExpandMoreOutlined } from "@mui/icons-material";
const center = { lat: 40.557156, lng: 8.321282 }

const GeneraTratta = ({ source, useJsApiLoader, bk0_idnumber, id }) => {

    const jwt = localStorage.getItem("feathers-jwt");

    const [map, setMap] = useState(/** @type google.maps.Map */(null));
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [distance, setDistance] = useState('');
    const [duration, setDuration] = useState('');
    const [latitudine, setLatitudine] = useState('');
    const [longitudine, setLongitudine] = useState('');

    /** @type React.MutableRefObject<HTMLInputElement> */
    const originRef = useRef()
    /** @type React.MutableRefObject<HTMLInputElement> */
    const destiantionRef = useRef()

    function refreshPage() {
        window.location.reload();
    }

    async function calculateRoute() {
        if (originRef.current.value === '' || destiantionRef.current.value === '') {
            return
        }
        // eslint-disable-next-line no-undef
        const directionsService = new google.maps.DirectionsService();
        const results = await directionsService.route({
            origin: originRef.current.value,
            destination: destiantionRef.current.value,
            // eslint-disable-next-line no-undef
            travelMode: google.maps.TravelMode.DRIVING
        })
        setDirectionsResponse(results)
        setDistance(results.routes[0].legs[0].distance.text)
        setDuration(results.routes[0].legs[0].duration.text)
        console.log(results);

        // query su booking_2 passando booking_0.id
        // Se restuisce valore incrementa di 1
        // Se non restituisce valore metti 1
        let configOne = {
            method: 'get',
            maxBodyLength: Infinity,
            // url: 'http://localhost:3030/booking-2?bk2_idnumber=' + bk0_idnumber + '&$sort[bk2_idriga]=-1&$limit=1',
            url: 'http://tuv.btechitalia.it:3030/booking-2?bk2_idnumber=' + bk0_idnumber + '&$sort[bk2_idriga]=-1&$limit=1',
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        };

        axios.request(configOne)
            .then((response) => {
                // console.log(JSON.stringify(response.data));
                console.log(response.data);
                var prossimoNumeroRiga;
                // console.log(response.data.data[0].bk2_idriga);
                if (
                    response.data.data.length === 0) {
                    prossimoNumeroRiga = 0;
                } else {
                    prossimoNumeroRiga = response.data.data[0].bk2_idriga;
                }

                var distanza = parseFloat(results.routes[0].legs[0].distance.text);

                var origine = results.routes[0].legs[0].start_address;
                var destinazione = results.routes[0].legs[0].end_address

                var lattStart = results.routes[0].legs[0].start_location.lat();
                var longStart = results.routes[0].legs[0].start_location.lng();

                var lattEnd = results.routes[0].legs[0].end_location.lat();
                var longEnd = results.routes[0].legs[0].end_location.lng();

                const urlGooglemaps = "http://www.google.com/maps?f=d&source=s_d&saddr=" + lattStart + "," + longStart + "&daddr=" + lattEnd + "," + longEnd + "&hl=it&sensor=false&language=it";

                console.log(urlGooglemaps);

                var tempoInSecondi = parseInt(results.routes[0].legs[0].duration.value);
                const hours = Math.floor(tempoInSecondi / 3600);
                tempoInSecondi -= hours * 3600;
                const minutes = Math.floor(tempoInSecondi / 60);
                tempoInSecondi -= minutes * 60;

                // Converte in stringa e aggiunge uno zero davanti se è un singolo numero
                const strHours = String(hours).padStart(2, '0');
                const strMinutes = String(minutes).padStart(2, '0');
                const strSeconds = String(tempoInSecondi).padStart(2, '0');

                var tempo = `${strHours}:${strMinutes}:${strSeconds}`;

                const currentDate = new Date();
                const year = currentDate.getFullYear();
                const month = String(currentDate.getMonth() + 1).padStart(2, '0');  // I mesi vanno da 0 a 11, quindi aggiungiamo 1
                const day = String(currentDate.getDate()).padStart(2, '0');

                const baseDate = `${year}-${month}-${day}T`;
                const baseTimezone = ".000Z";

                const combinedDateTime = `${baseDate}${tempo}${baseTimezone}`;

                let data = JSON.stringify({
                    "booking0Id": id,
                    "bk2_idnumber": bk0_idnumber,
                    "bk2_idstatus": 7,
                    "bk2_idserie": 0,
                    "bk2_idriga": ++prossimoNumeroRiga,
                    "bk2_datastart": "2023-05-19T09:30:00.000Z",
                    "bk2_ore": strHours,
                    "bk2_minuti": strMinutes,
                    "bk2_secondi": strSeconds,
                    "bk2_partenza": origine,
                    "bk2_arrivo": destinazione,
                    "bk2_pkmt": distanza,
                    "bk2_ptime": combinedDateTime,
                    "bk2_purl": urlGooglemaps
                });

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    // url: 'http://localhost:3030/booking-2',
                    url: 'http://tuv.btechitalia.it:3030/booking-2',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + jwt
                    },
                    data: data
                };

                axios.request(config)
                    .then((response) => {
                        console.log(JSON.stringify(response.data));
                        refreshPage();
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (<><Box
        p={4}
        borderRadius='lg'
        m={4}
        bgColor='white'
        shadow='base'
        minW='container.md'
        zIndex='1'
    >
        <HStack spacing={2} justifyContent='space-between'>
            <Box flexGrow={1}>
                <Autocomplete>
                    <Input type='text' placeholder='Origin' ref={originRef} />
                </Autocomplete>
            </Box>
            <Box flexGrow={1}>
                <Autocomplete>
                    <Input
                        type='text'
                        placeholder='Destination'
                        ref={destiantionRef}
                    />
                </Autocomplete>
            </Box>

            <ButtonGroup>
                {/*<Button type='submit' onClick={(e) => {
                    e.preventDefault();
                    calculateRoute();
                }}>
                    Calcola percorso
                </Button>
                 <IconButton
                    aria-label='center back'
                    icon={<BookIcon />}
                    onClick={clearRoute}
                /> */}
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" type='submit' onClick={(e) => {
                        e.preventDefault();
                        calculateRoute();
                    }}>
                        Calcola e aggiungi
                    </Button>
                </Stack>
            </ButtonGroup>
        </HStack>
        <HStack spacing={4} mt={4} justifyContent='space-between'>
            <Text>Distanza: {distance} </Text>
            <Text>Durata: {duration} </Text>
            <IconButton
                aria-label='center back'
                icon={<BookIcon />}
                isRound
                onClick={() => {
                    map.panTo(center)
                    map.setZoom(15)
                }}
            />
        </HStack>
    </Box></>
    )

}



const ListActions = () => (
    <TopToolbar>
        <ExportButton sx={columnsButtonCss} />
        <CreateButton sx={exportButtonCss} />
    </TopToolbar>
);

export const Booking0List = props => {

    return (
        <List title={<>
            <Button>
                <InsertInvitationIcon></InsertInvitationIcon>
                &nbsp;Booking - Gestione Tratte
            </Button>
        </>} filters={Booking0Filters} actions={<ListActions />} exporter={true} {...props}>
            <EditableDatagrid
                sx={listCss}
                noDelete
                editForm={<Booking0Form />}
            >

                {<TextField label="id" source="id" />}
                {<TextField label="bk0_idnumber" source="bk0_idnumber" />}
                {/*                 {<DateField label="Data Start" source="dataStart" />}
                {<DateField label="Data End" source="dataEnd" />}
                {<TextField label="Categoria" source="categoria" />}
                {<TextField label="Note apertura" source="noteApertura" />}
                {<TextField label="Note chiusura" source="noteChiusura" />}
                {<TextField label="Eseguito" source="eseguito" />}
                {<TextField label="Da notificare" source="daNotificare" />}
                {<TextField label="Notificato" source="notificato" />} */}
                {/*                 <ReferenceField link={false} label="Operatore" source="operatoriId" reference="operatori">
                    <FunctionField render={record => record && `${record.cognome} ${record.nome}`} />
                </ReferenceField> */}

                {<EditButton sx={editButtonCss} label="Modifica" />}
                {<ShowButton sx={showButtonCss} label="Dettaglio" />}
            </EditableDatagrid>
        </List>
    );
}

export const Booking0Create = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary"><br></br>
                                {<NumberInput label="bk0_idstatus" source="bk0_idstatus" />}<br></br>
                                {<NumberInput label="bk0_idnumber" source="bk0_idnumber" />}<br></br>
                                {<NumberInput label="bk0_idserie" source="bk0_idserie" />}<br></br>
                                {<DateInput label="bk0_iddata" source="bk0_iddata" />}<br></br>
                                {<TextInput label="bk0_iduser" source="bk0_iduser" />}<br></br>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {<TextInput label="bk0_cliente" source="bk0_cliente" />}<br></br>
                                {<TextInput label="bk0_clientecod" source="bk0_clientecod" />}<br></br>
                                {<TextInput label="bk0_contatto" source="bk0_contatto" />}<br></br>
                                {<NumberInput label="bk0_richiestatipo" source="bk0_richiestatipo" />}<br></br>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);

export const Booking0Edit = (props) => {

    console.log(props);
    if (!props.isLoaded) {
        return <div><Loading /></div>;
    }

    return (
        <Edit {...props}>
            <SimpleForm redirect="list">
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary"><br></br>
                                    {<NumberInput label="bk0_idstatus" source="bk0_idstatus" />}<br></br>
                                    {<NumberInput label="bk0_idnumber" source="bk0_idnumber" />}<br></br>
                                    {<DateInput label="bk0_iddata" source="bk0_iddata" />}<br></br>
                                    {<TextInput label="bk0_iduser" source="bk0_iduser" />}<br></br>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    {<TextInput label="bk0_cliente" source="bk0_cliente" />}<br></br>
                                    {<TextInput label="bk0_clientecod" source="bk0_clientecod" />}<br></br>
                                    {<TextInput label="bk0_contatto" source="bk0_contatto" />}<br></br>
                                    {<NumberInput label="bk0_richiestatipo" source="bk0_richiestatipo" />}<br></br>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <p></p>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <ListaTratteBooking2></ListaTratteBooking2>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>


            </SimpleForm>
        </Edit>
    );
}

export const Booking0Show = (props) => {
    return (
        <Show>
            <SimpleShowLayout>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    <h3>Data : </h3>{<DateField label="Data" source="data" />}<br></br>
                                    <h3>Data Start : </h3>{<DateField label="Data Start" source="dataStart" />}<br></br>
                                    <h3>Data End : </h3>{<DateField label="Data End" source="dataEnd" />}<br></br>
                                    <h3>Categoria : </h3>{<TextField label="Categoria" source="categoria" />}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    <h3>Note apertura : </h3>{<TextField label="Note apertura" source="noteApertura" />}<br></br>
                                    <h3>Note chiusura : </h3>{<TextField label="Note chiusura" source="noteChiusura" />}<br></br>
                                    <h3>Eseguito : </h3>{<TextField label="Eseguito" source="eseguito" />}<br></br>
                                    <h3>Da notificare : </h3>{<TextField label="Da notificare" source="daNotificare" />}<br></br>
                                    <h3>Notificato : </h3>{<TextField label="Notificato" source="notificato" />}<br></br>
                                    <h3>Operatore : </h3><ReferenceField link={false} label="Operatore" source="operatoriId" reference="operatori">
                                        <FunctionField render={record => record && `${record.cognome} ${record.nome}`} />
                                    </ReferenceField>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </SimpleShowLayout>
        </Show>
    );
}


const Booking0Form = (props) => (
    <RowForm {...props}>
        {<DateInput label="Data" source="data" />}
        {<DateInput label="Data Start" source="dataStart" />}
        {<DateInput label="Data End" source="dataEnd" />}
        {<TextInput label="Categoria" source="categoria" />}
        {<TextInput label="Note apertura" source="noteApertura" />}
        {<TextInput label="Note chiusura" source="noteChiusura" />}
        {<TextInput label="Eseguito" source="eseguito" />}
        {<TextInput label="Da notificare" source="daNotificare" />}
        {<TextInput label="Notificato" source="notificato" />}
    </RowForm>
);

const Booking0Filters = [
    <DateInput label="Seleziona data" source="data" />,
    <DateInput label="Seleziona dataStart" source="dataStart" />,
    <DateInput label="Seleziona dataEnd" source="dataEnd" />,
    <TextInput label="Categoria" source="categoria" />,
    <TextInput label="Note apertura" source="noteApertura" />,
    <TextInput label="Note chiusura" source="noteChiusura" />,
    <TextInput label="Eseguito" source="eseguito" />,
    <TextInput label="Da notificare" source="daNotificare" />,
    <TextInput label="Notificato" source="notificato" />
];

const ListaTratteBooking2 = ({ source }) => {

    const record = useRecordContext();

    return <>
        <h2>Lista tratte Booking2</h2>
        <p> <GeneraTratta bk0_idnumber={record["bk0_idnumber"]} id={record["id"]} /></p>

        <ReferenceManyField
            label="Lista Automezzi"
            reference="booking-0-automezzi"
            target="booking0Id"
        >
            <EditableDatagrid bulkActionButtons={false}>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreOutlined />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        ID booking-0-automezzi : <TextField source="id" /> -
                        <GetValoreCampo label="Gruppo" source="gruppoId" reference="tabellealfa" />
                    </AccordionSummary>
                    <AccordionDetails>
                        <ReferenceManyField
                            reference="booking-2"
                            target="booking0_automezziId"
                            fullWidth
                            perPage={10}
                            sort={{ field: 'id', order: 'ASC' }}
                        >

                            <EditableDatagrid

                                bulkActionButtons={false}
                                // createForm={<PresaInCaricoRowFormCard defaultValues={{ booking0Id: record["id"] }} />}
                                editForm={<PercorsoRowFormCard defaultValues={{ booking0Id: record["id"] }} />}
                            >

                                {/* <TextField source="bk2_idriga" /> */}
                                <GetOrario source="bk2_datastart" label="Data" />
                                <TextField source="bk2_partenza" label="Partenza" />
                                <TextField source="bk2_arrivo" label="Arrivo" />
                                <NumberField source="bk2_pkmt" label="Distanza (Km)" />
                                {/* <GetOrarioTempoPercorrenza source="bk2_ptime" label="Tempo (hh:mm)" /> */}
                                <TimeDisplayComponent label="Tempo (hh:mm)"></TimeDisplayComponent>
                                <MyUrlField source="bk2_purl" label="Mappa"></MyUrlField>
                            </EditableDatagrid>
                        </ReferenceManyField>
                    </AccordionDetails>
                </Accordion>

            </EditableDatagrid>
        </ReferenceManyField>

    </>;
}

const ore = Array.from({ length: 24 }, (v, i) => ({
    id: i,
    name: i.toString().padStart(2, '0') // per avere numeri come "01", "02", ..., "23"
}));

const minuti = Array.from({ length: 60 }, (v, i) => ({
    id: i,
    name: i.toString().padStart(2, '0') // per avere numeri come "01", "02", ..., "59"
}));

const secondi = Array.from({ length: 60 }, (v, i) => ({
    id: i,
    name: i.toString().padStart(2, '0') // per avere numeri come "01", "02", ..., "59"
}));

const selectInputStyle = {

    padding: '0',         // Riduci il padding
};



const TimeInputComponent = ({ source }) => {
    const record = useRecordContext();
    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [seconds, setSeconds] = useState('');

    useEffect(() => {
        if (record && record[source]) {
            const date = new Date(record[source]);
            setHours(date.getUTCHours().toString().padStart(2, '0'));
            setMinutes(date.getUTCMinutes().toString().padStart(2, '0'));
            setSeconds(date.getUTCSeconds().toString().padStart(2, '0'));
        }
    }, [record, source]);

    const handleInputChange = (type, event) => {
        const val = event.target.value;

        if (type === 'hours' && (val >= 0 && val <= 23)) {
            setHours(val);
        } else if (type === 'minutes' && (val >= 0 && val <= 59)) {
            setMinutes(val);
        } else if (type === 'seconds' && (val >= 0 && val <= 59)) {
            setSeconds(val);
        }
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <NumberInput
                source="bk2_ore"
                value={hours}
                onChange={(e) => handleInputChange('hours', e)}
                style={{ width: '70px' }}
                min={0}
                max={23}
                label="Ore"
            />

            <span>:</span>

            <NumberInput
                source="bk2_minuti"
                value={minutes}
                onChange={(e) => handleInputChange('minutes', e)}
                style={{ width: '70px' }}
                min={0}
                max={59}
                label="Minuti"
            />

            <span>:</span>

            <NumberInput
                source="bk2_secondi"
                value={seconds}
                onChange={(e) => handleInputChange('seconds', e)}
                style={{ width: '70px' }}
                min={0}
                max={59}
                label="Secondi"
            />
        </div>
    );
};

const TimeDisplayComponent = () => {
    const record = useRecordContext();

    const hours = record.bk2_ore ? record.bk2_ore.toString().padStart(2, '0') : '';
    const minutes = record.bk2_minuti ? record.bk2_minuti.toString().padStart(2, '0') : '';
    const seconds = record.bk2_secondi ? record.bk2_secondi.toString().padStart(2, '0') : '';

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <TextField
                source="bk2_ore"
                record={{ bk2_ore: hours }}
                label="Ore"
            />

            <span>:</span>

            <TextField
                source="bk2_minuti"
                record={{ bk2_minuti: minutes }}
                label="Minuti"
            />

            <span>:</span>

            <TextField
                source="bk2_secondi"
                record={{ bk2_secondi: seconds }}
                label="Secondi"
            />
        </div>
    );
};

export const PercorsoRowFormCard = ({ booking0Id, ...props }) => {
    return (
        <RowForm {...props}>
            <DateTimeInput source="bk2_datastart" label="" />
            <TextField source="bk2_partenza" />
            <TextField source="bk2_arrivo" />
            <NumberInput source="bk2_pkmt" label="" />
            {/* <TimeInput source="bk2_ptime" label="" /> */}
            {/*   <SelectInput source="bk2_ore" choices={ore} label="Ore" style={selectInputStyle}></SelectInput>
            <SelectInput source="bk2_minuti" choices={minuti} label="Minuti" style={selectInputStyle}></SelectInput>
            <SelectInput source="bk2_secondi" choices={secondi} label="secondi" style={selectInputStyle}></SelectInput>
             */}
            <TimeInputComponent source="bk2_ptime" />
            <MyUrlField source="bk2_purl"></MyUrlField>
        </RowForm>
    );
}

const GetOrario = ({ source }) => {

    const record = useRecordContext();

    const date = new Date(record[source]); // Inserisci la data desiderata
    const timezoneOffset = date.getTimezoneOffset(); // Calcola l'offset del fuso orario in minuti
    const hoursOffset = -Math.floor(timezoneOffset / 60); // Calcola l'offset in ore arrotondando verso il basso
    const minutesOffset = Math.abs(timezoneOffset) % 60; // Calcola i minuti di offset rimanenti

    const localHours = date.getUTCHours() + hoursOffset; // Calcola le ore locali aggiungendo l'offset in ore
    const localMinutes = date.getUTCMinutes() + minutesOffset; // Calcola i minuti locali aggiungendo l'offset in minuti

    const localTime = `${localHours}:${localMinutes < 10 ? '0' : ''}${localMinutes}`; // Formatta l'ora locale come "hh:mm"

    // console.log(localTime); // Stampa l'ora locale


    return record[source] ?
        <>{localTime}
        </>
        : <>
        </>;
}

const GetOrarioTempoPercorrenza = ({ source }) => {
    const record = useRecordContext();
    const date = new Date(record[source]);

    const utcHours = date.getUTCHours();
    const utcMinutes = date.getUTCMinutes();

    const utcTime = `${String(utcHours).padStart(2, '0')}:${String(utcMinutes).padStart(2, '0')}`; // Formatta l'ora UTC come "hh:mm"

    return record[source] ? <>{utcTime}</> : <></>;
}
