// preventivi/index.js
import React from 'react';
import { ListGuesser } from 'react-admin';
import { PreventiviList } from './preventiviList';
import { PreventiviShow } from './preventiviShow';

export default {
    list: PreventiviList,
    // Se desideri, puoi aggiungere create, edit, show
    // create: PreventiviCreate,
    // edit: PreventiviEdit,
    show: PreventiviShow,
};
