import React, { useState } from 'react';
import {
    Show,
    SimpleShowLayout,
    TopToolbar,
    TextField,
    EditButton,
    TabbedShowLayout,
    SelectInput,
    ReferenceInput,
    TextInput,
    useRecordContext,
    ReferenceManyField,
    ReferenceField,
    BooleanInput,
    DateField,
    NumberField
} from 'react-admin';
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import { ToBoolean } from '../shared/ToBoolean';
import { editButtonCss } from '../shared/Styles/buttonStyles';
import { fieldBaseForShow } from '../shared/Styles/fieldsStyles';
import PeopleIcon from '@mui/icons-material/People';
import Button from '@mui/material/Button';
// import { Breadcrumb } from '@react-admin/ra-navigation';
import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";
import { formCss } from "../shared/Styles/formCss";
import Grid from '@mui/material/Grid';
import CustomTextFieldDataOra from "../shared/FormFields/CustomTextFieldDataOra";
import { Divider } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AutocompleteComponent from '../shared/GoogleMaps/AutocompleteComponent';

const ShowActions = () => (
    <ShowTopToolbarDettaglio></ShowTopToolbarDettaglio>
);

const ShowTopToolbarDettaglio = () => (
    <TopToolbar>
        <EditButton sx={editButtonCss} label="Modifica" />
    </TopToolbar>
);

export const pacchettiShow = () => (
    <Show
        title={
            <Button startIcon={<PeopleIcon />}>
                Dettaglio record di pacchetti
            </Button>
        }
        actions={<ShowActions />}
    >

        <SimpleShowLayout sx={formCss}>
            <TabbedShowLayout sx={formCss}>
                <TabbedShowLayout.Tab label="Anagrafica Base">
                    <Grid container spacing={3}>
                        {/* Colonna sinistra */}
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>Informazioni Generali</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2">ID</Typography>
                                            <TextField source="id" sx={fieldBaseForShow} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2">Anagrafica</Typography>
                                            <ReferenceField link={null} label="" source="anagenId" reference="anagen" sx={fieldBaseForShow} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2">Nome Pacchetto</Typography>
                                            <TextField source="nome" sx={fieldBaseForShow} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2">Descrizione</Typography>
                                            <TextField source="descrizione" sx={fieldBaseForShow} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2">Numero Minimo</Typography>
                                            <NumberField source="numMinimo" sx={fieldBaseForShow} />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>

                            <Card sx={{ mt: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>Indirizzi</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2">Indirizzo Prelievo</Typography>
                                            <TextField source="indirizzoPrelievo" sx={fieldBaseForShow} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2">Indirizzo Destinazione</Typography>
                                            <TextField source="indirizzoDestinazione" sx={fieldBaseForShow} />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Colonna destra */}
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>Date</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2">Data Inizio</Typography>
                                            <DateField source="dataFrom" sx={fieldBaseForShow} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2">Data Fine</Typography>
                                            <DateField source="dataTo" sx={fieldBaseForShow} />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>

                            <Card sx={{ mt: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>Dettagli Aggiuntivi</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2">Condizioni Particolari</Typography>
                                            <TextField source="condizioniParticolari" sx={fieldBaseForShow} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2">Extras Promozione</Typography>
                                            <TextField source="extrasPromozione" sx={fieldBaseForShow} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2">Note</Typography>
                                            <TextField source="note" sx={fieldBaseForShow} />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Proprieta" path="proprieta">
                    <div>
                        <div>
                            {<CustomTextFieldDataOra label="Data creazione" source="createdAt" />}
                            <br></br>
                            {<CustomTextFieldDataOra label="Data ultima modifica" source="updatedAt" />}
                        </div>
                    </div>
                    <div>
                        {<ToBoolean label="Attivo" source="flAttivo" labelOn={true}></ToBoolean>}
                        {<ToBoolean label="Visibile" source="fiVisibile" labelOn={true}></ToBoolean>}
                        {<ToBoolean label="Annullato" source="flAnnullato" labelOn={true}></ToBoolean>}
                    </div>
                    <Divider variant="middle" />
                    <ConfirmDialogDeleteButtonCustom
                        titolo={"Cancella Pacchetto"}
                        messaggio={"Sei sicuro di vole cancellare questo Pacchetto ?"}
                        servizio={"pacchetti"} />
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Pacchetti Servpax" path="pacchettiServpax">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            {<GetListPacchettiServpax></GetListPacchettiServpax>}
                        </Grid>
                    </Grid>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Punti di prelievo" path="prelievoServpax">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            {<GetListPacchettiPartenze></GetListPacchettiPartenze>}
                        </Grid>
                    </Grid>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Punti di destinazione" path="destinazioneServpax">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            {<GetListPacchettiArrivi></GetListPacchettiArrivi>}
                        </Grid>
                    </Grid>
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>
        </SimpleShowLayout>
    </Show>
);

/* Blocco di codice per la gestione dei pacchetti_servpax - inizio */

const GetListPacchettiServpax = (props) => {
    const record = useRecordContext();
    const gnfind = record.gnfind;
    if (!record) return null;

    return (
        <ReferenceManyField
            reference="pacchetti-servpax"
            target="pacchettiId"
        >
            <EditableDatagrid
                bulkActionButtons={false}
                editForm={<PacchettiServpaxRowForm isCreate={false} id={record.id} anagenId={record.anagenId} />}
                createForm={<PacchettiServpaxRowForm isCreate={true} id={record.id} anagenId={record.anagenId} />}
            >
                <ReferenceField label="Servizio" source="servpaxId" reference="servpax">
                    <TextField source="descrizione" />
                </ReferenceField>
                <TextField label="Note" source="note" sx={fieldBaseForShow} />
                <ToBoolean label="Attivo" source="flAttivo"></ToBoolean>
            </EditableDatagrid>
        </ReferenceManyField>
    );
}

const PacchettiServpaxRowForm = ({ isCreate, ...props }) => {
    return (
        <RowForm {...props}>
            {isCreate && <TextInput source="pacchettiId" defaultValue={parseInt(props.id)} disabled style={{ display: 'none' }} />}
            <ReferenceInput fullWidth
                sx={{ mb: 2 }}
                label="Servizi Abilitati"
                source="servpaxId"
                reference="view-fornitori-servpax"
                filter={{ anagenId: parseInt(props.anagenId) }}
                perPage={null}
            >
                <SelectInput optionText="descrizione" />
            </ReferenceInput>
            <TextInput label="Note" source="note" sx={fieldBaseForShow} />
            <BooleanInput label="Attivo" source="flAttivo"></BooleanInput>
        </RowForm>
    );
}

/* Blocco di codice per la gestione dei pacchetti_servpax - fine */


/* Blocco di codice per la gestione dei pacchetti_partenze - inizio */

const GetListPacchettiPartenze = (props) => {
    const record = useRecordContext();
    const gnfind = record.gnfind;
    if (!record) return null;

    return (
        <ReferenceManyField
            reference="pacchetti-partenze"
            target="pacchettiId"
        >
            <EditableDatagrid
                bulkActionButtons={false}
                editForm={<PacchettiPartenzeRowForm isCreate={false} id={record.id} anagenId={record.anagenId} />}
                createForm={<PacchettiPartenzeRowForm isCreate={true} id={record.id} anagenId={record.anagenId} />}
            >
                <TextField label="" source="id" style={{ display: 'none' }} />
                <TextField label="Nome" source="nome" sx={fieldBaseForShow} />
                <TextField label="Indirizzo completo" source="indirizzoCompleto" sx={fieldBaseForShow} />
                <ToBoolean label="Attivo" source="flAttivo"></ToBoolean>
            </EditableDatagrid>
        </ReferenceManyField>
    );
}

const PacchettiPartenzeRowForm = ({ isCreate, ...props }) => {
    return (
        <RowForm {...props}>
            {isCreate && <TextInput source="pacchettiId" defaultValue={parseInt(props.id)} disabled style={{ display: 'none' }} />}

            <AutocompleteComponent
                source="nome"
                label="Nome"
                placeholder="Cerca e seleziona un luogo"
                onSelect={(value) => props.form.change('nome', value)}
            />
            <TextInput label="Indirizzo completo" source="indirizzoCompleto" sx={fieldBaseForShow} />
            <BooleanInput label="Attivo" source="flAttivo" />
        </RowForm>
    );
};

/* Blocco di codice per la gestione dei pacchetti_partenze - fine */

/* Blocco di codice per la gestione dei pacchetti_arrivi - inizio */

const GetListPacchettiArrivi = (props) => {
    const record = useRecordContext();
    const gnfind = record.gnfind;
    if (!record) return null;

    return (
        <ReferenceManyField
            reference="pacchetti-arrivi"
            target="pacchettiId"
        >
            <EditableDatagrid
                bulkActionButtons={false}
                editForm={<PacchettiArriviRowForm isCreate={false} id={record.id} anagenId={record.anagenId} />}
                createForm={<PacchettiArriviRowForm isCreate={true} id={record.id} anagenId={record.anagenId} />}
            >
                <TextField label="" source="id" style={{ display: 'none' }} />
                <TextField label="Nome" source="nome" sx={fieldBaseForShow} />
                <TextField label="Indirizzo completo" source="indirizzoCompleto" sx={fieldBaseForShow} />
                <ToBoolean label="Attivo" source="flAttivo"></ToBoolean>
            </EditableDatagrid>
        </ReferenceManyField>
    );
}

const PacchettiArriviRowForm = ({ isCreate, ...props }) => {
    return (
        <RowForm {...props}>
            {isCreate && <TextInput source="pacchettiId" defaultValue={parseInt(props.id)} disabled style={{ display: 'none' }} />}

            <AutocompleteComponent
                source="nome"
                label="Nome"
                placeholder="Cerca e seleziona un luogo"
                onSelect={(value) => props.form.change('nome', value)}
            />
            <TextInput label="Indirizzo completo" source="indirizzoCompleto" sx={fieldBaseForShow} />
            <BooleanInput label="Attivo" source="flAttivo" />
        </RowForm>
    );
};

/* Blocco di codice per la gestione dei pacchetti_arrivi - fine */
