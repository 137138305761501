import React, { useState } from 'react';
import {
    useNotify,
    useGetOne,
    useUpdate,
    Loading,
    Error,
} from 'react-admin';
import {
    Card,
    CardContent,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    CircularProgress,
    Grid,
    Chip,
    CardActions,
    Avatar,
    Box,
    LinearProgress,
    InputAdornment
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import WorkIcon from '@mui/icons-material/Work';
import LockIcon from '@mui/icons-material/Lock';
import {
    cancelButtonCss,
    editButtonCss,
    saveButtonCss,
    uploadButtonCss
} from '../shared/Styles/buttonStyles';

// Stili aggiornati
const useStyles = makeStyles((theme) => ({
    card: {
        margin: 'auto',
        maxWidth: 600,
        marginTop: '2em',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    },
    content: {
        padding: theme.spacing(3),
    },
    title: {
        marginBottom: theme.spacing(3),
        color: "#0077be",
        textAlign: 'center',
    },
    field: {
        marginBottom: theme.spacing(2),
    },
    infoItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    icon: {
        marginRight: theme.spacing(1),
        color: theme.palette.secondary.main,
    },
    chip: {
        marginTop: theme.spacing(1),
    },
    avatarContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    avatar: {
        width: 150,
        height: 150,
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    uploadButton: {
        marginBottom: theme.spacing(2),
    },
    status: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    statusIcon: {
        height: 12,
        width: 12,
        borderRadius: '50%',
        marginRight: theme.spacing(1),
    },
    green: {
        backgroundColor: 'green',
    },
    red: {
        backgroundColor: 'red',
    },
    passwordStrength: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
}));

// Funzione per calcolare la forza della password
const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (password.match(/[a-z]/) && password.match(/[A-Z]/)) strength++;
    if (password.match(/\d/)) strength++;
    if (password.match(/[^a-zA-Z\d]/)) strength++;
    return strength;
};

const DatiPersonali = () => {
    const classes = useStyles();
    const notify = useNotify();
    const userId = localStorage.getItem("RaStore.profile.idUser");

    const { data: userData, loading, error, refetch } = useGetOne('users', { id: userId });
    const [update, { loading: updateLoading }] = useUpdate();

    const [open, setOpen] = useState(false);
    const [editData, setEditData] = useState({});
    const [passwordStrength, setPasswordStrength] = useState(0);

    const handleOpen = () => {
        setEditData(userData);
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEditData({ ...editData, [name]: value });
        if (name === 'password') {
            setPasswordStrength(calculatePasswordStrength(value));
        }
    };

    const handleSubmit = () => {
        const updatedData = { ...editData };
        update(
            'users',
            { id: userId, data: updatedData, previousData: userData },
            {
                onSuccess: () => {
                    notify('Dati utente aggiornati con successo', 'success');
                    setOpen(false);
                    refetch();
                },
                onFailure: (error) => {
                    notify(error.message || 'Errore nell\'aggiornamento dei dati utente', 'error');
                },
            }
        );
    };

    const handleUpload = () => {
        notify('Funzionalità di upload in arrivo!', 'info');
    };

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!userData) {
        return (
            <Card className={classes.card}>
                <CardContent>
                    <Typography>Nessun dato disponibile.</Typography>
                </CardContent>
            </Card>
        );
    }

    return (
        <Card className={classes.card}>
            <CardContent className={classes.content}>
                <Typography variant="h4" className={classes.title}>I tuoi dati personali</Typography>
                <div className={classes.avatarContainer}>
                    <Avatar className={classes.avatar}>
                        <AccountCircleIcon fontSize="large" />
                    </Avatar>
                    <Button
                        variant="contained"
                        component="label"
                        sx={uploadButtonCss}
                        onClick={handleUpload}
                    >
                        Carica Foto
                    </Button>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12} className={classes.infoItem}>
                        <AccountCircleIcon className={classes.icon} />
                        <Typography><strong>Nome e Cognome:</strong> {userData.nome} {userData.cognome}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.infoItem}>
                        <EmailIcon className={classes.icon} />
                        <Typography><strong>Email:</strong> {userData.email}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.infoItem}>
                        <PhoneIcon className={classes.icon} />
                        <Typography><strong>Telefono:</strong> {userData.telefono}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.infoItem}>
                        <WorkIcon className={classes.icon} />
                        <Typography><strong>Ruolo:</strong> {userData.roles}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.status}>
                        <div className={`${classes.statusIcon} ${userData.status === 1 ? classes.green : classes.red}`} />
                        <Typography><strong>Status:</strong> {userData.status === 1 ? 'Attivo' : 'Disattivo'}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Chip
                            label={`Creato il: ${new Date(userData.createdAt).toLocaleString()}`}
                            className={classes.chip}
                            color="primary"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Chip
                            label={`Aggiornato il: ${new Date(userData.updatedAt).toLocaleString()}`}
                            className={classes.chip}
                            color="secondary"
                            variant="outlined"
                        />
                    </Grid>
                </Grid>

                <CardActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpen}
                        sx={editButtonCss}
                        fullWidth
                    >
                        Modifica Dati
                    </Button>
                </CardActions>
            </CardContent>

            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle>Modifica Dati Personali</DialogTitle>
                <DialogContent>
                    <TextField
                        className={classes.field}
                        fullWidth
                        label="Nome"
                        name="nome"
                        value={editData.nome || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        className={classes.field}
                        fullWidth
                        label="Cognome"
                        name="cognome"
                        value={editData.cognome || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        className={classes.field}
                        fullWidth
                        label="Email"
                        name="email"
                        value={editData.email || ''}
                        onChange={handleChange}
                        disabled
                    />
                    <TextField
                        className={classes.field}
                        fullWidth
                        label="Telefono"
                        name="telefono"
                        value={editData.telefono || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        className={classes.field}
                        fullWidth
                        type="password"
                        label="Nuova Password"
                        name="password"
                        value={editData.password || ''}
                        onChange={handleChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Box className={classes.passwordStrength}>
                        <LinearProgress
                            variant="determinate"
                            value={passwordStrength * 25}
                            sx={{
                                '& .MuiLinearProgress-bar': {
                                    backgroundColor:
                                        passwordStrength <= 1 ? '#f44336' :
                                            passwordStrength === 2 ? '#ff9800' :
                                                passwordStrength === 3 ? '#4caf50' : '#2196f3'
                                }
                            }}
                        />
                        <Typography variant="caption">
                            Forza password: {
                                passwordStrength <= 1 ? 'Debole' :
                                    passwordStrength === 2 ? 'Media' :
                                        passwordStrength === 3 ? 'Forte' : 'Molto forte'
                            }
                        </Typography>
                    </Box>
                    <TextField
                        className={classes.field}
                        fullWidth
                        label="Ruolo"
                        name="roles"
                        value={editData.roles || ''}
                        disabled
                    />
                    <TextField
                        className={classes.field}
                        fullWidth
                        label="Status"
                        name="status"
                        value={editData.status || ''}
                        disabled
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} sx={cancelButtonCss} color="primary" disabled={updateLoading}>
                        Annulla
                    </Button>
                    <Button onClick={handleSubmit} sx={saveButtonCss} color="primary" variant="contained" disabled={updateLoading}>
                        {updateLoading ? <CircularProgress size={24} /> : 'Salva'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
};

export default DatiPersonali;