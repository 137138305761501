import * as React from 'react';
import { useState } from 'react';
import {
    TextField,
    ReferenceManyField,
    useRecordContext,
    NumberField,
    useRefresh,
    useNotify,
    FunctionField,
    useGetList,
    Datagrid,
    ReferenceField,
    BooleanField,
} from 'react-admin';
import {
    Alert,
    CircularProgress,
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button as MuiButton,
    Paper,
    Grid,
    Chip,
} from '@mui/material';
import {
    DirectionsCar,
    AccessTime,
    Add as AddIcon,
    ExpandMoreOutlined,
    Map as MapIcon
} from '@mui/icons-material';
import AddRouteDialog from './AddRouteDialog';
import EditRouteDialog from './EditRouteDialog';
import AddVehicleDialog from './AddVehicleDialog';
import MyUrlField from '../MyUrlField';
import GetValoreCampo from "../shared/FormFields/GetValoreCampo";
import { createButtonCss } from '../shared/Styles/buttonStyles';
import { DeleteButtonCustomWithRefresh } from '../shared/DeleteButtonCustomWithRefresh';
import EditableTimeComponent from './EditableTimeComponent';
import TariffSelectionDialog from './tariffe/TariffSelectionDialog';
import SelectedTariffCard from './tariffe/SelectedTariffCard';
import SelectedTariffBInfo from './tariffe/SelectedTariffBCard';
import TariffBSelectionDialog from './tariffe/TariffBSelectionDialog';
import Simulatore from '../tariffea/simulatore/simulatore';
import TrattaKmVuotoToggle from './TrattaKmVuotoToggle';
import TrattaSupplementoNotturno from './TrattaSupplementoNotturno';

// Componente per formattare l'orario
const GetOrario = ({ source }) => {
    const record = useRecordContext();
    if (!record || !record[source]) return null;

    const date = new Date(record[source]);
    const formattedDate = date.toLocaleString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });

    return <Typography>{formattedDate}</Typography>;
};

// Componente per visualizzare il tempo
const TimeDisplayComponent = () => {
    const record = useRecordContext();
    if (!record) return null;

    const hours = record.bk2_ore ? record.bk2_ore.toString().padStart(2, '0') : '00';
    const minutes = record.bk2_minuti ? record.bk2_minuti.toString().padStart(2, '0') : '00';

    return (
        <Typography>
            {hours}:{minutes}
        </Typography>
    );
};

// Componente per calcolare e visualizzare i totali
const TotalKilometersAndTime = () => {
    const record = useRecordContext();
    const { data, isLoading, error } = useGetList(
        'booking-2',
        {
            filter: { booking0_automezziId: record.id },
            sort: { field: 'bk2_idriga', order: 'ASC' },
            pagination: { page: 1, perPage: 1000 },
        }
    );

    if (isLoading) {
        return <CircularProgress size={20} />;
    }

    if (error) {
        return <Typography color="error">Errore nel caricamento dei dati</Typography>;
    }

    if (!data || data.length === 0) {
        return null;
    }

    const totalKilometers = data.reduce((sum, route) => sum + (parseFloat(route.bk2_pkmt) || 0), 0);
    const totalSeconds = data.reduce((sum, route) => sum + (parseInt(route.bk2_tempoInSecondi) || 0), 0);

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <MapIcon color="primary" />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    N. Tratte : {data.length}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <DirectionsCar color="primary" />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    {totalKilometers.toFixed(1)} km
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <AccessTime color="primary" />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    {hours}h {minutes}m {seconds}s
                </Typography>
            </Box>
        </Box>
    );
};

const TotalKilometersAndTimeDisplay = ({ totals }) => {
    if (!totals) return null;

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <MapIcon color="primary" />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    N. Tratte : {totals.numberOfRoutes}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <DirectionsCar color="primary" />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    {totals.kilometers.toFixed(1)} km
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <AccessTime color="primary" />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    {totals.hours}h {totals.minutes}m {totals.seconds}s
                </Typography>
            </Box>
        </Box>
    );
};

const useTotalKilometersAndTime = (automezzoId) => {
    const { data, isLoading, error } = useGetList(
        'booking-2',
        {
            filter: { booking0_automezziId: automezzoId },
            sort: { field: 'bk2_idriga', order: 'ASC' },
            pagination: { page: 1, perPage: 1000 },
        }
    );

    if (isLoading || error || !data) {
        return {
            isLoading,
            error,
            totals: null
        };
    }

    const totalKilometers = data.reduce((sum, route) => sum + (parseFloat(route.bk2_pkmt) || 0), 0);
    const totalSeconds = data.reduce((sum, route) => sum + (parseInt(route.bk2_tempoInSecondi) || 0), 0);

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return {
        isLoading,
        error,
        totals: {
            kilometers: totalKilometers,
            hours,
            minutes,
            seconds,
            numberOfRoutes: data.length,
            totalTimeInSeconds: totalSeconds
        }
    };
};

// Componente per gestire la singola riga veicolo
const VehicleRow = ({ record, bookingRecord }) => {
    const [tariffBDialogOpen, setTariffBDialogOpen] = useState(false);
    const [addRouteDialogOpen, setAddRouteDialogOpen] = useState(false);
    const [editRouteDialogOpen, setEditRouteDialogOpen] = useState(false);
    const [selectedRouteData, setSelectedRouteData] = useState(null);

    const { totals, isLoading, error } = useTotalKilometersAndTime(record.id);

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{
                    backgroundColor: 'rgba(0, 0, 0, 0.03)',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                }}
            >
                <Grid container alignItems="center" spacing={2}>
                    {/* Sezione Informazioni */}
                    <Grid item xs={12} sm={8}>
                        <Typography variant="subtitle1">
                            <strong>ID booking-0-automezzi:</strong> <TextField source="id" />
                        </Typography>
                        <Typography variant="subtitle1">
                            <strong>Gruppo:</strong> <GetValoreCampo
                                label="Gruppo"
                                source="gruppoId"
                                reference="tabellealfa"
                            />
                        </Typography>
                        <Typography variant="subtitle1">
                            <strong>gruppoId:</strong> <TextField source="gruppoId" />
                        </Typography>
                    </Grid>

                    {/* Sezione Azioni */}
                    <Grid item xs={12} sm={4} container spacing={1} justifyContent="flex-end">
                        <Grid item>
                            <MuiButton
                                variant="contained"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setTariffBDialogOpen(true);
                                }}
                            >
                                Seleziona Tariffa B per gruppo&nbsp;&nbsp;
                                <GetValoreCampo
                                    label="Gruppo"
                                    source="gruppoId"
                                    reference="tabellealfa"
                                />
                            </MuiButton>
                        </Grid>
                        <Grid item>
                            <DeleteButtonCustomWithRefresh
                                titolo={"Cancella"}
                                messaggio={"Sei sicuro di voler cancellare questo automezzo?"}
                                servizio={"booking-0-automezzi"}
                            />
                        </Grid>
                    </Grid>

                    {/* Sezione Tariffe e Totali */}
                    <Grid item xs={12}>
                        <ReferenceField source="tariffeBId" reference="tariffe-b">
                            <FunctionField
                                source="tariffeBId"
                                label=""
                                render={tariffRecord => tariffRecord && (
                                    <Box>
                                        <SelectedTariffBInfo
                                            tariff={tariffRecord}
                                            totals={totals}
                                        />
                                    </Box>
                                )}
                            />
                        </ReferenceField>

                        <TariffBSelectionDialog
                            open={tariffBDialogOpen}
                            onClose={() => setTariffBDialogOpen(false)}
                            record={record}
                            tariffeAId={bookingRecord.tariffeAId}
                            gruppoId={record.gruppoId}
                        />

                        <TotalKilometersAndTimeDisplay totals={totals} />
                    </Grid>
                </Grid>
            </AccordionSummary>

            <AccordionDetails>
                <Box sx={{ p: 2, backgroundColor: '#f5f5f5' }}>
                    <MuiButton
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={(e) => {
                            e.stopPropagation();
                            setAddRouteDialogOpen(true);
                        }}
                        size="small"
                        sx={createButtonCss}
                    >
                        Aggiungi Tratta
                    </MuiButton>
                </Box>

                <ReferenceManyField
                    reference="booking-2"
                    target="booking0_automezziId"
                    fullWidth
                    perPage={1000}
                    sort={{ field: 'bk2_idriga', order: 'ASC' }}
                >
                    <Datagrid
                        bulkActionButtons={false}
                        sx={{
                            '& .RaDatagrid-headerCell': {
                                backgroundColor: '#f5f5f5',
                            },
                        }}
                    >
                        <TextField source="id" label="ID" />
                        <GetOrario source="bk2_datastart" label="Data" />
                        <TextField source="bk2_partenza" label="Partenza" />
                        <TextField source="bk2_arrivo" label="Arrivo" />
                        <NumberField
                            source="bk2_pkmt"
                            label="Distanza (Km)"
                            options={{ minimumFractionDigits: 1 }}
                        />
                        <EditableTimeComponent label="Tempo (hh:mm)" />
                        <FunctionField
                            source="bk2_suplementoNotturno" // used for sorting
                            label="Notturna(Si/No)"
                            render={record => {
                                if (!record) return "No";
                                const value = record.bk2_suplementoNotturno === "0" || record.bk2_suplementoNotturno == null ? "" : "Si";
                                return value === "Si" ? (
                                    <>
                                        <TrattaSupplementoNotturno />
                                    </>
                                ) : (
                                    <>
                                        <TrattaSupplementoNotturno />
                                    </>
                                );
                            }}
                        />
                        <FunctionField
                            source="bk2_tvuoto" // used for sorting
                            label="Km Vuoto(Si/No)"
                            render={record => {
                                if (!record) return "No";
                                const value = record.bk2_tvuoto === "0" || record.bk2_tvuoto == null ? "" : "Si";
                                return value === "Si" ? (
                                    <>
                                        {/* <Chip label={<strong>{value}</strong>} color="primary" /> */}
                                        <TrattaKmVuotoToggle />
                                    </>
                                ) : (
                                    <>
                                        <TrattaKmVuotoToggle />
                                    </>
                                );
                            }}
                        />
                        <MyUrlField source="bk2_purl" label="Mappa" />
                        <FunctionField
                            label="Azioni"
                            render={routeRecord => (
                                <Box>
                                    <MuiButton
                                        variant="outlined"
                                        size="small"
                                        onClick={() => {
                                            setSelectedRouteData(routeRecord);
                                            setEditRouteDialogOpen(true);
                                        }}
                                        sx={{ mr: 1 }}
                                    >
                                        Modifica
                                    </MuiButton>
                                    <DeleteButtonCustomWithRefresh
                                        titolo={"Cancella"}
                                        messaggio={"Sei sicuro di voler cancellare questa tratta?"}
                                        servizio={"booking-2"}
                                    />
                                </Box>
                            )}
                        />
                    </Datagrid>
                </ReferenceManyField>

                <AddRouteDialog
                    open={addRouteDialogOpen}
                    onClose={() => setAddRouteDialogOpen(false)}
                    booking0Id={bookingRecord.id}
                    booking0_automezziId={record.id}
                    bk0_idnumber={bookingRecord.bk0_idnumber}
                    bk0_idserie={bookingRecord.bk0_idserie}
                />

                <EditRouteDialog
                    open={editRouteDialogOpen}
                    onClose={() => {
                        setEditRouteDialogOpen(false);
                        setSelectedRouteData(null);
                    }}
                    routeData={selectedRouteData}
                    booking0Id={bookingRecord.id}
                    booking0_automezziId={record.id}
                    bk0_idnumber={bookingRecord.bk0_idnumber}
                />
            </AccordionDetails>
        </Accordion>
    );
};

const TratteList = () => {
    const record = useRecordContext();
    const [addVehicleDialogOpen, setAddVehicleDialogOpen] = useState(false);
    const [tariffDialogOpen, setTariffDialogOpen] = useState(false);
    const { data: tariffData } = useGetList('tariffe-a', {
        filter: { id: record?.tariffeAId }
    });
    const selectedTariff = tariffData?.find(t => t.id === record?.tariffeAId);
    const refresh = useRefresh();
    const notify = useNotify();

    if (!record) {
        return (
            <Box p={2}>
                <Alert severity="error">
                    Dati del booking non disponibili
                </Alert>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 2 }}>
            <Paper sx={{ p: 2, mb: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6">
                        Gestione Automezzi e Tratte
                    </Typography>
                    <Box>
                        {selectedTariff ? (
                            <React.Suspense fallback={<CircularProgress size={20} />}>
                                <SelectedTariffCard tariff={selectedTariff} />
                            </React.Suspense>
                        ) : (
                            <Typography variant="h6">
                                <Alert severity="info">Seleziona una tariffa</Alert>
                            </Typography>
                        )}
                    </Box>
                    <MuiButton
                        variant="contained"
                        onClick={() => setTariffDialogOpen(true)}
                    >
                        Seleziona Tariffa A
                    </MuiButton>

                    <TariffSelectionDialog
                        open={tariffDialogOpen}
                        onClose={() => setTariffDialogOpen(false)}
                        record={record}
                    />
                    <MuiButton
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => setAddVehicleDialogOpen(true)}
                        sx={createButtonCss}
                    >
                        Aggiungi Gruppo Automezzo
                    </MuiButton>
                </Box>
            </Paper>

            <ReferenceManyField
                label="Lista Automezzi"
                reference="booking-0-automezzi"
                target="booking0Id"
            >
                <Datagrid bulkActionButtons={false}><FunctionField
                    render={vehicleRecord => (
                        <VehicleRow
                            record={vehicleRecord}
                            bookingRecord={record}
                        />
                    )}
                />
                </Datagrid>
            </ReferenceManyField>

            <AddVehicleDialog
                open={addVehicleDialogOpen}
                onClose={() => setAddVehicleDialogOpen(false)}
                booking0Id={record.id}
                bk0_paxad={record.bk0_paxad}
                bk0_paxch={record.bk0_paxch}
                bk0_paxin={record.bk0_paxin}
                onSuccess={() => {
                    refresh();
                    notify('Automezzo aggiunto con successo', { type: 'success' });
                }}
            />
        </Box>
    );
};

export default TratteList;