import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';
import { Box, Typography, IconButton, Tooltip, Paper, Grid } from '@mui/material';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const truckIcon = new Icon({
    iconUrl: "data:image/svg+xml;base64," + btoa(`
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
          <g>
            <circle cx="32" cy="32" r="30" fill="#FF9800" stroke="#F57C00" stroke-width="4"/>
            <rect x="16" y="24" width="32" height="16" fill="#fff" stroke="#000" stroke-width="2"/>
            <circle cx="24" cy="44" r="4" fill="#000"/>
            <circle cx="40" cy="44" r="4" fill="#000"/>
            <path d="M16 24v-4h32v4" fill="#fff"/>
          </g>
        </svg>
    `),
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
});

const getAddressFromCoordinates = async (lat, lon) => {
    try {
        const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lon}`, {
            headers: {
                'User-Agent': 'MySimpleMapApp/1.0 (alessandro.piras@btechitalia.it)',
            },
        });
        if (!response.ok) {
            if (response.status === 429) throw new Error('Troppi tentativi di richiesta. Riprova più tardi.');
            throw new Error('Errore nella richiesta di geocoding');
        }
        return await response.json();
    } catch (error) {
        console.error('Errore nel reverse geocoding:', error);
        return error.message;
    }
};

const MySimpleMap = ({ latitude, longitude }) => {
    const [address, setAddress] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [copiedCoords, setCopiedCoords] = useState(false);
    const [copiedAddress, setCopiedAddress] = useState(false);

    const handlePopupOpen = async () => {
        if (!address && !loading && !error) {
            setLoading(true);
            setError(null);
            const data = await getAddressFromCoordinates(latitude, longitude);
            if (typeof data === 'string') setError(data);
            else if (data?.display_name) setAddress(data.display_name);
            else setAddress(null);
            setLoading(false);
        }
    };

    const copyCoordinates = () => {
        navigator.clipboard.writeText(`Latitudine: ${latitude}, Longitudine: ${longitude}`)
            .then(() => {
                setCopiedCoords(true);
                setTimeout(() => setCopiedCoords(false), 2000);
            })
            .catch(err => console.error('Errore durante la copia:', err));
    };

    const copyAddress = () => {
        if (address) {
            navigator.clipboard.writeText(address)
                .then(() => {
                    setCopiedAddress(true);
                    setTimeout(() => setCopiedAddress(false), 2000);
                })
                .catch(err => console.error('Errore durante la copia:', err));
        }
    };

    return (
        <Box sx={{
            height: '600px',
            width: '600px',
            bgcolor: 'background.paper',
            borderRadius: 1,
            boxShadow: 1,
            overflow: 'hidden'
        }}>
            <MapContainer
                center={[latitude, longitude]}
                zoom={13}
                style={{ height: '100%', width: '100%' }}
                scrollWheelZoom={true}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker
                    position={[latitude, longitude]}
                    icon={truckIcon}
                    eventHandlers={{
                        click: handlePopupOpen,
                    }}
                >
                    <Popup>
                        <Paper elevation={3} sx={{ padding: 2, maxWidth: 300 }}>
                            {loading && (
                                <Typography variant="body1" sx={{ mb: 1 }}>
                                    Caricamento...
                                </Typography>
                            )}
                            {error && (
                                <Typography variant="body1" color="error" sx={{ mb: 1 }}>
                                    {error}
                                </Typography>
                            )}
                            {address && (
                                <Box mb={2}>
                                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                                        Informazioni Geografiche
                                    </Typography>
                                    <Box display="flex" alignItems="center" mb={1}>
                                        <Typography variant="body1" fontWeight="bold" sx={{ flexGrow: 1 }}>
                                            {address}
                                        </Typography>
                                        <Tooltip title={copiedAddress ? "Copiato!" : "Copia Indirizzo"}>
                                            <IconButton onClick={copyAddress} size="small">
                                                {copiedAddress ? <CheckIcon color="success" /> : <ContentCopyIcon />}
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            )}
                            <Box mb={1}>
                                <Grid container alignItems="center">
                                    <Grid item xs={8}>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                            Latitudine: {latitude}
                                        </Typography>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                            Longitudine: {longitude}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Tooltip title={copiedCoords ? "Copiate!" : "Copia Coordinate"}>
                                            <IconButton onClick={copyCoordinates} size="small">
                                                {copiedCoords ? <CheckIcon color="success" /> : <ContentCopyIcon />}
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Box>
                            {!loading && !address && !error && (
                                <Typography variant="body1">
                                    Nessun indirizzo specifico disponibile.
                                </Typography>
                            )}
                        </Paper>
                    </Popup>
                </Marker>
            </MapContainer>
        </Box>
    );
};

export default MySimpleMap;