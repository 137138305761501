import { Loading, useGetList } from 'react-admin';
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent, TimelineOppositeContent } from '@mui/lab';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import EditNoteDialog from '../note/CustomEditNote';
import CustomDeleteNote from "../note/CustomDeleteNote";
import GetUserEmail from "../shared/ComponentsUser/GetUserEmail";


const NoteList = ({ tiporecordId, recordId, emailUser }) => {

    const { data, total, isLoading, error } = useGetList(
        'note',
        {
            pagination: { page: 1, perPage: 10 },
            // sort: { field: 'published_at', order: 'DESC' }
            filter: {
                recordId: recordId,
                tiporecordId: tiporecordId
            }
        }
    );
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return (
        <Box display="flex" justifyContent="top" alignItems="top" minHeight="100vh">
            <Box width="70%">
                <Timeline sx={{ [`& .MuiTimelineItem-root::before`]: { flex: 0, padding: 0 } }}>
                    {data.map((note) => (
                        <TimelineItem key={note.id}>
                            <TimelineOppositeContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Card sx={{ minWidth: 275, margin: 2 }}>
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Avatar sx={{ bgcolor: 'grey.500', mb: 1, width: 56, height: 56 }}> {/* Avatar ingrandito e in grigio */}
                                            <PersonIcon />
                                        </Avatar>
                                        <Typography color="textPrimary" gutterBottom>
                                            {/* userId: {note.userId} */}
                                            <GetUserEmail userId={note.userId} />
                                        </Typography>
                                        <Typography color="textSecondary">
                                            {new Date(note.createdAt).toLocaleDateString()} {new Date(note.createdAt).toLocaleTimeString()}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <EditNoteDialog note={note} label="Nota" />
                                        <CustomDeleteNote recordId={note.id} record={note} />
                                    </CardActions>
                                </Card>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Nota {note.id}
                                </Typography>
                                <Typography component="div" variant="body1">
                                    <div dangerouslySetInnerHTML={{ __html: note.descrizione }} />
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>
            </Box>
        </Box>
    );

    /*
    const [notes, setNotes] = useState([]);
    const jwt = localStorage.getItem("feathers-jwt");

    useEffect(() => {
        const fetchNotes = async () => {
            const config = {
                method: 'get',
                url: `http://localhost:3030/note?tiporecordId=${tiporecordId}&recordId=${recordId}`,
                headers: { Authorization: 'Bearer ' + jwt }
            };

            try {
                const response = await axios.request(config);
                setNotes(response.data.data);
            } catch (error) {
                console.error("Errore nel caricamento delle note:", error);
            }
        };

        fetchNotes();
    }, [tiporecordId, recordId]);

    return (
        <Timeline sx={{ [`& .MuiTimelineItem-root::before`]: { flex: 0, padding: 0 } }}>
            {notes.map((note) => (
                <TimelineItem key={note.id}>
                    <TimelineOppositeContent color="textSecondary" sx={{ m: 'auto 0' }}>
                        {new Date(note.createdAt).toLocaleDateString()} {new Date(note.createdAt).toLocaleTimeString()}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant="h6" component="span">
                            Nota {note.id}
                        </Typography>
                        <Typography>{note.descrizione}</Typography>
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    ); */
};

export default NoteList;
