
import {
    Show,
    List,
    SimpleShowLayout,
    TextField,
    DateField,
    NumberField,
    TopToolbar,
    EditButton,
    TabbedShowLayout,
    TabbedForm,
    useRecordContext,
    ReferenceManyField,
    FunctionField,
    TextInput,
    DateInput,
    NumberInput,
    useCreate,
    useNotify,
    useRefresh,
    ListBase,
    CreateButton,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    AutocompleteInput

} from 'react-admin';
import { Link } from 'react-router-dom';
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import { makeStyles } from '@mui/styles';

// List
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

// Import CSS
import { editButtonCss, saveButtonCss, showButtonCss, exportButtonCss, columnsButtonCss, deleteButtonCss, filterButtonCss } from '../shared/Styles/buttonStyles';
import { formCss } from "../shared/Styles/formCss";

// Icone
import PeopleIcon from '@mui/icons-material/People';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import AssignmentIcon from '@mui/icons-material/Assignment';

// Card
import Card from '@mui/material/Card';
import { CardHeader } from '@mui/material';

// Grid
import Grid from '@mui/material/Grid';

import TopicIcon from '@mui/icons-material/Topic';

/* CSS - BASE - FORM - START */
const styles = {
    TextInput: {
        // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        // background: 'linear-gradient(45deg, #01b5d0 10%, #ffffff 90%)',
        border: 0,
        borderRadius: 1,
        // boxShadow: '0px 0px 3px 2px #01b5d0',
        color: 'green',
        height: 50,
        padding: '0 0px',
    },
};
const useStyles = makeStyles(styles);

const ShowActions = () => (
    <TopToolbar>
        <EditButton sx={editButtonCss} label="Modifica" />
    </TopToolbar>
);


export const ServpaxShow = ({ id, ...props }) => {
    const classes = useStyles();

    return (
        <Show
            title={<>
                <Button sx={{ color: 'white' }}>
                    <PeopleIcon sx={{ color: 'white' }}></PeopleIcon>
                    &nbsp;Dettaglio : &nbsp;
                </Button>
            </>}
            {...props} actions={<ShowActions />}>
            <Card sx={{ minWidth: 275 }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: '#01b5d0' }}>
                            <AssignmentIcon />
                        </Avatar>
                    }
                    title={<>Dati Tariffa Pax</>}
                    subheader={<><TextField source="descrizione" /></>}
                />
            </Card>

            <TabbedForm toolbar={null}>
                <TabbedForm.Tab label="Anagrafica Base" icon={<TopicIcon />}
                    sx={{
                        '&.Mui-selected': {
                            borderBottom: '3px solid #01b5d0',
                            backgroundColor: '#e8e8e8'
                        }
                    }}
                >
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={6}>
                            <List pagination="" actions={false}>

                                <ListItem>
                                    <ListItemText
                                        primary="id"
                                        secondary="id"
                                    />

                                    <NumberField source="id" />

                                </ListItem>
                                ,
                                <ListItem>
                                    <ListItemText
                                        primary="descrizione"
                                        secondary="descrizione"
                                    />

                                    <TextField source="descrizione" />

                                </ListItem>
                                ,
                                <ListItem>
                                    <ListItemText
                                        primary="note"
                                        secondary="note"
                                    />

                                    <TextField source="note" />

                                </ListItem>
                                ,
                                <ListItem>
                                    <ListItemText
                                        primary="flAttivo"
                                        secondary="flAttivo"
                                    />

                                    <NumberField source="flAttivo" />

                                </ListItem>
                                ,
                                <ListItem>
                                    <ListItemText
                                        primary="flVisibile"
                                        secondary="flVisibile"
                                    />

                                    <NumberField source="flVisibile" />

                                </ListItem>
                                ,
                                <ListItem>
                                    <ListItemText
                                        primary="flAnnullato"
                                        secondary="flAnnullato"
                                    />

                                    <NumberField source="flAnnullato" />

                                </ListItem>
                                ,
                                <ListItem>
                                    <ListItemText
                                        primary="ordinamento"
                                        secondary="ordinamento"
                                    />

                                    <NumberField source="ordinamento" />

                                </ListItem>
                                ,
                                <ListItem>
                                    <ListItemText
                                        primary="createdAt"
                                        secondary="createdAt"
                                    />

                                    <DateField source="createdAt" />

                                </ListItem>
                                ,
                                <ListItem>
                                    <ListItemText
                                        primary="updatedAt"
                                        secondary="updatedAt"
                                    />

                                    <DateField source="updatedAt" />

                                </ListItem>

                            </List>
                        </Grid>
                    </Grid>
                    {/* </TabbedShowLayout.Tab> */}
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Tariffe Servizi" icon={<TopicIcon />}
                    sx={{
                        '&.Mui-selected': {
                            borderBottom: '3px solid #01b5d0',
                            backgroundColor: '#e8e8e8'
                        }
                    }}
                >
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <GetListTariffaServpax></GetListTariffaServpax>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                {/* </TabbedShowLayout> */}
            </TabbedForm>
        </Show>
    );
}

const GetListTariffaServpax = (props) => {
    const record = useRecordContext();

    const recordServpax = useRecordContext();
    if (!record) return null;
    if (!recordServpax) return null;

    const servpaxDescrizione = record.descrizione;

    return (
        <ReferenceManyField
            reference="tariffa-servpax"
            target="servpaxId"
        // sort={{ field: 'id', order: 'ASC' }}
        >

            <EditableDatagrid
                bulkActionButtons={false}
                editForm={<TariffaServpaxRowForm isCreate={false} />}
                createForm={<TariffaServpaxRowForm isCreate={true} id={record.id} />}
            >
                <FunctionField label="Servizio" render={record => record && `${servpaxDescrizione}`} />
                <ReferenceField link={false} label="Fornitore Abilitato" source="anagenId" reference="anagen">
                    <FunctionField
                        label="Dettaglio Fornitore"
                        render={record => record && (
                            <Button
                                component={Link}
                                to={`/anagen/${record.id}/show/tariffeServiziPax`}
                                size="small"
                                sx={{
                                    backgroundColor: '#2196f3',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: '#1976d2',
                                    },
                                }}
                            >
                                {record.gnragnom}
                            </Button>
                        )}
                    />
                    {/* <FunctionField render={record => record && `${record.gnragnom} - ${record.gnfind}`} /> */}
                </ReferenceField>

                {/* <TextField fullWidth label="ServpaxId" source="servpaxId" /> */}
                <DateField fullWidth label="Data Dal" source="dataFrom" />
                <DateField fullWidth label="Data Al" source="dataTo" />
                {/* <TextField fullWidth label="Costo" source="costo" /> */}
                <NumberField source="costo" label="Costo" options={{ style: 'currency', currency: 'EUR' }} />
                {/* <FunctionField
                    label="Simulatore"
                    render={record => {

                        // console.log(recordTariffea);

                        record.tfa_bdgkm = recordTariffea.tfa_bdgkm;
                        record.tfa_bdghh = recordTariffea.tfa_bdghh;
                        record.tfa_frgmmec = recordTariffea.tfa_frgmmec;
                        record.tfa_frgmmnt = recordTariffea.tfa_frgmmnt;

                        return record && <Simulatore record={record}></Simulatore>;
                    }}
                /> */}



            </EditableDatagrid>

        </ReferenceManyField>
    );
}

// const TariffaServpaxRowFormEdit = (props) => (
//     <RowForm {...props}>
//         {/* <TextInput fullWidth label="Id" source="id" /> */}
//         <TextInput fullWidth label="Fornitore" source="anagenId" />
//         {/* <TextInput fullWidth label="Servpax" source="servpaxId" /> */}
//         <DateInput fullWidth label="Data Dal" source="dataFrom" />
//         <DateInput fullWidth label="Data Al" source="dataTo" />
//         <NumberInput min={0.000} fullWidth label="Costo" source="costo" options={{ style: "currency", currency: "EUR" }} />
//     </RowForm>
// );


const TariffaServpaxRowForm = ({ isCreate, ...props }) => (
    <RowForm {...props}>
        {/* {isCreate && <TextInput source="servpaxId" defaultValue={parseInt(props.id)} disabled style={{ display: 'none' }} />} */}
        <TextInput source="servpaxId" defaultValue={parseInt(props.id)} style={{ display: 'none' }} />
        {/* <TextInput fullWidth label="Fornitore" source="anagenId" /> */}
        <ReferenceInput
            fullWidth
            sx={{ mb: 2 }}
            label="Fornitore"
            source="anagenId"
            reference="view-fornitori-servpax"
            perPage={null}
            sort={{ field: 'descrizione', order: 'ASC' }}
            filter={{ servpaxId: props.id }} // Filtra per il servpaxId corrente
        >
            <AutocompleteInput
                label="Fornitori abilitati"
                optionText={(record) =>
                    record ? `${record.gncfind} - ${record.descrizione}` : ''
                }
                optionValue="anagenId"
            />
        </ReferenceInput>

        <DateInput fullWidth label="Data Dal" source="dataFrom" />
        <DateInput fullWidth label="Data Al" source="dataTo" />
        <NumberInput min={0.000} fullWidth label="Costo" source="costo" options={{ style: "currency", currency: "EUR" }} />
    </RowForm>
);

// const TariffaServpaxRowFormCreate = ({ id, ...props }) => (
//     <RowForm {...props}>
//         <TextInput source="servpaxId" defaultValue={parseInt(id)} disabled />
//         {/* <TextInput fullWidth label="Id" source="id" /> */}
//         <TextInput fullWidth label="Fornitore" source="anagenId" />
//         {/* <TextInput fullWidth label="Servpax" source="servpaxId" /> */}
//         <DateInput fullWidth label="Data Dal" source="dataFrom" />
//         <DateInput fullWidth label="Data Al" source="dataTo" />
//         <NumberInput min={0.000} fullWidth label="Costo" source="costo" options={{ style: "currency", currency: "EUR" }} />
//     </RowForm>
// );

export default GetListTariffaServpax;