import React, { useState } from 'react';
import {
    ReferenceField,
    TextField,
    useRecordContext,
    SimpleForm,
    TextInput,
    BooleanInput,
    SaveButton,
    useUpdate,
    useNotify,
    useRefresh,
    required,
    FunctionField,
    useCreate,
} from 'react-admin';
import {
    Card,
    CardHeader,
    CardContent,
    Avatar,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Box,
    Divider,
} from '@mui/material';
import { Link } from 'react-router-dom';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

// Validazioni
const validateEmail = (value) => {
    if (!value) return undefined;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value) ? undefined : 'Email non valida';
};

const validatePhoneNumber = (value) => {
    if (!value) return undefined;
    const phoneRegex = /^[0-9]+$/;
    const minLength = 8;
    const maxLength = 15;
    if (!phoneRegex.test(value)) {
        return 'Il numero di telefono deve contenere solo cifre';
    }
    if (value.length < minLength || value.length > maxLength) {
        return `Il numero di telefono deve essere tra ${minLength} e ${maxLength} cifre`;
    }
    return undefined;
};

const validateProvince = (value) => {
    if (!value) return undefined;
    const provinceRegex = /^[A-Z]{2}$/i;
    return provinceRegex.test(value) ? undefined : 'Provincia non valida, servono due lettere';
};

const validateCodiceFiscale = (value) => {
    if (!value) return undefined;
    const cfRegex = /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/i;
    return cfRegex.test(value) ? undefined : 'Codice Fiscale non valido';
};

const NoAdvancedDetails = () => {
    const [open, setOpen] = useState(false);
    const record = useRecordContext();

    return (
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gap={2}
                    py={4}
                >
                    <AssignmentIcon
                        sx={{
                            fontSize: 60,
                            color: 'text.secondary',
                            opacity: 0.5
                        }}
                    />
                    <Typography
                        variant="h6"
                        color="text.secondary"
                    >
                        Nessuna anagrafica avanzata associata
                    </Typography>
                    <Button
                        variant="contained"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() => setOpen(true)}
                        sx={{
                            bgcolor: '#01b5d0',
                            '&:hover': {
                                bgcolor: '#018fa3',
                            }
                        }}
                    >
                        Crea Anagrafica Avanzata
                    </Button>
                </Box>
            </CardContent>

            <CreateDialog
                open={open}
                onClose={() => setOpen(false)}
                userId={record.id}
            />
        </Card>
    );
};

const EditDialog = ({ open, onClose, record }) => {
    const [update] = useUpdate();
    const notify = useNotify();
    const refresh = useRefresh();

    const onSubmit = async (data) => {
        try {
            await update(
                'anagen',
                { id: record.id, data: { ...data } },
                {
                    onSuccess: () => {
                        notify('Anagrafica aggiornata con successo', { type: 'success' });
                        refresh();
                        onClose();
                    },
                    onError: (error) => {
                        notify(`Errore durante l'aggiornamento: ${error.message}`, { type: 'error' });
                    },
                }
            );
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>Modifica Anagrafica Avanzata</DialogTitle>
            <DialogContent>
                <SimpleForm toolbar={null} onSubmit={onSubmit} defaultValues={record}>
                    <Box sx={{ '& .MuiTextField-root': { m: 2, width: '30ch' } }}>
                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>ID:</Typography>
                                <TextInput source="id" disabled />
                                <Typography variant="body1" style={{ marginRight: 8 }}>Ricerca nome:</Typography>
                                <TextInput source="gnfind" validate={[required()]} />
                            </Box>
                        </div>
                        <Divider variant="middle" />
                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Nome:</Typography>
                                <TextInput source="gnpfnome" validate={[required()]} />
                                <Typography variant="body1" style={{ marginRight: 8 }}>Cognome:</Typography>
                                <TextInput source="gnragnom" validate={[required()]} />
                            </Box>
                        </div>
                        <Divider variant="middle" />
                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Partita IVA:</Typography>
                                <TextInput source="gncdiva" validate={[required()]} />
                                <Typography variant="body1" style={{ marginRight: 8 }}>Codice Fiscale:</Typography>
                                <TextInput source="gncdfisc" validate={[validateCodiceFiscale]} />
                            </Box>
                        </div>
                        <Divider variant="middle" />
                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Ragione Sociale:</Typography>
                                <TextInput source="gnragnom" validate={[required()]} />
                                <TextInput source="gnrag2" />
                            </Box>
                        </div>
                        <Divider variant="middle" />
                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Indirizzo:</Typography>
                                <TextInput source="gnind" />
                                <Typography variant="body1" style={{ marginRight: 8 }}>CAP:</Typography>
                                <TextInput source="gncap" />
                            </Box>
                        </div>
                        <Divider variant="middle" />
                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Comune:</Typography>
                                <TextInput source="gncomune" />
                                <Typography variant="body1" style={{ marginRight: 8 }}>Frazione:</Typography>
                                <TextInput source="gnfrazione" />
                            </Box>
                        </div>
                        <Divider variant="middle" />
                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Provincia:</Typography>
                                <TextInput source="gnprov" validate={[validateProvince]} />
                                <Typography variant="body1" style={{ marginRight: 8 }}>Nazione:</Typography>
                                <TextInput source="gnnaz" />
                            </Box>
                        </div>
                        <Divider variant="middle" />
                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Email:</Typography>
                                <TextInput source="gnemail" validate={[validateEmail]} />
                                <Typography variant="body1" style={{ marginRight: 8 }}>Website:</Typography>
                                <TextInput source="gnhomep" />
                            </Box>
                        </div>
                        <Divider variant="middle" />
                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Telefono:</Typography>
                                <TextInput source="gntel" validate={[validatePhoneNumber]} />
                                <Typography variant="body1" style={{ marginRight: 8 }}>Cellulare:</Typography>
                                <TextInput source="gncel" validate={[validatePhoneNumber]} />
                                <Typography variant="body1" style={{ marginRight: 8 }}>Fax:</Typography>
                                <TextInput source="gnfax" />
                            </Box>
                        </div>
                        <Divider variant="middle" />
                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Attivo:</Typography>
                                <BooleanInput source="flAttivo" />
                                <Typography variant="body1" style={{ marginRight: 8 }}>Visibile:</Typography>
                                <BooleanInput source="fiVisibile" />
                                <Typography variant="body1" style={{ marginRight: 8 }}>Annullato:</Typography>
                                <BooleanInput source="flAnnullato" />
                            </Box>
                        </div>
                    </Box>
                    <DialogActions>
                        <Button onClick={onClose}>Annulla</Button>
                        <SaveButton
                            label="Salva modifiche"
                            type="submit"
                            sx={{
                                bgcolor: '#01b5d0',
                                '&:hover': {
                                    bgcolor: '#018fa3',
                                }
                            }}
                        />
                    </DialogActions>
                </SimpleForm>
            </DialogContent>
        </Dialog>
    );
};

const CreateDialog = ({ open, onClose, userId }) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const [create] = useCreate();
    const [update] = useUpdate();

    // Valori di default iniziali
    const initialValues = {
        gntypepc: 'CL',  // Cliente
        gncdtan: 1135,  // Codice anagrafica
        flAttivo: true,
        fiVisibile: true,
        flAnnullato: false
    };

    const handleSubmit = async (values) => {
        // Rimuoviamo l'id se presente e assicuriamoci che ci siano i valori di default
        const { id, ...createData } = {
            ...initialValues,  // Prima i valori di default
            ...values,         // Poi i valori del form (non sovrascriveranno gntypepc e gnctan)
        };

        try {
            create(
                'anagen',
                { data: createData },
                {
                    onSuccess: (data) => {
                        update(
                            'users',
                            {
                                id: userId,
                                data: { clientiId: data.id }
                            },
                            {
                                onSuccess: () => {
                                    notify('Anagrafica creata con successo', { type: 'success' });
                                    refresh();
                                    onClose();
                                },
                                onError: (error) => {
                                    notify(`Errore nell'aggiornamento dell'utente: ${error.message}`, { type: 'warning' });
                                }
                            }
                        );
                    },
                    onError: (error) => {
                        notify(`Errore nella creazione dell'anagrafica: ${error.message}`, { type: 'error' });
                    }
                }
            );
        } catch (error) {
            console.error(error);
            notify('Errore durante la creazione', { type: 'error' });
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>Creazione Nuova Anagrafica</DialogTitle>
            <DialogContent>
                <SimpleForm
                    toolbar={null}
                    onSubmit={handleSubmit}
                    defaultValues={initialValues}
                >
                    <Box sx={{ '& .MuiTextField-root': { m: 2, width: '30ch' } }}>
                        {/* Campi nascosti per i valori di default */}
                        <TextInput source="gntypepc" style={{ display: 'none' }} />
                        <TextInput source="gnctan" style={{ display: 'none' }} />

                        {/* Campi visibili del form */}
                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Ricerca nome:</Typography>
                                <TextInput source="gnfind" validate={[required()]} />
                            </Box>
                        </div>
                        <Divider variant="middle" />
                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Nome:</Typography>
                                <TextInput source="gnpfnome" validate={[required()]} />
                                <Typography variant="body1" style={{ marginRight: 8 }}>Cognome:</Typography>
                                <TextInput source="gnragnom" validate={[required()]} />
                            </Box>
                        </div>
                        <Divider variant="middle" />
                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Partita IVA:</Typography>
                                <TextInput source="gncdiva" validate={[required()]} />
                                <Typography variant="body1" style={{ marginRight: 8 }}>Codice Fiscale:</Typography>
                                <TextInput source="gncdfisc" validate={[validateCodiceFiscale]} />
                            </Box>
                        </div>
                        <Divider variant="middle" />
                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Ragione Sociale:</Typography>
                                <TextInput source="gnragnom" validate={[required()]} />
                                <TextInput source="gnrag2" />
                            </Box>
                        </div>
                        <Divider variant="middle" />
                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Indirizzo:</Typography>
                                <TextInput source="gnind" />
                                <Typography variant="body1" style={{ marginRight: 8 }}>CAP:</Typography>
                                <TextInput source="gncap" />
                            </Box>
                        </div>
                        <Divider variant="middle" />
                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Comune:</Typography>
                                <TextInput source="gncomune" />
                                <Typography variant="body1" style={{ marginRight: 8 }}>Frazione:</Typography>
                                <TextInput source="gnfrazione" />
                            </Box>
                        </div>
                        <Divider variant="middle" />
                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Provincia:</Typography>
                                <TextInput source="gnprov" validate={[validateProvince]} />
                                <Typography variant="body1" style={{ marginRight: 8 }}>Nazione:</Typography>
                                <TextInput source="gnnaz" />
                            </Box>
                        </div>
                        <Divider variant="middle" />
                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Email:</Typography>
                                <TextInput source="gnemail" validate={[validateEmail]} />
                                <Typography variant="body1" style={{ marginRight: 8 }}>Website:</Typography>
                                <TextInput source="gnhomep" />
                            </Box>
                        </div>
                        <Divider variant="middle" />
                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Telefono:</Typography>
                                <TextInput source="gntel" validate={[validatePhoneNumber]} />
                                <Typography variant="body1" style={{ marginRight: 8 }}>Cellulare:</Typography>
                                <TextInput source="gncel" validate={[validatePhoneNumber]} />
                                <Typography variant="body1" style={{ marginRight: 8 }}>Fax:</Typography>
                                <TextInput source="gnfax" />
                            </Box>
                        </div>
                        <Divider variant="middle" />
                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Attivo:</Typography>
                                <BooleanInput source="flAttivo" />
                                <Typography variant="body1" style={{ marginRight: 8 }}>Visibile:</Typography>
                                <BooleanInput source="fiVisibile" />
                                <Typography variant="body1" style={{ marginRight: 8 }}>Annullato:</Typography>
                                <BooleanInput source="flAnnullato" />
                            </Box>
                        </div>
                    </Box>
                    <DialogActions>
                        <Button onClick={onClose}>Annulla</Button>
                        <SaveButton
                            label="Crea Anagrafica"
                            type="submit"
                            sx={{
                                bgcolor: '#01b5d0',
                                '&:hover': {
                                    bgcolor: '#018fa3',
                                }
                            }}
                        />
                    </DialogActions>
                </SimpleForm>
            </DialogContent>
        </Dialog>
    );
};

const AdvancedDetailsView = ({ record }) => {
    const [editDialogOpen, setEditDialogOpen] = useState(false);

    return (
        <Card sx={{ minWidth: 275 }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: '#01b5d0' }}>
                        <AssignmentIcon />
                    </Avatar>
                }
                action={
                    <Button
                        startIcon={<EditIcon />}
                        onClick={() => setEditDialogOpen(true)}
                        sx={{
                            bgcolor: '#01b5d0',
                            color: 'white',
                            '&:hover': {
                                bgcolor: '#018fa3',
                            }
                        }}
                    >
                        Modifica
                    </Button>
                }
                title={<><strong>ID Anagrafica:</strong> <TextField source="id" />
                    <br></br>
                    <FunctionField
                        label="Dettaglio Anagrafica"
                        render={record => record && (
                            <Button
                                component={Link}
                                to={`/anagen/${record.id}/show`}
                                size="small"
                                sx={{
                                    backgroundColor: '#2196f3',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: '#1976d2',
                                    },
                                }}
                            >
                                Dettaglio: {record.gnragnom}
                            </Button>
                        )}
                    />

                </>}
            />
            <CardContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <div>
                        <Typography variant="subtitle1" style={{ marginBottom: 8 }}>Informazioni Principali</Typography>
                        <Box display="flex" gap={4}>
                            <div>
                                <strong>Nome:</strong> <TextField source="gnpfnome" />
                            </div>
                            <div>
                                <strong>Cognome:</strong> <TextField source="gnragnom" />
                            </div>
                        </Box>
                    </div>
                    <Divider />
                    <div>
                        <Typography variant="subtitle1" style={{ marginBottom: 8 }}>Dati Fiscali</Typography>
                        <Box display="flex" gap={4}>
                            <div>
                                <strong>P.IVA:</strong> <TextField source="gncdiva" />
                            </div>
                            <div>
                                <strong>Cod. Fiscale:</strong> <TextField source="gncdfisc" />
                            </div>
                        </Box>
                    </div>
                    <Divider />
                    <div>
                        <Typography variant="subtitle1" style={{ marginBottom: 8 }}>Contatti</Typography>
                        <Box display="flex" gap={4}>
                            <div>
                                <strong>Email:</strong> <TextField source="gnemail" />
                            </div>
                            <div>
                                <strong>Telefono:</strong> <TextField source="gntel" />
                            </div>
                        </Box>
                    </div>
                    <Divider />
                    <div>
                        <Typography variant="subtitle1" style={{ marginBottom: 8 }}>Indirizzo</Typography>
                        <Box display="flex" flexDirection="column" gap={1}>
                            <div>
                                <strong>Via:</strong> <TextField source="gnind" />
                            </div>
                            <div>
                                <strong>Comune:</strong> <TextField source="gncomune" /> (<TextField source="gnprov" />)
                            </div>
                            <div>
                                <strong>CAP:</strong> <TextField source="gncap" />
                            </div>
                        </Box>
                    </div>
                </Box>
            </CardContent>
            <EditDialog
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                record={record}
            />
        </Card>
    );
};

const AdvancedUserDetails = () => {
    const record = useRecordContext();

    if (!record || !record.clientiId || record.clientiId === 0) {
        return <NoAdvancedDetails />;
    }

    return (
        <ReferenceField
            source="clientiId"
            reference="anagen"
            link={false}
        >
            <FunctionField
                source="clientiId"
                label=""
                render={record => record && <AdvancedDetailsView record={record} />}
            />
        </ReferenceField>
    );
};

export default AdvancedUserDetails;