import React, { useState, useMemo } from 'react';
import { useNotify, Notification } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {
    TextField,
    Button,
    Paper,
    Typography,
    Container,
    CircularProgress,
    InputAdornment,
    IconButton,
    LinearProgress
} from '@material-ui/core';
import { Visibility, VisibilityOff, Lock as LockIcon } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { Checkbox, FormControlLabel, Link } from '@mui/material';

import { urlLogo } from '../../utils/urlBase';
import config from '../../config/config.json';

const host = config.production.host;
const privacyPolicyUrl = 'https://www.gruppotuvoni.it/privacy-policy?tmpl=component';

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f0f4f8',
    },
    paper: {
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: theme.shape.borderRadius,
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
    },
    logo: {
        width: 150,
        marginBottom: theme.spacing(3),
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#00acc1',
        color: 'white',
        '&:hover': {
            backgroundColor: '#00838f',
        },
        padding: theme.spacing(1.5, 0),
    },
    title: {
        color: '#00acc1',
        fontWeight: 'bold',
        marginBottom: theme.spacing(3),
    },
    inputField: {
        marginBottom: theme.spacing(2),
    },
    errorMessage: {
        color: theme.palette.error.main,
        textAlign: 'center',
        marginTop: theme.spacing(2),
    },
    checkboxContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    privacyLink: {
        cursor: 'pointer',
    },
    passwordStrength: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
}));

const calculatePasswordStrength = (password) => {
    let score = 0;

    // Lunghezza
    if (password.length > 5) score += (password.length > 10) ? 2 : 1;

    // Complessità
    const hasLower = /[a-z]/.test(password);
    const hasUpper = /[A-Z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecial = /[^a-zA-Z0-9]/.test(password);

    score += hasLower + hasUpper + hasDigit + hasSpecial;

    // Varietà di caratteri
    const uniqueChars = new Set(password).size;
    score += Math.min(2, Math.floor(uniqueChars / 5));

    // Bonus per combinazioni
    if (hasLower && hasUpper && hasDigit && hasSpecial) score += 2;

    return Math.min(score, 10);  // Massimo 10 punti
};

const RegisterPage = () => {
    const classes = useStyles();
    const notify = useNotify();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        nome: '',
        cognome: '',
        telefono: '',
        privacyAccepted: false,
    });
    const [errors, setErrors] = useState({
        email: '',
        password: '',
        nome: '',
        cognome: '',
        telefono: '',
        privacyAccepted: ''
    });
    const [passwordStrength, setPasswordStrength] = useState(0);

    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return re.test(String(email).toLowerCase());
    };

    const validatePassword = (password) => {
        return password.length >= 8;
    };

    const validatePhone = (phone) => {
        const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
        return re.test(String(phone));
    };

    const validateName = (name) => {
        return name.length >= 2;
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? (checked ? 1 : 0) : value
        });

        // Validazione in tempo reale
        let error = '';
        switch (name) {
            case 'email':
                error = validateEmail(value) ? '' : 'Indirizzo email non valido';
                break;
            case 'password':
                error = validatePassword(value) ? '' : 'La password deve essere lunga almeno 8 caratteri';
                setPasswordStrength(calculatePasswordStrength(value));
                break;
            case 'telefono':
                error = validatePhone(value) ? '' : 'Numero di telefono non valido';
                break;
            case 'nome':
            case 'cognome':
                error = validateName(value) ? '' : 'Deve contenere almeno 2 caratteri';
                break;
            case 'privacyAccepted':
                error = checked ? '' : 'Devi accettare il trattamento dei dati per continuare';
                break;
            default:
                break;
        }
        setErrors({ ...errors, [name]: error });
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        // Validazione finale prima dell'invio
        const newErrors = {
            email: validateEmail(formData.email) ? '' : 'Indirizzo email non valido',
            password: validatePassword(formData.password) ? '' : 'La password deve essere lunga almeno 8 caratteri',
            nome: validateName(formData.nome) ? '' : 'Nome non valido',
            cognome: validateName(formData.cognome) ? '' : 'Cognome non valido',
            telefono: validatePhone(formData.telefono) ? '' : 'Numero di telefono non valido',
            privacyAccepted: formData.privacyAccepted ? '' : 'Devi accettare il trattamento dei dati per continuare',
        };

        setErrors(newErrors);

        if (Object.values(newErrors).some(error => error !== '') || formData.privacyAccepted !== 1) {
            setErrorMessage('Per favore, correggi gli errori nel form e accetta il trattamento dei dati prima di inviare.');
            return;
        }

        setLoading(true);

        try {
            const response = await fetch(`${host}/users/createOnlyClient`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    ...formData,
                    roles: 'gruppi',
                    gruppiId: 2,
                    status: 1,
                }),
            });

            if (response.ok) {
                notify('Registrazione completata con successo', { type: 'success' });
                navigate('/login');
            } else {
                const error = await response.json();
                setErrorMessage(error.message || 'Errore durante la registrazione');
            }
        } catch (error) {
            setErrorMessage('Si è verificato un errore durante la registrazione');
        } finally {
            setLoading(false);
        }
    };

    const handlePrivacyLinkClick = (event) => {
        event.preventDefault();
        window.open(privacyPolicyUrl, '_blank', 'noopener,noreferrer');
    };

    const passwordStrengthLabel = useMemo(() => {
        if (passwordStrength <= 2) return 'Molto debole';
        if (passwordStrength <= 4) return 'Debole';
        if (passwordStrength <= 6) return 'Media';
        if (passwordStrength <= 8) return 'Forte';
        return 'Molto forte';
    }, [passwordStrength]);

    const passwordStrengthColor = useMemo(() => {
        if (passwordStrength <= 2) return '#f44336';
        if (passwordStrength <= 4) return '#ff9800';
        if (passwordStrength <= 6) return '#ffeb3b';
        if (passwordStrength <= 8) return '#4caf50';
        return '#2196f3';
    }, [passwordStrength]);

    return (
        <Container component="main" maxWidth="xs" className={classes.container}>
            <Paper className={classes.paper} elevation={3}>
                <img src={urlLogo} alt="Logo" className={classes.logo} />
                <Typography component="h1" variant="h5" className={classes.title}>
                    Registrazione Nuovo Cliente
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Indirizzo Email"
                        name="email"
                        autoComplete="off"
                        autoFocus
                        value={formData.email}
                        onChange={handleChange}
                        error={!!errors.email}
                        helperText={errors.email}
                        className={classes.inputField}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="new-password"
                        value={formData.password}
                        onChange={handleChange}
                        error={!!errors.password}
                        helperText={errors.password}
                        className={classes.inputField}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <div className={classes.passwordStrength}>
                        <LinearProgress
                            variant="determinate"
                            value={(passwordStrength / 10) * 100}
                            style={{ backgroundColor: passwordStrengthColor }}
                        />
                        <Typography variant="caption">
                            Forza password: {passwordStrengthLabel}
                        </Typography>
                    </div>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="nome"
                        label="Nome"
                        name="nome"
                        value={formData.nome}
                        onChange={handleChange}
                        error={!!errors.nome}
                        helperText={errors.nome}
                        className={classes.inputField}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="cognome"
                        label="Cognome"
                        name="cognome"
                        value={formData.cognome}
                        onChange={handleChange}
                        error={!!errors.cognome}
                        helperText={errors.cognome}
                        className={classes.inputField}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="telefono"
                        label="Numero di Telefono"
                        name="telefono"
                        value={formData.telefono}
                        onChange={handleChange}
                        error={!!errors.telefono}
                        helperText={errors.telefono}
                        className={classes.inputField}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.privacyAccepted === 1}
                                onChange={handleChange}
                                name="privacyAccepted"
                                color="primary"
                            />
                        }
                        label={
                            <span>
                                Accetto il trattamento dei dati personali e l'
                                <Link
                                    component="span"
                                    onClick={handlePrivacyLinkClick}
                                    className={classes.privacyLink}
                                >
                                    informativa sulla privacy
                                </Link>
                            </span>
                        }
                        className={classes.checkboxContainer}
                    />
                    {errors.privacyAccepted && (
                        <Typography variant="caption" color="error">
                            {errors.privacyAccepted}
                        </Typography>
                    )}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={loading || Object.values(errors).some(error => error !== '') || formData.privacyAccepted !== 1}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Completa Registrazione'}
                    </Button>
                    {errorMessage && (
                        <Typography className={classes.errorMessage}>
                            {errorMessage}
                        </Typography>
                    )}
                </form>
            </Paper>
            <Notification />
        </Container>
    );
};

export default RegisterPage;