import { useRecordContext } from 'react-admin';
import AnagenContattiList from "../CustomAnagenContatti/CustomAnagenContatti";
import CustomCreateContatto from "../CustomAnagenContatti/CustomCreateContatto";

const GestioneContattiAnagen = ({ /* tiporecordId, */ refresh }) => {
    const record = useRecordContext();
    // const userId = localStorage.getItem("RaStore.profile.idUser");
    const emailUser = localStorage.getItem("RaStore.profile.email");
    if (!record) return null;
    return record ? (
        <>

            {<CustomCreateContatto angenId={record.id} label="Voci Contatto " />}
            {<AnagenContattiList recordId={record.id} emailUser={emailUser}></AnagenContattiList>} </>
    ) : null;
};

export default GestioneContattiAnagen;
