
import { Booking0List } from './Booking0List';
import Booking0Show from './Booking0Show';

const booking0 = {
    list: Booking0List,
    show: Booking0Show
    /*         edit: usersEdit,
            show: usersShow, */
};

export default booking0;
