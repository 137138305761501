import React, { useState } from 'react';
import { useNotify, ReferenceField, FunctionField } from 'react-admin';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { resetButtonCss, saveButtonCss } from '../../shared/Styles/buttonStyles';
import { fieldBaseDialogSimulatorForShow } from '../../shared/Styles/fieldsStyles';
import GetListAutomezzi from './GetListAutomezzi';

function Simulatore({ record, noSimulatore }) {

    if (noSimulatore) {
        console.log("noSimulatore esiste ed è true ", record);
    }

    const [open, setOpen] = useState(false);
    const [inputValues, setInputValues] = useState({
        input_km: 0,
        input_minuti: 0,
        input_km_servizio: 0,
        input_minuti_servizio: 0,
        input_km_vuoto: 0,
        input_minuti_vuoto: 0,
    });
    const [results, setResults] = useState({});
    const [tfbCkm, setTfbCkm] = useState(null); // Inizializza a zero
    const [numPosti, setNumPosti] = useState(0);
    const notify = useNotify();

    const handleClickOpen = () => {
        setInputValues({
            input_km: 0,
            input_minuti: 0,
            input_km_servizio: 0,
            input_minuti_servizio: 0,
            input_km_vuoto: 0,
            input_minuti_vuoto: 0,
        });
        setResults({});
        setTfbCkm(0); // Inizializza a zero
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputValues(prevValues => ({
            ...prevValues,
            [name]: value,
        }));
    };

    // Calcoli aggiornati basati sui campi del file Excel

    // =SE(input_km>tfa_bdgkm;(input_km-tfa_bdgkm)*tfb_ekm+tfa_bdgkm*tfb_ckm;tfa_bdgkm*tfb_ckm)
    const calcImportoBudgetKm = (input_km, tfa_bdgkm, tfb_ekm, tfb_ckm) => {
        let result;
        if (input_km > tfa_bdgkm) {
            console.log("FLAG1");
            result = (input_km - tfa_bdgkm) * tfb_ekm + tfa_bdgkm * tfb_ckm;
        } else {
            console.log("FLAG2");
            result = tfa_bdgkm * tfb_ckm;
        }
        return result.toFixed(2);
    };

    //  // =SE(calc_km_totali<=input_km;0;calc_km_totali-input_km)
    const calcKmEccedenza = (calc_km_totali, input_km) => {

        if (calc_km_totali <= input_km) {
            return 0;
        } else {
            return calc_km_totali - input_km;
        }
    };

    const calcKmTotali = (calc_km_servizio, calc_km_vuoto_ad, calc_km_vuoto_nd) => {
        return (calc_km_servizio + calc_km_vuoto_ad + calc_km_vuoto_nd).toFixed(2);
    };

    // // =SE(input_km>tfa_bdgkm;(input_km-tfa_bdgkm)*tfb_ekm+calc_km_eccedenza*tfb_evkm;0)
    const calcImportoTotaleSupplKm = (input_km, tfa_bdgkm, tfb_ekm, calc_km_eccedenza, tfb_evkm) => {
        if (input_km > tfa_bdgkm) {
            return ((input_km - tfa_bdgkm) * tfb_ekm + calc_km_eccedenza * tfb_evkm).toFixed(2);
        } else {
            return 0;
        }
    };

    const calcImportoTotaleKmVuoto = (calc_km_eccedenza, tfb_evkm) => {
        return (calc_km_eccedenza * tfb_evkm).toFixed(2);
    };

    const calcImportoBudgetHH = (input_minuti, tfa_bdghh, tfa_frgmmec, tfb_ehh) => {
        if (input_minuti > ((tfa_bdghh * 60) + tfa_frgmmec)) {
            return ((input_minuti - ((tfa_bdghh * 60) + tfa_frgmmec)) * tfb_ehh).toFixed(2);
        } else {
            return 0.00;
        }
    };

    const calcMinutiTotali = (calc_minuti_di_servizio, calc_minuti_a_vuoto_ad, calc_minuti_a_vuoto_nd) => {
        return (calc_minuti_di_servizio + calc_minuti_a_vuoto_ad + calc_minuti_a_vuoto_nd).toFixed(2);
    };

    const calcMinutiEccedenza = (calc_minuti_totali, input_minuti, tfa_frgmmec) => {
        if (calc_minuti_totali <= input_minuti + tfa_frgmmec) {
            return 0;
        } else {
            return calc_minuti_totali - (input_minuti + tfa_frgmmec);
        }
    };

    const calcImportoTotaleSupplHH = (input_minuti, tfa_bdghh, tfa_frgmmec, tfb_ehh) => {

        if (input_minuti > tfa_bdghh * 60 + tfa_frgmmec) {
            return ((input_minuti - (tfa_bdghh * 60 + tfa_frgmmec)) * tfb_ehh).toFixed(2)
        } else {
            return 0.00;
        }
    };

    const calcImportoTotaleMinutiAVuoto = (calc_minuti_eccedenza, tfb_evhh) => {
        return (calc_minuti_eccedenza * tfb_evhh).toFixed(2);
    }

    const calcTotaleImponibileDiServizio = (calc_importo_budget_km, calc_importo_totale_suppl_km, calc_importo_budget_hh, calc_importo_totale_suppl_hh, calc_importo_suppl_notturno) => {
        return (parseFloat(calc_importo_budget_km) + parseFloat(calc_importo_totale_suppl_km) + parseFloat(calc_importo_budget_hh) + parseFloat(calc_importo_totale_suppl_hh) + parseFloat(calc_importo_suppl_notturno)).toFixed(2);
    }

    const calcCostoPerPosto = (totaleImponibile, numPosti) => {
        if (numPosti > 0) {
            return (parseFloat(totaleImponibile) / numPosti).toFixed(2);
        }
        return "N/A";
    };

    const handleReset = () => {
        setTfbCkm(null);
    }

    const handleCalculate = () => {

        let calc_importo_budget_km = calcImportoBudgetKm(parseFloat(inputValues.input_km), record.tfa_bdgkm, record.tfb_ekm, tfbCkm ?? record.tfb_ckm);

        let calc_km_servizio = parseFloat(inputValues.input_km_servizio);
        let calc_km_vuoto_ad = parseFloat(inputValues.input_km_vuoto);
        let calc_km_vuoto_nd = 0;
        let calc_km_totali = calcKmTotali(calc_km_servizio, calc_km_vuoto_ad, calc_km_vuoto_nd);

        let input_km = parseFloat(inputValues.input_km);
        let input_minuti = parseFloat(inputValues.input_minuti);
        let km_servizio = parseFloat(inputValues.input_km_servizio);
        let km_budget = parseFloat(record.tfa_bdgkm);
        let costo_km_base = parseFloat((tfbCkm == null) ? record.tfb_ckm : tfbCkm);
        let costo_km_extra_budget = parseFloat(record.tfb_ekm);


        let calc_km_eccedenza = calcKmEccedenza(calc_km_totali, input_km);


        let calc_importo_totale_suppl_km = calcImportoTotaleSupplKm(input_km, record.tfa_bdgkm, record.tfb_ekm, calc_km_eccedenza, record.tfb_evkm);

        // =calc_km_eccedenza*tfb_evkm
        let calc_importo_totale_km_vuoto = calcImportoTotaleKmVuoto(calc_km_eccedenza, record.tfb_evkm);


        let calc_importo_budget_hh = calcImportoBudgetHH(input_minuti, record.tfa_bdghh, record.tfa_frgmmec, record.tfb_ehh);


        let calc_minuti_di_servizio = parseFloat(inputValues.input_minuti_servizio);
        let calc_minuti_a_vuoto_ad = parseFloat(inputValues.input_minuti_vuoto);
        let calc_minuti_a_vuoto_nd = 0;
        let calc_minuti_totali = calcMinutiTotali(calc_minuti_di_servizio, calc_minuti_a_vuoto_ad, calc_minuti_a_vuoto_nd);

        let calc_minuti_eccedenza = calcMinutiEccedenza(calc_minuti_totali, input_minuti, record.tfa_frgmmec);


        let calc_importo_totale_suppl_hh = calcImportoTotaleSupplHH(input_minuti, record.tfa_bdghh, record.tfa_frgmmec, record.tfb_ehh);

        // =calc_minuti_eccedenza*tfb_evhh
        let calc_importo_totale_minuti_a_vuoto = calcImportoTotaleMinutiAVuoto(calc_minuti_eccedenza, record.tfb_evhh);

        let calc_importo_suppl_notturno = 0;

        // =calc_importo_budget_km+calc_importo_totale_suppl_km+calc_importo_budget_hh+calc_importo_totale_suppl_hh+calc_importo_suppl_notturno
        let calc_totale_imponibile_di_servizio = calcTotaleImponibileDiServizio(calc_importo_budget_km, calc_importo_totale_suppl_km, calc_importo_budget_hh, calc_importo_totale_suppl_hh, calc_importo_suppl_notturno);

        let calc_costo_per_posto = calcCostoPerPosto(calc_totale_imponibile_di_servizio, numPosti);


        setResults({
            calc_importo_budget_km,
            calc_km_eccedenza,
            calc_km_totali,
            calc_importo_totale_suppl_km,
            calc_importo_totale_km_vuoto,
            calc_importo_budget_hh,
            calc_minuti_totali,
            calc_minuti_eccedenza,
            calc_importo_totale_suppl_hh,
            calc_importo_totale_minuti_a_vuoto,
            calc_importo_suppl_notturno,
            calc_totale_imponibile_di_servizio,
            calc_totale_imponibile_di_servizio,
            calc_costo_per_posto
        });
    };

    const handleSave = () => {
        setOpen(false);
        notify('Calcoli salvati con successo', 'info');
    };

    return (
        <Box>
            <Button
                sx={saveButtonCss}
                variant="outlined"
                onClick={handleClickOpen}
            >
                {noSimulatore ? "Calcola costi" : "Simulatore"}
            </Button>

            <Dialog fullScreen open={open} onClose={handleClose}>
                <Box sx={{ display: 'flex', margin: 2, justifyContent: 'space-between', alignItems: 'center' }}>
                    <Button sx={saveButtonCss} onClick={handleClose}>Torna indietro</Button>
                    <Box sx={{ flex: 1, textAlign: 'center' }}>
                        <Typography variant="h4">Gestione Trasporti Tuvoni</Typography>
                    </Box>
                    <Box sx={{ flex: 1, textAlign: 'center' }}>
                        <Typography variant="h4">
                            | Tariffe | {noSimulatore ? "Calcola costi" : "Simulatore"}
                        </Typography>
                    </Box>

                    <Box sx={{ flex: 1 }}></Box> {/* Questo Box vuoto serve per centrare il titolo */}
                    <hr />
                </Box>

                <DialogContent sx={{ display: 'flex', height: '100vh' }}>
                    {/* Colonna Sinistra */}
                    <Box sx={{ width: '20%', padding: 2, borderRight: '1px solid #ccc' }}>
                        <Typography variant="h3">Lista Automezzi</Typography>

                        <div><strong>Id:</strong> {record.id}</div>
                        <div><strong>Gruppo:</strong> {record.tfb_gruppo}</div>
                        <div><strong>Descrizione:</strong> {record.tfb_des}</div>
                        <div><strong>Posti a sedere: </strong>
                            <ReferenceField link={false} label="" source="tfb_gruppo" reference="tabellealfa">
                                <FunctionField
                                    source=""
                                    label=""
                                    render={record => {
                                        const posti = record && parseInt(record.tbmy, 10);
                                        setNumPosti(posti); // Memorizza il numero di posti nello stato
                                        return posti;
                                    }}
                                />
                            </ReferenceField>
                        </div>
                        <hr />
                        <Box sx={{ height: 'calc(100vh - 64px)', overflowY: 'auto', scrollbarWidth: 'thin', scrollbarColor: 'cyan transparent' }}>
                            <style>
                                {`
                                    ::-webkit-scrollbar {
                                        width: 12px;
                                    }

                                    ::-webkit-scrollbar-track {
                                        background: transparent;
                                    }

                                    ::-webkit-scrollbar-thumb {
                                        background-color: cyan;
                                        border-radius: 20px;
                                        border: 3px solid transparent;
                                    }
                                `}
                            </style>
                            <GetListAutomezzi amtgruppo={record.tfb_gruppo} onUpdateTfbCkm={setTfbCkm} />
                        </Box>
                    </Box>
                    {/* Divider */}
                    <Divider orientation="vertical" flexItem />
                    {/* Colonna Destra */}
                    <Box sx={{ width: '80%', padding: 2 }}>
                        <Typography variant="h6">Parametri Input</Typography>
                        <Box component="form" sx={{ width: '100%' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="input_km"
                                        label="KM"
                                        type="number"
                                        value={inputValues.input_km}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="input_minuti"
                                        label="Minuti"
                                        type="number"
                                        value={inputValues.input_minuti}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="input_km_servizio"
                                        label="KM Servizio"
                                        type="number"
                                        value={inputValues.input_km_servizio}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="input_minuti_servizio"
                                        label="Minuti Servizio"
                                        type="number"
                                        value={inputValues.input_minuti_servizio}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="input_km_vuoto"
                                        label="KM a Vuoto"
                                        type="number"
                                        value={inputValues.input_km_vuoto}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="input_minuti_vuoto"
                                        label="Minuti a Vuoto"
                                        type="number"
                                        value={inputValues.input_minuti_vuoto}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                                    <Button onClick={handleCalculate} sx={{ ...saveButtonCss, margin: '10px' }}>Calcola</Button>
                                    <Button onClick={handleReset} sx={{ ...resetButtonCss, margin: '10px' }}>Reset Costo Km di Base</Button>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', width: '100%', mt: 2 }}>
                            <Box>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}>Budget km Tot tfa_bdgkm : <strong>{record.tfa_bdgkm}</strong></Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}>(Da Gruppo) Costo Km di Base tfb_ckm : <strong>{record.tfb_ckm}</strong>  €</Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}>(Da Automezzo) Costo Km di Base tfb_ckm : <strong>{tfbCkm}</strong>  €</Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}>Costo Supplemento Km ExtraBudget tfb_ekm : <strong>{record.tfb_ekm}</strong>  €</Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}>Costo Supplemento Km Extrabudget a vuoto tfb_evkm : <strong>{record.tfb_evkm}</strong>  €</Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}>Budget HH tfa_bdghh : <strong>{record.tfa_bdghh}</strong></Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}>Costo Minuto di Budget tfb_chh : <strong>{record.tfb_chh}</strong></Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}>Costo Supplemento Minuto ExtraBudget tfb_ehh : <strong>{record.tfb_ehh}</strong></Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}>Costo Suplemento Minuto  a vuoto tfb_evhh : <strong>{record.tfb_evhh}</strong></Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}>Minuti franchigia supp HH tfa_frgmmec : <strong>{record.tfa_frgmmec}</strong></Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}>Supplemento Notturno (%) supl_notturno : <strong>{record.tfb_snotturno}</strong></Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}>Minuti franchigia notturno : <strong>{record.tfa_frgmmnt}</strong></Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}>Max km a vuoto fatturabili tfb_vkm : <strong>{record.tfb_vkm}</strong></Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}>Max minuti a vuoto fatturabili tfb_vhh : <strong>{record.tfb_vhh}</strong></Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}>Max (%) Km a Vuoto Fatt tfb_vkmperc : <strong>{record.tfb_vkmperc}</strong></Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}>Max (%) Min a Vuoto Fatt tfb_vhhperc : <strong>{record.tfb_vhhperc}</strong></Box></div>
                            </Box>
                            <Box>
                                <Typography variant="h6">Risultato Calcoli</Typography>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}><strong>Importo budget km:</strong> {results.calc_importo_budget_km}</Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}><strong>KM di servizio:</strong> {inputValues.input_km_servizio}</Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}><strong>KM a vuoto AD:</strong> {inputValues.input_km_vuoto}</Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}><strong>KM a vuoto ND:</strong> </Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}><strong>KM totali:</strong> {results.calc_km_totali}</Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}><strong>KM eccedenza:</strong> {results.calc_km_eccedenza}</Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}><strong>Importo totale suppl. km:</strong> {results.calc_importo_totale_suppl_km}  €</Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}><strong>Importo totale km a vuoto:</strong> {results.calc_importo_totale_km_vuoto}  €</Box></div>
                                <hr />
                                <div><Box sx={fieldBaseDialogSimulatorForShow}><strong>Importo budget HH:</strong> {results.calc_importo_budget_hh}</Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}><strong>Minuti di servizio:</strong> {inputValues.input_minuti_servizio}</Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}><strong>Minuti a vuoto AD:</strong> {inputValues.input_minuti_vuoto}</Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}><strong>Minuti a vuoto ND:</strong> </Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}><strong>Minuti totali:</strong> {results.calc_minuti_totali}</Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}><strong>Minuti eccedenza:</strong> {results.calc_minuti_eccedenza}</Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}><strong>Importo totale suppl. HH:</strong> {results.calc_importo_totale_suppl_hh}</Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}><strong>Importo totale minuti a vuoto:</strong> {results.calc_importo_totale_minuti_a_vuoto}</Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}><strong>Importo suppl. notturno:</strong> {results.calc_importo_suppl_notturno} €</Box></div>
                                <hr />
                                <div><Box sx={fieldBaseDialogSimulatorForShow}><strong>Totale imponibile di servizio:</strong> {results.calc_totale_imponibile_di_servizio} €</Box></div>
                                <div><Box sx={fieldBaseDialogSimulatorForShow}><strong>Costo per posto:</strong> {results.calc_costo_per_posto} €</Box></div>
                                <br></br>
                                <br></br>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default Simulatore;
