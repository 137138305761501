// EditPassengersDialog.js
import * as React from 'react';
import { useUpdate, useNotify, useRefresh } from 'react-admin';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    TextField,
} from '@mui/material';
import { Group as GroupIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';

export const EditPassengersDialog = ({ open, onClose, record }) => {
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({
        bk0_paxad: record?.bk0_paxad || 0,
        bk0_paxch: record?.bk0_paxch || 0,
        bk0_paxin: record?.bk0_paxin || 0,
        bk0_nroseg: record?.bk0_nroseg || 0,
    });
    const [update] = useUpdate();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleChange = (field) => (event) => {
        const value = parseInt(event.target.value) || 0;
        setValues(prev => ({ ...prev, [field]: value }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await update(
                'booking-0',
                {
                    id: record.id,
                    data: values,
                }
            );
            notify('Informazioni passeggeri aggiornate', { type: 'success' });
            refresh();
            onClose();
        } catch (error) {
            notify('Errore durante l\'aggiornamento', { type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <GroupIcon />
                Modifica Informazioni Passeggeri
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            label="Adulti"
                            type="number"
                            value={values.bk0_paxad}
                            onChange={handleChange('bk0_paxad')}
                            fullWidth
                            inputProps={{ min: 0 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            label="Bambini"
                            type="number"
                            value={values.bk0_paxch}
                            onChange={handleChange('bk0_paxch')}
                            fullWidth
                            inputProps={{ min: 0 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            label="Infanti"
                            type="number"
                            value={values.bk0_paxin}
                            onChange={handleChange('bk0_paxin')}
                            fullWidth
                            inputProps={{ min: 0 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            label="Seggiolini"
                            type="number"
                            value={values.bk0_nroseg}
                            onChange={handleChange('bk0_nroseg')}
                            fullWidth
                            inputProps={{ min: 0 }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annulla</Button>
                <LoadingButton
                    loading={loading}
                    onClick={handleSubmit}
                    variant="contained"
                >
                    Salva
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
