import { AutomezziList } from '../automezzi/automezziList';
import { AutomezziShow } from '../automezzi/AutomezziShow';
import {
    AutomezziCreate,
    AutomezziEdit,
} from '../automezzi/automezzi';

export default {
    list: AutomezziList,
    create: AutomezziCreate,
    edit: AutomezziEdit,
    show: AutomezziShow,
};