import React from 'react';
import {
    useRecordContext,
    Loading,
    useGetOne
} from 'react-admin';
import CustomChip from '../CustomFields/CustomChip';

const GetValoreCampo = ({ source, reference }) => {
    const record = useRecordContext();
    const { data: user, isLoading, error } = useGetOne(reference, { id: record[source] });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return <CustomChip text={user.tbdes}></CustomChip>;
};

export default GetValoreCampo;
