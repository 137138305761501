import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    required,
    SimpleShowLayout,
    Show,
    ReferenceManyField,
    localStorageStore,
    useRecordContext,
    EditButton,
    TabbedShowLayout,
    useListContext,
    ShowButton,
    DatagridConfigurable,
    SelectColumnsButton,
    TopToolbar,
    ExportButton,
    FunctionField
} from 'react-admin';

import { useState, useEffect, Fragment } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import { Tabs, Tab, Divider } from '@mui/material';

import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";

import axiosInstance from "../../utils/axios"; // TODO: Necessario per l'upload

// Necessari per il form
import { /* Container, Row, Col, Form, */ ProgressBar /* , Button */ } from "react-bootstrap";

// Init store
const storeGenerale = localStorageStore();

const SetCurrentProgetto = ({ source, titolo, descrizione, clientiId }) => {
    const record = useRecordContext();
    // console.log("SetCurrentProgetto >>> " + record[clientiId]);
    // storeGenerale.setItem('profile.currentIstanzaEscursione', res);
    storeGenerale.setItem('profile.currentProgetto', 0);
    storeGenerale.setItem('profile.currentTitolo', 0);
    storeGenerale.setItem('profile.descrizioneProgetti', 0);
    storeGenerale.setItem('profile.clientiId', 0);
    storeGenerale.setItem('profile.currentProgetto', record[source]);
    storeGenerale.setItem('profile.currentTitolo', record[titolo]);
    storeGenerale.setItem('profile.descrizioneProgetti', record[descrizione]);
    storeGenerale.setItem('profile.clientiId', record[clientiId]);
    return record ? (
        <>
            {/* {record["clientiId"]} */}</>

    ) : <>
    </>;
}

// Validazione
const validateOperatoreCreation = (values) => {
    const errors = {};
    if (!values.nome) {
        errors.nome = 'Il nome è necessario';
    }
    if (!values.cognome) {
        errors.cognome = 'Il cognome è necessario';
    }
    /* if (!values.age) {
        // You can return translation keys
        errors.age = 'ra.validation.required';
    } else if (values.age < 18) {
        // Or an object if the translation messages need parameters
        errors.age = {
            message: 'ra.validation.minValue',
            args: { min: 18 }
        };
    } */
    return errors
};


/* const ClientiTitle = ({ record }) => {
    return <span>Modifica Team {record ? `"${record.nome}"` : ''}</span>;
}; */


/* const teamsFilters = [
    <TextInput source="nome" label="Cerca" alwaysOn />,
];
 */

const tabs = [
    { id: 'tab1', name: 'tab1' },
    { id: 'tab1', name: 'tab1' },
];

const ListActions = () => (
    <TopToolbar>
        <ExportButton />
        <SelectColumnsButton />
    </TopToolbar>
);

export const ClientiList = props => {

    return (
        <List filters={ClientiFilters} perPage={50} actions={<ListActions />}>
            {/* <Datagrid> */}
            <DatagridConfigurable
                rowClick="edit"
                omit={['note']}
                //  noDelete
                /*  editForm={<ClientiListForm />} */
                bulkActionButtons={false}
                expand={<ClientiExpand />}

            >

                {< TextField label="Cliente Id" source="id" />}
                {<TextField label="Ragione Sociale" source="ragionesociale" />}
                {<TextField label="Indirizzo" source="indirizzo" />}
                {<TextField label="Email" source="email" />}
                {<TextField label="Telefono" source="telefono" />}
                {<TextField label="Note" source="note" />}
                <FunctionField orderBy="status" label="status" render={record => {

                    return (
                        <><h3>{(record.status === 0 ? "Disattivo" : "Attivo")}</h3></>
                    );

                }} />
                <EditButton label="Modifica" />
                <ShowButton label="Dettaglio"></ShowButton>
            </DatagridConfigurable>


        </List >
    );
}

const ClientiListForm = (props) => (
    <RowForm {...props}>
        {<TextField label="Cliente Id" source="id" />}
        {<TextInput label="Ragione Sociale" source="ragionesociale" validate={[required()]} />}
        {<TextInput label="Indirizzo" source="indirizzo" validate={[required()]} />}
        {<TextInput label="Email" source="email" validate={[required()]} />}
        {<TextInput label="Telefono" source="telefono" validate={[required()]} />}
        {<TextInput label="Note" source="note" validate={[required()]} />}
    </RowForm>
);

const ClientiFilters = [
    <TextInput source="id" label="Cliente Id" />,
    <TextInput source="email" label="Email" />
];


const ClientiName = ({ record }) => {
    return <span>Operatore {record ? `"${record.name} ${record.cognome}"` : ''}</span>;
};

/* export const ClientiEdit = (props) => (
    <Edit title={<ClientiTitle />} name={<ClientiName />} {...props}>
        <SimpleForm validate={validateOperatoreCreation}>
        <TextInput disabled source="id" />
        <TextInput source="cognome" />
        <TextInput source="nome" />
        <ReferenceInput source="gruppiClientiId" reference="gruppi-addettis">
                        <SelectInput optionText="descrizione"/>
        </ReferenceInput>
        <TextField disabled source="updatedAt" />
        <TextField disabled source="createdAt" />
        </SimpleForm>
    </Edit>
); */

export const ClientiCreate = (props) => (
    <Create /* title={<ClientiTitle />} name={<ClientiName />} */ {...props}>
        <SimpleForm >


            {<TextInput label="Note" source="note" validate={[required()]} />}
            {<TextInput label="Ragione Sociale" source="ragionesociale" validate={[required()]} />}

            {<TextInput label="Indirizzo" source="indirizzo" validate={[required()]} />}
            {<TextInput label="Email" source="email" validate={[required()]} />}
            {<TextInput label="Telefono" source="telefono" validate={[required()]} />}



        </SimpleForm>
    </Create>
);

export const ClientiShow = (props) => (
    <Show>
        <TabbedShowLayout>
            <TabbedShowLayout.Tab label="summary">
                {<TextField label="Note" source="note" />}
                {<TextField label="Ragione Sociale" source="ragionesociale" />}
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label="body" path="body">
                {<TextField label="Indirizzo" source="indirizzo" />}
                {<TextField label="Email" source="email" />}
                {<TextField label="Telefono" source="telefono" />}
            </TabbedShowLayout.Tab>
        </TabbedShowLayout>
    </Show>
);


export const ClientiEdit = ({ id, ...props }) => (
    <Edit /* title={<ClientiTitle />} name={<ClientiName />} */ {...props}>
        <SimpleForm >
            {<TextField label="Cliente Id" source="id" />}
            {<TextInput label="Ragione Sociale" source="ragionesociale" validate={[required()]} />}
            {<TextInput label="Indirizzo" source="indirizzo" validate={[required()]} />}
            {<TextInput label="Email" source="email" validate={[required()]} />}
            {<TextInput label="Telefono" source="telefono" validate={[required()]} />}
            {<TextInput label="Note" source="note" validate={[required()]} />}
            <SetCurrentProgetto source="id" titolo="titolo" descrizione="descrizione" clientiId="id"></SetCurrentProgetto>
            <List pagination="" actions={false}>

                <ListItem>
                    {/*  <ListItemText
                        primary="Lista progetti"
                        secondary='Progetti legati al presente cliente.'
                    /> */}
                    <ReferenceManyField
                        reference="progetti"
                        target="clientiId"
                        fullWidth
                        perPage={10}
                        sort={{ field: 'id', order: 'ASC' }}
                    >
                        <EditableDatagrid
                            bulkActionButtons={false}
                            editForm={<ProgettiRowFormCard defaultValues={{ clientiId: id }} />}
                            createForm={<ProgettiRowFormCard defaultValues={{ clientiId: id }} />}
                        >
                            {/*                             <TextField source="id"></TextField>
                            <TextField source="titolo"></TextField> */}
                            <SimpleShowLayout>

                                <Grid /* container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} */>
                                    <Grid item xs={12} md={12}>
                                        {/* <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                                            Input progetto
                                        </Typography> */}

                                        <List pagination="" actions={false}>

                                            <ListItem>
                                                <TextField source="titolo"></TextField>
                                            </ListItem>

                                            <ListItem>
                                                <TextField source="descrizione"></TextField>
                                            </ListItem>


                                            {/* TODO: DA CREARE INPUT PER DETERMINARE SE IL PROGETTO è ABILITATO OPPURE NO */}
                                            {/* {<SelectInput label="Status" source="status" validate={[required()]} choices={[{ id: 'abilitato', name: 'abilitato' }, { id: 'disabilitato', name: 'disabilitato' }]} />} */}
                                        </List>
                                    </Grid>
                                </Grid>
                            </SimpleShowLayout>
                        </EditableDatagrid>

                    </ReferenceManyField>
                </ListItem>

            </List>
            {/* {<SelectInput label="Status" source="status" validate={[required()]} choices={[{ id: 'attivo', name: 'attivo' }, { id: 'disattivo', name: 'disattivo' }]} />} */}
        </SimpleForm>
    </Edit>
);

export const ClientiExpand = ({ id, ...props }) => {

    return (
        <Show {...props} id={id} /* aside={<Aside />} */>

            <SimpleShowLayout>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={6}>
                        {/*                         <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                            Crea nuovo progetto
                        </Typography> */}

                        <List pagination="" actions={false}>

                            <ListItem>
                                <ListItemText
                                    primary="Lista progetti"
                                    secondary='Progetti legati al presente cliente.'
                                />
                                {/*                                 <ReferenceManyField
                                    reference="progetti"
                                    target="clientiId"
                                    fullWidth
                                    perPage={10}
                                    sort={{ field: 'id', order: 'ASC' }}
                                >
                                    <EditableDatagrid
                                        bulkActionButtons={false}
                                        editForm={<ProgettiRowFormCard defaultValues={{ clientiId: id }} />}
                                        createForm={<ProgettiRowFormCard defaultValues={{ clientiId: id }} />}
                                    >
                                        <TextField source="id"></TextField>
                                        <TextField source="titolo"></TextField>
                                    </EditableDatagrid>

                                </ReferenceManyField> */}
                            </ListItem>

                        </List>
                    </Grid>
                </Grid>
            </SimpleShowLayout>

        </Show>
    );
}

export const ProgettiRowFormCard = ({ istanzeEscursioniId, serviziAggiuntiviId, ...props }) => {

    // console.log(storeGenerale.getItem('profile.currentIstanzaEscursione'));;
    // console.log(">>>>>>>>>>> " + clientiId);
    const idRecordProgetti = storeGenerale.getItem('profile.currentProgetto');
    const titoloProgetti = storeGenerale.getItem('profile.titoloProgetti');
    const descrizioneProgetti = storeGenerale.getItem('profile.descrizioneProgetti');
    const userId = storeGenerale.getItem('profile.idUser');
    const clientiId = storeGenerale.getItem('profile.clientiId');
    return (
        <RowForm {...props}>
            <Card mx={{ maxWidth: 345 }}>
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        {<TextInput disabled source="userId" label="userId" defaultValue={userId} validate={[required()]} />}
                        {<TextInput disabled source="clientiId" label="clientiId" defaultValue={clientiId} validate={[required()]} />}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {/* {<TextInput source="clientiId" label="clientiId" defaultValue={idRecordProgetti} validate={[required()]} />} */}
                        {<TextInput source="titolo" label="titolo" defaultValue={titoloProgetti} validate={[required()]} />}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {<TextInput source="descrizione" label="descrizione" defaultValue={descrizioneProgetti} validate={[required()]} />}
                    </Typography>
                </CardContent>

            </Card>

        </RowForm>
    );
}


