import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

const DettaglioButton = ({ codice }) => {
    // Costruisce il link dinamico
    const dettaglioLink = `/#/anagen/${codice}/show/tariffeServiziPax`;

    return (
        <Tooltip title="Dettaglio">
            <IconButton
                href={dettaglioLink}
                target="_blank" // Apre in una nuova scheda
                rel="noopener noreferrer"
                color="primary"
            >
                <InfoIcon />
            </IconButton>
        </Tooltip>
    );
};

export default DettaglioButton;
