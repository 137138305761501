
import * as React from "react";
import {
    List,
    EditButton,
    TextInput,
    Create,
    SimpleForm,
    TextField,
    Edit,
    TopToolbar,
    Show,
    ShowButton,
    WrapperField,
    CreateButton,
    Toolbar,
    SaveButton,
    ReferenceField,
    TabbedForm,
    ReferenceManyCount,
    ReferenceManyField,
    useRecordContext,
    FunctionField,
    Datagrid,
    usePermissions,
    useRefresh,
    RichTextField,
    BooleanInput
} from 'react-admin';

// Importo componenti input custom
// import CustomAutocompleInput from '../shared/FormFields/CustomAutocompleInput';
import CustomAutocompleInputUsers from '../shared/FormFields/CustomAutocompleInputUsers';

import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";

import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";

import { RichTextInput } from 'ra-input-rich-text';

import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import NoteIcon from '@mui/icons-material/Note';
import WorkIcon from '@mui/icons-material/Work';
import Button from '@mui/material/Button';
import BusinessIcon from '@mui/icons-material/Business';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import { ToBoolean } from '../shared/ToBoolean';
import { Divider } from '@mui/material';

// Impost Custom List Page 
import EmptyListCustom from '../shared/emptyPageCustom/empryPageCustom';
import EmptyListCustomNoCreate from '../shared/emptyPageCustom/empryPageCustomNoCreate';

// Importo il css per i Bottoni
import { editButtonCss, saveButtonCss, showButtonCss, exportButtonCss, columnsButtonCss, createButtonCss, filterButtonCss } from '../shared/Styles/buttonStyles';
import { listCss, listItemDefault } from '../shared/Styles/listStyles';

import CustomTextFieldDataOra from "../shared/FormFields/CustomTextFieldDataOra";

// Gestione lista note
import GestioneNote from "../note/GestioneNote";
import GetDescrizioneVoce from "./GetDescrizioneVoce";

const ShowActions = () => (
    <TopToolbar>
        <EditButton sx={editButtonCss} label="Modifica" />
    </TopToolbar>
);


const GruppiShow = (props) => {

    const refresh = useRefresh();

    return (
        <Show title={<>
            &nbsp;Dettaglio Gruppo&nbsp;
            <TextField label="Nome" source="descrizione" />
        </>} actions={<ShowActions />}>

            <TabbedForm toolbar={null}>
                <TabbedForm.Tab label="Dati Gruppo">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                                <HomeIcon />
                                                Nome Gruppo: <TextField label="" source="descrizione" />
                                            </Box>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                                <HomeIcon />
                                                Specifiche di gruppo: <RichTextField label="" source="specifiche" />
                                            </Box>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Users" count={<ReferenceManyCount reference="users-gruppi"
                    target="gruppiId" />}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Alert severity="info">Attenzione, in questo tab puoi selezionare solo user con ruolo <strong>Gruppi</strong></Alert>
                            <ReferenceManyField
                                reference="users-gruppi"
                                target="gruppiId"
                                sort={{ field: 'id', order: 'ASC' }}
                            >
                                <GestioneUsersInShow></GestioneUsersInShow>
                            </ReferenceManyField>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Permessi Voci Menu" count={<ReferenceManyCount reference="permessi"
                    target="usersGroupId" />}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Alert severity="info">Gestione permessi di gruppi</Alert>
                        </Grid>
                        <ReferenceManyField
                            reference="permessi"
                            target="usersGroupId"
                            sort={{ field: 'menuprogId', order: 'ASC' }}
                            filter={{ tipoPermesso: 'G' }}
                        >
                            <GestionePermessiInShow></GestionePermessiInShow>
                        </ReferenceManyField>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Permessi Servizi">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Alert severity="info">Gestione permessi dei singoli servizi</Alert>
                        </Grid>
                        <Box sx={{ margin: 2 }} >
                            <ReferenceManyField
                                reference="servicegroup"
                                target="gruppiId"
                                sort={{ field: 'id', order: 'ASC' }}
                                perPage={100}
                            // pagination={null}

                            >
                                <GestionePermessiServicesInShow></GestionePermessiServicesInShow>
                            </ReferenceManyField>
                        </Box>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Messaggi" path="annotazioni">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <p><strong>Comunicazioni interne</strong></p>
                            <GestioneNote tiporecordId="4" refresh={refresh} ></GestioneNote>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Proprieta" path="proprieta">
                    <div>
                        <div>
                            {<CustomTextFieldDataOra label="Data creazione" source="createdAt" />}
                            <br></br>
                            {<CustomTextFieldDataOra label="Data ultima modifica" source="updatedAt" />}
                        </div>
                    </div>
                    <div>
                        {<ToBoolean label="Attivo" source="flAttivo" labelOn={true}></ToBoolean>}
                        {<ToBoolean label="Visibile" source="flVisibile" labelOn={true}></ToBoolean>}
                        {<ToBoolean label="Annullato" source="flAnnullato" labelOn={true}></ToBoolean>}
                    </div>
                    <Divider variant="middle" />
                    <ConfirmDialogDeleteButtonCustom
                        titolo={"Cancella Gruppo"}
                        messaggio={"Sei sicuro di vole cancellare questo gruppo ?"}
                        servizio={"gruppi"} />
                </TabbedForm.Tab>
            </TabbedForm>

        </Show >
    );
}

const GestioneUsersInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    return (<EditableDatagrid
        bulkActionButtons={false}
        editForm={<UsersShowDisponibiliRowForm />}
        createForm={<UsersShowDisponibiliRowForm id={record.id} />}
    >
        <Card variant="outlined"
            sx={{ margin: 1, borderRadius: 2 }}
        >
            <CardContent>
                <ReferenceField source="usersId" reference="users" link={false}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Avatar sx={{ width: 56, height: 56, marginRight: 2 }}>

                        </Avatar>
                        <Typography variant="subtitle1" component="div">
                            ID User : <TextField source="id" />
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                        <Typography variant="subtitle1" component="div">
                            Email : <strong><TextField source="email" /></strong>
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                        <Typography variant="subtitle1" component="div">
                            {<FunctionField
                                source="usersId"
                                render={record => {
                                    console.log(record);
                                    return <NavigateButton recordId={record.id} service="users" message="Dettaglio User"></NavigateButton>;
                                }}
                            />}
                        </Typography>
                    </Box>

                </ReferenceField>
            </CardContent>
        </Card>
    </EditableDatagrid>);

}

const GestionePermessiInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    return (<EditableDatagrid noDelete
        bulkActionButtons={false}
        editForm={<PermessiShowDisponibiliRowForm />}
    >
        {/* <Card variant="outlined"
            sx={{ margin: 1, borderRadius: 2 }}
        >
            <CardContent> */}
        {/* <TextField source="id" ></TextField> */}
        <ReferenceManyField sortable={false} label="" reference="permessi" target="usersGroupId">
            {/* <TextField source="menuprogId" /> */}
            <FunctionField
                sortable={false}
                source="menuprogId" // used for sorting
                label="Voce menu"
                render={record => record && <GetDescrizioneVoce id={record.menuprogId} ></GetDescrizioneVoce>}
            />
        </ReferenceManyField>



        {/* </CardContent>
        </Card> */}

        {<ToBoolean sortable={false} label="Attivo" source="flAttivo" labelOn={true}></ToBoolean>}
        {<ToBoolean sortable={false} label="Visibile" source="flVisibile" labelOn={true}></ToBoolean>}
        {<ToBoolean sortable={false} label="Annullato" source="flAnnullato" labelOn={true}></ToBoolean>}
        {<ToBoolean sortable={false} label="Abilitato" source="flAbilitato" labelOn={true}></ToBoolean>}

    </EditableDatagrid>);

}

const GestionePermessiServicesInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    return (<EditableDatagrid noDelete
        bulkActionButtons={false}
        editForm={<PermessiServicesShowDisponibiliRowForm />}
    >
        {/* <Card variant="outlined"
            sx={{ margin: 1, borderRadius: 2 }}
        >
            <CardContent> */}
        {/* <TextField source="id" ></TextField> */}
        <TextField sx={listItemDefault} source="nome" ></TextField>
        <TextField source="descrizione" ></TextField>
        <ToBoolean label="Read" source="read_permission" labelOn={true} />
        <ToBoolean label="Create" source="create_permission" labelOn={true} />
        <ToBoolean label="Update" source="update_permission" labelOn={true} />
        <ToBoolean label="Patch" source="patch_permission" labelOn={true} />
        <ToBoolean label="Remove" source="remove_permission" labelOn={true} />

        {/* <ReferenceManyField sortable={false} label="" reference="servicegroup" target="gruppiId">
          
            <FunctionField
                sortable={false}
                source="menuprogId" // used for sorting
                label="Voce menu"
                render={record => record && <GetDescrizioneVoce id={record.menuprogId} ></GetDescrizioneVoce>}
            />
        </ReferenceManyField> */}



        {/* </CardContent>
        </Card> */}

        {/*  {<ToBoolean sortable={false} label="Attivo" source="flAttivo" labelOn={true}></ToBoolean>}
        {<ToBoolean sortable={false} label="Visibile" source="flVisibile" labelOn={true}></ToBoolean>}
        {<ToBoolean sortable={false} label="Annullato" source="flAnnullato" labelOn={true}></ToBoolean>} */}

    </EditableDatagrid>);

}


const NavigateButton = ({ recordId, service, message }) => {
    const handleNavigate = () => {

        const url = `#/${service}/${recordId}/show`;
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <Button sx={showButtonCss} onClick={handleNavigate}>{message}</Button>
    );
};

const UsersShowDisponibiliRowForm = ({ id, ...props }) => {
    return (
        <RowForm  {...props}>
            <TextInput source="gruppiId" defaultValue={parseInt(id)} disabled ></TextInput>
            <CustomAutocompleInputUsers isFiltered={true} filterField="roles" filterValue="gruppi" source="usersId" reference="users" optionProperty="email" label="" ></CustomAutocompleInputUsers>

        </RowForm>
    );

}

const PermessiShowDisponibiliRowForm = ({ id, ...props }) => {
    return (
        <RowForm  {...props}>
            <ReferenceManyField label="" reference="permessi" target="usersGroupId">
                {/* <TextField source="menuprogId" /> */}
                <FunctionField
                    source="menuprogId" // used for sorting
                    label="Voce menu"
                    render={record => record && <GetDescrizioneVoce id={record.menuprogId} ></GetDescrizioneVoce>}
                />
            </ReferenceManyField>
            <BooleanInput label="Attivo" source="flAttivo" />
            <BooleanInput label="Visibile" source="flVisibile" />
            <BooleanInput label="Annullato" source="flAnnullato" />
            <BooleanInput label="Abilitato" source="flAbilitato" />
            <TextInput source="usersGroupId" defaultValue={parseInt(id)} disabled ></TextInput>
        </RowForm>
    );

}

const PermessiServicesShowDisponibiliRowForm = ({ id, ...props }) => {
    return (
        <RowForm  {...props}>
            <TextField source="nome" ></TextField>
            <TextField source="descrizione" ></TextField>
            <BooleanInput label="Read" source="read_permission" />
            <BooleanInput label="Create" source="create_permission" />
            <BooleanInput label="Update" source="update_permission" />
            <BooleanInput label="Patch" source="patch_permission" />
            <BooleanInput label="Remove" source="remove_permission" />
            <TextInput source="gruppiId" defaultValue={parseInt(id)} disabled ></TextInput>
        </RowForm>
    );

}

export default GruppiShow;