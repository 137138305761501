import React from 'react';
import { Chip, Tooltip } from '@mui/material';
import { styled } from '@mui/system';

// Creiamo un CHIP stilizzato con sfondo grigio e contorno verde
const StyledChip = styled(Chip)(({ theme }) => ({
    backgroundColor: '#f0f0f0', // Sfondo grigio chiaro
    border: '1px solid green', // Contorno verde
    color: 'black', // Testo nero
    fontWeight: 'bold',
    transition: 'transform 0.3s ease', // Animazione fluida al passaggio del mouse
    '&:hover': {
        transform: 'scale(1.05)', // Leggero ingrandimento al passaggio del mouse
    }
}));

const CustomChip = ({ text }) => (
    <Tooltip title={text} arrow>
        <StyledChip label={text} />
    </Tooltip>
);

export default CustomChip;
