
import { Create, SimpleForm, TextInput, DateInput, NumberInput } from 'react-admin';

export const TariffaServpaxCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <NumberInput source="anagenId" />
            <NumberInput source="servpaxId" />
            <DateInput source="dataFrom" />
            <DateInput source="dataTo" />
            <TextInput source="costo" />
            <TextInput source="costo2" />
            <TextInput source="costo3" />
            <NumberInput source="flAttivo" />
            <NumberInput source="flVisibile" />
            <NumberInput source="flAnnullato" />
            <NumberInput source="flPredefinito" />
            <NumberInput source="ordinamento" />
            <DateInput source="createdAt" />
            <DateInput source="updatedAt" />
        </SimpleForm>
    </Create>
);
