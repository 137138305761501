import React, { useState } from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Paper, Typography, Container, CircularProgress } from '@material-ui/core';
import Grid from '@mui/material/Grid';

import { urlLogo } from '../../utils/urlBase';
import { Dialog, DialogActions, DialogContent, DialogTitle, Link as MuiLink } from '@mui/material';


import config from '../../config/config.json';

const host = config.production.host;

const useStyles = makeStyles((theme) => ({
    recoverPassword: {
        marginTop: theme.spacing(2),
        textAlign: 'center',
    },
    recoverPasswordLink: {
        color: '#00acc1',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    resetPasswordDialog: {
        padding: theme.spacing(3),
        backgroundColor: '#f0f4f8',
        borderRadius: '8px',
    },
    errorMessage: {
        color: 'red',
        textAlign: 'center',
        marginTop: theme.spacing(2),
    },
    logo: {
        marginBottom: '1rem',
        width: '200px',
        height: 'auto',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    logoTuvoni: {
        marginBottom: '1rem',
        width: '45px',
        height: 'auto',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3),
        border: 'none',
        boxShadow: 'none',
    },
    form: {
        border: 0,
        width: '100%',
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    submit: {
        fontFamily: 'Roboto',
        letterSpacing: '2px',
        fontSize: '10px',
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#00acc1',
        color: 'white',
        '&:hover': {
            backgroundColor: '#00838f',
        }
    },
    container: {
        backgroundColor: '#00acc1',
        backgroundSize: 'cover',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
    },
    containerbg: {
        backgroundColor: '#00acc1',
        backgroundSize: 'cover',
        height: '100vh',
        alignItems: 'center',
    },
    inputField: {
        '& label.Mui-focused': {
            color: '#00acc1',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: 'white',
        },
        '& .MuiInputBase-root': {
            color: 'white',
            backgroundColor: '#00acc1',
        },
        letterSpacing: '2px',
    },
    passwordLabel: {
        color: '#00acc1',
        fontWeight: 'bold',
        textAlign: 'center',
        fontFamily: 'Roboto',
        fontSize: '20px',
        marginTop: theme.spacing(8),
        letterSpacing: '2px'
    },
    idLabel: {
        color: '#00acc1',
        fontWeight: 'bold',
        textAlign: 'center',
        fontFamily: 'Roboto',
        fontSize: '20px',
        letterSpacing: '3px'
    },
    resetData: {
        marginTop: theme.spacing(2),
        textAlign: 'center',
        color: '#00acc1',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    loadingIndicator: {
        marginTop: theme.spacing(2),
    },
    dialogButton: {
        backgroundColor: '#00acc1',
        color: 'white',
        '&:hover': {
            backgroundColor: '#00838f',
        }
    }

}));

const MyLoginPage = ({ theme }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const login = useLogin();
    const notify = useNotify();
    const classes = useStyles();
    const [resetDialogOpen, setResetDialogOpen] = useState(false);
    const [resetEmail, setResetEmail] = useState('');

    const isInputPopulated = email !== '' || password !== '';

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        setLoading(true);
        try {
            await login({ username: email, password });
        } catch (error) {
            console.log(error.message);

            if (error.message === 'Invalid login') {
                setErrorMessage('Password errata');
            } else if (error.body && error.body.message) {
                setErrorMessage(error.body.message);
            } else if (error.message === 'NetworkError when attempting to fetch resource.') {
                setErrorMessage('Server non raggiungibile al momento, verificare la rete. ' + error.message);
            } else {
                setErrorMessage('Si è verificato un errore durante il login. Motivo: ' + error.message);
            }
            notify('Errore di autenticazione', { type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleResetData = () => {
        setEmail('');
        setPassword('');
    };

    const handleResetPassword = async () => {
        try {
            const response = await fetch(`${host}/password-reset`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: resetEmail }),
            });

            if (response.ok) {
                notify('Email di reset password inviata. Controlla la tua casella di posta.', { type: 'info' });
                setResetDialogOpen(false);
            } else {
                const error = await response.json();
                notify(`Errore: ${error.message}`, { type: 'error' });
            }
        } catch (error) {
            notify('Errore durante l\'invio dell\'email di reset', { type: 'error' });
        }
    };
    return (
        <Container maxWidth="xl" className={classes.containerbg}>
            <Container component="main" maxWidth="xs" className={classes.container}>
                <Grid container spacing={3} direction="column" >
                    <Grid item xs>
                        <Paper className={classes.paper} >
                            <Typography component="h1" variant="h5">
                                <img src={urlLogo} alt="Logo" className={classes.logo} />
                            </Typography>

                            <form className={classes.form} onSubmit={handleSubmit}>

                                <Typography variant="h7" className={classes.idLabel}>
                                    LOGIN
                                </Typography>
                                <TextField
                                    variant="standard"
                                    margin="normal"
                                    required
                                    fullWidth
                                    type="email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    className={classes.inputField}
                                />
                                <Typography variant="h7" className={classes.passwordLabel}>
                                    PASSWORD
                                </Typography>
                                <TextField
                                    variant="standard"
                                    margin="normal"
                                    required
                                    fullWidth
                                    type="password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    className={classes.inputField}
                                />

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    disabled={loading}
                                >
                                    {loading ? <CircularProgress size={24} className={classes.loadingIndicator} /> : 'Accedi'}
                                </Button>
                                {errorMessage && (
                                    <Typography className={classes.errorMessage}>
                                        {errorMessage}
                                    </Typography>
                                )}
                                {!isInputPopulated ? (
                                    <Typography className={classes.recoverPassword}>
                                        <MuiLink
                                            component="button"
                                            variant="body2"
                                            onClick={() => setResetDialogOpen(true)}
                                            className={classes.recoverPasswordLink}
                                        >
                                            Recupera password
                                        </MuiLink>
                                    </Typography>
                                ) : (
                                    <Typography
                                        className={classes.resetData}
                                        onClick={handleResetData}
                                    >
                                        Reset Dati
                                    </Typography>
                                )}
                            </form>
                        </Paper>
                        <Notification />
                    </Grid>
                </Grid>
            </Container>

            <Dialog open={resetDialogOpen} onClose={() => setResetDialogOpen(false)} className={classes.resetPasswordDialog}>
                <DialogTitle>Recupera Password</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="resetEmail"
                        label="Indirizzo Email"
                        type="email"
                        fullWidth
                        value={resetEmail}
                        onChange={(e) => setResetEmail(e.target.value)}
                        variant="outlined"
                        InputProps={{
                            style: {
                                backgroundColor: 'white',
                                borderRadius: '4px',
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setResetDialogOpen(false)} className={classes.dialogButton}>
                        Annulla
                    </Button>
                    <Button onClick={handleResetPassword} className={classes.dialogButton}>
                        Invia Email di Reset
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default MyLoginPage;