


            import * as React from 'react';
            import { Create, SimpleForm, Toolbar, TextInput, BooleanInput,
                SaveButton,
                required } from 'react-admin';
            import { editButtonCss, saveButtonCss, showButtonCss, exportButtonCss, columnsButtonCss, deleteButtonCss, filterButtonCss } from '../shared/Styles/buttonStyles';
            import { formCss, formCssTextInput } from "../shared/Styles/formCss";
            import PeopleIcon from '@mui/icons-material/People';
            import Button from '@mui/material/Button';

            

const MyToolbarEdit = () => (
    <Toolbar>
        <SaveButton sx={saveButtonCss} /* sx={{
            border: 1,
            borderColor: 'black',
            borderRadius: '20px',
            backgroundColor: '#01b5d0'
        }} */ label="Salva parametri" alwaysEnable />
    </Toolbar>
);

export const serviziotestCreate = () => (
    <Create title={<>
            <Button>
                <PeopleIcon></PeopleIcon>
                &nbsp;Crea record su serviziotest
            </Button>
        </>} redirect="show">
        <SimpleForm sx={formCss} toolbar={<MyToolbarEdit />}>
 <TextInput source="nome" validate={required()}/>
            <TextInput source="note" validate={required()}/>
            <BooleanInput source="flAttivo" validate={required()}/>
            <BooleanInput source="fiVisibile" validate={required()}/>
            <BooleanInput source="flAnnullato" validate={required()}/>
        </SimpleForm>
    </Create>
);