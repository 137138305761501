import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import HorizontalLinearStepper from './HorizontalLinearStepper'; // Assicurati di importare correttamente lo Stepper
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Footer from './Footer'; // Assicurati di avere un componente Footer

/* CSS */
import { cssAppBar, cssAppBarTitle, cssLogo, cssBoxLogo } from './css/cssPreventiviPrenotazioni';

/* LOGO */
import { urlLogo } from '../../utils/urlBase';

function PreventiviPrenotazioni() {
    return (
        <div>
            {/* <AppBar position="static" sx={cssAppBar}>
                <Toolbar>
                    <Box sx={cssBoxLogo}>
                        <img src={urlLogo} alt="Logo" style={cssLogo} />
                    </Box>
                    <Typography variant="h6" sx={cssAppBarTitle}>
                        PREVENTIVI E PRENOTAZIONI
                    </Typography>
                </Toolbar>
            </AppBar> */}

            <Container component="main" maxWidth="md" sx={{ mt: 10 }}>
                <Box sx={{ my: 4 }}>
                    <Paper elevation={3} sx={{ p: 3 }}>
                        <HorizontalLinearStepper />
                    </Paper>
                </Box>
            </Container>

            {/* <Footer /> */}
        </div>
    );
}

export default PreventiviPrenotazioni;
