import React from 'react';
import {
    Box,
    Paper,
    Typography,
    Link,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Alert,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import FolderIcon from '@mui/icons-material/Folder';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CodeIcon from '@mui/icons-material/Code';
import HelpIcon from '@mui/icons-material/Help';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const InstallationGuide = () => (
    <Accordion defaultExpanded={false} sx={{ mb: 3 }}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
                backgroundColor: 'background.paper',
                borderBottom: '1px solid',
                borderColor: 'divider',
                '&:hover': {
                    backgroundColor: 'action.hover',
                }
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <HelpIcon color="primary" />
                <Typography variant="h6" color="primary">
                    Guida importazione dati da Software Amico
                </Typography>
            </Box>
        </AccordionSummary>

        <AccordionDetails sx={{ pt: 3, px: 3 }}>
            <Alert severity="info" sx={{ mb: 3 }}>
                Prima di procedere con l'importazione, è necessario configurare il client per lo scarico automatico dei dati.
            </Alert>

            <Stepper orientation="vertical">
                <Step active={true}>
                    <StepLabel>
                        <Typography variant="subtitle1">Download del Programma</Typography>
                    </StepLabel>
                    <StepContent>
                        <Typography paragraph>
                            Scaricare il programma di importazione da:
                        </Typography>
                        <Link
                            href="https://www.amico-on-line.it/programmi/importRifo.exe"
                            target="_blank"
                            sx={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                gap: 1,
                                backgroundColor: '#f5f5f5',
                                padding: '8px 16px',
                                borderRadius: '4px',
                                textDecoration: 'none'
                            }}
                        >
                            <DownloadIcon /> importRifo.exe
                        </Link>
                    </StepContent>
                </Step>

                <Step active={true}>
                    <StepLabel>
                        <Typography variant="subtitle1">Installazione</Typography>
                    </StepLabel>
                    <StepContent>
                        <Typography paragraph>
                            Il programma può scaricare e decodificare i dati dei rifornimenti in una qualunque cartella del PC dove è installato il software Amico.
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'text.secondary' }}>
                            <FolderIcon />
                            <Typography variant="body2">
                                Si consiglia di creare una cartella dedicata per l'importazione
                            </Typography>
                        </Box>
                    </StepContent>
                </Step>

                <Step active={true}>
                    <StepLabel>
                        <Typography variant="subtitle1">Configurazione</Typography>
                    </StepLabel>
                    <StepContent>
                        <Typography paragraph>
                            Il comando da inserire nel file batch per avviare lo scarico è:
                        </Typography>
                        <Paper sx={{ p: 2, bgcolor: '#f5f5f5' }}>
                            <code>importRifo.exe [SERVER] [CUSTOMERCODE] [SECURITYCODE]</code>
                        </Paper>
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                            <CodeIcon sx={{ fontSize: 'small', verticalAlign: 'middle', mr: 0.5 }} />
                            Sostituire i parametri con i valori forniti dal supporto tecnico
                        </Typography>
                    </StepContent>
                </Step>

                <Step active={true}>
                    <StepLabel>
                        <Typography variant="subtitle1">Schedulazione</Typography>
                    </StepLabel>
                    <StepContent>
                        <Typography paragraph>
                            Il programma può essere schedulato per l'esecuzione automatica con le seguenti caratteristiche:
                        </Typography>
                        <Box sx={{ pl: 2 }}>
                            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <ScheduleIcon sx={{ fontSize: 'small' }} />
                                Frequenza massima: 60 minuti
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                • Prima esecuzione: scarica i dati degli ultimi 3 mesi
                            </Typography>
                            <Typography variant="body2">
                                • Il file amico.dat verrà creato/aggiornato ad ogni esecuzione
                            </Typography>
                            <Typography variant="body2">
                                • Un file log.txt registrerà l'esito di ogni operazione
                            </Typography>
                        </Box>
                    </StepContent>
                </Step>
            </Stepper>

            <Alert severity="warning" sx={{ mt: 3 }}>
                <Typography variant="subtitle2" gutterBottom>
                    Note Importanti:
                </Typography>
                <Typography variant="body2">
                    • Il file amico.dat può essere cancellato dopo l'importazione
                </Typography>
                <Typography variant="body2">
                    • Verrà ricreato automaticamente alla prossima esecuzione
                </Typography>
                <Typography variant="body2">
                    • Controllare periodicamente il file log.txt per verificare il corretto funzionamento
                </Typography>
            </Alert>
        </AccordionDetails>
    </Accordion>
);

export default InstallationGuide;