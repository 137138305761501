


import { Show, SimpleShowLayout, TopToolbar, TextField, DateField, BooleanField, EditButton } from 'react-admin';
import { ToBoolean } from '../shared/ToBoolean';
import { editButtonCss, saveButtonCss, showButtonCss, exportButtonCss, columnsButtonCss, deleteButtonCss, filterButtonCss } from '../shared/Styles/buttonStyles';
import { fieldBaseForShow } from '../shared/Styles/fieldsStyles';
import PeopleIcon from '@mui/icons-material/People';
import Button from '@mui/material/Button';
import { Breadcrumb } from '@react-admin/ra-navigation';
import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";
import { formCss, formCssTextInput } from "../shared/Styles/formCss";



const ShowActions = () => (
    <ShowTopToolbarDettaglio></ShowTopToolbarDettaglio>
);

const ShowTopToolbarDettaglio = () => (
    <TopToolbar>
        <EditButton sx={editButtonCss} label="Modifica" />
        <ConfirmDialogDeleteButtonCustom
            titolo={"Cancella record"}
            messaggio={"Sei sicuro di vole cancellare questo record ?"}
            servizio={"serviziotest"} />
    </TopToolbar>
);


export const serviziotestShow = () => (
    <Show title={<>
        <Button>
            <PeopleIcon></PeopleIcon>
            &nbsp;Dettaglio record di serviziotest
        </Button>
    </>}
        actions={<ShowActions />}
    >
        <Breadcrumb />
        <SimpleShowLayout sx={formCss} >

            <TextField source="nome" />
            <TextField source="note" />
            <ToBoolean source="flAttivo" />
            <ToBoolean source="fiVisibile" />
            <ToBoolean source="flAnnullato" />

            <EditButton sx={editButtonCss} label="Modifica" />
        </SimpleShowLayout>
    </Show>
);