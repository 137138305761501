import { useGetOne, Loading } from 'react-admin';


const GetDescrizioneTipoDocumento = ({ tipodocumentoId }) => {
    const { data: recordTipoDocumento, isLoading, error } = useGetOne('tipodocumento', { id: tipodocumentoId });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return <>{recordTipoDocumento.descrizione}</>;
};

export default GetDescrizioneTipoDocumento;