import React, { useState } from 'react';
import axios from 'axios';
import {
    TextField,
    Button,
    Typography,
    IconButton,
    InputAdornment,
    makeStyles
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import config from '../../config/config.json';

const host = config.production.host;
const hostLogin = config.production.hostLogin;

const useStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '20px auto',
        width: 300,
    },
    textField: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    button: {
        color: "white",
        backgroundColor: "orange",
        width: '100%',
        marginTop: theme.spacing(2),
    },
    statusMessage: {
        marginTop: theme.spacing(2),
        textAlign: 'center',
    },
}));

const GetEditPassword = ({ token }) => {
    const classes = useStyles();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [resetStatus, setResetStatus] = useState('');

    const handleShowPasswordClick = () => {
        setShowPassword(!showPassword);
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setResetStatus('Le password non coincidono');
            return;
        }

        try {
            // Modifica qui: utilizziamo PATCH invece di POST e l'endpoint corretto
            await axios.patch(`${host}/password-reset`, { token, newPassword });
            setResetStatus('Password reset avvenuto con successo. Sarai reindirizzato alla pagina di login.');
            setTimeout(() => {
                window.location.href = hostLogin;
            }, 3000);
        } catch (error) {
            console.error('Errore durante il reset della password:', error);
            setResetStatus(`Errore durante il reset della password: ${error.response?.data?.message || error.message}`);
        }
    };

    return (
        <form className={classes.formContainer} onSubmit={handleResetPassword}>
            <TextField
                label="Nuova password"
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
                className={classes.textField}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleShowPasswordClick}>
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                label="Conferma nuova password"
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
                className={classes.textField}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleShowPasswordClick}>
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
            >
                Reset Password
            </Button>
            {resetStatus && (
                <Typography variant="body1" color={resetStatus.includes('successo') ? 'primary' : 'error'} className={classes.statusMessage}>
                    {resetStatus}
                </Typography>
            )}
        </form>
    );
};

export default GetEditPassword;