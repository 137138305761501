import { AnagenList } from '../anagen/anagenList';
import { AnagenCreate } from '../anagen/anagenCreate';
import { AnagenEdit } from '../anagen/anagenEdit';
import { AnagenShow } from '../anagen/anagenShow';

export default {
    list: AnagenList,
    create: AnagenCreate,
    edit: AnagenEdit,
    show: AnagenShow,
};