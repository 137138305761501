import React from 'react';
import { useRecordContext } from 'react-admin';


export const ToBoolean = ({ source, label, labelOn }) => {
    const record = useRecordContext();

    return record ? (
        <p>
            {(labelOn) ?
                <strong>
                    {label}&nbsp;{record[source] === 1 ? " Si" : " No"}
                    {/* {record[source]} - Se vuoi mostrare il valore originale */}
                    {/* <LaunchIcon className={classes.icon} /> - Aggiungi l'icona se necessario */}
                </strong>
                :
                <strong>
                    &nbsp;{record[source] === 1 ? " Si" : " No"}
                    {/* {record[source]} - Se vuoi mostrare il valore originale */}
                    {/* <LaunchIcon className={classes.icon} /> - Aggiungi l'icona se necessario */}
                </strong>
            }
        </p>
    ) : null;
};

