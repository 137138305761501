
import { serviziotestList } from './serviziotestList';
import { serviziotestCreate } from './serviziotestCreate';
import { serviziotestEdit } from './serviziotestEdit';
import { serviziotestShow } from './serviziotestShow';

const serviziotest = {
    list: serviziotestList,
    create: serviziotestCreate,
    edit: serviziotestEdit,
    show: serviziotestShow,
};

export default serviziotest;
