import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import { Title, usePermissions } from 'react-admin';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { blue, green, orange } from '@mui/material/colors';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import PersonIcon from '@mui/icons-material/Person';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import BusinessIcon from '@mui/icons-material/Business';
import feathersClient from '../../feathersClient';

const services = [
    { name: 'Anagrafiche', service: 'anagen', icon: PersonIcon, color: blue[500] },
    { name: 'Autisti', service: 'autisti', icon: LocalShippingIcon, color: green[500] },
    { name: 'Fornitori Servizi', service: 'view-fornitori-servpax', icon: BusinessIcon, color: orange[500] }
];

function ServiceCard({ service, count }) {
    const Icon = service.icon;
    return (
        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Icon sx={{ fontSize: 60, color: service.color, mb: 2 }} />
                <Typography variant="h5" component="div" gutterBottom>
                    {service.name}
                </Typography>
                <Typography variant="h3" color="text.secondary">
                    {count !== undefined ? count : 'Caricamento...'}
                </Typography>
            </CardContent>
        </Card>
    );
}

function MonitorPage() {
    const { permissions } = usePermissions();
    const [serviceCounts, setServiceCounts] = useState({});

    const fetchServiceData = useCallback(async () => {
        const newCounts = {};
        for (const service of services) {
            try {
                const result = await feathersClient.service(service.service).find({
                    query: {
                        $limit: 0
                    }
                });
                newCounts[service.service] = result.total;
            } catch (error) {
                console.error(`Error fetching data for ${service.name}:`, error);
                newCounts[service.service] = 'Error';
            }
        }
        setServiceCounts(newCounts);
    }, []);

    useEffect(() => {
        fetchServiceData();
        const intervalId = setInterval(fetchServiceData, 30000); // Aggiorna ogni 30 secondi

        return () => clearInterval(intervalId);
    }, [fetchServiceData]);

    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>
            <Title title="Monitor Servizi" />
            {permissions === "admin" && (
                <Card sx={{ mb: 3, backgroundColor: '#fff3f3' }}>
                    <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <ErrorOutlineIcon sx={{ fontSize: 40, color: 'red', mr: 2 }} />
                        <Typography variant="h5" color="error">
                            GRUPPO ADMIN - Monitoraggio Servizi
                        </Typography>
                    </CardContent>
                </Card>
            )}
            <Typography variant="h4" sx={{ mb: 3, color: '#01b5d0', textAlign: 'center' }}>
                Dashboard Servizi
            </Typography>
            <Grid container spacing={3}>
                {services.map((service) => (
                    <Grid item xs={12} sm={6} md={4} key={service.service}>
                        <ServiceCard service={service} count={serviceCounts[service.service]} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default MonitorPage;