import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { DateInput, DateField, required } from 'react-admin';
import { makeStyles } from '@mui/styles';

const styles = {
    TextInput: {
        border: 0,
        borderRadius: 1,
        color: 'green',
        height: 70,
        padding: '0 0px',
    },
    card: {
        marginBottom: '16px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    // Aggiungiamo stili specifici per il label
    cardHeader: {
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
        padding: '8px 16px',
    },
    label: {
        fontWeight: 500,
        fontSize: '1rem',
        color: '#424242',
    },
    content: {
        padding: '16px',
    }
};

const useStyles = makeStyles(styles);

const CustomDateInputNoMargini = ({ source, reference, optionProperty, label, readOnly }) => {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            {/* Utilizziamo CardHeader per posizionare il label in modo prominente */}
            <CardHeader
                className={classes.cardHeader}
                title={
                    <Typography className={classes.label}>
                        {label || 'Data'} {/* Forniamo un fallback nel caso il label non sia definito */}
                    </Typography>
                }
            />
            <CardContent className={classes.content}>
                <Box display="flex" alignItems="center">
                    <Typography variant="body1" style={{ marginBottom: 10, width: '100%' }}>
                        {(readOnly) ?
                            <DateField
                                color="success"
                                className={classes.TextInput}
                                variant="outlined"
                                source={source}
                                placeholder=""
                                InputProps={{
                                    readOnly: readOnly ?? false,
                                    'aria-label': label, // Aggiungiamo l'aria-label per l'accessibilità
                                }}
                            />
                            :
                            <DateInput
                                color="success"
                                className={classes.TextInput}
                                variant="outlined"
                                source={source}
                                placeholder=""
                                InputProps={{
                                    readOnly: readOnly ?? false,
                                    'aria-label': label, // Aggiungiamo l'aria-label per l'accessibilità
                                }}
                            />
                        }
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

export default CustomDateInputNoMargini;