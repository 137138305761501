import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TopToolbar,
    TextField,
    EditButton,
    TabbedShowLayout,
    useRecordContext,
    useUpdate,
    useNotify,
    useRefresh,
    ReferenceManyField,
    Datagrid,
    DateField,
} from 'react-admin';
import { editButtonCss } from '../shared/Styles/buttonStyles';
import { formCss } from "../shared/Styles/formCss";
import PeopleIcon from '@mui/icons-material/People';
import Button from '@mui/material/Button';
import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";
import Grid from '@mui/material/Grid';
import CustomTextFieldDataOra from "../shared/FormFields/CustomTextFieldDataOra";
import {
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Switch,
    Box,
    Avatar,
    Typography
} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import { makeStyles } from '@mui/styles';
import ServicesField from './preventivi/ServicesField';
import AdvancedUserDetails from './AdvancedUserDetails';
import UserActivities from './UserActivities';

const useStyles = makeStyles((theme) => ({
    headerCard: {
        marginBottom: theme.spacing(3),
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    },
    headerContent: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2),
    },
    avatar: {
        width: 100,
        height: 100,
        marginRight: theme.spacing(3),
    },
    userInfo: {
        flexGrow: 1,
    },
    infoItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    icon: {
        marginRight: theme.spacing(1),
        color: theme.palette.secondary.main,
    },
}));

const ShowActions = () => (
    <ShowTopToolbarDettaglio />
);

const ShowTopToolbarDettaglio = () => (
    <TopToolbar>
        <EditButton sx={editButtonCss} label="Modifica" />
    </TopToolbar>
);

const StatusLED = ({ status }) => (
    <Box display="flex" alignItems="center">
        <FiberManualRecordIcon
            sx={{
                color: status ? 'green' : 'red',
                marginRight: 1,
                fontSize: '0.8rem'
            }}
        />
        <Typography>{status ? 'Attivo' : 'Inattivo'}</Typography>
    </Box>
);
const StatusEditDialog = () => {
    const record = useRecordContext();
    const [open, setOpen] = React.useState(false);
    const [status, setStatus] = React.useState(record.status);
    const [update] = useUpdate();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        setStatus(event.target.checked);
    };

    const handleSave = () => {
        update(
            'users',
            { id: record.id, data: { status } },
            {
                onSuccess: () => {
                    notify('Status aggiornato con successo', { type: 'success' });
                    refresh();
                    handleClose();
                },
                onError: (error) => notify(`Errore: ${error.message}`, { type: 'error' }),
            }
        );
    };

    return (
        <>
            <Button variant="outlined" onClick={handleClickOpen} size="small">
                Modifica Status
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Modifica Status Utente</DialogTitle>
                <DialogContent>
                    <Box my={2}>
                        <StatusLED status={status} />
                    </Box>
                    <Switch
                        checked={status}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Annulla</Button>
                    <Button onClick={handleSave}>Salva</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const UserStatus = () => {
    const record = useRecordContext();
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <StatusLED status={record.status} />
            <StatusEditDialog />
        </Box>
    );
};

const UserHeader = () => {
    const record = useRecordContext();
    const classes = useStyles();

    return (
        <Card className={classes.headerCard}>
            <CardContent className={classes.headerContent}>
                <Avatar
                    src={record.avatar}
                    className={classes.avatar}
                >
                    {!record.avatar && <AccountCircleIcon fontSize="large" />}
                </Avatar>
                <div className={classes.userInfo}>
                    <Typography variant="h5">
                        {record.nome} {record.cognome}
                    </Typography>
                    <div className={classes.infoItem}>
                        <EmailIcon className={classes.icon} />
                        <Typography>{record.email}</Typography>
                    </div>
                    <StatusLED status={record.status} />
                </div>
            </CardContent>
        </Card>
    );
};

export const usersShow = () => (
    <Show
        title={
            <Button startIcon={<PeopleIcon />}>
                Dettaglio User
            </Button>
        }
        actions={<ShowActions />}
    >
        <SimpleShowLayout sx={formCss}>
            <UserHeader />
            <TabbedShowLayout sx={formCss}>
                <TabbedShowLayout.Tab label="Anagrafica Base"
                    sx={{
                        '&.Mui-selected': {
                            borderBottom: '3px solid #01b5d0',
                            backgroundColor: '#e8e8e8'
                        }
                    }}
                >
                    <Grid container spacing={3}>
                        {/* Colonna sinistra */}
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>User e tipologia</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" style={{ marginRight: 8 }}>
                                                Email : <TextField label="Email" source="email" fullWidth />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" style={{ marginRight: 8 }}>
                                                Ruolo : <TextField label="Ruolo" source="roles" fullWidth />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Colonna destra */}
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>Dettagli di contatto</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" style={{ marginRight: 8 }}>
                                                Nome :  <TextField label="Nome" source="nome" fullWidth />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" style={{ marginRight: 8 }}>
                                                Cognome :  <TextField label="Cognome" source="cognome" fullWidth />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" style={{ marginRight: 8 }}>
                                                Telefono :  <TextField label="Telefono" source="telefono" />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Anagrafica Avanzata" path="anagraficaavanzata"
                    sx={{
                        '&.Mui-selected': {
                            borderBottom: '3px solid #01b5d0',
                            backgroundColor: '#e8e8e8'
                        }
                    }}
                >
                    <Grid container spacing={3}>
                        {/* Colonna sinistra */}
                        <Grid item xs={12} md={6}>
                            <AdvancedUserDetails />
                        </Grid>

                        {/* Colonna destra */}
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardContent>
                                    {/* DA RIEMPIRE PROSSIMAMENTE */}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Preventivi Pacchetto" path="preventivi"
                    sx={{
                        '&.Mui-selected': {
                            borderBottom: '3px solid #01b5d0',
                            backgroundColor: '#e8e8e8'
                        }
                    }}
                >
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Lista dei Preventivi Pacchetto</Typography>
                        </CardContent>
                    </Card>
                    {/* Lista dei preventivi associati */}
                    <ReferenceManyField
                        label="Preventivi"
                        reference="preventivi"
                        target="userId"
                    >
                        <Datagrid bulkActionButtons={false}>
                            <DateField source="createdAt" />
                            <TextField source="data.startPoint" label="Partenza" />
                            <TextField source="data.endPoint" label="Destinazione" />
                            <TextField source="data.distance" label="Distanza" />
                            <TextField source="data.duration" label="Durata" />
                            <TextField source="data.totalCost" label="Costo Totale" />
                            <ServicesField label="Servizi" />
                        </Datagrid>
                    </ReferenceManyField>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Proprieta" path="proprieta"
                    sx={{
                        '&.Mui-selected': {
                            borderBottom: '3px solid #01b5d0',
                            backgroundColor: '#e8e8e8'
                        }
                    }}
                >
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Informazioni di Sistema</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CustomTextFieldDataOra label="Data creazione" source="createdAt" />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomTextFieldDataOra label="Data ultima modifica" source="updatedAt" />
                                </Grid>
                                <Grid item xs={12}>
                                    <UserStatus />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    {/* <Box mt={2} display="flex" justifyContent="flex-end">
                        <ConfirmDialogDeleteButtonCustom
                            titolo={"Cancella User"}
                            messaggio={"Sei sicuro di voler cancellare questo User?"}
                            servizio={"users"}
                            sx={{
                                fontSize: '0.8rem',
                                padding: '4px 8px',
                                backgroundColor: '#f44336',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#d32f2f',
                                }
                            }}
                        />
                    </Box> */}
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Attività" path="attivita"
                    sx={{
                        '&.Mui-selected': {
                            borderBottom: '3px solid #01b5d0',
                            backgroundColor: '#e8e8e8'
                        }
                    }}
                >
                    <UserActivities />
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>
        </SimpleShowLayout>
    </Show>
);