import React from 'react';
import { Box, Typography, Tooltip, styled } from '@mui/material';
import { Brightness2 } from '@mui/icons-material'; // Icona di luna da Material-UI

// Creazione dell'icona stilizzata
const StyledMoon = styled(Brightness2)(({ bk2_suplementoNotturno }) => ({
    color: bk2_suplementoNotturno === 1 ? 'yellow' : 'transparent', // Verde se attivo, trasparente altrimenti
    fontSize: '2rem',
    marginRight: '8px',
    transition: 'transform 0.3s ease', // Animazione fluida
    transform: bk2_suplementoNotturno === 1 ? 'scale(1.2)' : 'scale(1)', // Cambiamento di dimensione
}));

const TrattaNotturnaLED = ({ bk2_suplementoNotturno }) => (
    <Tooltip title={bk2_suplementoNotturno ? 'Questo record è attivo' : 'Questo record è inattivo'} arrow>
        <Box display="flex" alignItems="center">
            {bk2_suplementoNotturno === 1 && ( // Mostra la luna solo se è attivo
                <StyledMoon bk2_suplementoNotturno={bk2_suplementoNotturno} />
            )}
            <Typography variant="body2" sx={{ fontWeight: bk2_suplementoNotturno === 1 ? 'bold' : 'normal' }}>
                {bk2_suplementoNotturno === 1 ? 'Si' : '-'}
            </Typography>
        </Box>
    </Tooltip>
);

export default TrattaNotturnaLED;
