
import { ServpaxEdit } from './servpaxEdit';
import { ServpaxList } from './servpaxList';
import { ServpaxShow } from './servpaxShow';
import { ServpaxCreate } from './servpaxCreate';
import MapIcon from '@material-ui/icons/Map';

export default {
    list: ServpaxList,
    edit: ServpaxEdit,
    show: ServpaxShow,
    create: ServpaxCreate,
    icon: MapIcon
};
