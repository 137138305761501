import {
    AutistiList,
    AutistiCreate,
    AutistiEdit,
    AutistiShow,
} from './autisti';

export default {
    list: AutistiList,
    create: AutistiCreate,
    edit: AutistiEdit,
    show: AutistiShow,
};