import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReferenceInput, TextInput, required } from 'react-admin';
import { formCssDropdownMenu } from "../Styles/formCss";
import { makeStyles } from '@mui/styles';

import CopyToClipboardButton from '../FormFields/CopyToClipboardButton'

// TODO: DA SPOSTARE I CSS
/* CSS - BASE - FORM - START */
const styles = {

    TextInput: {
        // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        // background: 'linear-gradient(45deg, #01b5d0 10%, #ffffff 90%)',
        border: 0,
        borderRadius: 1,
        // boxShadow: '0px 0px 3px 2px #01b5d0',
        color: 'green',
        height: 50,
        padding: '0 0px',
    },
};


const useStyles = makeStyles(styles);
/* CSS - BASE - FORM - END */

const CustomTextInput = ({ source, reference, optionProperty, label, readOnly }) => {
    const classes = useStyles();

    return (
        <div>
            <Box display="flex" alignItems="center" >
                <Typography variant="body1" style={{ marginRight: 8 }}>{label}: </Typography>
                {<TextInput nolabel="true" className={classes.TextInput} variant="outlined" label={label} source={source} /* validate={[required()]} */
                    color="success"
                    InputProps={{
                        readOnly: readOnly ?? false, // readOny non viene passato metto per default false
                    }} />}
                {<CopyToClipboardButton source={source} />}
            </Box>
        </div>
    );
};

export default CustomTextInput;
