import { useRecordContext } from 'react-admin';
import CostiUfficialiList from "./CostiUfficialiList";

const GestioneCostiUfficiali = ({ tiporecordId, refresh }) => {
    const record = useRecordContext();
    const userId = localStorage.getItem("RaStore.profile.idUser");
    const emailUser = localStorage.getItem("RaStore.profile.email");
    if (!record) return null;
    return record ? (
        <>
            <CostiUfficialiList tiporecordId={tiporecordId} recordId={record.id} emailUser={emailUser}></CostiUfficialiList>
        </>
    ) : null;
};

export default GestioneCostiUfficiali;
