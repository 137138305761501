import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FilterList, FilterListItem } from 'react-admin';
import AssignmentIcon from '@material-ui/icons/Assignment';
import config from '../../../config/config.json';

const host = config.production.host;

// Destruttura i props direttamente nella firma della funzione
const FilterListDinamico = ({ service, label, source }) => {
    const [items, setItems] = useState([]);

    const jwt = localStorage.getItem("feathers-jwt");

    useEffect(() => {
        const fetchData = async () => {
            const headers = {
                Authorization: 'Bearer ' + jwt,
            };

            try {
                // Usa un template string per costruire l'URL
                const response = await axios.get(`${host}/${service}`, { headers });
                if (response.status === 200) {
                    setItems(response.data.data);
                }
            } catch (error) {
                console.error('Si è verificato un errore durante il recupero dei dati:', error);
            }
        };

        fetchData();
    }, [service]); // Aggiungi service come dipendenza per rifare la fetch se cambia

    return (
        <FilterList label={label} icon={<AssignmentIcon />}>
            {items.map((item) => {
                // Costruisci myValue dinamicamente all'interno del loop
                const myValue = { [source]: item.id };
                return (
                    <FilterListItem key={item.id} label={item.descrizione} value={myValue} />
                );
            })}
        </FilterList>
    );
};

export default FilterListDinamico;
