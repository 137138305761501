import React from 'react';
import { Card, CardContent, Typography, Box, Grid, Divider } from '@mui/material';
import { Note as NoteIcon } from '@mui/icons-material';


const ImpegnoNonServizioCard = ({ impegno }) => {
    if (!impegno) return null;

    return (
        <Card sx={{ mb: 3, boxShadow: 3 }}>
            <CardContent>
                {/* Titolo */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '2px solid #1976d2',
                        paddingBottom: 1,
                        marginBottom: 3,
                    }}
                >
                    <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                        <NoteIcon sx={{ mr: 1 }} />
                        Impegno Non Servizio Calcolato
                    </Typography>
                </Box>

                {/* Contenuto */}
                <Grid container spacing={3}>
                    {/* Dettagli Km */}
                    <Grid item xs={6} md={6}>
                        <Typography variant="subtitle1" color="primary" gutterBottom>
                            Km Totale
                        </Typography>
                        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <span>Km: <Typography component="strong" fontWeight="bold">{impegno.total_pkmt}</Typography></span>
                        </Typography>
                    </Grid>
                    {/* Dettagli Tempo */}
                    <Grid item xs={6} md={6}>
                        <Typography variant="subtitle1" color="primary" gutterBottom>
                            Tempo Totale
                        </Typography>
                        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <span>Ore: <Typography component="strong" fontWeight="bold">{impegno.recalculated_ore}</Typography></span>
                            <span>|</span>
                            <span>Minuti: <Typography component="strong" fontWeight="bold">{impegno.recalculated_minuti}</Typography></span>
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ImpegnoNonServizioCard;
