import React, { useState } from 'react';
import { useUpdate, useNotify } from 'react-admin';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { cancelButtonCss, saveButtonCss } from '../../shared/Styles/buttonStyles';

function EditCostiUffciali({ record }) {
    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const initialData = {
        amtarga: record.amtarga || '',
        prezzoVenditaEff: record.prezzoVenditaEff || 0,
        costiFissiPrev: record.costiFissiPrev || 0,
        costiFissiEff: record.costiFissiEff || 0,
        costiVarPrev: record.costiVarPrev || 0,
        costiVarEff: record.costiVarEff || 0,
        costiGenPrev: record.costiGenPrev || 0,
        costiGenEff: record.costiGenEff || 0,
        costiComplPrev: record.costiComplPrev || 0,
        costiComplEff: record.costiComplEff || 0,
        kmPrev: record.kmPrev || 0,
        kmEff: record.kmEff || 0,
        ricaricoStabPrev: record.ricaricoStabPrev || 0,
        ricaricoStabEff: record.ricaricoStabEff || 0,
        ricaricoStabPercPrev: record.ricaricoStabPercPrev || 0,
        ricaricoStabPercEff: record.ricaricoStabPercEff || 0
    };
    const [editData, setEditData] = useState(initialData);
    const [update, { isLoading, error }] = useUpdate();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setEditData(initialData);
        setOpen(false);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEditData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSave = () => {
        update(
            'automezzi-costi',
            { id: record.id, data: editData, previousData: record },
            {
                onSuccess: ({ data }) => {
                    notify('Costi aggiornati con successo');
                },
                onFailure: (error) => {
                    notify(error.message, 'warning');
                }
            }
        );
        setOpen(false);
    };

    if (error) {
        return <p>ERROR</p>;
    }

    return (
        <Box>
            <Button sx={saveButtonCss} variant="outlined" onClick={handleClickOpen}>
                Modifica
            </Button>
            <Dialog maxWidth={"md"} open={open} onClose={handleClose}>
                <DialogTitle>Modifica Costi Ufficiali</DialogTitle>
                <DialogContent sx={{
                    width: 500,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    {/* <TextField
                        autoFocus
                        margin="dense"
                        name="amtarga"
                        label="Targa"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={editData.amtarga}
                        onChange={handleChange}
                    /> */}
                    <TextField
                        margin="dense"
                        name="prezzoVenditaEff"
                        label="Prezzo Vendita Effettivo"
                        inputProps={{ min: 0 }} type="number"
                        fullWidth
                        variant="outlined"
                        value={editData.prezzoVenditaEff}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="costiFissiPrev"
                        label="Costi Fissi Previsti"
                        inputProps={{ min: 0 }} type="number"
                        fullWidth
                        variant="outlined"
                        value={editData.costiFissiPrev}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="costiFissiEff"
                        label="Costi Fissi Effettivi"
                        inputProps={{ min: 0 }} type="number"
                        fullWidth
                        variant="outlined"
                        value={editData.costiFissiEff}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="costiVarPrev"
                        label="Costi Variabili Previsti"
                        inputProps={{ min: 0 }} type="number"
                        fullWidth
                        variant="outlined"
                        value={editData.costiVarPrev}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="costiVarEff"
                        label="Costi Variabili Effettivi"
                        inputProps={{ min: 0 }} type="number"
                        fullWidth
                        variant="outlined"
                        value={editData.costiVarEff}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="costiGenPrev"
                        label="Costi Generali Previsti"
                        inputProps={{ min: 0 }} type="number"
                        fullWidth
                        variant="outlined"
                        value={editData.costiGenPrev}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="costiGenEff"
                        label="Costi Generali Effettivi"
                        inputProps={{ min: 0 }} type="number"
                        fullWidth
                        variant="outlined"
                        value={editData.costiGenEff}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="costiComplPrev"
                        label="Costi Complessivi Previsti"
                        inputProps={{ min: 0 }} type="number"
                        fullWidth
                        variant="outlined"
                        value={editData.costiComplPrev}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="costiComplEff"
                        label="Costi Complessivi Effettivi"
                        inputProps={{ min: 0 }} type="number"
                        fullWidth
                        variant="outlined"
                        value={editData.costiComplEff}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="kmPrev"
                        label="Km Previsti"
                        inputProps={{ min: 0 }} type="number"
                        fullWidth
                        variant="outlined"
                        value={editData.kmPrev}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="kmEff"
                        label="Km Effettivi"
                        inputProps={{ min: 0 }} type="number"
                        fullWidth
                        variant="outlined"
                        value={editData.kmEff}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="ricaricoStabPrev"
                        label="Ricarico Stabilito Previsto"
                        inputProps={{ min: 0 }} type="number"
                        fullWidth
                        variant="outlined"
                        value={editData.ricaricoStabPrev}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="ricaricoStabEff"
                        label="Ricarico Stabilito Effettivo"
                        inputProps={{ min: 0 }} type="number"
                        fullWidth
                        variant="outlined"
                        value={editData.ricaricoStabEff}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="ricaricoStabPercPrev"
                        label="Ricarico Stabilito % Previsto"
                        inputProps={{ min: 0 }} type="number"
                        fullWidth
                        variant="outlined"
                        value={editData.ricaricoStabPercPrev}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="ricaricoStabPercEff"
                        label="Ricarico Stabilito % Effettivo"
                        inputProps={{ min: 0 }} type="number"
                        fullWidth
                        variant="outlined"
                        value={editData.ricaricoStabPercEff}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button sx={cancelButtonCss} onClick={handleClose}>Annulla</Button>
                    <Button sx={saveButtonCss} onClick={handleSave}>Salva</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default EditCostiUffciali;
