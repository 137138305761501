import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Plot from 'react-plotly.js';
import config from '../../config/config.json';

const StatusPieChartBooking0Status = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const jwt = localStorage.getItem("feathers-jwt");

    useEffect(() => {



        const fetchData = async () => {
            try {
                const configBackend = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `${config.production.host}/view-booking-0-status-riepilogo`,
                    headers: {
                        'Authorization': `Bearer ${jwt}`,
                    },
                };


                const response = await axios.request(configBackend);
                console.log("response : ", response);

                setData(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Errore durante il fetch dei dati:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <p>Caricamento in corso...</p>;
    }

    // Estrarre i dati necessari per il grafico a torta
    const labels = data.map(item => item.descrizione);
    const values = data.map(item => item.count);

    return (
        <div>
            <Plot
                data={[
                    {
                        type: 'pie',
                        labels: labels,
                        values: values,
                        textinfo: 'label+percent',
                        textposition: 'inside',
                        automargin: true,
                    },
                ]}
                layout={{
                    title: 'Conteggio Preventivi per Status',
                    height: 400,
                    width: 500,
                    margin: { t: 50, b: 50, l: 50, r: 50 },
                }}
                config={{
                    responsive: true,
                }}
            />
        </div>
    );
};

export default StatusPieChartBooking0Status;
