// src/components/CustomError.js
import * as React from 'react';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';

const CustomError = ({ error, onRetry }) => (
    <Card>
        <CardContent>
            <Box
                sx={{
                    textAlign: 'center',
                    py: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2
                }}
            >
                <Typography variant="h5" color="error" gutterBottom>
                    {error.title}
                </Typography>
                <Typography variant="body1">
                    {error.message}
                </Typography>
                {error.title === 'Accesso Negato' && (
                    <Typography variant="body2" color="textSecondary">
                        Contatta l'amministratore se pensi che questo sia un errore.
                    </Typography>
                )}
                <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                    {error.title !== 'Accesso Negato' && (
                        <Button
                            variant="outlined"
                            onClick={onRetry}
                            startIcon={<RestartAltIcon />}
                        >
                            Riprova
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        onClick={() => window.location.href = '#/'}
                    >
                        Torna alla Dashboard
                    </Button>
                </Box>
            </Box>
        </CardContent>
    </Card>
);

export default CustomError;