// EditableTimeComponent.js
import React, { useState } from 'react';
import { useRecordContext, useUpdate, useRefresh, useNotify } from 'react-admin';
import { TextField, IconButton, Box } from '@mui/material';
import { Edit as EditIcon, Save as SaveIcon, Cancel as CancelIcon } from '@mui/icons-material';

const EditableTimeComponent = () => {
    const record = useRecordContext();
    const [isEditing, setIsEditing] = useState(false);
    const [hours, setHours] = useState(record?.bk2_ore || '00');
    const [minutes, setMinutes] = useState(record?.bk2_minuti || '00');
    const [update] = useUpdate();
    const refresh = useRefresh();
    const notify = useNotify();

    if (!record) return null;

    const handleEdit = () => {
        setHours(record.bk2_ore || '00');
        setMinutes(record.bk2_minuti || '00');
        setIsEditing(true);
    };

    const handleSave = async () => {
        const hoursNum = parseInt(hours, 10);
        const minutesNum = parseInt(minutes, 10);

        if (isNaN(hoursNum) || isNaN(minutesNum) ||
            hoursNum < 0 || hoursNum > 23 ||
            minutesNum < 0 || minutesNum > 59) {
            notify('Formato tempo non valido', { type: 'error' });
            return;
        }

        // Calcola il tempo totale in secondi
        const tempoInSecondi = (hoursNum * 3600) + (minutesNum * 60);

        try {
            await update(
                'booking-2',
                {
                    id: record.id,
                    data: {
                        bk2_ore: hours.padStart(2, '0'),
                        bk2_minuti: minutes.padStart(2, '0'),
                        bk2_secondi: '00',
                        bk2_tempoInSecondi: tempoInSecondi,
                        // Mantieni gli altri campi invariati
                        bk2_ptime: record.bk2_ptime
                    },
                }
            );
            notify('Tempo aggiornato con successo', { type: 'success' });
            setIsEditing(false);
            refresh();
        } catch (error) {
            notify('Errore nell\'aggiornamento del tempo', { type: 'error' });
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
    };

    if (isEditing) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <TextField
                    size="small"
                    value={hours}
                    onChange={(e) => setHours(e.target.value)}
                    sx={{ width: '60px' }}
                    inputProps={{
                        maxLength: 2,
                        style: { textAlign: 'center' }
                    }}
                />
                <span>:</span>
                <TextField
                    size="small"
                    value={minutes}
                    onChange={(e) => setMinutes(e.target.value)}
                    sx={{ width: '60px' }}
                    inputProps={{
                        maxLength: 2,
                        style: { textAlign: 'center' }
                    }}
                />
                <IconButton size="small" onClick={handleSave} color="primary">
                    <SaveIcon />
                </IconButton>
                <IconButton size="small" onClick={handleCancel} color="error">
                    <CancelIcon />
                </IconButton>
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <span>{record.bk2_ore || '00'}:{record.bk2_minuti || '00'}</span>
            <IconButton size="small" onClick={handleEdit}>
                <EditIcon />
            </IconButton>
        </Box>
    );
};

export default EditableTimeComponent;