import React from 'react';
import { useNotify } from 'react-admin';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const CopyToClipboardButtonString = ({ text }) => {
    const notify = useNotify();

    const copyToClipboard = async (textToCopy) => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            notify('Copiato negli appunti!', 'info');
        } catch (err) {
            notify('Errore durante la copia!', 'warning');
        }
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => copyToClipboard(text)} aria-label="copy">
                <FileCopyIcon />
            </IconButton>
        </div>
    );
};

export default CopyToClipboardButtonString;
