import * as React from "react";
import {
    List,
    TextField,
    SimpleShowLayout,
    Show,
    EditButton,
    TopToolbar,
    TabbedForm,
    NumberInput,
    RichTextField,
    useRefresh,
    FunctionField,
} from 'react-admin';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import { CardActionArea, CardActions, CardHeader } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import PeopleIcon from '@mui/icons-material/People';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";

// Gestione lista note
import GestioneNote from "../note/GestioneNote";

// Gestione costi ufficiali
import GestioneCostiUfficiali from "./costiUfficiali/GestioneCostiUfficiali";

// Importo il css per i Bottoni
import { editButtonCss } from '../shared/Styles/buttonStyles';
import { formCss } from "../shared/Styles/formCss";
import { ToBoolean } from '../shared/ToBoolean';

// Importo componenti input custom
import CustomAutocompleInput from '../shared/FormFields/CustomAutocompleInput';
import CustomTextInput from '../shared/FormFields/CustomTextInput';
import CustomTextFieldDataOra from "../shared/FormFields/CustomTextFieldDataOra";
import CustomTextFieldData from "../shared/FormFields/CustomTextFieldDataOra";

// Necessari per il form
import MySimpleMap from "../shared/OpenStreet/MySimpleMap";
import GeoDetails from "./GeoDetails";

/* CSS - BASE - FORM - START */
const styles = {

    TextInput: {
        border: 0,
        borderRadius: 1,
        color: 'green',
        height: 50,
        padding: '0 0px',
    },
};


const useStyles = makeStyles(styles);
/* CSS - BASE - FORM - END */


const ShowActions = () => (
    <TopToolbar>
        <EditButton sx={editButtonCss} label="Modifica" />
    </TopToolbar>
);

export const AutomezziShow = (props) => {

    // Coordinate Base di Budoni
    // Latitudine: 40.7095, Longitude: 9.69923
    const latitude = 40.7095;
    const longitude = 9.69923;

    const classes = useStyles();
    const refresh = useRefresh();

    return (
        <Show title={<>
            <Button>
                <PeopleIcon></PeopleIcon>
                &nbsp;Dettaglio Automezzo
            </Button>
        </>} actions={<ShowActions />}>
            <Card sx={{ minWidth: 275 }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: '#01b5d0' }}>
                            <AssignmentIcon />
                        </Avatar>
                    }
                    title={<><strong>ID Automezzo :</strong>< TextField label="Automezzi Id" source="id" /></>}
                    subheader={
                        <>
                            <strong>Testo per ricerca :</strong> {<TextField label="Targa" source="amtarga" />}
                            <br />
                        </>}
                />
            </Card>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 2, width: '30ch' },
                }}
            >
                <TabbedForm sx={formCss} toolbar={null}>
                    <TabbedForm.Tab label="Anagrafica 1"
                        sx={{
                            '&.Mui-selected': {
                                borderBottom: '3px solid #01b5d0',
                                backgroundColor: '#e8e8e8'
                            }
                        }}
                    >
                        <Divider variant="middle" />
                        <CustomTextInput source="amid" label="Id Classificazione Automezzo" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="id" label="Automezzo Id su DB" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amtarga" label="Targa" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomAutocompleInput source="amtmarca_d1" reference="view-tabellaalfa-mar" optionProperty="descrizione" label="Marca" readOnly={true} ></CustomAutocompleInput>
                        <Divider variant="middle" />


                        <div>
                            <Divider variant="middle" />
                            <CustomAutocompleInput source="amtdestinazione_j1" reference="view-tabellaalfa-dsu" optionProperty="descrizione" label="Destinazione" readOnly={true} ></CustomAutocompleInput>
                            <Divider variant="middle" />
                        </div>
                        <div>
                            <Divider variant="middle" />
                            <CustomAutocompleInput source="amtmarca_d1" reference="view-tabellaalfa-mar" optionProperty="descrizione" label="Carrozzeria***" readOnly={true} ></CustomAutocompleInput>
                            <Divider variant="middle" />

                        </div>
                        <div>
                            <Divider variant="middle" />
                            <CustomAutocompleInput source="amtgruppo" reference="view-tabellaalfa-grp" optionProperty="descrizione" label="Gruppo" readOnly={true} ></CustomAutocompleInput>
                            <Divider variant="middle" />
                        </div>


                        {/*  */}

                        <Divider variant="middle" />
                        {<CustomTextFieldData label="Data prima immatricolazione B" source="amimmdata_b" />}
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amimmnro" label="Numero di immatricolazione" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        {<CustomTextFieldData label="Data prima immatricolazione I" source="amimmdata_i" />}
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomAutocompleInput source="amtintest_c2" reference="view-tabellaalfa-int" optionProperty="descrizione" label="Organizzazione" readOnly={true} ></CustomAutocompleInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amtelaio_e" label="Numero telaio" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amnrmotore_p5" label="Numero motore" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amnromologa_k" label="Numero omologazione" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomAutocompleInput source="amtcategoria_j" reference="view-tabellaalfa-cat" optionProperty="descrizione" label="Categoria" readOnly={true} ></CustomAutocompleInput>
                        <Divider variant="middle" />
                        <CustomAutocompleInput source="amtcambio" reference="view-tabellaalfa-cmb" optionProperty="descrizione" label="Cambio" readOnly={true} ></CustomAutocompleInput>
                        <Divider variant="middle" />
                        <CustomAutocompleInput source="amtcombustibile_p3" reference="view-tabellaalfa-gas" optionProperty="descrizione" label="Alimentazione" readOnly={true} ></CustomAutocompleInput>
                        <Divider variant="middle" />
                        <CustomAutocompleInput source="amtaereazione" reference="view-tabellaalfa-aer" optionProperty="descrizione" label="Aerazione" readOnly={true} ></CustomAutocompleInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amcilindrata_p1" label="Cilindrata CM3" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amkw_p2" label="Potenza Kw" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amnrassi_l" label="Numero assi" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="ammassamax_f2" label="Massa Max a carico Kg" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="Anagrafica 2"
                        sx={{
                            '&.Mui-selected': {
                                borderBottom: '3px solid #01b5d0',
                                backgroundColor: '#e8e8e8'
                            }
                        }}
                    >

                        <Divider variant="middle" />
                        <CustomTextInput source="ampaxnrdw_s1" label="Posti a sedere" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="ampaxnrdw_ass" label="Posti assistente" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="ampaxnrdw_tot" label="Posti a sedere totali" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="ampaxnrup_s2" label="Posti in piedi" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="ampaxnrtot" label="Posti Totali" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="ampaxkgdw" label="Portata Pax seduti Kg" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="ampaxkgup" label="Portata Pax in piedi Kg" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amtyresant" label="Anteriori" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amtyrespos" label="Posteriori" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amtyresalt" label="Alternativi" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amlung" label="Lunghezza mm" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amlarg" label="Largezza mm" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amalte" label="Altezza mm" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        {/*  */}

                        <Divider variant="middle" />
                        <CustomTextInput source="amuscite" label="Uscita emergenza" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amtarga1" label="Targa 1" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amtarga2" label="Targa 2" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amtarga3" label="Targa 3" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        {/*  */}


                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="Anagrafica 3"
                        sx={{
                            '&.Mui-selected': {
                                borderBottom: '3px solid #01b5d0',
                                backgroundColor: '#e8e8e8'
                            }
                        }}
                    >

                        {/* ESTREMI ACQUISTO */}
                        <Divider variant="middle" />
                        {<CustomTextFieldData label="Data acquisto" source="amacqdata" />}
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amacqprezzo" label="Prezzo acquisto" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amacqfinan" label="Tipo finanziamento" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        {/*  */}

                        <Divider variant="middle" />
                        {<CustomTextFieldData label="Data autorizzazione" source="amautimmdata" />}
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amautimmnro" label="Numero autorizzazione" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amautimmtipo" label="Tipo autorizzazione" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        {<CustomTextFieldData label="Data cessione" source="amcessione" />}
                        <Divider variant="middle" />

                        {/*  */}

                        <Divider variant="middle" />
                        {<CustomTextFieldData label="Data bollo" source="amdtbollo" />}
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        {<CustomTextFieldData label="Data assicurazione" source="amdtassic" />}
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        {<CustomTextFieldData label="Data Tagliando" source="amdttagl" />}
                        <Divider variant="middle" />

                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Km tagliando: </Typography>
                                {<NumberInput color="success" className={classes.TextInput} variant="outlined" label="Km tagliando" source="amkmtagl" placeholder="" />}
                            </Box>
                        </div>

                        <Divider variant="middle" />
                        <CustomTextInput source="amkmtagl" label="Km tagliando" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />


                        <Divider variant="middle" />
                        {<CustomTextFieldData label="Data revisione" source="amdtrevi" />}
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        {<CustomTextFieldData label="Data tachigrafo" source="amdtaki" />}
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        {<CustomTextFieldData label="Data estintore" source="amdtesti" />}
                        <Divider variant="middle" />
                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="Anagrafica 4"
                        sx={{
                            '&.Mui-selected': {
                                borderBottom: '3px solid #01b5d0',
                                backgroundColor: '#e8e8e8'
                            }
                        }}
                    >

                        {<ToBoolean label="Fuori servizio" source="amfservizio" labelOn={true}></ToBoolean>}



                        {<RichTextField source="amnote" label="Annotazioni"></RichTextField>}

                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="Proprieta" path="proprieta"
                        sx={{
                            '&.Mui-selected': {
                                borderBottom: '3px solid #01b5d0',
                                backgroundColor: '#e8e8e8'
                            }
                        }}
                    >
                        <div>
                            <div>
                                {<CustomTextFieldDataOra label="Data creazione" source="createdAt" />}
                                <br></br>
                                {<CustomTextFieldDataOra label="Data ultima modifica" source="updatedAt" />}
                            </div>
                        </div>
                        <div>
                            {<ToBoolean label="Attivo" source="flAttivo" labelOn={true}></ToBoolean>}
                            {<ToBoolean label="Visibile" source="fiVisibile" labelOn={true}></ToBoolean>}
                            {<ToBoolean label="Annullato" source="flAnnullato" labelOn={true}></ToBoolean>}
                        </div>
                        <Divider variant="middle" />
                        <ConfirmDialogDeleteButtonCustom
                            titolo={"Cancella Autista"}
                            messaggio={"Sei sicuro di vole cancellare questo Autista ?"}
                            servizio={"autisti"} />
                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="Annotazioni" path="annotazioni"
                        sx={{
                            '&.Mui-selected': {
                                borderBottom: '3px solid #01b5d0',
                                backgroundColor: '#e8e8e8'
                            }
                        }}
                    >
                        <p><strong>Annotazioni</strong></p>
                        <GestioneNote tiporecordId="2" refresh={refresh} ></GestioneNote>
                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="Costi Ufficiali" path="costiufficiali"
                        sx={{
                            '&.Mui-selected': {
                                borderBottom: '3px solid #01b5d0',
                                backgroundColor: '#e8e8e8'
                            }
                        }}
                    >
                        <p><strong>Costi Ufficiali</strong></p>
                        <GestioneCostiUfficiali tiporecordId="2" refresh={refresh} ></GestioneCostiUfficiali>
                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="Geolocalizza" path="posizione"
                        sx={{
                            '&.Mui-selected': {
                                borderBottom: '3px solid #01b5d0',
                                backgroundColor: '#e8e8e8'
                            }
                        }}
                    >
                        <FunctionField
                            source="" // used for sorting
                            label=""
                            render={record => record && <GeoDetails propValueMask={record.amtarga} />}
                        />

                    </TabbedForm.Tab>
                </TabbedForm>
            </Box>
        </Show>
    );
}
