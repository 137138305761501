import React from 'react';
import { Card, CardContent, Typography, Box, Grid, Divider } from '@mui/material';
import { Note as NoteIcon } from '@mui/icons-material';


const ImpegnoCard = ({ impegno }) => {
    if (!impegno) return null;

    return (
        <Card sx={{ mb: 3, boxShadow: 3 }}>
            <CardContent>
                {/* Titolo */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '2px solid #1976d2',
                        paddingBottom: 1,
                        marginBottom: 3,
                    }}
                >
                    <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                        <NoteIcon sx={{ mr: 1 }} />
                        Impegno Calcolato
                    </Typography>
                </Box>

                {/* Contenuto */}
                <Grid container spacing={3}>
                    {/* Nome Cliente */}
                    {/*                     <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1" color="primary">
                            Nome Cliente
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                            {impegno.gnragnom}
                        </Typography>
                    </Grid> */}

                    {/* Servizio */}
                    {/*                     <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1" color="primary">
                            Servizio
                        </Typography>
                        <Typography variant="body1">{impegno.ss_des}</Typography>
                    </Grid> */}

                    {/* <Divider sx={{ width: '100%', mt: 2, mb: 2 }} /> */}

                    {/* Dettagli Periodo */}
                    <Grid item xs={12} md={6}>
                        <Typography
                            variant="subtitle1"
                            color="primary"
                            gutterBottom
                            sx={{ fontWeight: 600 }}
                        >
                            Periodo dell'Impegno
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 0.5
                            }}
                        >
                            <span>
                                Da: {new Date(impegno.bk0_datada).toLocaleDateString('it-IT', {
                                    weekday: 'long',
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })}
                            </span>
                            <span>
                                A: {new Date(impegno.bk0_dataal).toLocaleDateString('it-IT', {
                                    weekday: 'long',
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })}
                            </span>
                        </Typography>
                    </Grid>

                    {/* Dettagli Tempo */}
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1" color="primary" gutterBottom>
                            Tempo Totale
                        </Typography>
                        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <span>Ore: <Typography component="strong" fontWeight="bold">{impegno.ore}</Typography></span>
                            <span>|</span>
                            <span>Minuti: <Typography component="strong" fontWeight="bold">{impegno.minuti}</Typography></span>
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ImpegnoCard;
