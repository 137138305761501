// Booking0Show.js
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    TextField,
    ReferenceField,
    DateField,
    Show,
    NumberField,
    TabbedShowLayout,
    Title,
    useRecordContext,
    FunctionField,
} from 'react-admin';
import {
    Card,
    CardContent,
    Typography,
    Box,
    Grid,
    IconButton,
    Button,
    CircularProgress,
} from '@mui/material';
import {
    EventNote as EventNoteIcon,
    Person as PersonIcon,
    Group as GroupIcon,
    Note as NoteIcon,
    Map as MapIcon,
    Edit as EditIcon,
    CarRentalRounded,
} from '@mui/icons-material';

import { Link } from 'react-router-dom';
import PeopleIcon from '@mui/icons-material/People';
import axios from 'axios';

// Host backend
import config from '../../config/config.json';


// Import dei componenti di dialogo
import EditClientDialog from './EditClientDialog';
import EditDatesDialog from './EditDatesDialog';
import { EditPassengersDialog } from './EditPassengersDialog';
import { EditNotesDialog } from './EditNotesDialog';
import TratteList from './TratteList';
import ImpegnoCard from './impegno/ImpegnoCard';
import ImpegnoNonServizioCard from './impegno/ImpegnoNonServizioCard';
import ImpegnoNotturnoCard from './impegno/ImpegnoNotturnoCard';
import ImpegnoVuotoCard from './impegno/ImpegnoVuotoCard';
import ImpegnoServizioCard from './impegno/ImpegnoServizioCard';

const host = config.production.host;

// Componente per il titolo del booking
const BookingTitle = () => {
    const record = useRecordContext();
    if (!record) return null;
    return (
        <Typography variant="h5" component="h1">
            Preventivo #{record.bk0_idnumber}
        </Typography>
    );
};

// Componente per le informazioni del cliente
const ClientInfo = () => {
    const record = useRecordContext();
    const [editDialogOpen, setEditDialogOpen] = useState(false);

    if (!record) return null;

    return (
        <>
            <Card>
                <CardContent>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '2px solid #1976d2',
                        paddingBottom: 1,
                        marginBottom: 2
                    }}>
                        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                            <PersonIcon sx={{ mr: 1 }} />
                            Informazioni Cliente
                        </Typography>
                        <IconButton
                            onClick={() => setEditDialogOpen(true)}
                            size="small"
                            color="primary"
                        >
                            <EditIcon />
                        </IconButton>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box mb={2}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Cliente
                                </Typography>
                                <ReferenceField
                                    source="bk0_clienteId"
                                    reference="anagen"
                                    link={false}
                                >
                                    <TextField source="gnfind" />
                                </ReferenceField>
                                <br></br>
                                <ReferenceField
                                    source="bk0_clienteId"
                                    reference="anagen"
                                    link={false}
                                >
                                    Codice Anagen: <TextField source="id" />
                                    <hr></hr>
                                    <FunctionField
                                        label="Dettaglio Anagrafica"
                                        render={record => record && (
                                            <Button
                                                component={Link}
                                                to={`/anagen/${record.id}/show/preventivi`}
                                                size="small"
                                                startIcon={<PeopleIcon />}
                                                sx={{
                                                    backgroundColor: '#2196f3',
                                                    color: 'white',
                                                    '&:hover': {
                                                        backgroundColor: '#1976d2',
                                                    },
                                                }}
                                            >
                                                Dettaglio Anagrafica
                                            </Button>
                                        )}
                                    />
                                </ReferenceField>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box mb={2}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Tipo Servizio
                                </Typography>
                                <ReferenceField
                                    source="bk0_servizio"
                                    reference="servizi"
                                    link={false}
                                >
                                    <TextField source="ss_des" />
                                </ReferenceField>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box mb={2}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Tipo Richiesta
                                </Typography>
                                <ReferenceField
                                    source="bk0_richiestatipo"
                                    reference="tabellealfa"
                                    link={false}
                                >
                                    <TextField source="tbdes" />
                                </ReferenceField>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <EditClientDialog
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                record={record}
            />
        </>
    );
};

// Componente per le date con layout migliorato
const DateInfo = () => {
    const record = useRecordContext();
    const [editDialogOpen, setEditDialogOpen] = useState(false);

    if (!record) return null;

    return (
        <>
            <Card
                sx={{
                    backgroundColor: '#fff',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    borderRadius: '8px'
                }}
            >
                <CardContent>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '2px solid #1976d2',
                        paddingBottom: 1,
                        marginBottom: 2
                    }}>
                        <Typography variant="h6" sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#1976d2'
                        }}>
                            <EventNoteIcon sx={{ mr: 1 }} />
                            Date del Servizio
                        </Typography>
                        <IconButton
                            onClick={() => setEditDialogOpen(true)}
                            size="small"
                            color="primary"
                        >
                            <EditIcon />
                        </IconButton>
                    </Box>

                    <Grid container spacing={3}>
                        {/* Prima riga con le date principali */}
                        <Grid item xs={12} md={6}>
                            <Card variant="outlined" sx={{
                                height: '100%',
                                backgroundColor: '#f8f9fa',
                                border: '1px solid #e0e0e0'
                            }}>
                                <CardContent>
                                    <Typography variant="subtitle1"
                                        sx={{
                                            color: '#1976d2',
                                            fontWeight: 500,
                                            marginBottom: 1
                                        }}>
                                        Periodo del Servizio
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2" color="textSecondary">
                                                Data Inizio
                                            </Typography>
                                            <DateField
                                                source="bk0_datada"
                                                showTime
                                                sx={{
                                                    fontSize: '1.1rem',
                                                    fontWeight: 500
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2" color="textSecondary">
                                                Data Fine
                                            </Typography>
                                            <DateField
                                                source="bk0_dataal"
                                                showTime
                                                sx={{
                                                    fontSize: '1.1rem',
                                                    fontWeight: 500
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Seconda riga con informazioni aggiuntive */}
                        <Grid item xs={12} md={6}>
                            <Card variant="outlined" sx={{
                                height: '100%',
                                backgroundColor: '#f8f9fa',
                                border: '1px solid #e0e0e0'
                            }}>
                                <CardContent>
                                    <Typography variant="subtitle1"
                                        sx={{
                                            color: '#1976d2',
                                            fontWeight: 500,
                                            marginBottom: 1
                                        }}>
                                        Dettagli Amministrativi
                                    </Typography>
                                    <Box>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            Data Creazione Preventivo
                                        </Typography>
                                        <DateField
                                            source="createdAt"
                                            showTime
                                            sx={{
                                                fontSize: '1.1rem',
                                                fontWeight: 500
                                            }}
                                        />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <EditDatesDialog
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                record={record}
            />
        </>
    );
};

// Componente per le informazioni dei passeggeri con layout migliorato
const PassengersInfo = () => {
    const record = useRecordContext();
    const [editDialogOpen, setEditDialogOpen] = useState(false);

    if (!record) return null;

    return (
        <>
            <Card sx={{
                backgroundColor: '#fff',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                borderRadius: '8px',
                mt: 2
            }}>
                <CardContent>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '2px solid #1976d2',
                        paddingBottom: 1,
                        marginBottom: 2
                    }}>
                        <Typography variant="h6" sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#1976d2'
                        }}>
                            <GroupIcon sx={{ mr: 1 }} />
                            Composizione del Gruppo
                        </Typography>
                        <IconButton
                            onClick={() => setEditDialogOpen(true)}
                            size="small"
                            color="primary"
                        >
                            <EditIcon />
                        </IconButton>
                    </Box>

                    <Grid container spacing={3}>
                        {/* Card per il totale passeggeri */}
                        <Grid item xs={12} md={6}>
                            <Card variant="outlined" sx={{
                                backgroundColor: '#f8f9fa',
                                border: '1px solid #e0e0e0'
                            }}>
                                <CardContent>
                                    <Typography variant="subtitle1" sx={{
                                        color: '#1976d2',
                                        fontWeight: 500,
                                        marginBottom: 2
                                    }}>
                                        Passeggeri
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <Box sx={{
                                                textAlign: 'center',
                                                padding: '10px',
                                                backgroundColor: '#fff',
                                                borderRadius: '8px',
                                                border: '1px solid #e0e0e0'
                                            }}>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    Adulti
                                                </Typography>
                                                <NumberField
                                                    source="bk0_paxad"
                                                    sx={{
                                                        fontSize: '1.5rem',
                                                        fontWeight: 'bold',
                                                        color: '#1976d2'
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Box sx={{
                                                textAlign: 'center',
                                                padding: '10px',
                                                backgroundColor: '#fff',
                                                borderRadius: '8px',
                                                border: '1px solid #e0e0e0'
                                            }}>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    Bambini
                                                </Typography>
                                                <NumberField
                                                    source="bk0_paxch"
                                                    sx={{
                                                        fontSize: '1.5rem',
                                                        fontWeight: 'bold',
                                                        color: '#1976d2'
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Box sx={{
                                                textAlign: 'center',
                                                padding: '10px',
                                                backgroundColor: '#fff',
                                                borderRadius: '8px',
                                                border: '1px solid #e0e0e0'
                                            }}>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    Infanti
                                                </Typography>
                                                <NumberField
                                                    source="bk0_paxin"
                                                    sx={{
                                                        fontSize: '1.5rem',
                                                        fontWeight: 'bold',
                                                        color: '#1976d2'
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Card per i seggiolini */}
                        <Grid item xs={12} md={6}>
                            <Card variant="outlined" sx={{
                                backgroundColor: '#f8f9fa',
                                border: '1px solid #e0e0e0',
                                height: '100%'
                            }}>
                                <CardContent>
                                    <Typography variant="subtitle1" sx={{
                                        color: '#1976d2',
                                        fontWeight: 500,
                                        marginBottom: 2
                                    }}>
                                        Attrezzatura Richiesta
                                    </Typography>
                                    <Box sx={{
                                        textAlign: 'center',
                                        padding: '10px',
                                        backgroundColor: '#fff',
                                        borderRadius: '8px',
                                        border: '1px solid #e0e0e0'
                                    }}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            Seggiolini
                                        </Typography>
                                        <NumberField
                                            source="bk0_nroseg"
                                            sx={{
                                                fontSize: '1.5rem',
                                                fontWeight: 'bold',
                                                color: '#1976d2'
                                            }}
                                        />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <EditPassengersDialog
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                record={record}
            />
        </>
    );
};

// Componente per le note
const NotesInfo = () => {
    const record = useRecordContext();
    const [editDialogOpen, setEditDialogOpen] = useState(false);

    if (!record) return null;

    return (
        <>
            <Card>
                <CardContent>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '2px solid #1976d2',
                        paddingBottom: 1,
                        marginBottom: 2
                    }}>
                        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                            <NoteIcon sx={{ mr: 1 }} />
                            Note
                        </Typography>
                        <IconButton
                            onClick={() => setEditDialogOpen(true)}
                            size="small"
                            color="primary"
                        >
                            <EditIcon />
                        </IconButton>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Box mb={2}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Note Booking
                                </Typography>
                                <TextField source="bk0_ntmemo" />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box mb={2}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Note Cliente
                                </Typography>
                                <TextField source="bk0_ntcliente" />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box mb={2}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Note Autista
                                </Typography>
                                <TextField source="bk0_ntautista" />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box mb={2}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Note Responsabile
                                </Typography>
                                <TextField source="bk0_ntresp" />
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <EditNotesDialog
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                record={record}
            />
        </>
    );
};

// Componenti per il fetch ------------------------------------ START

const useFetchImpegno = (id) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!id) return;

        const fetchData = async () => {
            try {
                const jwt = localStorage.getItem("feathers-jwt");
                const response = await axios.get(`${host}/view-booking-0-impegno-servizio?id=${id}`, {
                    headers: { 'Authorization': `Bearer ${jwt}` },
                });
                setData(response.data.data || []);
            } catch (err) {
                setError(err);
                console.error('Error fetching data:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    return { data, loading, error };
};

const useFetchImpegnoNonServizio = (id) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!id) return;

        const fetchData = async () => {
            try {
                const jwt = localStorage.getItem("feathers-jwt");
                const response = await axios.get(`${host}/view-booking-2-impegno-non-servizio?id=${id}`, {
                    headers: { 'Authorization': `Bearer ${jwt}` },
                });
                setData(response.data.data || []);
            } catch (err) {
                setError(err);
                console.error('Error fetching data:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    return { data, loading, error };
};

const useFetchImpegnoNotturno = (id) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!id) return;

        const fetchData = async () => {
            try {
                const jwt = localStorage.getItem("feathers-jwt");
                const response = await axios.get(`${host}/view-booking-2-impegno-notturno?id=${id}`, {
                    headers: { 'Authorization': `Bearer ${jwt}` },
                });
                setData(response.data.data || []);
            } catch (err) {
                setError(err);
                console.error('Error fetching data:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    return { data, loading, error };
};

const useFetchImpegnoVuoto = (id) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!id) return;

        const fetchData = async () => {
            try {
                const jwt = localStorage.getItem("feathers-jwt");
                const response = await axios.get(`${host}/view-booking-2-impegno-vuoto?id=${id}`, {
                    headers: { 'Authorization': `Bearer ${jwt}` },
                });
                setData(response.data.data || []);
            } catch (err) {
                setError(err);
                console.error('Error fetching data:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    return { data, loading, error };
};

const useFetchImpegnoServizio = (id) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!id) return;

        const fetchData = async () => {
            try {
                const jwt = localStorage.getItem("feathers-jwt");
                const response = await axios.get(`${host}/view-booking-2-impegno-servizio?id=${id}`, {
                    headers: { 'Authorization': `Bearer ${jwt}` },
                });
                setData(response.data.data || []);
            } catch (err) {
                setError(err);
                console.error('Error fetching data:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    return { data, loading, error };
};

// Componenti per il fetch ------------------------------------ FINE //

// Componenti per Visualizzazione Impegno ------------------------------------ INIZIO //

const ImpegnoComplessivoInfo = () => {
    const record = useRecordContext(); // Context fornito da React-Admin
    const { data, loading, error } = useFetchImpegno(record?.id);

    if (!record) return null;

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">Errore nel caricamento dei dati.</Typography>;

    return (
        <>
            {data.length > 0 ? (
                data.map((impegno) => <ImpegnoCard key={impegno.id} impegno={impegno} />)
            ) : (
                <Typography variant="body2" color="textSecondary">
                    {/* NESSUN IMPEGNO TROVATO */}
                </Typography>
            )}
        </>
    );
};

const ImpegnoNonServizioInfo = () => {
    const record = useRecordContext(); // Context fornito da React-Admin
    const { data, loading, error } = useFetchImpegnoNonServizio(record?.id);

    if (!record) return null;

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">Errore nel caricamento dei dati.</Typography>;

    return (
        <>
            {data.length > 0 ? (
                data.map((impegno) => <ImpegnoNonServizioCard key={impegno.id} impegno={impegno} />)
            ) : (
                <Typography variant="body2" color="textSecondary">
                    {/* NESSUN IMPEGNO TROVATO */}
                </Typography>
            )}
        </>
    );
};

const ImpegnoNotturnoInfo = () => {
    const record = useRecordContext(); // Context fornito da React-Admin
    const { data, loading, error } = useFetchImpegnoNotturno(record?.id);

    if (!record) return null;

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">Errore nel caricamento dei dati.</Typography>;

    return (
        <>
            {data.length > 0 ? (
                data.map((impegno) => <ImpegnoNotturnoCard key={impegno.id} impegno={impegno} />)
            ) : (
                <Typography variant="body2" color="textSecondary">
                    {/* NESSUN IMPEGNO TROVATO */}
                </Typography>
            )}
        </>
    );
};

const ImpegnoVuotoInfo = () => {
    const record = useRecordContext(); // Context fornito da React-Admin
    const { data, loading, error } = useFetchImpegnoVuoto(record?.id);

    if (!record) return null;

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">Errore nel caricamento dei dati.</Typography>;

    return (
        <>
            {data.length > 0 ? (
                data.map((impegno) => <ImpegnoVuotoCard key={impegno.id} impegno={impegno} />)
            ) : (
                <Typography variant="body2" color="textSecondary">
                    {/* NESSUN IMPEGNO TROVATO */}
                </Typography>
            )}
        </>
    );
};

const ImpegnoServizioInfo = () => {
    const record = useRecordContext(); // Context fornito da React-Admin
    const { data, loading, error } = useFetchImpegnoServizio(record?.id);

    if (!record) return null;

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">Errore nel caricamento dei dati.</Typography>;

    return (
        <>
            {data.length > 0 ? (
                data.map((impegno) => <ImpegnoServizioCard key={impegno.id} impegno={impegno} />)
            ) : (
                <Typography variant="body2" color="textSecondary">
                    {/* NESSUN IMPEGNO TROVATO */}
                </Typography>
            )}
        </>
    );
};

// Componenti per Visualizzazione Impegno ------------------------------------ FINE //

// Componente principale
const Booking0Show = () => {
    return (
        <Show title={<BookingTitle />}>
            <TabbedShowLayout>
                <TabbedShowLayout.Tab
                    sx={{
                        '&.Mui-selected': {
                            borderBottom: '3px solid #01b5d0',
                            backgroundColor: '#e8e8e8'
                        }
                    }}
                    label="Informazioni Generali"
                    icon={<CarRentalRounded />}
                    path=""
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ClientInfo />
                        </Grid>
                        <Grid item xs={3}>
                            <ImpegnoComplessivoInfo />
                        </Grid>
                        <Grid item xs={3}>
                            <ImpegnoNonServizioInfo />
                        </Grid>
                        <Grid item xs={3}>
                            <ImpegnoNotturnoInfo />
                        </Grid>
                        <Grid item xs={3}>
                            <ImpegnoVuotoInfo />
                        </Grid>
                        <Grid item xs={3}>
                            <ImpegnoServizioInfo />
                        </Grid>
                        <Grid item xs={12}>
                            <DateInfo />
                        </Grid>
                        <Grid item xs={12}>
                            <PassengersInfo />
                        </Grid>
                        <Grid item xs={12}>
                            <NotesInfo />
                        </Grid>
                    </Grid>
                </TabbedShowLayout.Tab>

                <TabbedShowLayout.Tab
                    sx={{
                        '&.Mui-selected': {
                            borderBottom: '3px solid #01b5d0',
                            backgroundColor: '#e8e8e8'
                        }
                    }}
                    label="Tratte"
                    icon={<MapIcon />}
                    path="tratte"
                >
                    <TratteList />
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export default Booking0Show;