import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography
} from '@mui/material';
import {
    useGetList,
    useUpdate,
    useNotify,
    ListBase,
    Datagrid,
    TextField,
    NumberField,
    FunctionField,
} from 'react-admin';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { GetNameFromResourceById } from '../../shared/CustomFields/GetNameFromResourceById';

const TariffSelectionDialog = ({ open, onClose, record }) => {
    const notify = useNotify();
    const [selectedTariff, setSelectedTariff] = useState(null);
    const [update] = useUpdate();

    const { data } = useGetList('tariffe-a', {
        filter: {
            tfa_clienteId: record?.bk0_clientecod,
            tfa_servizio: record?.bk0_servizio
        }
    });

    const handleUpdate = () => {
        if (!selectedTariff) return;

        console.log("Updating with data:", selectedTariff);
        update(
            'booking-0',
            {
                id: record.id,
                data: { tariffeAId: selectedTariff.id }
            },
            {
                onSuccess: () => {
                    notify('Tariffa aggiornata con successo', { type: 'success' });
                    onClose();
                },
                onError: (error) => {
                    console.error('Update error:', error);
                    notify('Errore durante l\'aggiornamento della tariffa', { type: 'error' });
                }
            }
        );
    };

    const ActionButtons = ({ record }) => (
        <Button
            variant="contained"
            size="small"
            onClick={(e) => {
                e.stopPropagation();
                setSelectedTariff(record);
            }}
            sx={{ mx: 1 }}
        >
            Seleziona
        </Button>
    );

    const handleCopyJson = () => {
        const jsonData = JSON.stringify({
            filter: {
                tfa_clienteId: record?.bk0_clientecod,
                tfa_servizio: record?.bk0_servizio
            },
            responseData: data,
            recordId: record?.id,
            selectedTariff: selectedTariff
        }, null, 2);

        navigator.clipboard.writeText(jsonData);
        notify('JSON copiato negli appunti', { type: 'success' });
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                Seleziona Tariffa - bk0_clientecod: {record?.bk0_clientecod} - bk0_servizio: {record?.bk0_servizio}
            </DialogTitle>
            <DialogContent>
                <Box sx={{ height: 400, width: '100%', mt: 2 }}>
                    <ListBase
                        resource="tariffe-a"
                        filter={{
                            tfa_clienteId: record?.bk0_clientecod,
                            tfa_servizio: record?.bk0_servizio
                        }}
                        sort={{ field: 'id', order: 'DESC' }}
                    >
                        <Datagrid
                            bulkActionButtons={false}
                            rowStyle={(record) => ({
                                backgroundColor: record.id === selectedTariff?.id ? '#eef' : undefined,
                            })}
                        >
                            <TextField source="id" />
                            <FunctionField
                                source="id"
                                label=""
                                render={record => record &&
                                    <GetNameFromResourceById
                                        id={record.tfa_servizio}
                                        resource="servizi"
                                        fieldName="ss_des"
                                    />
                                }
                            />
                            <TextField source="tfa_des" label="Descrizione" />
                            <NumberField source="tfa_bdgkm" label="Budget KM" />
                            <NumberField source="tfa_bdghh" label="Budget Ore" />
                            <NumberField source="tfa_frgmmec" label="Franchigia Mec." />
                            <FunctionField
                                label="Azioni"
                                render={record => <ActionButtons record={record} />}
                            />
                        </Datagrid>
                    </ListBase>
                </Box>

                <Accordion sx={{ mt: 2 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                            <Typography>Debug Data</Typography>
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleCopyJson();
                                }}
                                sx={{ mr: 2 }}
                            >
                                Copia JSON
                            </Button>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <pre>
                            {JSON.stringify({
                                filter: {
                                    tfa_clienteId: record?.bk0_clientecod,
                                    tfa_servizio: record?.bk0_servizio
                                },
                                responseData: data,
                                recordId: record?.id,
                                selectedTariff: selectedTariff
                            }, null, 2)}
                        </pre>
                    </AccordionDetails>
                </Accordion>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button onClick={onClose} sx={{ mr: 1 }}>
                        Annulla
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleUpdate}
                        disabled={!selectedTariff}
                    >
                        Conferma
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default TariffSelectionDialog;