import React from 'react';
import { Box, Typography } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';

// Mappa delle stringhe ai nomi corrispondenti
const gncdtanMap = {
    '1135': 'Anagrafiche Clienti',
    '1136': 'Anagrafiche Fornitori',
    '1148': 'Anagrafiche Dipendenti',
    '1137': 'Anagrafiche Cliente Fornitore',
};

const CalcolaTitoloAnagen = ({ gncdtan }) => {
    // Trova il nome corrispondente alla stringa
    const name = gncdtanMap[gncdtan] || 'Non definito';

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PeopleIcon sx={{ mr: 1 }} />
            <Typography variant="h6">{name}</Typography>
        </Box>
    );
};

export default CalcolaTitoloAnagen;
