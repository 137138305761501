import React from 'react';
import { Box, Paper, Grid, Chip } from '@mui/material';
import {
    NumberInput,
    ReferenceInput,
    SelectInput,
    useRecordContext,
} from "react-admin";
import CustomDateInputNoMargini from '../shared/FormFields/CustomDateInputNoMargini';
import CustomAutocompleInput from '../shared/FormFields/CustomAutocompleInput';

// Il componente dei filtri ora è una funzione che ritorna gli elementi
export const BookingFilters = () => [


    <CustomDateInputNoMargini
        key="date-from"
        source="bk0_datada"
        label="Data Inizio"
        alwaysOn
    />,
    <CustomDateInputNoMargini
        key="date-to"
        source="bk0_dataal"
        label="Data Fine"
        alwaysOn
    />,
    <CustomAutocompleInput
        key="client"
        source="bk0_clientecod"
        reference="anagen"
        optionProperty="gnfind"
        label="Seleziona Anagrafica"
        alwaysOn
    />,
    <NumberInput
        key="booking-number"
        source="bk0_idnumber"
        label="Numero Preventivo"
        alwaysOn
        sx={{ minWidth: 200 }}
    />,
    <ReferenceInput
        key="service"
        source="bk0_servizio"
        reference="servizi"
        alwaysOn
    >
        <SelectInput
            label="Tipo Servizio"
            sx={{ minWidth: 200 }}
        />
    </ReferenceInput>,
    <ReferenceInput
        key="request-type"
        source="bk0_richiestatipo"
        reference="view-tabellaalfa-trp"
        alwaysOn
    >
        <SelectInput
            label="Tipo Richiesta"
            sx={{ minWidth: 200 }}
        />
    </ReferenceInput>,
    <ReferenceInput
        key="request-type"
        source="bk0_idstatus"
        reference="view-booking-0-status-riepilogo"
        alwaysOn
    >
        <SelectInput
            optionText={<StatusComposito></StatusComposito>}
            label="Status"
            sx={{ minWidth: 200 }}
        />
    </ReferenceInput>,

];

const StatusComposito = () => {
    const record = useRecordContext();
    return <span>{record.descrizione} - <Chip label={`${record.count}`} ></Chip></span>;
}
