
import { TariffaServpaxEdit } from './tariffa_servpaxEdit';
import { TariffaServpaxList } from './tariffa_servpaxList';
import { TariffaServpaxShow } from './tariffa_servpaxShow';
import { TariffaServpaxCreate } from './tariffa_servpaxCreate';
import MapIcon from '@material-ui/icons/Map';

export default {
    list: TariffaServpaxList,
    edit: TariffaServpaxEdit,
    show: TariffaServpaxShow,
    create: TariffaServpaxCreate,
    icon: MapIcon
};
