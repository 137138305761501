


import * as React from 'react';
import {
    Create, SimpleForm, Toolbar, TextInput, BooleanInput,
    SaveButton,
    required
} from 'react-admin';
import { editButtonCss, saveButtonCss, showButtonCss, exportButtonCss, columnsButtonCss, deleteButtonCss, filterButtonCss } from '../shared/Styles/buttonStyles';
import { formCss, formCssTextInput } from "../shared/Styles/formCss";
import PeopleIcon from '@mui/icons-material/People';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import CustomAutocompleInput from '../shared/FormFields/CustomAutocompleInput';


const MyToolbarEdit = () => (
    <Toolbar>
        <SaveButton sx={saveButtonCss} /* sx={{
            border: 1,
            borderColor: 'black',
            borderRadius: '20px',
            backgroundColor: '#01b5d0'
        }} */ label="Salva parametri" alwaysEnable />
    </Toolbar>
);

export const tabellealfaCreate = () => (
    <Create title={<>
        <Button>
            <PeopleIcon></PeopleIcon>
            &nbsp;Crea record su tabellealfa
        </Button>
    </>} redirect="show">
        <SimpleForm sx={formCss} toolbar={<MyToolbarEdit />}>
            <Divider variant="middle" />
            <CustomAutocompleInput source="tbpref" reference="tipotabellealfa" optionProperty="descrizione" label="Tipologia"></CustomAutocompleInput>
            <Divider variant="middle" />
            <TextInput source="tbdes" validate={required()} />
            {/* <TextInput source="tbmy" validate={required()}/> */}
            <BooleanInput source="flAttivo" validate={required()} />
            <BooleanInput source="flVisibile" validate={required()} />
            <BooleanInput source="flAnnullato" validate={required()} />
        </SimpleForm>
    </Create>
);