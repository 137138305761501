import React, { useState } from 'react';
import {
    useUpdate,
    useNotify,
    useRecordContext
} from 'react-admin';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Switch,
    Box
} from '@mui/material';
import TrattaNotturnaLED from '../shared/CustomFields/TrattaNotturnaLED';

const TrattaSupplementoNotturno = () => {
    const record = useRecordContext();
    const [open, setOpen] = useState(false);
    const [bk2_suplementoNotturno, setStatus] = useState(record.bk2_suplementoNotturno);
    const [update] = useUpdate();
    const notify = useNotify();

    const handleClick = (e) => {
        e.stopPropagation();
        setOpen(true);
    };

    const handleClose = () => {
        setStatus(record.bk2_suplementoNotturno);
        setOpen(false);
    };

    const handleToggle = (event) => {
        setStatus(event.target.checked ? 1 : 0);
    };

    const handleSave = () => {
        update(
            'booking-2',
            { id: record.id, data: { ...record, bk2_suplementoNotturno } },
            {
                onSuccess: () => {
                    notify('Status aggiornato con successo', { type: 'success' });
                    setOpen(false);
                },
                onError: () => notify('Errore durante l\'aggiornamento dello bk2_suplementoNotturno', { type: 'error' }),
            }
        );
    };

    return (
        <>
            <Box
                onClick={handleClick}
                sx={{
                    cursor: 'pointer',
                    display: 'inline-flex',
                    alignItems: 'center'
                }}
            >
                <TrattaNotturnaLED bk2_suplementoNotturno={record.bk2_suplementoNotturno} />
            </Box>

            <Dialog
                open={open}
                onClose={handleClose}
                onClick={(e) => e.stopPropagation()}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>
                    Imposta Tratta Notturna
                </DialogTitle>
                <DialogContent>
                    <Box sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2
                    }}>
                        <Typography variant="body1">
                            <strong>Id Tratta:</strong> {record.id}
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            bgcolor: '#f5f5f5',
                            p: 2,
                            borderRadius: 1
                        }}>
                            <Typography>
                                Supplemento Notturno
                            </Typography>
                            <Switch
                                checked={bk2_suplementoNotturno === 1}
                                onChange={handleToggle}
                                color="primary"
                            />
                            <Typography color={bk2_suplementoNotturno === 1 ? 'success.main' : 'error.main'}>
                                {bk2_suplementoNotturno === 1 ? 'Si' : 'No'}
                            </Typography>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button
                        onClick={handleClose}
                        color="inherit"
                    >
                        Annulla
                    </Button>
                    <Button
                        onClick={handleSave}
                        variant="contained"
                        color="primary"
                    >
                        Salva
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TrattaSupplementoNotturno;