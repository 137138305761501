
import { tabellealfaList } from './tabellealfaList';
import { tabellealfaCreate } from './tabellealfaCreate';
import { tabellealfaEdit } from './tabellealfaEdit';
import { tabellealfaShow } from './tabellealfaShow';

const tabellealfa = {
    list: tabellealfaList,
    create: tabellealfaCreate,
    edit: tabellealfaEdit,
    show: tabellealfaShow,
};

export default tabellealfa;
