// preventivi/PreventiviList.js
import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    FunctionField,
    Button,
    ShowButton,
    ReferenceField,
    FilterButton,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    DateInput,
    useListContext,
    useRefresh
} from 'react-admin';

import { useFormContext } from "react-hook-form"

import { Box, Button as MUIButton } from '@mui/material';
import { Search, Clear } from '@mui/icons-material';

// Impost Custom List Page
import { listCss } from '../shared/Styles/listStyles';
import EmptyListCustomNoCreate from '../shared/emptyPageCustom/empryPageCustomNoCreate';
import { showButtonCss } from '../shared/Styles/buttonStyles';
import { FilterList } from '@mui/icons-material';
import CustomAutocompleInput from '../shared/FormFields/CustomAutocompleInput';

// FilterActions modificato per gestire manualmente il refresh
const FilterActions = () => {
    const { setFilters, filterValues } = useListContext();
    const refresh = useRefresh();
    const [pendingFilters, setPendingFilters] = React.useState({});

    const handleSearch = () => {
        setFilters(pendingFilters, {});
        refresh();
    };

    const handleReset = () => {
        setPendingFilters({});
        setFilters({}, {});
        refresh();
    };

    // Aggiorna i filtri pendenti senza triggherare la ricerca
    React.useEffect(() => {
        setPendingFilters(filterValues);
    }, [filterValues]);

    return (
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <MUIButton
                variant="outlined"
                onClick={handleReset}
                startIcon={<Clear />}
            >
                Reset Filtri
            </MUIButton>
        </Box>
    );
};

// Stili personalizzati per il pulsante dei filtri
const filterButtonStyle = {
    backgroundColor: 'white',
    color: 'white',
    marginLeft: '1rem',
    padding: '8px 16px',
    '&:hover': {
        backgroundColor: '#1565c0',
    }
};

// Componente validazione date aggiornato
const DateRangeInput = ({ source, validateDate }) => {
    const form = useFormContext();

    const validate = (value) => {
        if (!value) return undefined;

        const dateFrom = form.getValues('data.dateFrom');
        const dateTo = form.getValues('data.dateTo');

        if (dateFrom && dateTo) {
            const start = new Date(dateFrom);
            const end = new Date(dateTo);

            if (start > end) {
                return 'La data di inizio non può essere successiva alla data di fine';
            }
        }
        return undefined;
    };

    return (
        <DateInput
            source={source}
            validate={validate}
            sx={{ marginRight: '1rem' }}
        />
    );
};

const PreventiviFilters = [
    <TextInput
        source="id"
        label="Cerca per ID"
        alwaysOn
        sx={{ marginRight: '1rem' }}
    />,
    <CustomAutocompleInput
        key="client"
        source="userId"
        reference="users"
        optionProperty="email"
        label="Filtra per Utente"
        alwaysOn
    />,
    <ReferenceInput
        source="data.pacchettiId"
        reference="pacchetti"
        label="Filtra per Pacchetto"
        alwaysOn
        sx={{ marginRight: '1rem' }}
    >
        <AutocompleteInput optionText="nome" />
    </ReferenceInput>,
    <DateRangeInput
        source="data.dateFrom"
        label="Data Inizio"
        alwaysOn
    />,
    <DateRangeInput
        source="data.dateTo"
        label="Data Fine"
        alwaysOn
    />,
    <TextInput
        source="data.totalCost"
        label="Costo Totale"
        parse={v => parseFloat(v)}
        alwaysOn
    />
];

// Formattazione data per la visualizzazione
const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    }).format(date);
};

export const PreventiviList = (props) => (
    <List
        title={<>
            <Button sx={{ color: 'white' }}>
                &nbsp;Lista Preventivi Pacchetto
            </Button>
        </>}
        sort={{ field: 'id', order: 'desc' }}
        exporter={false}
        filters={PreventiviFilters}
        disableSyncWithLocation
        filterDefaultValues={{}}
        actions={false}
        storeKey="preventivi-list" // Chiave unica per questo componente
        mutationMode="pessimistic"
        queryOptions={{
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false
        }}
        empty={<EmptyListCustomNoCreate titolo="Lista"></EmptyListCustomNoCreate>}
        {...props}
    ><Box>
            <Box sx={{ p: 2, bgcolor: 'background.paper' }}>
                <FilterActions />
            </Box>
            <Datagrid sx={listCss} bulkActionButtons={null}>
                <TextField source="id" label="ID" />
                <ReferenceField link={false} source="userId" reference="users" label="User Mail" />
                <ReferenceField link={false} source="data.pacchettiId" reference="pacchetti" label="Pacchetto" />
                <FunctionField
                    label="Data Inizio"
                    source="data.dateFrom"
                    render={record => formatDate(record.data?.dateFrom)}
                />
                <FunctionField
                    label="Data Fine"
                    source="data.dateTo"
                    render={record => formatDate(record.data?.dateTo)}
                />
                <FunctionField
                    label="Costo Totale"
                    source="data.totalCost"
                    render={record => `${record.data?.totalCost || 0} €`}
                />
                <FunctionField
                    label="Durata"
                    source="data.duration"
                    render={record => record.data?.duration}
                />
                <DateField source="createdAt" label="Creato il" showTime />
                <ShowButton sx={showButtonCss} label="Dettaglio" />
            </Datagrid>
        </Box>
    </List>
);