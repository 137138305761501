import * as React from "react";
import {
    List,
    Datagrid,
    EditButton,
    TextInput,
    Create,
    SimpleForm,
    TextField,
    Edit,
    ReferenceManyField,
    Show,
    SimpleShowLayout,
    RichTextField,
    useRecordContext,
    FilterList,
    FilterListItem,
    ShowButton,
    TopToolbar,
    Toolbar,
    SaveButton,
    CreateButton,
    useRedirect,
    useNotify,
    TabbedForm,
    FunctionField,
    NumberInput,
    Button
} from 'react-admin';

// import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";

import { RichTextInput } from 'ra-input-rich-text';

import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";

import EuroIcon from '@mui/icons-material/Euro';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import ContentFilter from '@mui/icons-material/FilterList';
import CardContent from '@mui/material/CardContent';
import NoteIcon from '@mui/icons-material/Note';
import TopicIcon from '@mui/icons-material/Topic';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import Avatar from '@mui/material/Avatar';
import PeopleIcon from '@mui/icons-material/People';

import { List as ListMaterial, ListItem, ListItemIcon, ListItemText } from '@mui/material';


// Importo il css per i Bottoni
import { listCss } from '../shared/Styles/listStyles';
import { editButtonCss, saveButtonCss, showButtonCss, createButtonCss } from '../shared/Styles/buttonStyles';
import Simulatore from "./simulatore/simulatore";

// Simulatore Servpax
// Versione 1
import Servpax_simulator from './servpax-simulator';

const ListActions = () => (
    <TopToolbar>
        <CreateButton label="Crea" sx={createButtonCss} />
    </TopToolbar>
);

export const TariffeaList = props => {

    return (
        <List title={<>
            <Button>
                &nbsp;Lista delle Tariffe
            </Button>
        </>}
            perPage={100}
            sort={{ field: 'id', order: 'DESC' }}
            actions={<ListActions />}
            exporter={false}
            /* filters={escursioniFilters}  */
            {...props}>
            <Datagrid /* expand={<TariffeaExpand />} */
                sx={listCss}/*  expand={<EscursioniExpand />} */

                bulkActionButtons={false}
            >
                {<TextField label="Descrizione" source="tfa_des" />}
                {<TextField label="f Km Start" source="tfa_kmtratta0" />}
                {<TextField label="f Km Stop" source="tfa_kmtratta1" />}
                {<TextField label="Tipo" source="tfa_tipo" />} {/* 1 = Tempo km ; 2 = A passeggero */}
                <FunctionField
                    source="tfa_isresday"
                    label="Resoconto giornaliero"
                    render={company => {
                        return (company.tfa_isresday === 0) ? <strong>NO</strong> : <strong>SI</strong>;
                    }}
                />
                {<TextField label="Cliente" source="tfa_clienteId" />}
                {<TextField label="Servizio" source="tfa_servizio" />}
                {<TextField label="Budget km Tot" source="tfa_bdgkm" />}
                {<TextField label="Budget hh Tot" source="tfa_bdghh" />}
                {<TextField label="Vuoto MAX Km Fatturabili" source="tfa_maxkmvuoto" />}
                {<TextField label="Vuoto MAX mm Fatturabili" source="tfa_maxmmvuoto" />}
                {<TextField label="Minuti franchigia per supplemento o hh extra budget" source="tfa_frgmmec" />}
                {<TextField label="Minuti franchigia per orario notturno" source="tfa_frgmmnt" />}
                {<TextField label="Sconto 1" source="tfa_maxsc1" />}
                {<TextField label="Sconto 2" source="tfa_maxsc2" />}
                {<TextField label="Sconto 3" source="tfa_maxsc3" />}
                {<TextField label="Sconto 4" source="tfa_maxsc4" />}
                {<TextField label="Sconto 5" source="tfa_maxsc5" />}
                {<TextField label="Sconto 6" source="tfa_maxsc6" />}
                {<TextField label="Sconto 7" source="tfa_maxsc7" />}
                {<TextField label="Sconto 8" source="tfa_maxsc8" />}
                {<TextField label="Sconto 9" source="tfa_maxsc9" />}
                {<TextField label="Sconto 10" source="tfa_maxsc10" />}
                {/* <ImageField source="coverImg" title="Foto" /> */}
                {/* {<TextField label="Costo" source="Costo" />} */}
                {/* {<TextField label="Tipo servizio escursione" source="tipoCategoriaEscursione" />} */}
                {/* {<ImageField source="pathImage" title="pathImage" />} */}
                {/* {<TextField label="Citta" source="citta" />} */}
                {/* <ReferenceField label="Titolo" source="id" reference="escursioni-lingue"> */}
                {/* <SubReference source="escursioniId" reference="lingue"> */}
                {/* <TextField source="titolo" link={false} /> */}
                {/* </SubReference> */}
                {/* </ReferenceField> */}
                {/*                 <ReferenceManyField
                    reference="escursioni-lingue"
                    target="escursioniId"
                    
                    // perPage={1}
                    sort={{ field: 'id', order: 'ASC' }}
                >
                    <Datagrid>
                    
                    <TextField  label="Titolo" source="titolo" />
                    </Datagrid>
                </ReferenceManyField> */}
                {/* {<TextField label="Email" source="email" />} */}
                {/* {<TextField label="Ruolo" source="roles" />} */}
                {/* {<TextField label="Codice rivenditore" source="codiceRivenditore" />} */}
                {/* <ReferenceField label="Categoria" source="hotelId" reference="hotels"> */}
                {/* <FunctionField render={record => record && `${record.nome} ${record.zona} `} /> */}
                {/* </ReferenceField> */}
                {/* {<TextField label="Indirizzo" source="indirizzo" />} */}
                {/* {<TextField label="Cap" source="cap" />} */}
                {/* {<TextField label="Telefono" source="telefono" />} */}
                {/* {<EditButton sx={editButtonCss} label="Modifica Titolo Ricerca" />} */}
                {<ShowButton sx={showButtonCss} label="Dettaglio" />}
                {/* <WrapperField label="Elimina">
                    <ConfirmDialogDeleteButtonCustom
                        titolo={"Cancella Categoria"}
                        messaggio={"Sei sicuro di vole cancellare questa categoria ?"}
                        servizio={"categorie"} />
                </WrapperField> */}
            </Datagrid>
        </List>
    );
}

const redirect = () => `/escursioni`;

const MyToolbarCreate = () => (
    <Toolbar>
        <CreateButton sx={createButtonCss} label="Crea categoria" alwaysEnable />
    </Toolbar>
);


// TODO : da rendere questo componente come riutilizzabile
const TariffeaCreateToolbar = () => {
    const redirect = useRedirect();
    const notify = useNotify();
    return (
        <Toolbar>
            {/* <SaveButton
                label="post.action.save_and_show"
            /> */}
            <SaveButton sx={createButtonCss} label="Crea categoria" /* alwaysEnable */
                // label="post.action.save_and_add"
                mutationOptions={{
                    onSuccess: data => {
                        notify('Categoria creato.'/* 'ra.notification.created' */, {
                            type: 'info',
                            messageArgs: { smart_count: 1 },
                        });
                        // redirect(false);
                        redirect('list', 'categorie');
                    }
                }
                }
                type="button"
                variant="text"
            />
        </Toolbar>
    );
};

export const TariffeaCreate = (props) => (
    <Create    {...props}>
        <SimpleForm toolbar={<TariffeaCreateToolbar></TariffeaCreateToolbar>} redirect="show">
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                <TextInput label="Descrizione backoffice" source="titoloricerca" required />
                                <br></br>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                {/* <Grid item xs={12}>
                    <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        <br></br>
                        </Typography>
                    </CardContent>
                    </Card>
                </Grid> */}
            </Grid>
        </SimpleForm>
    </Create>
);

const ShowActions = () => (
    <TopToolbar>
        <EditButton sx={editButtonCss} label="Modifica Tariffa" />
    </TopToolbar>
);

const TariffeaLingueRowForm = (props) => (
    <RowForm {...props}>
        <ValutaBandieraLingua source="lingueId" label="Nazione" />
        <TextInput fullWidth label="Nome" source="nome" />
        <TextInput fullWidth label="Descrizione" source="descrizione" />
        {/* <TextInput label="Url file pdf" source="urlPdf" /> */}
    </RowForm>
);

const GetListTabelleb = (props) => {

    const recordTariffea = useRecordContext();
    if (!recordTariffea) return null;

    return (
        <ReferenceManyField
            reference="tariffe-b"
            target="tfa_id"
        // sort={{ field: 'id', order: 'ASC' }}
        >
            <EditableDatagrid noDelete
                bulkActionButtons={false}
                editForm={<TariffeBRowForm />}
            >
                <TextField fullWidth label="Gruppo" source="tfb_gruppo" />
                <TextField fullWidth label="Descrizione" source="tfb_des" />
                <TextField fullWidth label="Costo Km di Base" source="tfb_ckm" />
                <TextField fullWidth label="Costo Supplemento Km ExtraBudget" source="tfb_ekm" />
                <TextField fullWidth label="Costo Minuto di Budget" source="tfb_chh" />
                <TextField fullWidth label="Costo Supplemento Min ExtraBudget" source="tfb_ehh" />
                <TextField fullWidth label="Supplemento Notturno (%)" source="tfb_snotturno" />
                <TextField fullWidth label="tfb_cpaxa" source="tfb_cpaxa" />
                <TextField fullWidth label="tfb_cpaxc" source="tfb_cpaxc" />
                <TextField fullWidth label="tfb_cpaxc" source="tfb_cpaxi" />
                <TextField fullWidth label="tfb_npaxmin" source="tfb_npaxmin" />
                <TextField fullWidth label="KM a vuoto" source="tfb_vkm" />
                <TextField fullWidth label="Minuti a vuoto" source="tfb_vhh" />
                <TextField fullWidth label="Costi Extra Km a vuoto" source="tfb_evkm" />
                <TextField fullWidth label="Max % Km a Vuoto Fatt" source="tfb_vkmperc" />
                <TextField fullWidth label="Max % Min a Vuoto Fatt" source="tfb_vhhperc" />
                <FunctionField
                    label="ServPax Sim"
                    render={record => {
                        return record && <Servpax_simulator record={record} ></Servpax_simulator>;
                        // return record && <ServpaxSimulator record={record}></ServpaxSimulator>;
                    }}
                />

                <FunctionField
                    label="Simulatore"
                    render={record => {

                        // console.log(recordTariffea);

                        record.tfa_bdgkm = recordTariffea.tfa_bdgkm;
                        record.tfa_bdghh = recordTariffea.tfa_bdghh;
                        record.tfa_frgmmec = recordTariffea.tfa_frgmmec;
                        record.tfa_frgmmnt = recordTariffea.tfa_frgmmnt;

                        return record && <Simulatore record={record}></Simulatore>;
                    }}
                />



            </EditableDatagrid>
        </ReferenceManyField>
    );
}


const TariffeBRowForm = (props) => (
    <RowForm {...props}>
        <TextInput fullWidth label="Gruppo" source="tfb_gruppo" />
        <TextInput fullWidth label="Descrizione" source="tfb_des" />
        <NumberInput min={0.000} fullWidth label="Costo Km di Base" source="tfb_ckm" options={{ style: "currency", currency: "EUR" }} />
        <NumberInput min={0.000} fullWidth label="Costo Supplemento Km ExtraBudget" source="tfb_ekm" options={{ style: "currency", currency: "EUR" }} />
        <NumberInput min={0.000} fullWidth label="Costo Minuto di Budget" source="tfb_chh" options={{ style: "currency", currency: "EUR" }} />
        <NumberInput min={0.000} fullWidth label="Costo Supplemento Min ExtraBudget" source="tfb_ehh" />
        <NumberInput min={0} fullWidth label="Supplemento Notturno (%)" source="tfb_snotturno" />
        <NumberInput min={0.000} fullWidth label="tfb_cpaxa" source="tfb_cpaxa" />
        <NumberInput min={0.000} fullWidth label="tfb_cpaxc" source="tfb_cpaxc" />
        <NumberInput min={0.000} fullWidth label="tfb_cpaxi" source="tfb_cpaxi" />
        <NumberInput min={0.000} fullWidth label="tfb_npaxmin" source="tfb_npaxmin" />
        <NumberInput min={0} fullWidth label="Km a vuoto" source="tfb_vkm" />
        <NumberInput min={0} fullWidth label="Minuti a vuoto" source="tfb_vhh" />
        <NumberInput min={0.000} fullWidth label="Costi Extra Km a vuoto" source="tfb_evkm" />
        <NumberInput min={0} fullWidth label="Max % Km a Vuoto Fatt" source="tfb_vkmperc" />
        <NumberInput min={0} fullWidth label="Max % Min a Vuoto Fatt" source="tfb_vhhperc" />


    </RowForm>
);

export const TariffeaShow = (props) => {

    return (
        <Show title={<>
            <Button>&nbsp;Dettaglio Tariffa</Button>
        </>} actions={<ShowActions />}>

            <TabbedForm toolbar={null}>
                <TabbedForm.Tab label="Dettagli Tariffa" icon={<TopicIcon />}>
                    <ListMaterial>
                        <Grid container spacing={2}>
                            {[
                                { label: "Descrizione", source: "tfa_des", Icon: NoteIcon },
                                { label: "Tipo", source: "tfa_tipo", Icon: NoteIcon }, // 1 = Tempo km ; 2 = A passeggero
                                { label: "Cliente", source: "tfa_clienteId", Icon: PeopleIcon },
                                { label: "Servizio", source: "tfa_servizio", Icon: NoteIcon },
                                // Escludi i campi "f Km Start", "f Km Stop" e "Sconto"
                            ].filter(field => !["tfa_kmtratta0", "tfa_kmtratta1"].includes(field.source)).map(({ label, source, Icon }, index) => (
                                <Grid item xs={12} sm={6} key={`field-${index}`}>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon />
                                        </ListItemIcon>
                                        <ListItemText primary={label} secondary={<TextField label="" source={source} fullWidth />} />
                                    </ListItem>
                                </Grid>
                            ))}
                            {/* Raggruppa "f Km Start" e "f Km Stop" in una Card separata */}
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            {[
                                                { label: "f Km Start", source: "tfa_kmtratta0", Icon: NoteIcon },
                                                { label: "f Km Stop", source: "tfa_kmtratta1", Icon: NoteIcon },
                                            ].map(({ label, source, Icon }, index) => (
                                                <Grid item xs={12} sm={6} key={`km-${index}`}>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <Icon />
                                                        </ListItemIcon>
                                                        <ListItemText primary={label} secondary={<TextField label="" source={source} fullWidth />} />
                                                    </ListItem>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            {/* Raggruppa i campi "Sconto" in una Card separata */}
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            {Array.from({ length: 10 }, (_, i) => ({ label: `Sconto ${i + 1}`, source: `tfa_maxsc${i + 1}`, Icon: EuroIcon })).map(({ label, source, Icon }, index) => (
                                                <Grid item xs={6} sm={3} key={`discount-${index}`}>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <Icon />
                                                        </ListItemIcon>
                                                        <ListItemText primary={label} secondary={<TextField label="" source={source} fullWidth />} />
                                                    </ListItem>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </ListMaterial>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Gruppi tariffe" icon={<TopicIcon />}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <GetListTabelleb></GetListTabelleb>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
            </TabbedForm>
        </Show>
    );
}

const ShowActionsForEdit = () => (
    <ShowTopToolbarDettaglio></ShowTopToolbarDettaglio>
);

const ShowTopToolbarDettaglio = () => (
    <TopToolbar>
        <ShowButton sx={showButtonCss} label="Dettaglio" />
    </TopToolbar>
);

const MyToolbarEdit = () => (
    <Toolbar>
        <SaveButton sx={saveButtonCss} /* sx={{
            border: 1,
            borderColor: 'black',
            borderRadius: '20px',
            backgroundColor: '#01b5d0'
        }} */ label="Salva parametri" /* alwaysEnable */ />
    </Toolbar>
);

export const ServiziRowForm = (props) => (
    <RowForm {...props}>
        <ValutaBandieraLingua source="lingueId" label="Nazione" />
        <TextInput label="Nome" source="nome" />
        <TextInput label="Descrizione" source="titoloricerca" />
        <TextInput label="Descrizione breve" source="titoloricercaBreve" />
    </RowForm>
);

// Componente personalizzato che simula un NumberInput ma accetta la virgola
const EuroNumberInput = ({ source, label, ...rest }) => {
    // Gestore per l'input dell'utente
    const handleBlur = (event) => {
        let value = event.target.value;
        // Sostituisci la virgola con il punto per la conversione
        value = value.replace(',', '.');
        // Controlla che il valore sia un numero e limita a tre cifre decimali
        if (!isNaN(value) && value.trim() !== '') {
            value = parseFloat(value);
            // Tronca a tre cifre decimali senza arrotondamento
            value = Math.floor(value * 1000) / 1000;
            // Converti di nuovo in stringa sostituendo il punto con la virgola
            value = value.toString().replace('.', ',');
            // Aggiorna il valore del campo con la versione corretta
            event.target.value = value;
        }
    };

    return (
        <NumberInput
            min={0.000}
            label={label}
            source={source}
            onBlur={handleBlur}
            {...rest}
        />
    );
};

export const TariffeaEdit = (props) => {
    return (
        <Edit redirect="show" title={<><Button>&nbsp;Dettaglio Tariffa</Button></>} actions={<ShowActionsForEdit />} {...props}>
            <SimpleForm toolbar={<MyToolbarEdit />}  >
                <Grid container spacing={2}>
                    {/* I campi standard */}
                    {[
                        { label: "Descrizione", source: "tfa_des", Icon: NoteIcon },
                        { label: "Tipo", source: "tfa_tipo", Icon: NoteIcon }, // 1 = Tempo km ; 2 = A passeggero
                        // { label: "Cliente", source: "tfa_clienteId", Icon: NoteIcon },
                        { label: "Servizio", source: "tfa_servizio", Icon: NoteIcon },
                    ].map(({ label, source, Icon }, index) => (
                        <Grid item xs={12} sm={6} key={`field-edit-${index}`}>
                            <ListItem>
                                <ListItemIcon>
                                    <Icon />
                                </ListItemIcon>
                                <TextInput label={label} source={source} fullWidth />
                            </ListItem>
                        </Grid>
                    ))}
                    {/* "f Km Start" e "f Km Stop" raggruppati in una Card */}
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2}>
                                    {[
                                        { label: "f Km Start", source: "tfa_kmtratta0", Icon: NoteIcon },
                                        { label: "f Km Stop", source: "tfa_kmtratta1", Icon: NoteIcon },
                                    ].map(({ label, source, Icon }, index) => (
                                        <Grid item xs={12} sm={6} key={`km-edit-${index}`}>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <Icon />
                                                </ListItemIcon>
                                                <NumberInput min={0} label={label} source={source} fullWidth />
                                            </ListItem>
                                        </Grid>
                                    ))}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    {/* Campi "Sconto" raggruppati in una Card, ora con NumberInput */}
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            {Array.from({ length: 10 }, (_, i) => ({
                                                label: `Sconto ${i + 1} (€)`,
                                                source: `tfa_maxsc${i + 1}`,
                                                Icon: EuroIcon,
                                            })).map(({ label, source, Icon }, index) => (
                                                <Grid item xs={6} sm={3} key={`discount-edit-${index}`}>
                                                    <ListItem>
                                                        <ListItemIcon><Icon /></ListItemIcon>
                                                        {/* <NumberInput
                                                            label={label}
                                                            source={source}
                                                            fullWidth
                                                            min={0}
                                                        /> */}<EuroNumberInput label={label}
                                                            source={source} />
                                                    </ListItem>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
}

const ValutaBandieraLingua = ({ source }) => {
    const record = useRecordContext();
    let lingua = "";

    switch (record[source]) {
        case 1:
            lingua = "http://imgm.btechitalia.it/flags/flagita.png"
            break;
        case 2:
            lingua = "http://imgm.btechitalia.it/flags/flaguk.png"
            break;
        case 3:
            lingua = "http://imgm.btechitalia.it/flags/flagpol.png"
            break;
        case 4:
            lingua = "http://imgm.btechitalia.it/flags/flagger.png"
            break;
        case 5:
            lingua = "http://imgm.btechitalia.it/flags/flagpor.png"
            break;
        case 6:
            lingua = "http://imgm.btechitalia.it/flags/flagspa.png"
            break;
        case 7:
            lingua = "http://imgm.btechitalia.it/flags/flagfra.png"
            break;
        default:
            lingua = ""
            break;
    }


    return (
        <><div>
            <img src={lingua} width="20" height="20" />
        </div></>
    );
};
// http://imgm.btechitalia.it/testita.png
export const TariffeaExpand = ({ id, ...props }) => {

    return (
        <Show {...props} id={id} /* aside={<Aside />} */>

            <SimpleShowLayout>


                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={10}>
                        <h2>Lista titoli</h2>
                        <h3>{<TextField label="Titolo per ricerca" source="titoloricerca" />}
                        </h3><br></br>
                        <ReferenceManyField
                            reference="servizi-aggiuntivi-lingue"
                            target="serviziAggiuntiviId"

                            // perPage={1}
                            sort={{ field: 'id', order: 'ASC' }}
                        >
                            <EditableDatagrid noDelete
                                bulkActionButtons={false}
                                editForm={<TariffeaLingueRowForm />}
                            >

                                {/* <TextField  label="Id Lingua" source="lingueId" /> */}
                                <ValutaBandieraLingua source="lingueId" label="Nazione" />
                                {/* <ImageField  source="urlBandierina" label="Nazione" /> */}
                                <TextField label="Titolo" source="nome" />
                            </EditableDatagrid>
                        </ReferenceManyField>
                    </Grid>
                    <Grid item xs={2}>

                    </Grid>
                </Grid>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={10}>
                        <h2>Lista Descrizioni</h2>
                        <ReferenceManyField
                            reference="servizi-aggiuntivi-lingue"
                            target="serviziAggiuntiviId"

                            // perPage={1}
                            sort={{ field: 'id', order: 'ASC' }}
                        >
                            <EditableDatagrid noDelete
                                bulkActionButtons={false}
                                editForm={<TariffeaDescrizioniRowForm />} >
                                <ValutaBandieraLingua source="lingueId" label="Nazione" />
                                <RichTextField label="Descrizione" source="titoloricerca" />
                            </EditableDatagrid>
                        </ReferenceManyField>
                    </Grid>
                    <Grid item xs={6}>
                    </Grid>
                </Grid>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>

                        {/* {<RichTextField label="Consegna" source="consegna" />} */}

                    </Grid>
                </Grid>
            </SimpleShowLayout>

        </Show>
    );
}

const TariffeaRowForm = (props) => (
    <RowForm {...props}>
        <ValutaBandieraLingua source="lingueId" label="Nazione" />
        <TextInput label="Titolo" source="nome" />
    </RowForm>
);


export const TariffeaDescrizioniRowForm = (props) => (
    <RowForm {...props}>
        {/* <TextInput label="Descrizione" source="titoloricerca" /> */}
        <ValutaBandieraLingua source="lingueId" label="Nazione" />
        <RichTextInput source="titoloricerca"></RichTextInput>
    </RowForm>
);

// Filtri

const TariffeaFilterSidebar = () => (
    <Box
        sx={{
            display: {
                xs: 'none',
                sm: 'block'
            },
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        }}
    >
        <Card>
            <CardContent>
                <TariffeaFilter />
            </CardContent>
        </Card>
    </Box>
);

/// Filtri
const TariffeaFilter = () => (
    <Grid container spacing={2}>
        <Grid>
            <Box >
                {/*         <Box sx={{
        width: '12em',
    }}><h3></h3>
        <Card>
    <CardContent><FilterLiveSearch source="id" />
    </CardContent>
    </Card></Box> */}

                <FilterList label="Zone geografiche" icon={<ContentFilter />}>
                    <FilterListItem
                        label="Nord"
                        value={{
                            zona: "n"
                        }}
                    />
                    <FilterListItem
                        label="Nord-Ovest"
                        value={{
                            zona: "no"
                        }}
                    />
                    <FilterListItem
                        label="Nord-Est"
                        value={{
                            zona: "ne"
                        }}
                    />
                    <FilterListItem
                        label="Est"
                        value={{
                            zona: "e"
                        }}
                    />
                    <FilterListItem
                        label="Sud"
                        value={{
                            zona: "s"
                        }}
                    />
                    <FilterListItem
                        label="Sud-Ovest"
                        value={{
                            zona: "so"
                        }}
                    />
                    <FilterListItem
                        label="Sud-Est"
                        value={{
                            zona: "se"
                        }}
                    />
                    <FilterListItem
                        label="Ovest"
                        value={{
                            zona: "o"
                        }}
                    />
                </FilterList>
            </Box>
        </Grid>
        {/* {  <Grid>
    <Box>
    <FilterList label="Trimestre" icon={<ContentFilter />}>
    <FilterListItem
            label="Primo"
            value={{trimestre: 1
            }}
        />
         <FilterListItem
            label="Secondo"
            value={{trimestre: 2
            }}
        />
    <FilterListItem
            label="Terzo"
            value={{trimestre: 3
            }}
        />
         <FilterListItem
            label="Quarto"
            value={{trimestre: 4
            }}
        />
    </FilterList>
    </Box>
  </Grid>} */}
    </Grid>

);

const escursioniFilters = [
    // <TextInput label="Cerca per nome" source="nome" alwaysOn />,
    {/* <TextInput label="Cerca per id" source="id" />,
    <ReferenceInput perPage={100} source="id" reference="servizi-aggiuntivi" label="Titolo escursione">
        <SelectInput optionText="titoloricerca" />
    </ReferenceInput>, */}
    // <TextInput label="Cerca per titolo" source="titoloricerca" /* alwaysOn */ />
];

