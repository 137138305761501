import { useGetOne, Loading } from 'react-admin';


const GetDescrizioneTabellaAlfa = ({ gncdtia }) => {
    const { data: recordTabellealfa, isLoading, error } = useGetOne('tabellealfa', { id: gncdtia });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return <>{recordTabellealfa.tbdes}</>;
};

export default GetDescrizioneTabellaAlfa;