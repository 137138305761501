import React from 'react';
import { Loading, useGetList } from 'react-admin';
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent, TimelineOppositeContent } from '@mui/lab';
import { Typography, Chip } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import EditDocumentiDialog from '../documenti/CustomEditDocumenti';
import CustomDeleteDocumenti from "../documenti/CustomDeleteDocumenti";
import GetUserEmail from "../shared/ComponentsUser/GetUserEmail";
import CustomDownloadDocumento from "../documenti/CustomDownloadDocumento";
import GetDescrizioneTipoDocumento from "../documenti/GetDescrizioneTipoDocumento";
import { Divider } from 'material-ui';

const DocumentiList = ({ tiporecordId, recordId, emailUser }) => {
    const { data, isLoading, error } = useGetList(
        'documenti',
        {
            pagination: { page: 1, perPage: 10 },
            filter: {
                recordId: recordId,
                tiporecordId: tiporecordId
            }
        }
    );

    const getIntegritaFileColor = (stato) => {
        switch (stato) {
            case 0: return 'success';
            case 1: return 'error';
            case 2: return 'warning';
            default: return 'default';
        }
    };

    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    console.log(data);

    return (
        <Box display="flex" justifyContent="top" alignItems="top" minHeight="100vh">
            <Box width="70%">
                <Timeline sx={{ [`& .MuiTimelineItem-root::before`]: { flex: 0, padding: 0 } }}>
                    {data.map((doc) => (
                        <TimelineItem key={doc.id}>
                            <TimelineOppositeContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Card sx={{ minWidth: 275, margin: 2 }}>
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Avatar sx={{ bgcolor: 'grey.500', mb: 1, width: 56, height: 56 }}>
                                            <PersonIcon />
                                        </Avatar>
                                        <Typography color="textPrimary" gutterBottom>
                                            <GetUserEmail userId={doc.userId} />
                                        </Typography>
                                        <Typography color="textSecondary">
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: 2, // Puoi regolare lo spazio come preferisci
                                            }}>
                                                <strong>  Tipo Documento: <GetDescrizioneTipoDocumento tipodocumentoId={doc.tipodocumentoId}></GetDescrizioneTipoDocumento></strong>
                                                <Chip label={`Stato integrità: ${doc.statoIntegritaFile === 0 ? 'OK' : doc.statoIntegritaFile === 1 ? 'KO' : 'Altro'}`}
                                                    color={getIntegritaFileColor(doc.statoIntegritaFile)} />
                                                <br></br>
                                                Data creazione: {new Date(doc.createdAt).toLocaleDateString()} {new Date(doc.createdAt).toLocaleTimeString()}
                                            </Box>
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 2, // Puoi regolare lo spazio come preferisci
                                        }}>

                                            <CustomDeleteDocumenti recordId={doc.id} record={doc} />
                                            <CustomDownloadDocumento tiporecordId={tiporecordId} recordId={doc.recordId} nomeFile={doc.nomeFile}></CustomDownloadDocumento>
                                        </Box>
                                    </CardActions>
                                </Card>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot color={getIntegritaFileColor(doc.statoIntegritaFile)} />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    <InsertDriveFileIcon /> Nome File <strong>{doc.nomeFile}</strong>
                                    <br></br>Estensione: {doc.estensioneFile}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{ margin: 2 }} // Applica un margine su tutti i lati
                                >
                                    <span
                                        style={{
                                            fontWeight: 'bold', // Rende il testo in grassetto
                                            fontSize: '1.1rem', // Aumenta leggermente la dimensione del font
                                        }}
                                    >

                                        Descrizione:
                                    </span>
                                    {` ${doc.descrizione || "Nessuna descrizione disponibile."}`}</Typography>

                                <Typography
                                    variant="body2"
                                    sx={{ margin: 1 }} // Applica un margine su tutti i lati
                                >
                                    Data ultima modifica: {new Date(doc.updatedAt).toLocaleDateString()} {new Date(doc.updatedAt).toLocaleTimeString()}

                                </Typography>

                                <EditDocumentiDialog note={doc} label="Descrizione File " />

                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>
            </Box>
        </Box>
    );
};

export default DocumentiList;
