import React from 'react';
import {
    List,
    DatagridConfigurable,
    TextField,
    DateField,
    ShowButton,
    CreateButton,
    SelectColumnsButton,
    TopToolbar,
    ExportButton,
    FunctionField,
} from 'react-admin';
import {
    numberFilter,
    StackedFilters,
} from '@react-admin/ra-form-layout';
import { listCss } from '../shared/Styles/listStyles';
import { showButtonCss, exportButtonCss, columnsButtonCss, filterButtonCss } from '../shared/Styles/buttonStyles';
import PeopleIcon from '@mui/icons-material/People';
import Button from '@mui/material/Button';
import Avatar from '@material-ui/core/Avatar';
import { Box, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const ListActions = () => (
    <TopToolbar>
        <CreateButton label="Crea" sx={exportButtonCss} />
        <ExportButton label="Esporta" sx={exportButtonCss} />
        {/* <SelectColumnsButton sx={columnsButtonCss} /> */}
        {/* <StackedFilters sx={filterButtonCss} config={listFiltersusers} /> */}
    </TopToolbar>
);

const listFiltersusers = {
    'id': numberFilter({ operators: ["eq", "neq", "gt", "lt"] }),
};

const StatusLED = ({ status }) => (
    <Box display="flex" alignItems="center">
        <FiberManualRecordIcon
            sx={{
                color: status ? 'green' : 'red',
                fontSize: '0.8rem',
                marginRight: 1
            }}
        />
        <Typography variant="body2">
            {status ? 'Attivo' : 'Inattivo'}
        </Typography>
    </Box>
);

export const usersList = () => (
    <List
        mutationMode="pessimistic"
        title={
            <Button>
                <PeopleIcon />
                &nbsp;Lista Users
            </Button>
        }
        perPage={50}
        actions={<ListActions />}
    >
        <DatagridConfigurable sx={listCss} bulkActionButtons={false}>
            <FunctionField
                source="avatar"
                label="Avatar"
                render={record => record && <Avatar alt="" src={record.avatar || ""}></Avatar>}
            />
            <TextField label="Nome" source="nome" />
            <TextField label="Cognome" source="cognome" />
            <TextField label="Email" source="email" />
            <TextField label="Ruolo" source="roles" />
            <FunctionField
                label="Status"
                render={record => <StatusLED status={record.status} />}
            />
            <DateField source="createdAt" label="Data Creazione" />
            <ShowButton sx={showButtonCss} label="Dettaglio" />
        </DatagridConfigurable>
    </List>
);