import { useGetOne, Loading } from 'react-admin';

export const GetNameFromResourceById = ({ id, resource, fieldName }) => {
    const { data, isLoading, error } = useGetOne(resource, { id });

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <p>Errore: {error.message}</p>;
    }

    if (!data) {
        return <p>Nessun dato trovato</p>;
    }

    return <div>{data[fieldName]}</div>;
};