import * as React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    BooleanInput,
    DateInput,
    NumberInput,
    required,
    Toolbar,
    SaveButton,
    ReferenceInput
} from 'react-admin';
import {
    Grid,
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Card,
    CardContent,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PeopleIcon from '@mui/icons-material/People';
import { createButtonCss } from '../shared/Styles/buttonStyles';
import CustomAutocompleInput from "../shared/FormFields/CustomAutocompleInput";

const MyToolbarCreate = () => (
    <Toolbar>
        <SaveButton sx={createButtonCss} label="Crea Pacchetto" alwaysEnable />
    </Toolbar>
);

const PacchettiCreate = () => (
    <Create
        title={
            <Box display="flex" alignItems="center">
                <PeopleIcon color="primary" />
                <Typography variant="h6" component="span" sx={{ marginLeft: 1 }}>
                    Crea Nuovo Pacchetto
                </Typography>
            </Box>
        }
        redirect="show"
    >
        <SimpleForm toolbar={<MyToolbarCreate />}>
            <Grid container spacing={3}>
                {/* Colonna sinistra */}
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Informazioni Generali</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextInput fullWidth label="Nome Pacchetto" source="nome" validate={required()} />
                                </Grid>
                                <Grid item xs={12}>
                                    <NumberInput fullWidth label="Numero Minimo" source="numMinimo" defaultValue={0} />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomAutocompleInput source="anagenId" reference="anagen" optionProperty="gnfind" label="Seleziona Anagrafica" />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Card sx={{ mt: 2 }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Indirizzi</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextInput fullWidth label="Indirizzo Prelievo" source="indirizzoPrelievo" multiline />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput fullWidth label="Indirizzo Destinazione" source="indirizzoDestinazione" multiline />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Card sx={{ mt: 2 }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Date e Descrizione</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <DateInput fullWidth label="Data Inizio" source="dataFrom" />
                                </Grid>
                                <Grid item xs={6}>
                                    <DateInput fullWidth label="Data Fine" source="dataTo" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput fullWidth label="Descrizione Breve" source="descrizione" multiline />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Colonna destra */}
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Dettagli Aggiuntivi</Typography>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Mostra dettagli</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextInput fullWidth label="Note" source="note" multiline />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextInput fullWidth label="Condizioni Particolari" source="condizioniParticolari" multiline />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextInput fullWidth label="Extras Promozione" source="extrasPromozione" multiline />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextInput fullWidth label="Descrizione Completa" source="descrizioneCompleta" multiline />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </CardContent>
                    </Card>

                    <Card sx={{ mt: 2 }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Ordinamento e Stato</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <NumberInput fullWidth label="Ordinamento" source="ordinamento" defaultValue={0} />
                                </Grid>
                                <Grid item xs={4}>
                                    <BooleanInput fullWidth label="Attivo" source="flAttivo" />
                                </Grid>
                                <Grid item xs={4}>
                                    <BooleanInput fullWidth label="Visibile" source="flVisibile" />
                                </Grid>
                                <Grid item xs={4}>
                                    <BooleanInput fullWidth label="Annullato" source="flAnnullato" />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);

export default PacchettiCreate;