import React from 'react';
import { Loading, useGetList } from 'react-admin';
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent, TimelineOppositeContent } from '@mui/lab';
import { Typography, Chip } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
// import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CustomEditContatto from "../CustomAnagenContatti/CustomEditContatto";
import CustomDeleteContatto from "../CustomAnagenContatti/CustomDeleteContatto";
// import GetUserEmail from "../shared/ComponentsUser/GetUserEmail";
import GetDescrizioneTabellaAlfa from "../CustomAnagenContatti/GetDescrizioneTabellaAlfa";
import CopyToClipboardButtonString from "../shared/FormFields/CopyToClipboardButtonString";

/* TODO: crea un file a parte per il seguente componente - START */
const CampoCopyContattoField = ({ label, text }) => {
    return (
        <Typography sx={{ margin: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} variant="h7" component="div">
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* <InsertDriveFileIcon /> */}
                <span>{label} <strong>{text}</strong></span>
            </div>
            <CopyToClipboardButtonString text={text}></CopyToClipboardButtonString>
        </Typography>
    );
};
/* STOP */

const AnagenContattiList = ({ /* tiporecordId,  */recordId, emailUser }) => {
    const { data, isLoading, error } = useGetList(
        'anagen-contatti',
        {
            pagination: { page: 1, perPage: 10 },
            filter: {
                angenId: recordId,
            }
        }
    );

    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    return (
        <Box display="flex" justifyContent="top" alignItems="top" minHeight="100vh">
            <Box width="70%">
                <Timeline sx={{ [`& .MuiTimelineItem-root::before`]: { flex: 0, padding: 0 } }}>
                    {data.map((doc) => (
                        <TimelineItem key={doc.id}>
                            <TimelineOppositeContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Card sx={{ minWidth: 275, margin: 2 }}>
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                                        <Avatar sx={{ bgcolor: 'grey.500', mb: 1, width: 56, height: 56 }}>
                                            <PersonIcon />
                                        </Avatar>
                                        <Typography color="textPrimary" gutterBottom>
                                            {/* <GetUserEmail userId={doc.userId} /> */}
                                        </Typography>
                                        <Typography color="textSecondary">
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: 2, // Puoi regolare lo spazio come preferisci
                                            }}>

                                                <Typography
                                                    variant="body2"
                                                    sx={{ margin: 1 }} // Applica un margine su tutti i lati
                                                >
                                                    <strong>  Tipo Contatto: <GetDescrizioneTabellaAlfa gncdset={doc.gncdset}></GetDescrizioneTabellaAlfa></strong>

                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    sx={{ margin: 1 }} // Applica un margine su tutti i lati
                                                >
                                                    Data creazione: {new Date(doc.createdAt).toLocaleDateString()} {new Date(doc.createdAt).toLocaleTimeString()}

                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    sx={{ margin: 1 }} // Applica un margine su tutti i lati
                                                >
                                                    Data ultima modifica: {new Date(doc.updatedAt).toLocaleDateString()} {new Date(doc.updatedAt).toLocaleTimeString()}

                                                </Typography>
                                            </Box>
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 2, // Puoi regolare lo spazio come preferisci
                                        }}>

                                            <CustomDeleteContatto recordId={doc.id} record={doc} />
                                        </Box>
                                        {<CustomEditContatto doc={doc} label="Voci Contatto " />}

                                    </CardActions>
                                </Card>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot color={"success"} />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <CampoCopyContattoField label={"Nominativo"} text={doc.nominativo}></CampoCopyContattoField>
                                <CampoCopyContattoField label={"Mail"} text={doc.mail}></CampoCopyContattoField>
                                <CampoCopyContattoField label={"Pec"} text={doc.pec}></CampoCopyContattoField>
                                <CampoCopyContattoField label={"Telefono1"} text={doc.telefono1}></CampoCopyContattoField>
                                <CampoCopyContattoField label={"Telefono2"} text={doc.telefono2}></CampoCopyContattoField>
                                <CampoCopyContattoField label={"Telefono3"} text={doc.telefono3}></CampoCopyContattoField>
                                <CampoCopyContattoField label={"Indirizzo"} text={doc.indirizzo}></CampoCopyContattoField>
                                <CampoCopyContattoField label={"Comune"} text={doc.comune}></CampoCopyContattoField>
                                <CampoCopyContattoField label={"CAP"} text={doc.cap}></CampoCopyContattoField>
                                <CampoCopyContattoField label={"Provincia"} text={doc.prov}></CampoCopyContattoField>
                                <CampoCopyContattoField label={"Frazione"} text={doc.frazione}></CampoCopyContattoField>
                                <br></br>
                                <Typography
                                    variant="body2"
                                    sx={{ margin: 2 }} // Applica un margine su tutti i lati
                                >
                                    <span
                                        style={{
                                            fontWeight: 'bold', // Rende il testo in grassetto
                                            fontSize: '1.1rem', // Aumenta leggermente la dimensione del font
                                        }}
                                    >

                                        Note Contatto:
                                    </span>
                                    {` ${doc.note || "Nessuna nota disponibile."}`}

                                    <br></br>
                                    <CopyToClipboardButtonString text={doc.note}></CopyToClipboardButtonString>
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>
            </Box>
        </Box>
    );
};

export default AnagenContattiList;
