import React, { useState, useEffect } from "react";
import {
    usePermissions,
    useNotify,
    useRefresh,
    useGetIdentity
} from 'react-admin';
import {
    Card,
    CardContent,
    Typography,
    Box,
    Button,
    Snackbar,
    Alert,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Paper,
    Grid,
    CircularProgress,
    Divider
} from '@mui/material';
import { blue, green, red } from '@mui/material/colors';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import axios from "axios";
import config from '../../config/config.json';

const host = config.production.host;

const WialonStatus = () => {
    const { permissions } = usePermissions();
    const { identity } = useGetIdentity();
    const notify = useNotify();
    const refresh = useRefresh();

    const [status, setStatus] = useState(null);
    const [lastCheck, setLastCheck] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [autoRefresh, setAutoRefresh] = useState(true);

    const checkStatus = async () => {
        try {
            setLoading(true);
            setError(null);

            const response = await axios.get(
                `${host}/wialon/health`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem('feathers-jwt')
                    }
                }
            );

            setStatus(response.data);
            setLastCheck(new Date());

            if (response.data.status === 'error') {
                notify('Attenzione: Il servizio Wialon presenta degli errori', {
                    type: 'warning',
                    multiLine: true,
                });
            }
        } catch (error) {
            console.error('Errore nel controllo dello stato:', error);
            setError(error);
            setStatus({
                status: 'error',
                code: 'CONNECTION_ERROR',
                message: 'Errore di connessione al servizio',
                error: error.response?.data || error.message
            });
            notify('Errore durante il controllo dello stato Wialon', {
                type: 'error',
                multiLine: true,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (permissions === 'admin') {
            checkStatus();
            let interval;
            if (autoRefresh) {
                interval = setInterval(checkStatus, 300000); // ogni 5 minuti
            }
            return () => {
                if (interval) clearInterval(interval);
            };
        }
    }, [permissions, autoRefresh]);

    const handleRefresh = () => {
        checkStatus();
        refresh();
    };

    const toggleAutoRefresh = () => {
        setAutoRefresh(!autoRefresh);
        notify(`Aggiornamento automatico ${!autoRefresh ? 'attivato' : 'disattivato'}`, {
            type: 'info'
        });
    };

    const handleCopyJson = (e) => {
        e?.stopPropagation();
        navigator.clipboard.writeText(JSON.stringify(status, null, 2))
            .then(() => {
                setOpenSnackbar(true);
                notify('JSON copiato negli appunti', { type: 'success' });
            })
            .catch(error => {
                console.error('Errore durante la copia:', error);
                notify('Errore durante la copia del JSON', { type: 'error' });
            });
    };

    if (permissions !== "admin") {
        return (
            <Card>
                <CardContent>
                    <Typography variant="h5" color="error">
                        Accesso non autorizzato
                    </Typography>
                    <Typography variant="body1">
                        È necessario essere amministratori per visualizzare questa pagina.
                    </Typography>
                </CardContent>
            </Card>
        );
    }

    return (
        <Box sx={{ width: '100%' }}>
            {/* Header con Refresh */}
            <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6">
                        Stato del Servizio Wialon
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button
                            startIcon={<AutorenewIcon />}
                            onClick={toggleAutoRefresh}
                            color={autoRefresh ? "primary" : "error"}
                            variant={autoRefresh ? "contained" : "outlined"}
                            size="small"
                        >
                            Auto {autoRefresh ? 'ON' : 'OFF'}
                        </Button>
                        <Button
                            startIcon={<RefreshIcon />}
                            onClick={handleRefresh}
                            disabled={loading}
                            variant="contained"
                            size="small"
                        >
                            Aggiorna
                        </Button>
                    </Box>
                </Box>
            </Paper>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                    <CircularProgress />
                </Box>
            ) : error ? (
                <Alert severity="error" sx={{ mb: 3 }}>
                    {error.message || 'Si è verificato un errore durante il controllo dello stato'}
                </Alert>
            ) : (
                <>
                    {/* Status Card */}
                    <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                                    <FiberManualRecordIcon
                                        sx={{
                                            color: status?.status === 'ok' ? green[500] :
                                                status?.status === 'error' ? red[500] :
                                                    blue[500],
                                            animation: status?.status === 'ok' ? 'pulse 2s infinite' : 'none',
                                            '@keyframes pulse': {
                                                '0%': { opacity: 1 },
                                                '50%': { opacity: 0.5 },
                                                '100%': { opacity: 1 },
                                            }
                                        }}
                                    />
                                    <Typography variant="h6">
                                        Status: {status?.status?.toUpperCase() || 'N/A'}
                                    </Typography>
                                </Box>
                                {lastCheck && (
                                    <Typography variant="body2" color="textSecondary">
                                        Ultimo controllo: {lastCheck.toLocaleString('it-IT', {
                                            dateStyle: 'medium',
                                            timeStyle: 'medium'
                                        })}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Dettagli Servizio
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Code: {status?.code || 'N/A'}
                                </Typography>
                                <Typography variant="body2">
                                    Message: {status?.message || 'N/A'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>

                    {/* Service Details */}
                    {status?.data?.serviceStatus && (
                        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Informazioni Servizio
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>Response Time:</strong> {status.data.serviceStatus.responseTime || "N/A"}
                                    </Typography>
                                    <Typography variant="body2">
                                        <strong>Base URL:</strong> {status.data.serviceStatus.baseUrl || "N/A"}
                                    </Typography>
                                    <Typography variant="body2">
                                        <strong>Online:</strong> {status.data.serviceStatus.online ? "Si" : "No"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {status.data.sessionInfo && (
                                        <>
                                            <Typography variant="body2" gutterBottom>
                                                <strong>Session Valid:</strong> {status.data.sessionInfo.valid ? "Si" : "No"}
                                            </Typography>
                                            <Typography variant="body2">
                                                <strong>Session ID:</strong> {status.data.sessionInfo.id || "N/A"}
                                            </Typography>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </Paper>
                    )}

                    {/* API Response Details */}
                    {status?.data?.apiResponse && (
                        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Risposta API - Endpoint checked core/search_items
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ mt: 2 }}>
                                        <Typography variant="body1" gutterBottom sx={{ fontWeight: "bold" }}>
                                            Numero Autovetture censite su TopFly:
                                        </Typography>
                                        <Typography variant="h5" sx={{ color: "#2e7d32" }}>
                                            {status.data.apiResponse.totalItems || "0"}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mt: 2 }}>
                                        <Typography variant="body1" gutterBottom sx={{ fontWeight: "bold" }}>
                                            Orario Server:
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: "#616161" }}>
                                            {new Date(status.data.apiResponse.serverTime * 1000).toLocaleString('it-IT') || "N/A"}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    )}


                    {/* JSON Data */}
                    <Accordion sx={{ '&:before': { display: 'none' } }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{ backgroundColor: 'background.paper' }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <Typography>Dati JSON Completi</Typography>
                                <Button
                                    startIcon={<ContentCopyIcon />}
                                    onClick={handleCopyJson}
                                    size="small"
                                    sx={{ mr: 2 }}
                                >
                                    Copia JSON
                                </Button>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={{ bgcolor: 'background.default', p: 0 }}>
                            <pre style={{
                                overflow: 'auto',
                                maxHeight: '400px',
                                backgroundColor: '#1e1e1e',
                                color: '#d4d4d4',
                                padding: '16px',
                                margin: 0,
                                fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
                                fontSize: '14px',
                                lineHeight: '1.5'
                            }}>
                                {JSON.stringify(status, null, 2)}
                            </pre>
                        </AccordionDetails>
                    </Accordion>
                </>
            )}

            {/* Snackbar */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="success"
                    sx={{ width: '100%' }}
                >
                    JSON copiato negli appunti!
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default WialonStatus;