import React from 'react';
import Chip from '@mui/material/Chip';

// Mappa delle stringhe ai nomi corrispondenti
const gncdtanMap = {
    '1135': 'Cliente',
    '1136': 'Fornitore',
    '1148': 'Dipendente',
    '1137': 'Cliente Fornitore',
};

const DescriptionChip = ({ gncdtan }) => {
    // Trova il nome corrispondente alla stringa
    const name = gncdtanMap[gncdtan] || 'Non definito';

    return (
        <Chip
            label={name}
            style={{
                backgroundColor: 'lightgray',
                border: '1px solid green',
                color: 'black'
            }}
        />
    );
};

export default DescriptionChip;
