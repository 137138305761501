
// EditNotesDialog.js
import * as React from 'react';
import { useUpdate, useNotify, useRefresh } from 'react-admin';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    TextField
} from '@mui/material';
import { Note as NoteIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';

export const EditNotesDialog = ({ open, onClose, record }) => {
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({
        bk0_ntmemo: record?.bk0_ntmemo || '',
        bk0_ntcliente: record?.bk0_ntcliente || '',
        bk0_ntautista: record?.bk0_ntautista || '',
        bk0_ntresp: record?.bk0_ntresp || '',
    });
    const [update] = useUpdate();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleChange = (field) => (event) => {
        setValues(prev => ({ ...prev, [field]: event.target.value }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await update(
                'booking-0',
                {
                    id: record.id,
                    data: values,
                }
            );
            notify('Note aggiornate', { type: 'success' });
            refresh();
            onClose();
        } catch (error) {
            notify('Errore durante l\'aggiornamento', { type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <NoteIcon />
                Modifica Note
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Note Booking"
                            value={values.bk0_ntmemo}
                            onChange={handleChange('bk0_ntmemo')}
                            fullWidth
                            multiline
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Note Cliente"
                            value={values.bk0_ntcliente}
                            onChange={handleChange('bk0_ntcliente')}
                            fullWidth
                            multiline
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Note Autista"
                            value={values.bk0_ntautista}
                            onChange={handleChange('bk0_ntautista')}
                            fullWidth
                            multiline
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Note Responsabile"
                            value={values.bk0_ntresp}
                            onChange={handleChange('bk0_ntresp')}
                            fullWidth
                            multiline
                            rows={4}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annulla</Button>
                <LoadingButton
                    loading={loading}
                    onClick={handleSubmit}
                    variant="contained"
                >
                    Salva
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};