import React from 'react';
import { makeStyles } from '@mui/styles';
import CustomResourceBreadcrumbItems from './CustomResourceBreadcrumbItems'; // Verifica il percorso

const useStyles = makeStyles({
    breadcrumbContainer: {
        backgroundColor: '#e0f7fa',
        padding: '8px 16px',
        borderRadius: '4px',
    },
    // Puoi aggiungere altri stili se necessario
});

export const MyBreadcrumb = () => {
    const classes = useStyles();

    return (
        <div className={classes.breadcrumbContainer}>
            <CustomResourceBreadcrumbItems />
            {/* Aggiungi altri elementi se necessario */}
        </div>
    );
};
