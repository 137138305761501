import React, { useState } from 'react';
import { useUpdate, useNotify } from 'react-admin';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { cancelButtonCss, saveButtonCss } from '../shared/Styles/buttonStyles';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import GetTipoContatto from '../CustomAnagenContatti/GetTipoContatto';

/* TODO: crea un componente a parte - start */

const CustomTextFieldContatto = ({ id, label, value, onChange }) => {
    return (
        <TextField
            margin="dense"
            id={id}
            label={label}
            type="text"
            fullWidth
            variant="outlined"
            value={value}
            onChange={onChange}
        />
    );
};

/* stop */

function CustomEditContatto({ doc, label, refresh }) {
    // const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const [editText, setEditText] = useState(doc.note || '');
    const [nominativoText, setNominativoText] = useState(doc.nominativo || '');
    const [mailText, setMailText] = useState(doc.mail || '');
    const [pecText, setPecText] = useState(doc.pec || '');
    const [gncdsetText, setGncdsetText] = useState(doc.gncdset || '');
    const [telefono1Text, setTelefono1Text] = useState(doc.telefono1 || '');
    const [telefono2Text, setTelefono2Text] = useState(doc.telefono2 || '');
    const [telefono3Text, setTelefono3Text] = useState(doc.telefono3 || '');
    const [indirizzoText, setIndirizzoText] = useState(doc.indirizzo || '');
    const [comuneText, setComuneText] = useState(doc.comune || '');
    const [capText, setCapText] = useState(doc.cap || '');
    const [provinciaText, setProvinciaText] = useState(doc.prov || '');
    const [frazioneText, setFrazioneText] = useState(doc.frazione || '');


    const [update, { isLoading, error }] = useUpdate();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    /*     const handleSave = () => {
            console.log('Testo modificato:', editText);
            // Qui dovresti inserire la logica per salvare effettivamente la nota modificata
            setOpen(false);
        }; */
    const handleTipoContattoChange = (tipoContattoId) => {
        setGncdsetText(tipoContattoId);
    };

    const handleSave = () => {
        const data = {
            gncdset: gncdsetText,
            note: editText,
            nominativo: nominativoText,
            mail: mailText,
            pec: pecText,
            telefono1: telefono1Text,
            telefono2: telefono2Text,
            telefono3: telefono3Text,
            indirizzo: indirizzoText,
            comune: comuneText,
            cap: capText,
            provincia: provinciaText,
            frazione: frazioneText,
        };


        update(
            'anagen-contatti',
            { id: doc.id, data: data, previousData: doc },
            {
                onSuccess: ({ data }) => {
                    // refresh();
                    notify('Contatto aggiornato con successo');
                    //setEditText(''); // Reset della note se necessario
                },
                onFailure: (error) => {
                    notify(error.message, 'warning');
                }
            }
        );
        setOpen(false);
    }
    if (error) { return <p>ERROR</p>; }

    return (
        <Box >
            <Button sx={saveButtonCss} variant="outlined" onClick={handleClickOpen}>
                Modifica{/*  {label} */}
            </Button>
            <Dialog /* fullScreen={fullScreen} */ maxWidth={"md"} open={open} onClose={handleClose}>
                <DialogTitle>Modifica {label}</DialogTitle>
                <GetTipoContatto onTipoContattoChange={handleTipoContattoChange} defaultValue={gncdsetText}></GetTipoContatto>
                <DialogContent sx={{
                    width: 500, // Imposta la larghezza desiderata qui
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center', // Centra i contenuti se desiderato
                }}>
                    <CustomTextFieldContatto
                        id="nominativo"
                        label="Nominativo"
                        value={nominativoText}
                        onChange={(e) => setNominativoText(e.target.value)}
                    />

                    <CustomTextFieldContatto
                        id="mail"
                        label="Mail"
                        value={mailText}
                        onChange={(e) => setMailText(e.target.value)}
                    />

                    <CustomTextFieldContatto
                        id="pec"
                        label="PEC"
                        value={pecText}
                        onChange={(e) => setPecText(e.target.value)}
                    />

                    <CustomTextFieldContatto
                        id="telefono1"
                        label="Telefono 1"
                        value={telefono1Text}
                        onChange={(e) => setTelefono1Text(e.target.value)}
                    />

                    <CustomTextFieldContatto
                        id="telefono2"
                        label="Telefono 2"
                        value={telefono2Text}
                        onChange={(e) => setTelefono2Text(e.target.value)}
                    />

                    <CustomTextFieldContatto
                        id="telefono3"
                        label="Telefono 3"
                        value={telefono3Text}
                        onChange={(e) => setTelefono3Text(e.target.value)}
                    />

                    <CustomTextFieldContatto
                        id="indirizzo"
                        label="Indirizzo"
                        value={indirizzoText}
                        onChange={(e) => setIndirizzoText(e.target.value)}
                    />

                    <CustomTextFieldContatto
                        id="comune"
                        label="Comune"
                        value={comuneText}
                        onChange={(e) => setComuneText(e.target.value)}
                    />

                    <CustomTextFieldContatto
                        id="cap"
                        label="CAP"
                        value={capText}
                        onChange={(e) => setCapText(e.target.value)}
                    />

                    <CustomTextFieldContatto
                        id="provincia"
                        label="Provincia"
                        value={provinciaText}
                        onChange={(e) => setProvinciaText(e.target.value)}
                    />

                    <CustomTextFieldContatto
                        id="frazione"
                        label="Frazione"
                        value={frazioneText}
                        onChange={(e) => setFrazioneText(e.target.value)}
                    />
                    <TextField

                        autoFocus
                        margin="dense"
                        id="name"
                        label="Nota Contatto"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        value={editText}
                        onChange={(e) => setEditText(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button sx={cancelButtonCss} onClick={handleClose}>Annulla</Button>
                    <Button sx={saveButtonCss} onClick={handleSave}>Salva</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default CustomEditContatto;
