import * as React from "react";
import {
    List,
    TextField,
    Edit,
    Create,
    TextInput,
    required,
    SimpleShowLayout,
    Show,
    localStorageStore,
    useRecordContext,
    EditButton,
    ShowButton,
    DatagridConfigurable,
    SelectColumnsButton,
    TopToolbar,
    ExportButton,
    FunctionField,
    SaveButton,
    Toolbar,
    BooleanInput,
    DateInput,
    WrapperField,
    TabbedForm,
    NumberInput,
    CreateButton,
    useSaveContext,
    RichTextField,
    useListContext,
    SavedQueriesList,
    FilterList,
    FilterListItem,
    useRefresh,
    useGetList,
    Loading
} from 'react-admin';
import { useFormState } from "react-hook-form";
import { /*  useEffect, */ useState, Fragment } from 'react';
import {
    useWizardFormContext,
    WizardForm,
    WizardFormStep,
} from '@react-admin/ra-form-layout';

import {
    numberFilter,
    textFilter,
    StackedFilters,
} from '@react-admin/ra-form-layout';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// import Timeline from '@mui/lab/Timeline';
// import TimelineItem from '@mui/lab/TimelineItem';
// import TimelineSeparator from '@mui/lab/TimelineSeparator';
// import TimelineConnector from '@mui/lab/TimelineConnector';
// import TimelineContent from '@mui/lab/TimelineContent';
// import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
/* import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent'; */
import { CardActionArea, CardActions, CardHeader } from '@mui/material';
import Avatar from '@mui/material/Avatar';
// import Stack from '@mui/material/Stack';
import AssignmentIcon from '@mui/icons-material/Assignment';
// import { green } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import PeopleIcon from '@mui/icons-material/People';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";
// import axiosInstance from "../../utils/axios"; // TODO: Necessario per l'upload

// Gestione lista note
import GestioneNote from "../note/GestioneNote";

// Gestione costi ufficiali
import GestioneCostiUfficiali from "./costiUfficiali/GestioneCostiUfficiali";

// Importo il css per i Bottoni
import { createButtonCss, editButtonCss, saveButtonCss, showButtonCss, exportButtonCss, columnsButtonCss, deleteButtonCss, filterButtonCss } from '../shared/Styles/buttonStyles';
import { listCss } from '../shared/Styles/listStyles';
import { formCss, formCssTextInput, formCssDropdownMenu } from "../shared/Styles/formCss";
import { ToBoolean } from '../shared/ToBoolean';

// Importo componenti input custom
import CustomAutocompleInput from '../shared/FormFields/CustomAutocompleInput';
import CustomTextInput from '../shared/FormFields/CustomTextInput';
import CustomRichTextInput from '../shared/FormFields/CustomRichTextInput';
import CustomDateInput from '../shared/FormFields/CustomDateInput';
import CustomNumberInput from '../shared/FormFields/CustomNumberInput';
import CustomTextFieldDataOra from "../shared/FormFields/CustomTextFieldDataOra";
import CustomTextFieldData from "../shared/FormFields/CustomTextFieldDataOra";
import AutomezziEditTotaliPosti from './customComponents/automezziEditTotaliPosti';

// import CustomFilters from "../shared/FormFields/CustomFilters";

// Filterlist dinamica
import FilterListDinamico from "../shared/CustomFields/FilterListDinamico";

// Campi custom 
import GetValoreCampo from '../shared/FormFields/GetValoreCampo';

// Importa validators
// import { validateNome, validateCognome } from '../../utils/validators/formValidators';

// Necessari per il form
import { /* Container, Row, Col, Form, */ ProgressBar /* , Button */ } from "react-bootstrap";
import MySimpleMap from "../shared/OpenStreet/MySimpleMap";

// Init store
const storeGenerale = localStorageStore();

/* CSS - BASE - FORM - START */
const styles = {

    TextInput: {
        // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        // background: 'linear-gradient(45deg, #01b5d0 10%, #ffffff 90%)',
        border: 0,
        borderRadius: 1,
        // boxShadow: '0px 0px 3px 2px #01b5d0',
        color: 'green',
        height: 50,
        padding: '0 0px',
    },
};


const useStyles = makeStyles(styles);
/* CSS - BASE - FORM - END */

const listStackFiltersAutomezzi = {
    'id': numberFilter({ operators: ["eq", "neq", "gt", "lt"] }),
};

const ListActions = () => (
    <Box width="100%">
        <TopToolbar>
            {/* {<FilterButton label="Filtri" sx={filterButtonCss} filters={AutomezziFilters} />} */}
            <CreateButton label="Crea" sx={exportButtonCss} />
            <ExportButton label="Esporta" sx={exportButtonCss} />
            <SelectColumnsButton sx={columnsButtonCss} />
            {/* <StackedFilters sx={filterButtonCss} config={listStackFiltersAutomezzi} /> */}
        </TopToolbar>
    </Box>
);

const TitoloRicerca = ({ source, label }) => {
    const record = useRecordContext();
    return record ? (
        <>
            <FunctionField label="Titolo Ricerca" render={record => {
                return (
                    <><strong> {record ? (record.gnfind + " ") : ''}</strong></>
                );

            }} />
        </>
    ) : null;
}

/* TEST */
const FilterHeader = ({ source }) => {
    const { setFilters, displayedFilters, filterValues } = useListContext();

    return (
        <TextInput
            label={`Filter by ${source}`}
            source={source}
            alwaysOn
            onChange={(event) => setFilters({ ...filterValues, [source]: event.target.value }, displayedFilters)}
        />
    );
};

/* BLOCCO FILTRI LATERALI */
const AutomezziFilterSidebar = () => (
    <Card sx={{ order: -1, mr: 2, mt: 9, width: 300 }}>
        <CardContent>
            <SavedQueriesList label="Salva Filtro" icon={<AssignmentIcon />} />
            <FilterList label="Status" icon={<AssignmentIcon />}>
                <FilterListItem label="Attivi" value={{ flAttivo: 1 }} />
                <FilterListItem label="Visibili" value={{ fiVisibile: 1 }} />
                <FilterListItem label="Annullati" value={{ flAnnullato: 1 }} />
            </FilterList>
            <FilterListDinamico service="view-tabellaalfa-aer" label="Aereazione" source="amtaereazione" />
            <FilterListDinamico service="view-tabellaalfa-mar" label="Marca" source="amtmarca_d1" />

        </CardContent>
    </Card>
);

export const AutomezziList = props => {

    return (
        <List title={<>
            <Button>
                <PeopleIcon></PeopleIcon>
                &nbsp;Anagrafiche Automezzi
            </Button>
        </>} sx={listCss} perPage={50} actions={<ListActions />} filters={AutomezziFilters} aside={<AutomezziFilterSidebar />}>
            {/* <Datagrid> */}
            <DatagridConfigurable
                sx={listCss}
                // rowClick="edit"
                omit={['note', 'gntypepc', 'gncodipc']}
                //  noDelete
                /*  editForm={<AutomezziListForm />} */
                bulkActionButtons={false}
            // expand={<AutomezziExpand />}

            >

                {/* {<TextField label="id" source="id" />} */}
                {<TextField label="amid" source="amid" />}
                {/* {<TextField label="Tipo" source="gntypepc" />} */}
                {/* {<TextField label="Cod." source="gncodipc" />} */}
                {/* <TitoloRicerca label="Targa"></TitoloRicerca> */}
                {<TextField label="Targa" source="amtarga" header={<FilterHeader source="amtarga" />} />}

                {/* <ReferenceField source="amtgruppo" reference="tabellealfa" >
                    <TextField source="tbdes" />
                </ReferenceField> */}

                {/*                 <ReferenceField link={false} label="Gruppo" source="amtgruppo" reference="tabellealfa">
                    <FunctionField render={record => `${record.tbdes}`} />
                </ReferenceField> */}
                {<TextField label="Posti a sedere" source="ampaxnrdw_s1" />}
                {<TextField label="Posti assistente" source="ampaxnrdw_ass" />}
                {<TextField label="Posti in piedi" source="ampaxnrup_s2" />}
                {<TextField label="Posti Totali" source="ampaxnrtot" />}
                {<TextField label="Posti a sedere totali" source="ampaxnrdw_tot" />}

                {<GetValoreCampo label="Gruppo" source="amtgruppo" reference="tabellealfa"></GetValoreCampo>}
                {<GetValoreCampo label="Marca" source="amtmarca_d1" reference="tabellealfa"></GetValoreCampo>}
                <WrapperField label="Organizzazione">{<GetValoreCampo label="Organizzazione" source="amtintest_c2" reference="tabellealfa"></GetValoreCampo>}</WrapperField>
                <WrapperField label="Categoria">{<GetValoreCampo label="Categoria" source="amtcategoria_j" reference="tabellealfa"></GetValoreCampo>}</WrapperField>
                {/*<WrapperField label="Destinazione">{<GetValoreCampo label="Destinazione" source="amtdestinazione_j1" reference="tabellealfa"></GetValoreCampo>}</WrapperField>
                 <WrapperField label="Cambio">{<GetValoreCampo label="Cambio" source="amtcambio" reference="tabellealfa"></GetValoreCampo>}</WrapperField>
                <WrapperField label="Combustibile">{<GetValoreCampo label="Combustibile" source="amtcombustibile_p3" reference="tabellealfa"></GetValoreCampo>}</WrapperField>
                <WrapperField label="Aereazione">{<GetValoreCampo label="Aereazione" source="amtaereazione" reference="tabellealfa"></GetValoreCampo>}</WrapperField>
 */}


                {<ToBoolean label="Attivo" source="flAttivo"></ToBoolean>}
                {<ToBoolean label="Visibile" source="fiVisibile"></ToBoolean>}
                {<ToBoolean label="Annullato" source="flAnnullato"></ToBoolean>}

                <EditButton sx={editButtonCss} label="Modifica" />
                <ShowButton sx={showButtonCss} label="Dettaglio" />
                {/* <DeleteButton sx={deleteButtonCss} label="Cancella" /> */}
                <WrapperField label="Elimina">
                    <ConfirmDialogDeleteButtonCustom
                        titolo={"Cancella Automezzo"}
                        messaggio={"Sei sicuro di vole cancellare questo Automezzo ?"}
                        servizio={"automezzi"} />
                </WrapperField>

            </DatagridConfigurable>


        </List >
    );
}

const AutomezziListForm = (props) => (
    <RowForm {...props}>
        {< TextField label="Automezzi Id" source="id" validate={[required()]} />}
        {<TextField label="Tipo" source="gntypepc" validate={[required()]} />}
        {<TextField label="Cod." source="gncodipc" validate={[required()]} />}
        {<TextField label="Ricerca" source="gnfind" validate={[required()]} />}
        {<TextField label="Rag.Soc." source="gnragnom" validate={[required()]} />}
        {<TextField label="Rag.Soc2" source="gnrag2" />}
        {<TextField label="Att" source="flAttivo" />}
        {<TextField label="Vis" source="fiVisibile" />}
        {<TextField label="Ann" source="flAnnullato" />}
    </RowForm>
);

const AutomezziFilters = [
    <CustomNumberInput source="amid" label="amid" alwaysOn ></CustomNumberInput>,
    <CustomTextInput source="amtarga" label="Targa" alwaysOn ></CustomTextInput>,
    <CustomNumberInput source="ampaxnrdw_s1" label="Posti a sedere" alwaysOn></CustomNumberInput>,
    <CustomNumberInput source="ampaxnrup_s2" label="Posti in piedi" alwaysOn></CustomNumberInput>,
    <CustomNumberInput source="ampaxnrdw_ass" label="Posti Assistente" alwaysOn></CustomNumberInput>,
    <CustomNumberInput source="ampaxnrtot" label="Totali" alwaysOn></CustomNumberInput>,
    <CustomNumberInput source="ampaxnrdw_tot" label="Totali a sedere" alwaysOn></CustomNumberInput>,
    <SavedQueriesList />
];


/* export const AutomezziEdit = (props) => (
    <Edit title={<AutomezziTitle />} name={<AutomezziName />} {...props}>
        <SimpleForm validate={validateOperatoreCreation}>
        <TextInput disabled source="id" />
        <TextInput source="cognome" />
        <TextInput source="nome" />
        <ReferenceInput source="gruppiAutomezziId" reference="gruppi-addettis">
                        <SelectInput optionText="descrizione"/>
        </ReferenceInput>
        <TextField disabled source="updatedAt" />
        <TextField disabled source="createdAt" />
        </SimpleForm>
    </Edit>
); */

/* TODO: da temrinare

Quando salvo ottengo
notNull Violation: automezzi.amid cannot be null

Ho quindi impostato come non required il campo lato mysql

ALTER TABLE backend_tuvoni.automezzi MODIFY COLUMN amid int NULL;

e su feathersj
            amid: {
                  type: DataTypes.INTEGER,
                  allowNull: true,
            },


*/

/* TODO: BUGGATO DA RIVEDERE */
const MyToolbarForStepper = () => {
    const { hasNextStep, hasPreviousStep, goToNextStep, goToPreviousStep } =
        useWizardFormContext();
    const { save } = useSaveContext();
    const { isValidating } = useFormState();

    // Stile custom per i bottoni
    const buttonStyle = {
        backgroundColor: '#1976d2', // Un esempio di blu
        color: 'white',
        margin: '10px'
    };

    return (
        <ul style={{ display: 'flex', justifyContent: 'space-between', listStyle: 'none', padding: 0 }}>
            {hasPreviousStep ? (
                <li>
                    <Button style={buttonStyle} onClick={() => goToPreviousStep()}>
                        PRECEDENTE
                    </Button>
                </li>
            ) : <li />} {/* Mantiene lo spazio se non c'è il bottone precedente */}
            {hasNextStep ? (
                <li>
                    <Button style={buttonStyle} disabled={isValidating} onClick={() => goToNextStep()}>
                        Prossimo step
                    </Button>
                </li>
            ) : (
                <li>
                    <Button style={buttonStyle} disabled={isValidating} onClick={save}>
                        SALVA
                    </Button>
                </li>
            )}
        </ul>
    );
};

export const AutomezziCreate = (props) => {

    const classes = useStyles();

    return (
        <Create redirect="show" title={<>
            <Button>
                <PeopleIcon sx={{ color: "yellow" }}></PeopleIcon>
                &nbsp;Aggiungi Automezzo
            </Button>
        </>}  {...props}>


            {/*           <SimpleForm sx={formCss} toolbar={<MyToolbarEdit />} >
                */}
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 2, width: '30ch' },
                }}
            >

                <WizardForm /* toolbar={<MyToolbarForStepper />} */ >
                    <WizardFormStep label="Anagrafica 1">

                        <Divider variant="middle" />
                        <CustomTextInput source="amid" label="Id Classificazione Automezzo"></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amtarga" label="Targa"></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomAutocompleInput source="amtmarca_d1" reference="view-tabellaalfa-mar" optionProperty="descrizione" label="Marca"></CustomAutocompleInput>
                        <Divider variant="middle" />


                        <div>
                            <Divider variant="middle" />
                            <CustomAutocompleInput source="amtdestinazione_j1" reference="view-tabellaalfa-dsu" optionProperty="descrizione" label="Destinazione"></CustomAutocompleInput>
                            <Divider variant="middle" />
                        </div>
                        <div>
                            <Divider variant="middle" />
                            <CustomAutocompleInput source="amtmarca_d1" reference="view-tabellaalfa-mar" optionProperty="descrizione" label="Carrozzeria***"></CustomAutocompleInput>
                            <Divider variant="middle" />

                        </div>
                        <div>
                            <Divider variant="middle" />
                            <CustomAutocompleInput source="amtgruppo" reference="view-tabellaalfa-grp" optionProperty="descrizione" label="Gruppo"></CustomAutocompleInput>
                            <Divider variant="middle" />
                        </div>


                        {/*  */}

                        <Divider variant="middle" />
                        <CustomDateInput source="amimmdata_b" label="Data prima immatricolazione B"></CustomDateInput>
                        <Divider variant="middle" />


                        <Divider variant="middle" />
                        <CustomTextInput source="amimmnro" label="Numero di immatricolazione"></CustomTextInput>
                        <Divider variant="middle" />


                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Data prima immatricolazione B: </Typography>
                                {<DateInput color="success" className={classes.TextInput} variant="outlined" label="Data prima immatricolazione I: " source="amimmdata_i" placeholder="" />}
                            </Box>
                        </div>

                        <Divider variant="middle" />
                        <CustomAutocompleInput source="amtintest_c2" reference="view-tabellaalfa-int" optionProperty="descrizione" label="Organizzazione"></CustomAutocompleInput>
                        <Divider variant="middle" />

                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Numero telaio: </Typography>
                                {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Numero telaio" source="amtelaio_e" placeholder="" />}
                            </Box>
                        </div>

                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Numero motore: </Typography>
                                {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Numero motore" source="amnrmotore_p5" placeholder="" />}
                            </Box>
                        </div>


                        <Divider variant="middle" />
                        <CustomTextInput source="amnromologa_k" label="Numero omologazione"></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomAutocompleInput source="amtcategoria_j" reference="view-tabellaalfa-cat" optionProperty="descrizione" label="Categoria"></CustomAutocompleInput>
                        <Divider variant="middle" />
                        <CustomAutocompleInput source="amtcambio" reference="view-tabellaalfa-cmb" optionProperty="descrizione" label="Cambio"></CustomAutocompleInput>
                        <Divider variant="middle" />
                        <CustomAutocompleInput source="amtcombustibile_p3" reference="view-tabellaalfa-gas" optionProperty="descrizione" label="Alimentazione"></CustomAutocompleInput>
                        <Divider variant="middle" />
                        <CustomAutocompleInput source="amtaereazione" reference="view-tabellaalfa-aer" optionProperty="descrizione" label="Aerazione"></CustomAutocompleInput>
                        <Divider variant="middle" />

                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Cilindrata CM3: </Typography>
                                {<NumberInput color="success" className={classes.TextInput} variant="outlined" label="Cilindrata CM3" source="amcilindrata_p1" placeholder="" />}
                            </Box>
                        </div>

                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Potenza Kw: </Typography>
                                {<NumberInput color="success" className={classes.TextInput} variant="outlined" label="Potenza Kw" source="amkw_p2" placeholder="" />}
                            </Box>
                        </div>

                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Numero assi: </Typography>
                                {<NumberInput color="success" className={classes.TextInput} variant="outlined" label="Numero assi" source="amnrassi_l" placeholder="" />}
                            </Box>
                        </div>

                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Massa Max a carico Kg: </Typography>
                                {<NumberInput color="success" className={classes.TextInput} variant="outlined" label="Massa Max a carico Kg" source="ammassamax_f2" placeholder="" />}
                            </Box>
                        </div>

                    </WizardFormStep>
                    <WizardFormStep label="Anagrafica 2">

                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Posti a sedere: </Typography>
                                {<NumberInput color="success" className={classes.TextInput} variant="outlined" label="Posti a sedere" source="ampaxnrdw_s1" placeholder="" />}
                            </Box>
                        </div>

                        <Divider variant="middle" />
                        <CustomNumberInput source="ampaxnrdp_s1" label="Posti in piedi"></CustomNumberInput>
                        <Divider variant="middle" />

                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Portata Pax seduti Kg: </Typography>
                                {<NumberInput color="success" className={classes.TextInput} variant="outlined" label="Portata Pax seduti Kg" source="ampaxkgdw" placeholder="" />}
                            </Box>
                        </div>
                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Portata Pax in piedi Kg: </Typography>
                                {<NumberInput color="success" className={classes.TextInput} variant="outlined" label="Portata Pax in piedi Kg" source="ampaxkgup" placeholder="" />}
                            </Box>
                        </div>

                        <Divider variant="middle" />
                        <CustomTextInput source="amtyresant" label="Anteriori"></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amtyrespos" label="Posteriori"></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amtyresalt" label="Alternativi"></CustomTextInput>
                        <Divider variant="middle" />

                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Lunghezza mm: </Typography>
                                {<NumberInput color="success" className={classes.TextInput} variant="outlined" label="Lunghezza mm" source="amlung" placeholder="" />}
                            </Box>
                        </div>
                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Largezza mm: </Typography>
                                {<NumberInput color="success" className={classes.TextInput} variant="outlined" label="Largezza mm" source="amlarg" placeholder="" />}
                            </Box>
                        </div>
                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Altezza mm: </Typography>
                                {<NumberInput color="success" className={classes.TextInput} variant="outlined" label="Altezza mm" source="amalte" placeholder="" />}
                            </Box>
                        </div>

                        {/*  */}

                        <Divider variant="middle" />
                        <CustomTextInput source="amuscite" label="Uscita emergenza"></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amtarga1" label="Targa 1"></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amtarga2" label="Targa 2"></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amtarga3" label="Targa 3"></CustomTextInput>
                        <Divider variant="middle" />

                        {/*  */}


                    </WizardFormStep>
                    <WizardFormStep label="Anagrafica 3">

                        {/* ESTREMI ACQUISTO */}
                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Data acquisto: </Typography>
                                {<DateInput color="success" className={classes.TextInput} variant="outlined" label="Data acquisto: " source="amacqdata" placeholder="" />}
                            </Box>
                        </div>

                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Prezzo acquisto: </Typography>
                                {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Prezzo acquisto" source="amacqprezzo" placeholder="" />}
                            </Box>
                        </div>

                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Tipo finanziamento: </Typography>
                                {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Tipo finanziamento" source="amacqfinan" placeholder="" />}
                            </Box>
                        </div>

                        {/*  */}

                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Data autorizzazione: </Typography>
                                {<DateInput color="success" className={classes.TextInput} variant="outlined" label="Data autorizzazione" source="amautimmdata" placeholder="" />}
                            </Box>
                        </div>

                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Numero autorizzazione: </Typography>
                                {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Numero autorizzazione" source="amautimmnro" placeholder="" />}
                            </Box>
                        </div>


                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Tipo autorizzazione: </Typography>
                                {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Tipo autorizzazione" source="amautimmtipo" placeholder="" />}
                            </Box>
                        </div>

                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Data cessione: </Typography>
                                {<DateInput color="success" className={classes.TextInput} variant="outlined" label="Data cessione" source="amcessione" placeholder="" />}
                            </Box>
                        </div>

                        {/*  */}
                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Data bollo: </Typography>
                                {<DateInput color="success" className={classes.TextInput} variant="outlined" label="Data bollo" source="amdtbollo" placeholder="" />}
                            </Box>
                        </div>

                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Data assicurazione: </Typography>
                                {<DateInput color="success" className={classes.TextInput} variant="outlined" label="Data assicurazione" source="amdtassic" placeholder="" />}
                            </Box>
                        </div>

                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Data Tagliando: </Typography>
                                {<DateInput color="success" className={classes.TextInput} variant="outlined" label="Data cessione" source="amdttagl" placeholder="" />}
                            </Box>
                        </div>

                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Km tagliando: </Typography>
                                {<NumberInput color="success" className={classes.TextInput} variant="outlined" label="Km tagliando" source="amkmtagl" placeholder="" />}
                            </Box>
                        </div>

                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Data revisione: </Typography>
                                {<DateInput color="success" className={classes.TextInput} variant="outlined" label="Data revisione" source="amdtrevi" placeholder="" />}
                            </Box>
                        </div>

                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Data tachigrafo: </Typography>
                                {<DateInput color="success" className={classes.TextInput} variant="outlined" label="Data tachigrafo" source="amdtaki" placeholder="" />}
                            </Box>
                        </div>

                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Data estintore: </Typography>
                                {<DateInput color="success" className={classes.TextInput} variant="outlined" label="Data estintore" source="amdtesti" placeholder="" />}
                            </Box>
                        </div>
                    </WizardFormStep>
                    <WizardFormStep label="Anagrafica 4">
                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Fuori servizio: </Typography>
                                {<BooleanInput color="success" className={classes.TextInput} variant="outlined" label="" source="amfservizio" placeholder="" />}
                            </Box>
                        </div>

                        <div>
                            <Divider variant="middle" />
                            <CustomRichTextInput source="amnote" label="Annotazioni"></CustomRichTextInput>
                            <Divider variant="middle" />
                        </div>

                    </WizardFormStep>
                </WizardForm>

            </Box>
            {/* {<SelectInput label="Status" source="status" validate={[required()]} choices={[{ id: 'attivo', name: 'attivo' }, { id: 'disattivo', name: 'disattivo' }]} />} */}
            {/* </SimpleForm> */}
        </Create>
    );
}

const ShowActions = () => (
    <TopToolbar>
        <EditButton sx={editButtonCss} label="Modifica" />
    </TopToolbar>
);

const ShowTopToolbarDettaglio = () => (
    <TopToolbar>
        <ShowButton sx={showButtonCss} label="Dettaglio" />
    </TopToolbar>
);

const ShowActionsForEdit = () => (
    <ShowTopToolbarDettaglio></ShowTopToolbarDettaglio>
);

export const AutomezziShow = (props) => {

    // Coordinate Base di Budoni
    // Latitudine: 40.7095, Longitude: 9.69923
    const latitude = 40.7095;
    const longitude = 9.69923;

    const classes = useStyles();
    const refresh = useRefresh();

    return (
        <Show title={<>
            <Button>
                <PeopleIcon></PeopleIcon>
                &nbsp;Dettaglio Automezzo
            </Button>
        </>} actions={<ShowActions />}>
            <Card sx={{ minWidth: 275 }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: '#01b5d0' }}>
                            <AssignmentIcon />
                        </Avatar>
                    }
                    title={<><strong>ID Automezzo :</strong>< TextField label="Automezzi Id" source="id" /></>}
                    subheader={
                        <>
                            <strong>Testo per ricerca :</strong> {<TextField label="Targa" source="amtarga" />}
                            <br />
                        </>}
                />
            </Card>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 2, width: '30ch' },
                }}
            >
                <TabbedForm sx={formCss} toolbar={null}>
                    <TabbedForm.Tab label="Anagrafica 1">
                        <Divider variant="middle" />
                        <CustomTextInput source="amid" label="Id Classificazione Automezzo" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="id" label="Automezzo Id su DB" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amtarga" label="Targa" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomAutocompleInput source="amtmarca_d1" reference="view-tabellaalfa-mar" optionProperty="descrizione" label="Marca" readOnly={true} ></CustomAutocompleInput>
                        <Divider variant="middle" />


                        <div>
                            <Divider variant="middle" />
                            <CustomAutocompleInput source="amtdestinazione_j1" reference="view-tabellaalfa-dsu" optionProperty="descrizione" label="Destinazione" readOnly={true} ></CustomAutocompleInput>
                            <Divider variant="middle" />
                        </div>
                        <div>
                            <Divider variant="middle" />
                            <CustomAutocompleInput source="amtmarca_d1" reference="view-tabellaalfa-mar" optionProperty="descrizione" label="Carrozzeria***" readOnly={true} ></CustomAutocompleInput>
                            <Divider variant="middle" />

                        </div>
                        <div>
                            <Divider variant="middle" />
                            <CustomAutocompleInput source="amtgruppo" reference="view-tabellaalfa-grp" optionProperty="descrizione" label="Gruppo" readOnly={true} ></CustomAutocompleInput>
                            <Divider variant="middle" />
                        </div>


                        {/*  */}

                        <Divider variant="middle" />
                        {<CustomTextFieldData label="Data prima immatricolazione B" source="amimmdata_b" />}
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amimmnro" label="Numero di immatricolazione" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        {<CustomTextFieldData label="Data prima immatricolazione I" source="amimmdata_i" />}
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomAutocompleInput source="amtintest_c2" reference="view-tabellaalfa-int" optionProperty="descrizione" label="Organizzazione" readOnly={true} ></CustomAutocompleInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amtelaio_e" label="Numero telaio" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amnrmotore_p5" label="Numero motore" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amnromologa_k" label="Numero omologazione" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomAutocompleInput source="amtcategoria_j" reference="view-tabellaalfa-cat" optionProperty="descrizione" label="Categoria" readOnly={true} ></CustomAutocompleInput>
                        <Divider variant="middle" />
                        <CustomAutocompleInput source="amtcambio" reference="view-tabellaalfa-cmb" optionProperty="descrizione" label="Cambio" readOnly={true} ></CustomAutocompleInput>
                        <Divider variant="middle" />
                        <CustomAutocompleInput source="amtcombustibile_p3" reference="view-tabellaalfa-gas" optionProperty="descrizione" label="Alimentazione" readOnly={true} ></CustomAutocompleInput>
                        <Divider variant="middle" />
                        <CustomAutocompleInput source="amtaereazione" reference="view-tabellaalfa-aer" optionProperty="descrizione" label="Aerazione" readOnly={true} ></CustomAutocompleInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amcilindrata_p1" label="Cilindrata CM3" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amkw_p2" label="Potenza Kw" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amnrassi_l" label="Numero assi" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="ammassamax_f2" label="Massa Max a carico Kg" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="Anagrafica 2">

                        <Divider variant="middle" />
                        <CustomTextInput source="ampaxnrdw_s1" label="Posti a sedere" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="ampaxnrdw_ass" label="Posti assistente" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="ampaxnrdw_tot" label="Posti a sedere totali" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="ampaxnrup_s2" label="Posti in piedi" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="ampaxnrtot" label="Posti Totali" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="ampaxkgdw" label="Portata Pax seduti Kg" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="ampaxkgup" label="Portata Pax in piedi Kg" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amtyresant" label="Anteriori" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amtyrespos" label="Posteriori" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amtyresalt" label="Alternativi" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amlung" label="Lunghezza mm" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amlarg" label="Largezza mm" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amalte" label="Altezza mm" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        {/*  */}

                        <Divider variant="middle" />
                        <CustomTextInput source="amuscite" label="Uscita emergenza" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amtarga1" label="Targa 1" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amtarga2" label="Targa 2" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amtarga3" label="Targa 3" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        {/*  */}


                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="Anagrafica 3">

                        {/* ESTREMI ACQUISTO */}
                        <Divider variant="middle" />
                        {<CustomTextFieldData label="Data acquisto" source="amacqdata" />}
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amacqprezzo" label="Prezzo acquisto" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amacqfinan" label="Tipo finanziamento" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        {/*  */}

                        <Divider variant="middle" />
                        {<CustomTextFieldData label="Data autorizzazione" source="amautimmdata" />}
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amautimmnro" label="Numero autorizzazione" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        <CustomTextInput source="amautimmtipo" label="Tipo autorizzazione" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        {<CustomTextFieldData label="Data cessione" source="amcessione" />}
                        <Divider variant="middle" />

                        {/*  */}

                        <Divider variant="middle" />
                        {<CustomTextFieldData label="Data bollo" source="amdtbollo" />}
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        {<CustomTextFieldData label="Data assicurazione" source="amdtassic" />}
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        {<CustomTextFieldData label="Data Tagliando" source="amdttagl" />}
                        <Divider variant="middle" />

                        <div>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: 8 }}>Km tagliando: </Typography>
                                {<NumberInput color="success" className={classes.TextInput} variant="outlined" label="Km tagliando" source="amkmtagl" placeholder="" />}
                            </Box>
                        </div>

                        <Divider variant="middle" />
                        <CustomTextInput source="amkmtagl" label="Km tagliando" readOnly={true}></CustomTextInput>
                        <Divider variant="middle" />


                        <Divider variant="middle" />
                        {<CustomTextFieldData label="Data revisione" source="amdtrevi" />}
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        {<CustomTextFieldData label="Data tachigrafo" source="amdtaki" />}
                        <Divider variant="middle" />

                        <Divider variant="middle" />
                        {<CustomTextFieldData label="Data estintore" source="amdtesti" />}
                        <Divider variant="middle" />
                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="Anagrafica 4">

                        {<ToBoolean label="Fuori servizio" source="amfservizio" labelOn={true}></ToBoolean>}



                        {<RichTextField source="amnote" label="Annotazioni"></RichTextField>}

                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="Proprieta" path="proprieta">
                        <div>
                            <div>
                                {<CustomTextFieldDataOra label="Data creazione" source="createdAt" />}
                                <br></br>
                                {<CustomTextFieldDataOra label="Data ultima modifica" source="updatedAt" />}
                            </div>
                        </div>
                        <div>
                            {<ToBoolean label="Attivo" source="flAttivo" labelOn={true}></ToBoolean>}
                            {<ToBoolean label="Visibile" source="fiVisibile" labelOn={true}></ToBoolean>}
                            {<ToBoolean label="Annullato" source="flAnnullato" labelOn={true}></ToBoolean>}
                        </div>
                        <Divider variant="middle" />
                        <ConfirmDialogDeleteButtonCustom
                            titolo={"Cancella Autista"}
                            messaggio={"Sei sicuro di vole cancellare questo Autista ?"}
                            servizio={"autisti"} />
                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="Annotazioni" path="annotazioni">
                        <p><strong>Annotazioni</strong></p>
                        <GestioneNote tiporecordId="2" refresh={refresh} ></GestioneNote>
                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="Costi Ufficiali" path="costiufficiali">
                        <p><strong>Costi Ufficiali</strong></p>
                        <GestioneCostiUfficiali tiporecordId="2" refresh={refresh} ></GestioneCostiUfficiali>
                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="Geolocalizza" path="posizione">
                        <MySimpleMap latitude={latitude} longitude={longitude} />
                    </TabbedForm.Tab>
                </TabbedForm>
            </Box>
        </Show>
    );
}

const MyToolbarEdit = () => (
    <Toolbar>
        <SaveButton sx={saveButtonCss} /* sx={{
            border: 1,
            borderColor: 'black',
            borderRadius: '20px',
            backgroundColor: '#01b5d0'
        }} */ label="Salva parametri" alwaysEnable />
    </Toolbar>
);

const MyToolbarCreate = () => (
    <Toolbar>
        <SaveButton sx={createButtonCss} /* sx={{
            border: 1,
            borderColor: 'black',
            borderRadius: '20px',
            backgroundColor: '#01b5d0'
        }} */ label="Crea anagrafica" alwaysEnable />
    </Toolbar>
);


//16.01.2024 Marco [${choice.ordinamento}]
// const optionRenderer = choice => `${choice.descrizione}`;

export const AutomezziEdit = ({ id, ...props }) => {

    const classes = useStyles();

    return (
        <Edit
            redirect="show"
            actions={<ShowActionsForEdit />}
            title={<>
                <Button>
                    <PeopleIcon sx={{ color: "yellow" }}></PeopleIcon>
                    &nbsp;Modifica anagrafica automezzo
                </Button>
            </>}
            {...props}>


            <TabbedForm sx={formCss} toolbar={<MyToolbarEdit />}>
                <TabbedForm.Tab label="Anagrafica 1">
                    <Divider variant="middle" />
                    <CustomTextInput source="amid" label="Id Classificazione Automezzo"></CustomTextInput>
                    <Divider variant="middle" />

                    <Divider variant="middle" />
                    <CustomTextInput source="id" label="Automezzo Id su DB" readOnly={true}></CustomTextInput>
                    <Divider variant="middle" />

                    <Divider variant="middle" />
                    <CustomTextInput source="amtarga" label="Targa"></CustomTextInput>
                    <Divider variant="middle" />

                    <Divider variant="middle" />
                    <CustomAutocompleInput source="amtmarca_d1" reference="view-tabellaalfa-mar" optionProperty="descrizione" label="Marca"></CustomAutocompleInput>
                    <Divider variant="middle" />


                    <div>
                        <Divider variant="middle" />
                        <CustomAutocompleInput source="amtdestinazione_j1" reference="view-tabellaalfa-dsu" optionProperty="descrizione" label="Destinazione"></CustomAutocompleInput>
                        <Divider variant="middle" />
                    </div>
                    <div>
                        <Divider variant="middle" />
                        <CustomAutocompleInput source="amtmarca_d1" reference="view-tabellaalfa-mar" optionProperty="descrizione" label="Carrozzeria***"></CustomAutocompleInput>
                        <Divider variant="middle" />

                    </div>
                    <div>
                        <Divider variant="middle" />
                        <CustomAutocompleInput source="amtgruppo" reference="view-tabellaalfa-grp" optionProperty="descrizione" label="Gruppo"></CustomAutocompleInput>
                        <Divider variant="middle" />
                    </div>


                    {/*  */}

                    <Divider variant="middle" />
                    <CustomDateInput source="amimmdata_b" label="Data prima immatricolazione B"></CustomDateInput>
                    <Divider variant="middle" />


                    <Divider variant="middle" />
                    <CustomTextInput source="amimmnro" label="Numero di immatricolazione"></CustomTextInput>
                    <Divider variant="middle" />


                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Data prima immatricolazione B: </Typography>
                            {<DateInput color="success" className={classes.TextInput} variant="outlined" label="Data prima immatricolazione I: " source="amimmdata_i" placeholder="" />}
                        </Box>
                    </div>

                    <Divider variant="middle" />
                    <CustomAutocompleInput source="amtintest_c2" reference="view-tabellaalfa-int" optionProperty="descrizione" label="Organizzazione"></CustomAutocompleInput>
                    <Divider variant="middle" />

                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Numero telaio: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Numero telaio" source="amtelaio_e" placeholder="" />}
                        </Box>
                    </div>

                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Numero motore: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Numero motore" source="amnrmotore_p5" placeholder="" />}
                        </Box>
                    </div>


                    <Divider variant="middle" />
                    <CustomTextInput source="amnromologa_k" label="Numero omologazione"></CustomTextInput>
                    <Divider variant="middle" />

                    <Divider variant="middle" />
                    <CustomAutocompleInput source="amtcategoria_j" reference="view-tabellaalfa-cat" optionProperty="descrizione" label="Categoria"></CustomAutocompleInput>
                    <Divider variant="middle" />
                    <CustomAutocompleInput source="amtcambio" reference="view-tabellaalfa-cmb" optionProperty="descrizione" label="Cambio"></CustomAutocompleInput>
                    <Divider variant="middle" />
                    <CustomAutocompleInput source="amtcombustibile_p3" reference="view-tabellaalfa-gas" optionProperty="descrizione" label="Alimentazione"></CustomAutocompleInput>
                    <Divider variant="middle" />
                    <CustomAutocompleInput source="amtaereazione" reference="view-tabellaalfa-aer" optionProperty="descrizione" label="Aerazione"></CustomAutocompleInput>
                    <Divider variant="middle" />

                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Cilindrata CM3: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Cilindrata CM3" source="amcilindrata_p1" placeholder="" />}
                        </Box>
                    </div>

                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Potenza Kw: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Potenza Kw" source="amkw_p2" placeholder="" />}
                        </Box>
                    </div>

                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Numero assi: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Numero assi" source="amnrassi_l" placeholder="" />}
                        </Box>
                    </div>

                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Massa Max a carico Kg: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Massa Max a carico Kg" source="ammassamax_f2" placeholder="" />}
                        </Box>
                    </div>

                </TabbedForm.Tab>
                <TabbedForm.Tab label="Anagrafica 2">

                    {/* **************** */}
                    {/* TODO: DA TEMRINARE */}
                    {<AutomezziEditTotaliPosti></AutomezziEditTotaliPosti>}

                    <Divider variant="middle" />
                    <CustomTextInput source="ampaxnrdw_s1" label="Posti a sedere" readOnly={true}></CustomTextInput>
                    <Divider variant="middle" />

                    <Divider variant="middle" />
                    <CustomTextInput source="ampaxnrdw_ass" label="Posti assistente" readOnly={true}></CustomTextInput>
                    <Divider variant="middle" />

                    {/* <Divider variant="middle" />
                    <CustomTextInput source="ampaxnrdw_tot" label="Posti a sedere totali" readOnly={true}></CustomTextInput>
                    <Divider variant="middle" /> */}

                    <Divider variant="middle" />
                    <CustomTextInput source="ampaxnrup_s2" label="Posti in piedi" readOnly={true}></CustomTextInput>
                    <Divider variant="middle" />

                    {/* <Divider variant="middle" />
                    <CustomTextInput source="ampaxnrtot" label="Posti Totali" readOnly={true}></CustomTextInput>
                    <Divider variant="middle" /> */}

                    {/* **************** */}

                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Portata Pax seduti Kg: </Typography>
                            {<NumberInput color="success" className={classes.TextInput} variant="outlined" label="Portata Pax seduti Kg" source="ampaxkgdw" placeholder="" />}
                        </Box>
                    </div>
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Portata Pax in piedi Kg: </Typography>
                            {<NumberInput color="success" className={classes.TextInput} variant="outlined" label="Portata Pax in piedi Kg" source="ampaxkgup" placeholder="" />}
                        </Box>
                    </div>

                    <Divider variant="middle" />
                    <CustomTextInput source="amtyresant" label="Anteriori"></CustomTextInput>
                    <Divider variant="middle" />

                    <Divider variant="middle" />
                    <CustomTextInput source="amtyrespos" label="Posteriori"></CustomTextInput>
                    <Divider variant="middle" />

                    <Divider variant="middle" />
                    <CustomTextInput source="amtyresalt" label="Alternativi"></CustomTextInput>
                    <Divider variant="middle" />

                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Lunghezza mm: </Typography>
                            {<NumberInput color="success" className={classes.TextInput} variant="outlined" label="Lunghezza mm" source="amlung" placeholder="" />}
                        </Box>
                    </div>
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Largezza mm: </Typography>
                            {<NumberInput color="success" className={classes.TextInput} variant="outlined" label="Largezza mm" source="amlarg" placeholder="" />}
                        </Box>
                    </div>
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Altezza mm: </Typography>
                            {<NumberInput color="success" className={classes.TextInput} variant="outlined" label="Altezza mm" source="amalte" placeholder="" />}
                        </Box>
                    </div>

                    {/*  */}

                    <Divider variant="middle" />
                    <CustomTextInput source="amuscite" label="Uscita emergenza"></CustomTextInput>
                    <Divider variant="middle" />

                    <Divider variant="middle" />
                    <CustomTextInput source="amtarga1" label="Targa 1"></CustomTextInput>
                    <Divider variant="middle" />

                    <Divider variant="middle" />
                    <CustomTextInput source="amtarga2" label="Targa 2"></CustomTextInput>
                    <Divider variant="middle" />

                    <Divider variant="middle" />
                    <CustomTextInput source="amtarga3" label="Targa 3"></CustomTextInput>
                    <Divider variant="middle" />

                    {/*  */}


                </TabbedForm.Tab>
                <TabbedForm.Tab label="Anagrafica 3">

                    {/* ESTREMI ACQUISTO */}
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Data acquisto: </Typography>
                            {<DateInput color="success" className={classes.TextInput} variant="outlined" label="Data acquisto: " source="amacqdata" placeholder="" />}
                        </Box>
                    </div>

                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Prezzo acquisto: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Prezzo acquisto" source="amacqprezzo" placeholder="" />}
                        </Box>
                    </div>

                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Tipo finanziamento: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Tipo finanziamento" source="amacqfinan" placeholder="" />}
                        </Box>
                    </div>

                    {/*  */}

                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Data autorizzazione: </Typography>
                            {<DateInput color="success" className={classes.TextInput} variant="outlined" label="Data autorizzazione" source="amautimmdata" placeholder="" />}
                        </Box>
                    </div>

                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Numero autorizzazione: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Numero autorizzazione" source="amautimmnro" placeholder="" />}
                        </Box>
                    </div>


                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Tipo autorizzazione: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Tipo autorizzazione" source="amautimmtipo" placeholder="" />}
                        </Box>
                    </div>

                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Data cessione: </Typography>
                            {<DateInput color="success" className={classes.TextInput} variant="outlined" label="Data cessione" source="amcessione" placeholder="" />}
                        </Box>
                    </div>

                    {/*  */}
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Data bollo: </Typography>
                            {<DateInput color="success" className={classes.TextInput} variant="outlined" label="Data bollo" source="amdtbollo" placeholder="" />}
                        </Box>
                    </div>

                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Data assicurazione: </Typography>
                            {<DateInput color="success" className={classes.TextInput} variant="outlined" label="Data assicurazione" source="amdtassic" placeholder="" />}
                        </Box>
                    </div>

                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Data Tagliando: </Typography>
                            {<DateInput color="success" className={classes.TextInput} variant="outlined" label="Data cessione" source="amdttagl" placeholder="" />}
                        </Box>
                    </div>



                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Km tagliando: </Typography>
                            {<NumberInput color="success" className={classes.TextInput} variant="outlined" label="Km tagliando" source="amkmtagl" placeholder="" />}
                        </Box>
                    </div>

                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Data revisione: </Typography>
                            {<DateInput color="success" className={classes.TextInput} variant="outlined" label="Data revisione" source="amdtrevi" placeholder="" />}
                        </Box>
                    </div>

                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Data tachigrafo: </Typography>
                            {<DateInput color="success" className={classes.TextInput} variant="outlined" label="Data tachigrafo" source="amdtaki" placeholder="" />}
                        </Box>
                    </div>

                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Data estintore: </Typography>
                            {<DateInput color="success" className={classes.TextInput} variant="outlined" label="Data estintore" source="amdtesti" placeholder="" />}
                        </Box>
                    </div>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Anagrafica 4">
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Fuori servizio: </Typography>
                            {<BooleanInput color="success" className={classes.TextInput} variant="outlined" label="" source="amfservizio" placeholder="" />}
                        </Box>
                    </div>

                    <div>
                        <Divider variant="middle" />
                        <CustomRichTextInput source="amnote" label="Annotazioni"></CustomRichTextInput>
                        <Divider variant="middle" />
                    </div>

                </TabbedForm.Tab>
                <TabbedForm.Tab label="Proprietà">
                    <p><strong>Proprietà</strong></p>
                    {<BooleanInput color="success" className={classes.TextInput} variant="outlined" label="Attivo" source="flAttivo" />}
                    {<BooleanInput color="success" className={classes.TextInput} variant="outlined" label="Visibile" source="fiVisibile" />}
                    {<BooleanInput color="success" className={classes.TextInput} variant="outlined" label="Annullato" source="flAnnullato" />}
                </TabbedForm.Tab>
            </TabbedForm>

        </Edit>
    );
}

export const AutomezziExpand = ({ id, ...props }) => {

    return (
        <Show {...props} id={id} /* aside={<Aside />} */>

            <SimpleShowLayout>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={6}>
                        {/*                         <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                            Crea nuovo progetto
                        </Typography> */}

                        <List pagination="" actions={false}>

                            <ListItem>
                                <ListItemText
                                    primary="Parametri automezzo"
                                    secondary='Scheda automezzo'
                                />
                                {/*                                 <ReferenceManyField
                                    reference="progetti"
                                    target="automezziId"
                                    fullWidth
                                    perPage={10}
                                    sort={{ field: 'id', order: 'ASC' }}
                                >
                                    <EditableDatagrid
                                        bulkActionButtons={false}
                                        editForm={<ProgettiRowFormCard defaultValues={{ automezziId: id }} />}
                                        createForm={<ProgettiRowFormCard defaultValues={{ automezziId: id }} />}
                                    >
                                        <TextField source="id"></TextField>
                                        <TextField source="titolo"></TextField>
                                    </EditableDatagrid>

                                </ReferenceManyField> */}
                            </ListItem>

                        </List>
                    </Grid>
                </Grid>
            </SimpleShowLayout>

        </Show>
    );
}
