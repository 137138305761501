import React, { useState } from 'react';
import axios from 'axios';
import {
    TextField,
    Button,
    Typography,
    makeStyles
} from '@material-ui/core';

import config from '../../config/config.json';

const host = config.production.host;

const useStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '20px auto',
        width: 300,
    },
    textField: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    button: {
        color: "white",
        backgroundColor: "orange",
        width: '100%',
        marginTop: theme.spacing(2),
    },
    statusMessage: {
        marginTop: theme.spacing(2),
        textAlign: 'center',
    },
}));

const RequestResetPassword = () => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [requestStatus, setRequestStatus] = useState('');

    const handleRequestReset = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${host}/password-reset`, { email });
            setRequestStatus('Email di reset inviata. Controlla la tua casella di posta.');
        } catch (error) {
            console.error('Errore durante la richiesta di reset:', error);
            setRequestStatus(`Errore: ${error.response?.data?.message || error.message}`);
        }
    };

    return (
        <form className={classes.formContainer} onSubmit={handleRequestReset}>
            <TextField
                label="Email"
                type="email"
                variant="outlined"
                className={classes.textField}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
            >
                Richiedi Reset Password
            </Button>
            {requestStatus && (
                <Typography variant="body1" color={requestStatus.includes('inviata') ? 'primary' : 'error'} className={classes.statusMessage}>
                    {requestStatus}
                </Typography>
            )}
        </form>
    );
};

export default RequestResetPassword;