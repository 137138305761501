import React from 'react';
import {
    useRecordContext,
    useGetMany,
} from 'react-admin';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Chip,
    Tooltip,
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Box
} from '@mui/material';
import { Check, Clear, Error } from '@mui/icons-material';

const ServicesField = () => {
    const record = useRecordContext();

    // Estraiamo i servpaxId all'inizio, anche se il record potrebbe essere vuoto
    const servpaxIds = record?.data?.services
        ? [...new Set(record.data.services.map(service => service.servpaxId))]
        : [];

    // Hook chiamato sempre, indipendentemente dal contenuto del record
    const { data: servpaxData, isLoading, error } = useGetMany(
        'servpax',
        { ids: servpaxIds }
    );

    // Funzione helper per calcolare il totale del servizio
    const calculateTotalService = (service) => {
        return (service.costo * (service.moltiplicatore || 1)).toFixed(2);
    };

    // Funzione helper per ottenere i dettagli del servpax
    const getServpaxDetails = (servpaxId) => {
        return servpaxData?.find(sp => sp.id === servpaxId) || null;
    };

    // Early returns dopo gli hooks
    if (!record?.data?.services || !Array.isArray(record.data.services)) {
        return (
            <Typography color="textSecondary">
                Nessun servizio disponibile
            </Typography>
        );
    }

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" p={2}>
                <CircularProgress size={24} />
                <Typography sx={{ ml: 2 }}>
                    Caricamento dettagli servizi...
                </Typography>
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" p={2} color="error.main">
                <Error />
                <Typography sx={{ ml: 2 }}>
                    Errore nel caricamento dei dettagli servizi
                </Typography>
            </Box>
        );
    }

    return (
        <Card variant="outlined">
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Dettaglio Servizi ({record.data.services.length}) - pacchetti_servpax
                </Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Tipo Servizio</TableCell>
                            <TableCell>Costo Base</TableCell>
                            <TableCell>Moltiplicatore</TableCell>
                            <TableCell>Totale</TableCell>
                            <TableCell>Obbligatorio</TableCell>
                            <TableCell>Note</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {record.data.services.map((service, index) => {
                            const servpaxDetails = getServpaxDetails(service.servpaxId);
                            return (
                                <TableRow
                                    key={index}
                                    sx={{
                                        '&:nth-of-type(odd)': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                        },
                                        opacity: servpaxDetails?.flAttivo ? 1 : 0.7
                                    }}
                                >
                                    <TableCell>
                                        <Tooltip title={`Servizio ID: ${service.id}`}>
                                            <Chip
                                                label={service.id}
                                                size="small"
                                                color="primary"
                                                variant="outlined"
                                            />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title={servpaxDetails?.note || 'Nessuna nota'}>
                                            <Box>
                                                <Typography variant="body2">
                                                    {servpaxDetails?.descrizione || 'N/D'}
                                                </Typography>
                                                {servpaxDetails?.flVisibile === 0 && (
                                                    <Chip
                                                        label="Non Visibile"
                                                        size="small"
                                                        color="warning"
                                                        sx={{ ml: 1 }}
                                                    />
                                                )}
                                            </Box>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>€{service.costo.toFixed(2)}</TableCell>
                                    <TableCell>
                                        {service.moltiplicatore || 1}x
                                    </TableCell>
                                    <TableCell>
                                        <Typography color="primary">
                                            €{calculateTotalService(service)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {service.flObbligatorio ? (
                                            <Tooltip title="Obbligatorio">
                                                <Check color="success" />
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="Opzionale">
                                                <Clear color="action" />
                                            </Tooltip>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {servpaxDetails?.note && (
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    maxWidth: 200,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                {servpaxDetails.note}
                                            </Typography>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>

                <Typography
                    variant="subtitle1"
                    sx={{ mt: 2, textAlign: 'right' }}
                    color="primary"
                >
                    Totale Pacchetto: €{record.data.totalCost.toFixed(2)}
                    {record.data.pacchettoMoltiplicatore > 1 && (
                        <Tooltip title="Moltiplicatore pacchetto applicato">
                            <Chip
                                label={`x${record.data.pacchettoMoltiplicatore}`}
                                size="small"
                                color="secondary"
                                sx={{ ml: 1 }}
                            />
                        </Tooltip>
                    )}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default ServicesField;