import React, { useState, useEffect } from 'react';
import { Typography, TextField, Paper, List, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useGetOne } from 'react-admin';

const useStyles = makeStyles((theme) => ({
    summaryPaper: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(3),
    },
    summaryItem: {
        marginBottom: theme.spacing(2),
    },
    input: {
        width: '100px',
        marginLeft: theme.spacing(1),
    },
    costEstimate: {
        color: theme.palette.primary.main,
    },
    taxes: {
        color: theme.palette.error.main,
    },
    otherCharges: {
        color: theme.palette.warning.main,
    },
    totalPackage: {
        color: theme.palette.success.main,
        fontWeight: 'bold',
    },
    markup: {
        color: theme.palette.info.main,
    },
    finalPrice: {
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
    },
}));

const ServpaxDetails = ({ id }) => {
    const { data, loading, error } = useGetOne('servpax', { id });
    if (loading) return 'Caricamento...';
    if (error) return 'Errore nel caricamento del servizio';
    return data ? data.descrizione : 'Servizio non trovato';
};

const SummaryComponent = ({
    selectedPacchetto,
    selectedServices,
    pacchettoMoltiplicatore,
    dateFrom,
    dateTo,
    calculateTotalCost,
    startPoint,
    endPoint,
    distance,
    duration
}) => {
    const classes = useStyles();
    const { data: pacchettoData } = useGetOne('pacchetti', { id: selectedPacchetto });
    const [taxes, setTaxes] = useState(0);
    const [otherCharges, setOtherCharges] = useState(0);
    const [markupPercentage, setMarkupPercentage] = useState(0);

    const [totalPackageCost, setTotalPackageCost] = useState(0);
    const [markupAmount, setMarkupAmount] = useState(0);
    const [finalPrice, setFinalPrice] = useState(0);

    const servicesCost = calculateTotalCost();

    useEffect(() => {
        const packageCost = servicesCost + taxes + otherCharges;
        setTotalPackageCost(packageCost);

        const markup = (packageCost * markupPercentage) / 100;
        setMarkupAmount(markup);

        setFinalPrice(packageCost + markup);
    }, [servicesCost, taxes, otherCharges, markupPercentage]);

    return (
        <Paper elevation={3} className={classes.summaryPaper}>
            <Typography variant="h6" gutterBottom>Riepilogo finale</Typography>
            <Typography className={classes.summaryItem}>Data inizio: {dateFrom}</Typography>
            <Typography className={classes.summaryItem}>Data fine: {dateTo}</Typography>
            <Typography className={classes.summaryItem}>Pacchetto: {pacchettoData?.nome}</Typography>
            <Typography className={classes.summaryItem}>Moltiplicatore pacchetto: {pacchettoMoltiplicatore}</Typography>
            <Typography className={classes.summaryItem}>Punto di partenza: {startPoint || 'Non selezionato'}</Typography>
            <Typography className={classes.summaryItem}>Punto di arrivo: {endPoint || 'Non selezionato'}</Typography>
            {distance && duration && (
                <>
                    <Typography className={classes.summaryItem}>Distanza: {distance}</Typography>
                    <Typography className={classes.summaryItem}>Durata stimata: {duration}</Typography>
                </>
            )}

            <Typography variant="h6" gutterBottom>Servizi selezionati:</Typography>
            <List>
                {selectedServices.filter(service => service.isSelected || service.flObbligatorio).map((service) => (
                    <ListItem key={service.id}>
                        <ListItemText
                            primary={<ServpaxDetails id={service.servpaxId} />}
                            secondary={`Costo: ${service.costo} € x ${service.moltiplicatore} = ${(service.costo * service.moltiplicatore).toFixed(2)} €`}
                        />
                    </ListItem>
                ))}
            </List>

            <Typography variant="h6" className={`${classes.summaryItem} ${classes.costEstimate}`}>
                Costo dei servizi totale stimato: {servicesCost.toFixed(2)} €
            </Typography>
            <Typography variant="h6" className={`${classes.summaryItem} ${classes.taxes}`}>
                Tasse / Imposte:
                <TextField
                    type="number"
                    value={taxes}
                    onChange={(e) => setTaxes(Number(e.target.value))}
                    className={classes.input}
                    InputProps={{ endAdornment: '€' }}
                />
            </Typography>
            <Typography variant="h6" className={`${classes.summaryItem} ${classes.otherCharges}`}>
                Altri servizi / Oneri:
                <TextField
                    type="number"
                    value={otherCharges}
                    onChange={(e) => setOtherCharges(Number(e.target.value))}
                    className={classes.input}
                    InputProps={{ endAdornment: '€' }}
                />
            </Typography>
            <Typography variant="h6" className={`${classes.summaryItem} ${classes.totalPackage}`}>
                Costo totale del pacchetto: {totalPackageCost.toFixed(2)} €
            </Typography>
            <Typography variant="h6" className={`${classes.summaryItem} ${classes.markup}`}>
                Ricarico stabilito:
                <TextField
                    type="number"
                    value={markupPercentage}
                    onChange={(e) => setMarkupPercentage(Number(e.target.value))}
                    className={classes.input}
                    InputProps={{ endAdornment: '%' }}
                />
                ({markupAmount.toFixed(2)} €)
            </Typography>
            <Typography variant="h6" className={`${classes.summaryItem} ${classes.finalPrice}`}>
                Prezzo di vendita finale: {finalPrice.toFixed(2)} €
            </Typography>
        </Paper>
    );
};

export default SummaryComponent;
