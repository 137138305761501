import React from 'react';
import styled from 'styled-components';
import {
    Stepper as MuiStepper,
    Step as MuiStep,
    StepButton as MuiStepButton,
    StepLabel as MuiStepLabel,
} from '@material-ui/core';
import { StylesProvider } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

const StyledWrapper = styled.div`
    && {
        .MuiStepper-root {
            background-color: transparent;
            padding: 24px 0;
        }

        .MuiStepConnector-line {
            border-color: #e0e0e0;
        }

        .MuiStepButton-root {
            padding: 12px;
        }

        .MuiStepLabel-root {
            flex-direction: column;
            align-items: center;
        }

        .MuiStepLabel-iconContainer {
            padding: 0;
            margin-bottom: 8px;
        }

        .MuiStepLabel-label {
            font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
            font-size: 14px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.54);
            text-align: center;
        }

        .MuiStepLabel-label.MuiStepLabel-active {
            color: #2196f3;
            font-weight: 600;
        }

        .MuiStepLabel-label.MuiStepLabel-completed {
            color: #4caf50;
        }
    }
`;

const CustomStepIcon = styled.div`
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: ${props => props.active ? '#2196f3' : props.completed ? '#4caf50' : '#bdbdbd'};
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    transition: all 0.3s ease;

    &:hover {
        transform: scale(1.1);
        box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    }
`;

const IsolatedStepper = ({ steps, activeStep, handleStep, completed }) => {
    return (
        <StylesProvider injectFirst>
            <StyledWrapper>
                <MuiStepper nonLinear activeStep={activeStep} alternativeLabel>
                    {steps.map((step, index) => (
                        <MuiStep key={step.id}>
                            <MuiStepButton onClick={() => handleStep(index)}>
                                <MuiStepLabel
                                    StepIconComponent={() => (
                                        <CustomStepIcon active={activeStep === index} completed={completed[index]}>
                                            {completed[index] ? <CheckCircleIcon fontSize="small" /> :
                                                activeStep === index ? index + 1 : <RadioButtonUncheckedIcon fontSize="small" />}
                                        </CustomStepIcon>
                                    )}
                                >
                                    {step.title}
                                </MuiStepLabel>
                            </MuiStepButton>
                        </MuiStep>
                    ))}
                </MuiStepper>
            </StyledWrapper>
        </StylesProvider>
    );
};

export default IsolatedStepper;