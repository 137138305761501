import React from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { downloadButtonCss } from '../shared/Styles/buttonStyles';
import config from '../../config/config.json';

class CustomDownloadDocumento extends React.Component {


    downloadFile = async () => {
        const { tiporecordId, recordId, nomeFile } = this.props; // Estrai le props
        const fileName = nomeFile || 'downloadedFile'; // Nome del file per il download, default se non fornito
        try {

            // console.log(recordId);
            // console.log(`http://localhost:3001/download/uploads/documenti/${tiporecordId}/${recordId}/${nomeFile}`);

            const response = await axios.get(`${config.production.hostUploads}/download/uploads/documenti/${tiporecordId}/${recordId}/${nomeFile}`, {
                responseType: 'blob', // Importante per ricevere il file come blob
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // Utilizza il nomeFile passato come prop per il nome del file scaricato
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.log(error);
        }
    };

    render() {
        return (

            <Button
                sx={downloadButtonCss}
                startIcon={<FileDownloadIcon />}
                onClick={this.downloadFile}
                color="error"
            >
                SCARICA
            </Button>

        );
    }
}

export default CustomDownloadDocumento;
