import React, { useState } from 'react';
import {
    Box,
    Paper,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Alert,
    CircularProgress,
    Tabs,
    Tab,
    Divider
} from '@mui/material';
import { useNotify } from 'react-admin';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import InstallationGuide from './InstallationAmicoGuide';

// Colonne per formato DAT
const DAT_COLUMNS = [
    { id: 'tagAutista', label: 'TAG Autista', width: 120 },
    { id: 'tagAutomezzo', label: 'TAG Automezzo', width: 120 },
    { id: 'kmDigitati', label: 'Km Digitati', width: 120 },
    { id: 'numeroPompa', label: 'N° Pompa', width: 100 },
    { id: 'litriErogati', label: 'Litri Erogati', width: 120 },
    { id: 'ora', label: 'Ora', width: 100 },
    { id: 'minuti', label: 'Minuti', width: 100 },
    { id: 'data', label: 'Data', width: 120 },
    { id: 'tipoTessera', label: 'Tipo Tessera', width: 120 }
];

// Colonne per formato CSV
const CSV_COLUMNS = [
    { id: 'formato', label: 'Formato', width: 80 },
    { id: 'codiceMacchina', label: 'Codice Macchina', width: 120 },
    { id: 'numeroPompa', label: 'N° Pompa', width: 100 },
    { id: 'numeroCisterna', label: 'N° Cisterna', width: 100 },
    { id: 'data', label: 'Data', width: 120 },
    { id: 'ora', label: 'Ora', width: 100 },
    { id: 'autista', label: 'Autista', width: 100 },
    { id: 'mezzo', label: 'Mezzo', width: 100 },
    { id: 'km', label: 'Km', width: 100 },
    { id: 'litriErogati', label: 'Litri Erogati', width: 120 },
    { id: 'totalizzatore', label: 'Totalizzatore', width: 120 },
    { id: 'giacenzaLogica', label: 'Giacenza Logica', width: 120 },
    { id: 'temperatura', label: 'Temperatura', width: 100 },
];

const SoftwareAmicoImport = () => {
    const [fileData, setFileData] = useState(null);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [error, setError] = useState(null);
    const [importType, setImportType] = useState('dat');
    const notify = useNotify();

    const parseTxtLine = (line) => {
        const values = line.split(',');
        if (values.length !== 9) return null;

        const rawDate = values[7];
        const formattedDate = `${rawDate.substr(6, 2)}/${rawDate.substr(4, 2)}/${rawDate.substr(0, 4)}`;

        return {
            tagAutista: values[0],
            tagAutomezzo: values[1],
            kmDigitati: values[2],
            numeroPompa: values[3],
            litriErogati: Number(values[4]).toFixed(2),
            ora: values[5],
            minuti: values[6],
            data: formattedDate,
            tipoTessera: values[8]
        };
    };

    const parseCsvLine = (line) => {
        const values = line.split(';');
        if (values.length < 15) return null;

        const [F, cm, np, nc, data, ora, auti, mez, km, litri, totalizz, giacl, temp] = values;

        return {
            formato: F,
            codiceMacchina: cm,
            numeroPompa: np,
            numeroCisterna: nc,
            data: data,
            ora: ora,
            autista: auti,
            mezzo: mez,
            km: km,
            litriErogati: litri,
            totalizzatore: totalizz,
            giacenzaLogica: giacl,
            temperatura: temp
        };
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const expectedExtension = importType === 'dat' ? '.dat' : '.csv';
        if (!file.name.toLowerCase().endsWith(expectedExtension)) {
            notify(`Per favore carica un file ${expectedExtension}`, { type: 'error' });
            return;
        }

        setLoading(true);
        setError(null);
        const reader = new FileReader();

        reader.onload = (e) => {
            try {
                const text = e.target.result;
                const lines = text.split('\n').filter(line => line.trim());
                const parseFunction = importType === 'dat' ? parseTxtLine : parseCsvLine;
                const parsedRows = lines
                    .map(line => parseFunction(line))
                    .filter(row => row !== null);

                if (parsedRows.length === 0) {
                    throw new Error('Nessun dato valido trovato nel file');
                }

                setFileData(text);
                setRows(parsedRows);
                notify('File caricato con successo', { type: 'success' });
            } catch (err) {
                setError(err.message);
                notify('Errore durante il parsing del file', { type: 'error' });
            } finally {
                setLoading(false);
            }
        };

        reader.onerror = () => {
            setError('Errore durante la lettura del file');
            setLoading(false);
            notify('Errore durante la lettura del file', { type: 'error' });
        };

        reader.readAsText(file);
    };

    const handleClearData = () => {
        setFileData(null);
        setRows([]);
        setError(null);
        notify('Dati cancellati', { type: 'info' });
    };

    return (
        <Box sx={{ width: '100%' }}>
            <InstallationGuide />
            <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                <Typography variant="h6" gutterBottom color="primary">
                    Importazione Rifornimenti
                </Typography>

                <Tabs
                    value={importType}
                    onChange={(e, newValue) => {
                        setImportType(newValue);
                        handleClearData();
                    }}
                    sx={{ mb: 3 }}
                >
                    <Tab value="dat" label="Formato DAT (amico.dat)" />
                    <Tab value="csv" label="Formato CSV (amico.csv)" />
                </Tabs>

                <Divider sx={{ mb: 3 }} />

                {/* Upload Area */}
                <Box
                    sx={{
                        border: '2px dashed #ccc',
                        borderRadius: 2,
                        p: 3,
                        mb: 3,
                        textAlign: 'center',
                        backgroundColor: '#f8f8f8'
                    }}
                >
                    <input
                        accept={importType === 'dat' ? '.dat' : '.csv'}
                        style={{ display: 'none' }}
                        id="file-upload"
                        type="file"
                        onChange={handleFileUpload}
                    />
                    <label htmlFor="file-upload">
                        <Button
                            component="span"
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                            sx={{ mb: 2 }}
                        >
                            Carica File {importType.toUpperCase()}
                        </Button>
                    </label>
                    <Typography variant="body2" color="textSecondary">
                        {importType === 'dat'
                            ? 'Supporta solo file DAT nel formato amico.dat'
                            : 'Supporta solo file CSV nel formato amico.csv'
                        }
                    </Typography>
                </Box>

                {loading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
                        <CircularProgress />
                    </Box>
                )}

                {error && (
                    <Alert severity="error" sx={{ mb: 3 }}>
                        {error}
                    </Alert>
                )}

                {fileData && !loading && (
                    <>
                        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="h6">
                                Anteprima Rifornimenti ({rows.length})
                            </Typography>
                            <Box>
                                <Button
                                    variant="outlined"
                                    startIcon={<DeleteIcon />}
                                    onClick={handleClearData}
                                    sx={{ mr: 2 }}
                                >
                                    Cancella
                                </Button>
                                <Button
                                    variant="contained"
                                    startIcon={<SendIcon />}
                                    onClick={() => setOpenDialog(true)}
                                    color="primary"
                                >
                                    Importa Dati
                                </Button>
                            </Box>
                        </Box>

                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader size="small">
                                <TableHead>
                                    <TableRow>
                                        {(importType === 'dat' ? DAT_COLUMNS : CSV_COLUMNS).map((column) => (
                                            <TableCell
                                                key={column.id}
                                                style={{ width: column.width }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, index) => (
                                        <TableRow key={index}>
                                            {(importType === 'dat' ? DAT_COLUMNS : CSV_COLUMNS).map((column) => (
                                                <TableCell key={column.id}>
                                                    {row[column.id]}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
            </Paper>

            {/* Dialog per l'importazione */}
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Importazione Rifornimenti</DialogTitle>
                <DialogContent>
                    <Alert severity="info" sx={{ mt: 2 }}>
                        Funzionalità in costruzione.
                        <br />
                        La feature di importazione sarà disponibile a breve.
                    </Alert>
                    <Typography sx={{ mt: 2 }}>
                        Numero rifornimenti da importare: {rows.length}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>
                        Chiudi
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default SoftwareAmicoImport;