import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    DateField,
    FunctionField,
    ArrayField,
    Datagrid,
    NumberField,
    ReferenceField,
    useRecordContext
} from 'react-admin';
import {
    Person as PersonIcon
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import PeopleIcon from '@mui/icons-material/People';
import { Card, CardContent, Typography, Grid, Divider, Button, Box, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import { Euro, CalendarToday, LocationOn, DirectionsCar, AccessTime, Print } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(2),
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    timeline: {
        padding: 0,
    },
    printButton: {
        marginBottom: theme.spacing(2),
    }
}));

const InfoItem = ({ icon, label, value }) => {
    const classes = useStyles();
    return (
        <Typography variant="body2" gutterBottom>
            {React.cloneElement(icon, { className: classes.icon })}
            <strong>{label}:</strong> {value}
        </Typography>
    );
};

// Componente per le informazioni dello user
const UserInfo = () => {
    const record = useRecordContext();
    if (!record) return null;

    return (
        <>
            <Card>
                <CardContent>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '2px solid #1976d2',
                        paddingBottom: 1,
                        marginBottom: 2
                    }}>
                        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                            <PersonIcon sx={{ mr: 1 }} />
                            Informazioni User
                        </Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box mb={2}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    User Mail
                                </Typography>
                                <ReferenceField link={false} source="userId" reference="users" label="User Mail" />
                                <br></br>
                                <ReferenceField
                                    source="userId"
                                    reference="users"
                                    link={false}
                                >
                                    Id User : <TextField source="id" />
                                    <hr></hr>
                                    <FunctionField
                                        label="Dettaglio User"
                                        render={record => record && (
                                            <Button
                                                component={Link}
                                                to={`/users/${record.id}/show/preventivi`}
                                                size="small"
                                                startIcon={<PeopleIcon />}
                                                sx={{
                                                    backgroundColor: '#2196f3',
                                                    color: 'white',
                                                    '&:hover': {
                                                        backgroundColor: '#1976d2',
                                                    },
                                                }}
                                            >
                                                Dettaglio User
                                            </Button>
                                        )}
                                    />
                                </ReferenceField>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

export const PreventiviShow = (props) => {
    const classes = useStyles();

    const handlePrint = () => {
        const originalContents = document.body.innerHTML;
        const printContents = document.getElementById('printable-area').innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
    };

    return (
        <Show {...props} title="Dettagli Preventivo">
            <SimpleShowLayout>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Print />}
                    onClick={handlePrint}
                    className={classes.printButton}
                >
                    Stampa
                </Button>
                <div id="printable-area">
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="h5" className={classes.title}>Informazioni Generali</Typography>
                            <UserInfo></UserInfo>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <InfoItem icon={<CalendarToday />} label="Data Inizio" value={<FunctionField render={(record) => record.data.dateFrom} />} />
                                    <InfoItem icon={<CalendarToday />} label="Data Fine" value={<FunctionField render={(record) => record.data.dateTo} />} />
                                    <InfoItem icon={<Euro />} label="Costo Totale" value={<FunctionField render={(record) => `${record.data.totalCost} €`} />} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InfoItem icon={<DirectionsCar />} label="Pacchetto" value={<FunctionField render={(record) => `ID: ${record.data.pacchettiId}`} />} />
                                    <InfoItem icon={<AccessTime />} label="Moltiplicatore Pacchetto" value={<FunctionField render={(record) => record.data.pacchettoMoltiplicatore} />} />
                                    <InfoItem icon={<DateField />} label="Creato il" value={<DateField source="createdAt" showTime />} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="h5" className={classes.title}>Dettagli Viaggio</Typography>
                            <Timeline className={classes.timeline}>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot color="primary"><LocationOn /></TimelineDot>
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Typography variant="h6">Partenza</Typography>
                                        <FunctionField render={(record) => record.data.startPoint} />
                                    </TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot color="secondary"><LocationOn /></TimelineDot>
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Typography variant="h6">Arrivo</Typography>
                                        <FunctionField render={(record) => record.data.endPoint} />
                                    </TimelineContent>
                                </TimelineItem>
                            </Timeline>
                            <Divider style={{ margin: '16px 0' }} />
                            <InfoItem icon={<DirectionsCar />} label="Distanza" value={<FunctionField render={(record) => record.data.distance} />} />
                            <InfoItem icon={<AccessTime />} label="Durata" value={<FunctionField render={(record) => record.data.duration} />} />
                        </CardContent>
                    </Card>

                    <Card>
                        <CardContent>
                            <Typography variant="h5" className={classes.title}>Servizi</Typography>
                            <ArrayField source="data.services">
                                <Datagrid bulkActionButtons={false}>
                                    <TextField source="id" label="ID" />
                                    <ReferenceField link={false} source='servpaxId' reference='servpax' label='Servizio' />
                                    <NumberField source="costo" label="Costo" options={{ style: 'currency', currency: 'EUR' }} />
                                    <NumberField source="moltiplicatore" label="Moltiplicatore" />
                                    <FunctionField
                                        label="Obbligatorio"
                                        render={(record) => (record.flObbligatorio ? 'Sì' : 'No')}
                                    />
                                </Datagrid>
                            </ArrayField>
                        </CardContent>
                    </Card>
                </div>
            </SimpleShowLayout>
        </Show>
    );
};