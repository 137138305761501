export const listCss = {
    backgroundColor: '#f5f5f5', // sfondo arancione
    color: 'white', // testo bianco
};

// styles.js
export const listItemLiv01Zero = {
    backgroundColor: '#e0ffff', // sfondo cyan chiaro
    color: 'black', // testo nero
    padding: '10px 20px',
    margin: '5px 0',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
};

export const listItemDefault = {
    backgroundColor: '#add8e6', // sfondo blue chiaro
    color: 'black', // testo nero
    padding: '10px 20px',
    margin: '5px 0',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
};


export const dialogCss = {
    backgroundColor: '#add8e6', // sfondo blue chiaro
    color: 'black', // testo nero
    padding: '10px 20px',
    margin: '5px 0',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
};
