import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReferenceInput, AutocompleteInput, required } from 'react-admin';
import { formCssDropdownMenu } from "../Styles/formCss";

const CustomAutocompleInputTipoUser = ({ source, reference, optionProperty, label, readOnly }) => {
    const optionRenderer = choice => `${choice[optionProperty]}`;

    return (
        <div>
            <Box display="flex" alignItems="center" sx={{ width: 700 }} >
                <Typography variant="body1" style={{ marginRight: 8 }}>{label}: </Typography>


                {(readOnly) ?
                    <ReferenceInput filter={{ tbpref: 'TAN' }} perPage={100} source={source} reference={reference}>
                        <AutocompleteInput
                            readOnly
                            fullWidth
                            label={label}
                            optionText={optionRenderer}
                            suggestionLimit={100}
                            sx={formCssDropdownMenu}
                            validate={[required("Campo obbligatorio")]}
                            translateChoice={false}
                        />
                    </ReferenceInput>
                    :

                    <ReferenceInput filter={{ tbpref: 'TAN' }} perPage={100} source={source} reference={reference} >
                        <AutocompleteInput
                            fullWidth

                            label={label}
                            optionText={optionRenderer}
                            suggestionLimit={100}
                            sx={formCssDropdownMenu}
                            validate={[required("Campo obbligatorio")]}
                            translateChoice={false}
                        />
                    </ReferenceInput>}
            </Box>
        </div>
    );
};

export default CustomAutocompleInputTipoUser;
