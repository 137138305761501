import { useRecordContext } from 'react-admin';
import NoteList from "../note/CustomNote";
import CreateNoteForm from "../note/CustomCreateNote";

const GestioneNote = ({ tiporecordId, refresh }) => {
    const record = useRecordContext();
    const userId = localStorage.getItem("RaStore.profile.idUser");
    const emailUser = localStorage.getItem("RaStore.profile.email");
    if (!record) return null;
    return record ? (
        <>
            <CreateNoteForm tiporecordId={tiporecordId} recordId={record.id} userId={userId} refresh={refresh}></CreateNoteForm>
            <NoteList tiporecordId={tiporecordId} recordId={record.id} emailUser={emailUser}></NoteList>
        </>
    ) : null;
};

export default GestioneNote;
