import React, { useState, useEffect } from 'react';
import { NumberInput, useNotify, useRecordContext, useDataProvider } from 'react-admin';
import { Box, Typography, Divider, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { cancelButtonCss, saveButtonCss } from '../../shared/Styles/buttonStyles';

const CustomComponent = ({ classes }) => {
    const record = useRecordContext();
    const notify = useNotify();
    const dataProvider = useDataProvider();

    // Stati per tenere traccia dei valori inseriti, inizializzati con i valori del record se disponibili
    const [sedi, setSedi] = useState(record?.ampaxnrdw_s1 || 0);
    const [assistente, setAssistente] = useState(record?.ampaxnrdw_ass || 0);
    const [inPiedi, setInPiedi] = useState(record?.ampaxnrup_s2 || 0);
    // Stati per i totali calcolati
    const [totSedi, setTotSedi] = useState(record?.ampaxnrdw_tot || 0);
    const [totGenerale, setTotGenerale] = useState(record?.ampaxnrtot || 0);
    // Stato per la gestione della Dialog
    const [open, setOpen] = useState(false);

    useEffect(() => {
        // Aggiorna i valori iniziali quando si apre la Dialog
        if (open) {
            setSedi(record?.ampaxnrdw_s1 || 0);
            setAssistente(record?.ampaxnrdw_ass || 0);
            setInPiedi(record?.ampaxnrup_s2 || 0);
            setTotSedi(record?.ampaxnrdw_tot || 0);
            setTotGenerale(record?.ampaxnrtot || 0);
        }
    }, [open, record]);

    useEffect(() => {
        // Calcola e aggiorna i totali ogni volta che cambiano i valori di input o il record
        const calcolatoTotSedi = (parseInt(sedi, 10) || 0) + (parseInt(assistente, 10) || 0);
        const calcolatoTotGenerale = calcolatoTotSedi + (parseInt(inPiedi, 10) || 0);

        setTotSedi(calcolatoTotSedi);
        setTotGenerale(calcolatoTotGenerale);
    }, [sedi, assistente, inPiedi]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        // Calcola nuovamente i totali per essere sicuri
        const ampaxnrdw_tot = parseInt(sedi, 10) || 0 + parseInt(assistente, 10) || 0;
        const ampaxnrtot = parseInt(inPiedi, 10) || 0 + ampaxnrdw_tot;

        // Prepara i dati per l'aggiornamento
        const dataToUpdate = {
            ...record,
            ampaxnrdw_s1: parseInt(sedi, 10),
            ampaxnrdw_ass: parseInt(assistente, 10),
            ampaxnrup_s2: parseInt(inPiedi, 10),
            ampaxnrdw_tot: ampaxnrdw_tot,
            ampaxnrtot: ampaxnrtot
        };

        dataProvider.update('automezzi', { id: record.id, data: dataToUpdate })
            .then(() => {
                notify('Posti Automezzo aggiornati con successo');
                handleClose();
            })
            .catch(error => {
                notify(error.message, 'warning');
            });
    };

    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Modifica Posti
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Calcola Posti</DialogTitle>
                <DialogContent>
                    <Box display="flex" alignItems="center">
                        <Typography variant="body1" style={{ marginRight: 8 }}>Posti a sedere:</Typography>
                        <NumberInput source="ampaxnrdw_s1" label="Posti a sedere" value={sedi} onChange={(e) => setSedi(e.target.value)} />
                    </Box>

                    <Box display="flex" alignItems="center">
                        <Typography variant="body1" style={{ marginRight: 8 }}>Posti assistente:</Typography>
                        <NumberInput source="ampaxnrdw_ass" label="Posti assistente" value={assistente} onChange={(e) => setAssistente(e.target.value)} />
                    </Box>

                    <Box display="flex" alignItems="center">
                        <Typography variant="body1" style={{ marginRight: 8 }}>Posti in piedi:</Typography>
                        <NumberInput source="ampaxnrup_s2" label="Posti in piedi" value={inPiedi} onChange={(e) => setInPiedi(e.target.value)} />
                    </Box>

                    <Divider />

                    {/* Visualizza i totali calcolati */}
                    <Box display="flex" alignItems="center">
                        <Typography variant="body1" style={{ marginRight: 8 }} value={totSedi}>Posti a sedere totali:</Typography>
                        <Typography>{totSedi}</Typography>
                    </Box>

                    <Box display="flex" alignItems="center">
                        <Typography variant="body1" style={{ marginRight: 8 }} value={totGenerale}>Posti Totali:</Typography>
                        <Typography>{totGenerale}</Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button style={cancelButtonCss} onClick={handleClose}>Annulla</Button>
                    <Button style={saveButtonCss} onClick={handleSave} /* disabled={isLoading} */>Salva</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CustomComponent;
