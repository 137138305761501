import React, { useState } from 'react';
import { useUpdate, useNotify } from 'react-admin';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { cancelButtonCss, saveButtonCss } from '../shared/Styles/buttonStyles';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

function EditDocumentiDialog({ note, label, refresh }) {
    // const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const [editText, setEditText] = useState(note.descrizione || '');
    const [update, { isLoading, error }] = useUpdate();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    /*     const handleSave = () => {
            console.log('Testo modificato:', editText);
            // Qui dovresti inserire la logica per salvare effettivamente la nota modificata
            setOpen(false);
        }; */


    const handleSave = () => {
        const data = {
            descrizione: editText,
        };

        update(
            'documenti',
            { id: note.id, data: data, previousData: note },
            {
                onSuccess: ({ data }) => {
                    // refresh();
                    notify('Descrizione file aggiornata con successo');
                    //setEditText(''); // Reset della descrizione se necessario
                },
                onFailure: (error) => {
                    notify(error.message, 'warning');
                }
            }
        );
        setOpen(false);
    }
    if (error) { return <p>ERROR</p>; }

    return (
        <Box >
            <Button sx={saveButtonCss} variant="outlined" onClick={handleClickOpen}>
                Modifica{/*  {label} */}
            </Button>
            <Dialog /* fullScreen={fullScreen} */ maxWidth={"md"} open={open} onClose={handleClose}>
                <DialogTitle>Modifica {label}</DialogTitle>
                <DialogContent sx={{
                    width: 500, // Imposta la larghezza desiderata qui
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center', // Centra i contenuti se desiderato
                }}>
                    <TextField

                        autoFocus
                        margin="dense"
                        id="name"
                        label="Descrizione File"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        value={editText}
                        onChange={(e) => setEditText(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button sx={cancelButtonCss} onClick={handleClose}>Annulla</Button>
                    <Button sx={saveButtonCss} onClick={handleSave}>Salva</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default EditDocumentiDialog;
