import React, { useState, useEffect } from 'react';
import {
    useDataProvider,
    useGetOne,
    Loading, useNotify, useRedirect, Title
} from 'react-admin';
import {
    Typography,
    List,
    ListItem,
    ListItemText,
    FormHelperText,
    TextField,
    Checkbox,
    CircularProgress,
    FormControl,
    InputLabel,
    Select as MuiSelect,
    MenuItem,
    Paper,
    Button as MuiButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import SummaryComponent from './SummaryComponent';
import AddressSelectionComponent from './AddressSelectionComponent';
import axios from 'axios';
import config from '../../../config/config.json';
import IsolatedStepper from './IsolatedStepper';

import { Card, CardContent } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    stepper: {
        backgroundColor: 'transparent',
        marginBottom: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(3),
    },
    inputContainer: {
        marginBottom: theme.spacing(3),
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(3),
    },
    button: {
        margin: theme.spacing(1),
    },
    errorText: {
        color: theme.palette.error.main,
    },
    datePicker: {
        marginBottom: theme.spacing(2),
    },
    completionMessage: {
        marginTop: theme.spacing(2),
        textAlign: 'center',
    },
}));

const steps = [
    { id: 1, title: 'Seleziona date e pacchetto' },
    { id: 2, title: 'Seleziona indirizzi partenza e destinazione' },
    { id: 3, title: 'Riepilogo' },
];

const SimulatoreClienteStepper = () => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    const [selectedDateFrom, setSelectedDateFrom] = useState(null);
    const [selectedDateTo, setSelectedDateTo] = useState(null);
    const [availablePacchetti, setAvailablePacchetti] = useState([]);
    const [selectedPacchetto, setSelectedPacchetto] = useState(null);
    const [selectedServices, setSelectedServices] = useState([]);
    const [dateError, setDateError] = useState('');
    const [pacchettoMoltiplicatore, setPacchettoMoltiplicatore] = useState(1);
    const [startPoint, setStartPoint] = useState('');
    const [endPoint, setEndPoint] = useState('');
    const [distance, setDistance] = useState('');
    const [duration, setDuration] = useState('');
    const [loadingRoute, setLoadingRoute] = useState(false);
    const [routeError, setRouteError] = useState('');
    const [showCompletionMessage, setShowCompletionMessage] = useState(false);
    const jwt = localStorage.getItem("feathers-jwt");
    const [totalCost, setTotalCost] = useState(0);
    const notify = useNotify();
    const redirect = useRedirect();

    const handleStartPointChange = (newValue) => {
        setStartPoint(newValue);
    };

    const handleEndPointChange = (newValue) => {
        setEndPoint(newValue);
    };

    const calculateRoute = () => {
        if (startPoint && endPoint) {
            setLoadingRoute(true);
            setRouteError('');
            const configBackend = {
                method: 'get',
                url: `${config.production.host}/google-maps-proxy?path=/directions/json&origin=${encodeURIComponent(
                    startPoint
                )}&destination=${encodeURIComponent(endPoint)}&mode=driving&language=it`,
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            };
            axios
                .request(configBackend)
                .then((response) => {
                    const routeData = response.data.routes[0].legs[0];
                    setDistance(routeData.distance.text);
                    setDuration(routeData.duration.text);
                    setLoadingRoute(false);
                })
                .catch((err) => {
                    setRouteError('Errore durante il calcolo della rotta. Riprova più tardi. Motivo : ' + err);
                    setLoadingRoute(false);
                });
        }
    };

    useEffect(() => {
        if (startPoint && endPoint) {
            calculateRoute();
        }
    }, [startPoint, endPoint]);

    const handleDateFromChange = (event) => {
        setSelectedDateFrom(event.target.value);
        setSelectedPacchetto(null);
        setAvailablePacchetti([]);
    };

    const handleDateToChange = (event) => {
        setSelectedDateTo(event.target.value);
        setSelectedPacchetto(null);
        setAvailablePacchetti([]);
    };

    const validateDates = (from, to) => {
        if (!from) {
            setDateError('La data di inizio è obbligatoria');
            return false;
        }
        if (to && from > to) {
            setDateError('La data di inizio deve essere precedente alla data di fine');
            return false;
        }
        setDateError('');
        return true;
    };

    const fetchPacchetti = async () => {
        if (!validateDates(selectedDateFrom, selectedDateTo)) return;

        try {
            const { data } = await dataProvider.getList('pacchetti', {
                pagination: { page: 1, perPage: 1000 },
                sort: { field: 'nome', order: 'ASC' },
                filter: {
                    flAttivo: 1,
                    flVisibile: 1,
                    flAnnullato: 0,
                    dataFrom: { $lte: selectedDateTo || selectedDateFrom },
                    dataTo: { $gte: selectedDateFrom }
                }
            });
            setAvailablePacchetti(data);
        } catch (error) {
            console.error('Errore nel recupero dei pacchetti:', error);
            setDateError('Errore nel recupero dei pacchetti disponibili');
        }
    };

    const fetchServizi = async (pacchettoId) => {
        try {
            const { data: serviziPacchetto } = await dataProvider.getList('pacchetti-servpax', {
                pagination: { page: 1, perPage: 1000 },
                sort: { field: 'ordinamento', order: 'ASC' },
                filter: {
                    pacchettiId: pacchettoId,
                    flAttivo: 1,
                    flVisibile: 1,
                    flAnnullato: 0
                }
            });

            const currentDateFrom = selectedDateFrom;
            const currentDateTo = selectedDateTo;

            const serviziConTariffe = await Promise.all(serviziPacchetto.map(async (servizio) => {
                try {
                    const { data: tariffe } = await dataProvider.getList('tariffa-servpax', {
                        pagination: { page: 1, perPage: 1 },
                        sort: { field: 'id', order: 'DESC' },
                        filter: {
                            servpaxId: servizio.servpaxId,
                            dataFrom: { $lte: currentDateTo || currentDateFrom },
                            dataTo: { $gte: currentDateFrom }
                        }
                    });

                    const tariffa = tariffe[0];

                    if (!tariffa) {
                        return null;
                    }

                    return {
                        ...servizio,
                        costo: tariffa.costo,
                        tariffaId: tariffa.id,
                        dataFrom: tariffa.dataFrom,
                        dataTo: tariffa.dataTo
                    };
                } catch (error) {
                    console.log("CATCH >>> " + error);
                    return null;
                }
            }));

            const serviziValidi = serviziConTariffe.filter(servizio => servizio !== null);

            return serviziValidi;
        } catch (error) {
            console.error('Errore nel recupero dei servizi del pacchetto:', error);
            return [];
        }
    };

    useEffect(() => {
        if (selectedDateFrom && selectedDateTo) {
            fetchPacchetti();
        }
    }, [selectedDateFrom, selectedDateTo]);

    const handlePacchettoSelect = async (event) => {
        const pacchettoId = event.target.value;
        setSelectedPacchetto(pacchettoId);
        setPacchettoMoltiplicatore(1);
        if (pacchettoId) {
            const serviziPacchetto = await fetchServizi(pacchettoId);
            setSelectedServices(serviziPacchetto.map(servizio => ({
                ...servizio,
                moltiplicatore: 1,
                isSelected: servizio.flObbligatorio
            })));
        }
    };

    const handleServiceSelection = (serviceId) => {
        setSelectedServices(prevServices => prevServices.map(service =>
            service.id === serviceId ? { ...service, isSelected: !service.isSelected } : service
        ));
    };

    const handleMoltiplicatoreChange = (serviceId, value) => {
        setSelectedServices(prevServices => prevServices.map(service =>
            service.id === serviceId ? { ...service, moltiplicatore: Math.max(1, parseInt(value) || 1) } : service
        ));
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        }
    };

    const handleNext = () => {
        if (activeStep === 1) {
            if (!startPoint || !endPoint) {
                setRouteError('Devi selezionare il punto di partenza e di arrivo prima di procedere.');
                return;
            }
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setCompleted((prevCompleted) => ({ ...prevCompleted, [activeStep]: true }));
    };

    const handleFinish = () => {
        const data = {
            dateFrom: selectedDateFrom,
            dateTo: selectedDateTo,
            pacchettiId: selectedPacchetto,
            pacchettoMoltiplicatore: pacchettoMoltiplicatore,
            startPoint,
            endPoint,
            distance,
            duration,
            services: selectedServices
                .filter(service => service.isSelected || service.flObbligatorio)
                .map(service => ({
                    id: service.id,
                    servpaxId: service.servpaxId,
                    costo: service.costo,
                    moltiplicatore: service.moltiplicatore,
                    flObbligatorio: service.flObbligatorio,
                })),
            totalCost,
        };

        dataProvider
            .create('preventivi', { data })
            .then((response) => {
                console.log('Preventivo created:', response);
                notify('Preventivo creato con successo.');
                setShowCompletionMessage(true);
                redirect('/preventivi')
            })
            .catch((error) => {
                console.error('Error creating preventivo:', error);
                notify(error.message, 'warning');
            });
    };

    const calculateTotalCost = () => {
        return selectedServices.reduce((total, service) => {
            if (service.isSelected || service.flObbligatorio) {
                return total + (service.costo || 0) * service.moltiplicatore;
            }
            return total;
        }, 0) * pacchettoMoltiplicatore;
    };

    return (

        <Card>
            <Title title="Crea Preventivo" />
            <CardContent>
                {/* <Paper className={classes.root}> */}
                <Typography variant="h4" gutterBottom>
                    Calcola Preventivo in 3 passi
                </Typography>
                <IsolatedStepper
                    steps={steps}
                    activeStep={activeStep}
                    handleStep={handleStep}
                    completed={completed}
                />
                <div className={classes.content}>
                    {activeStep === 0 && (
                        <Grid container spacing={2} className={classes.inputContainer}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1">Seleziona il range di date:</Typography>
                                <TextField
                                    type="date"
                                    label="Data inizio"
                                    value={selectedDateFrom || ''}
                                    onChange={handleDateFromChange}
                                    className={classes.datePicker}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                />
                                <TextField
                                    type="date"
                                    label="Data fine"
                                    value={selectedDateTo || ''}
                                    onChange={handleDateToChange}
                                    className={classes.datePicker}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                />
                                {dateError && (
                                    <FormHelperText error>{dateError}</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {availablePacchetti.length > 0 ? (
                                    <>
                                        <Typography variant="subtitle1">Seleziona un pacchetto:</Typography>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel id="pacchetto-label">Pacchetto</InputLabel>
                                            <MuiSelect
                                                labelId="pacchetto-label"
                                                value={selectedPacchetto || ''}
                                                onChange={handlePacchettoSelect}
                                                label="Pacchetto"
                                            >
                                                {availablePacchetti.map((pacchetto) => (
                                                    <MenuItem key={pacchetto.id} value={pacchetto.id}>
                                                        {`${pacchetto.nome} (${pacchetto.dataFrom} - ${pacchetto.dataTo})`}
                                                    </MenuItem>
                                                ))}
                                            </MuiSelect>
                                        </FormControl>
                                    </>
                                ) : (
                                    <Typography>
                                        {selectedDateFrom && selectedDateTo
                                            ? "Nessun pacchetto disponibile per le date selezionate"
                                            : "Seleziona le date per vedere i pacchetti disponibili"}
                                    </Typography>
                                )}
                            </Grid>
                            {selectedPacchetto && (
                                <>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Dettagli del pacchetto selezionato:</Typography>
                                        <PacchettoDetails
                                            id={selectedPacchetto}
                                            moltiplicatore={pacchettoMoltiplicatore}
                                            onMoltiplicatoreChange={setPacchettoMoltiplicatore} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Servizi obbligatori:</Typography>
                                        <List>
                                            {selectedServices.filter(service => service.flObbligatorio).map((service) => (
                                                <ListItem key={service.id}>
                                                    <ListItemText
                                                        primary={<ServpaxDetails id={service.servpaxId} />}
                                                        secondary={`Obbligatorio - Costo: ${service.costo} € \n 
                                                    dataFrom: ${service.dataFrom}
                                                    \n
                                                    dataTo: ${service.dataTo}
                                                    `}
                                                    />
                                                    <TextField
                                                        type="number"
                                                        label="Moltiplicatore"
                                                        value={service.moltiplicatore}
                                                        onChange={(e) => handleMoltiplicatoreChange(service.id, e.target.value)}
                                                        inputProps={{ min: 1 }}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Servizi opzionali:</Typography>
                                        <List>
                                            {selectedServices.filter(service => !service.flObbligatorio).map((service) => (
                                                <ListItem key={service.id}>
                                                    <ListItemText
                                                        primary={<ServpaxDetails id={service.servpaxId} />}
                                                        secondary={`Costo: ${service.costo} € \n 
                                                    dataFrom: ${service.dataFrom}
                                                    \n
                                                    dataTo: ${service.dataTo}
                                                    \n
                                                    id: ${service.tariffaId}
                                                    `}
                                                    />
                                                    <Checkbox
                                                        checked={service.isSelected}
                                                        onChange={() => handleServiceSelection(service.id)}
                                                    />
                                                    {service.isSelected && (
                                                        <TextField
                                                            type="number"
                                                            label="Moltiplicatore"
                                                            value={service.moltiplicatore}
                                                            onChange={(e) => handleMoltiplicatoreChange(service.id, e.target.value)}
                                                            inputProps={{ min: 1 }}
                                                        />
                                                    )}
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    )}
                    {activeStep === 1 && (
                        <>
                            <AddressSelectionComponent
                                onStartPointChange={handleStartPointChange}
                                onEndPointChange={handleEndPointChange}
                                selectedPacchetto={selectedPacchetto}
                            />
                            {routeError && (
                                <Typography color="error" style={{ marginTop: 10 }}>
                                    {routeError}
                                </Typography>
                            )}
                            {loadingRoute && (
                                <div style={{ textAlign: 'center', marginTop: 20 }}>
                                    <CircularProgress />
                                    <Typography>Calcolo della rotta in corso...</Typography>
                                </div>
                            )}
                            {!loadingRoute && distance && duration && (
                                <div style={{ marginTop: 20 }}>
                                    <Typography>Distanza: {distance}</Typography>
                                    <Typography>Durata: {duration}</Typography>
                                </div>
                            )}
                            <Grid container spacing={2} className={classes.inputContainer}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Riepilogo delle scelte:</Typography>
                                    <Typography>Data inizio: {selectedDateFrom}</Typography>
                                    <Typography>Data fine: {selectedDateTo}</Typography>
                                    <Typography>
                                        Pacchetto selezionato: {selectedPacchetto && <PacchettoDetails id={selectedPacchetto} />}
                                    </Typography>
                                    <Typography>Punto di partenza: {startPoint || 'Non selezionato'}</Typography>
                                    <Typography>Punto di arrivo: {endPoint || 'Non selezionato'}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Servizi inclusi nel pacchetto:</Typography>
                                    <List>
                                        {selectedServices
                                            .filter(service => service.isSelected || service.flObbligatorio)
                                            .map((service) => (
                                                <ListItem key={service.id}>
                                                    <ListItemText
                                                        primary={<ServpaxDetails id={service.servpaxId} />}
                                                        secondary={`Obbligatorio: ${service.flObbligatorio ? 'Sì' : 'No'} - Costo: ${service.costo} € x ${service.moltiplicatore}`}
                                                    />
                                                </ListItem>
                                            ))}
                                    </List>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {activeStep === 2 && (
                        <SummaryComponent
                            selectedPacchetto={selectedPacchetto}
                            selectedServices={selectedServices}
                            pacchettoMoltiplicatore={pacchettoMoltiplicatore}
                            dateFrom={selectedDateFrom}
                            dateTo={selectedDateTo}
                            calculateTotalCost={calculateTotalCost}
                            startPoint={startPoint}
                            endPoint={endPoint}
                            distance={distance}
                            duration={duration}
                            setTotalCost={setTotalCost}
                        />
                    )}
                    {showCompletionMessage && (
                        <Typography variant="h6" className={classes.completionMessage}>
                            Funzione di archiviazione dati da completare
                        </Typography>
                    )}
                </div>
                <div className={classes.buttonContainer}>
                    {activeStep > 0 && (
                        <MuiButton onClick={handleBack} className={classes.button}>
                            Indietro
                        </MuiButton>
                    )}
                    {activeStep < steps.length - 1 ? (
                        <MuiButton
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            className={classes.button}
                            disabled={
                                (activeStep === 0 && (!selectedDateFrom || !selectedPacchetto)) ||
                                (activeStep === 1 && (!startPoint || !endPoint))
                            }
                        >
                            Avanti
                        </MuiButton>
                    ) : (
                        <MuiButton
                            variant="contained"
                            color="primary"
                            onClick={handleFinish}
                            className={classes.button}
                        >
                            Fine
                        </MuiButton>
                    )}
                </div>
                {/* </Paper> */}
            </CardContent>
        </Card>
    );
};

const PacchettoDetails = ({ id, moltiplicatore, onMoltiplicatoreChange }) => {
    const { data, isLoading, error } = useGetOne('pacchetti', { id });

    if (isLoading) return <Loading />;
    if (error) return <div>Errore nel caricamento dei dati del pacchetto</div>;
    if (!data) return <div>Dati del pacchetto non disponibili</div>;

    return (
        <div>
            <Typography>Nome: {data.nome}</Typography>
            <Typography>Data inizio: {data.dataFrom}</Typography>
            <Typography>Data fine: {data.dataTo}</Typography>
            <Typography>Descrizione: {data.descrizione}</Typography>
            {moltiplicatore !== undefined && onMoltiplicatoreChange && (
                <TextField
                    type="number"
                    label="Moltiplicatore"
                    value={moltiplicatore}
                    onChange={(e) => onMoltiplicatoreChange(Math.max(1, parseInt(e.target.value) || 1))}
                    inputProps={{ min: 1 }}
                />
            )}
        </div>
    );
};

const ServpaxDetails = ({ id }) => {
    const { data, isLoading, error } = useGetOne('servpax', { id });

    if (isLoading) return <Loading />;
    if (error) return <div>Errore nel caricamento dei dati del servizio</div>;
    if (!data) return <div>Dati del servizio non disponibili</div>;

    return <>{data.descrizione}</>;
};

export default SimulatoreClienteStepper;