import React, { useState, useEffect } from 'react';
import { useGetList } from 'react-admin';
import { FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';

const GetTipoContatto = ({ onTipoContattoChange, defaultValue }) => {
    const { data, isLoading, error } = useGetList(
        'view-tabellaalfa-set',
        { pagination: { page: 1, perPage: 100 } }
    );

    const [selectedValue, setSelectedValue] = useState('');

    // Imposta il valore selezionato al valore di default quando il componente viene montato
    // o quando il defaultValue cambia.
    useEffect(() => {
        setSelectedValue(defaultValue || '');
        // Chiama la callback per impostare il valore di default nel componente padre,
        // se la callback è stata fornita e il defaultValue è valido.
        if (defaultValue) {
            onTipoContattoChange(defaultValue);
        }
    }, [defaultValue, onTipoContattoChange]);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
        if (onTipoContattoChange) {
            onTipoContattoChange(newValue);
        }
    };

    if (isLoading) {
        return <CircularProgress />;
    }

    if (error) {
        return <p>ERROR</p>;
    }

    return (
        <FormControl fullWidth>
            <InputLabel id="tipo-contatto-label">Tipologia Contatto</InputLabel>
            <Select
                labelId="tipo-contatto-label"
                id="tipo-contatto-select"
                value={selectedValue}
                onChange={handleChange}
                label="Tipologia Contatto"
            >
                {data.map(record => (
                    <MenuItem key={record.id} value={record.id}>
                        {record.descrizione}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default GetTipoContatto;
