import * as React from "react";
import { useRecordContext } from 'react-admin';
import { makeStyles } from '@mui/styles';
import LaunchIcon from '@mui/icons-material/Launch';

const useStyles = makeStyles({
    link: {
        textDecoration: 'none',
    },
    icon: {
        width: '0.5em',
        height: '0.5em',
        paddingLeft: 2,
    },
});

const MyUrlField = ({ source }) => {
    const record = useRecordContext();
    const classes = useStyles();
    return record ? (
        // <a href="http://google.com" target="_blank" className={classes.link}>
        <a href={record[source]} target="_blank" className={classes.link}>
            Mappa
            <LaunchIcon className={classes.icon} />
        </a>
    ) : null;
}

export default MyUrlField;