import { useGetOne, Loading } from 'react-admin';


const GetUserEmail = ({ userId }) => {
    const { data: user, isLoading, error } = useGetOne('users', { id: userId });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return <div>{user.email}</div>;
};

export default GetUserEmail;