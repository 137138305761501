import * as React from "react";
import { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@mui/material/Typography';
import PeopleIcon from '@mui/icons-material/People';
import AirlineSeatReclineExtraIcon from '@mui/icons-material/AirlineSeatReclineExtra';
import BusinessIcon from '@mui/icons-material/Business';
import {
  useDataProvider,
  Loading,
  Error,
  usePermissions,
  Title,
  List,
  useListContext,
  DateField,
  TextField,
  Button
} from 'react-admin';
import { Avatar } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { Link as RouterLink } from 'react-router-dom';

// Importo il css per i Bottoni
import { createButtonCss, showButtonCss } from '../shared/Styles/buttonStyles';
import StatusPieChartBooking0Status from "../statistiche/StatusPieChartBooking0Status";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    paddingTop: theme.spacing(10)
  },
  paper: {
    padding: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    fontSize: 60,
    marginBottom: theme.spacing(2),
  },
  // Stili per le liste personalizzate
  listCard: {
    backgroundColor: '#f5f5f5',
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  listCardContent: {
    paddingBottom: `${theme.spacing(2)}px !important`,
    flex: 1,
  },
  avatar: {
    margin: theme.spacing(2),
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: theme.palette.primary.main,
  },
  field: {
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
  fieldLabel: {
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
  // Stile per il pulsante Dettaglio
  detailButton: {
    marginTop: theme.spacing(2),
  },
}));

const services = [
  { name: 'Anagrafiche', service: 'anagen', icon: PeopleIcon, color: '#00796b' },
  { name: 'Autisti', service: 'autisti', icon: AirlineSeatReclineExtraIcon, color: '#388e3c' },
  { name: 'Fornitori Servizi', service: 'view-fornitori-servpax', icon: BusinessIcon, color: '#f57c00' }
];

// Mappa delle icone per le risorse
const resourceIcons = {
  'anagen': PeopleIcon,
  'autisti': AirlineSeatReclineExtraIcon,
  'view-fornitori-servpax': BusinessIcon,
  // Aggiungi altre risorse e icone se necessario
};

// Mappa dei colori per le risorse
const resourceColors = {
  'anagen': '#00796b',
  'autisti': '#388e3c',
  'view-fornitori-servpax': '#f57c00',
  // Aggiungi altre risorse e colori se necessario
};

const ServiceCard = ({ service, count, error }) => {
  const classes = useStyles();
  const Icon = service.icon;
  return (
    <Card className={classes.card} aria-label={`${service.name} card`}>
      <CardContent className={classes.cardContent}>
        <Icon className={classes.icon} style={{ color: service.color }} aria-hidden="true" />
        <Typography variant="h6" component="div" gutterBottom>
          {service.name}
        </Typography>
        {error ? (
          <Typography variant="body1" color="error">
            Errore nel caricamento
          </Typography>
        ) : (
          <Typography variant="h4" color="textSecondary">
            {count !== undefined ? count : 'Caricamento...'}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

// Componente personalizzato per la lista con Avatar e pulsante Dettaglio
const CustomLatestRecordsList = ({ fields, resource }) => {
  const classes = useStyles();
  const { data, isLoading } = useListContext();

  if (isLoading) return <Loading />;

  const ResourceIcon = resourceIcons[resource] || PersonIcon;
  const avatarColor = resourceColors[resource] || '#2196f3';

  return (
    <Grid container spacing={2}>
      {data.map((record) => {
        const avatarStyle = {
          backgroundColor: avatarColor,
        };

        // Determina il link per il pulsante Dettaglio
        let detailLink = '';
        if (resource === 'anagen') {
          detailLink = `/anagen/${record.id}/show`;
        } else if (resource === 'view-fornitori-servpax') {
          detailLink = `/tariffa-servpax`;
        }

        return (
          <Grid item xs={12} key={record.id}>
            <Card className={classes.listCard}>
              {/* Avatar con icona specifica per risorsa */}
              <Avatar className={classes.avatar} style={avatarStyle} aria-hidden="true">
                <ResourceIcon fontSize="large" />
              </Avatar>
              <CardContent className={classes.listCardContent}>
                {fields.map((field) => (
                  <div className={classes.field} key={field.source}>
                    <Typography variant="body2" className={classes.fieldLabel}>
                      {field.label || field.source}:
                    </Typography>
                    {field.type === 'date' ? (
                      <Typography variant="body2">
                        <DateField record={record} source={field.source} showTime={field.showTime} />
                      </Typography>
                    ) : (
                      <Typography variant="body2">
                        <TextField record={record} source={field.source} />
                      </Typography>
                    )}
                  </div>
                ))}
                {/* Pulsante Dettaglio */}
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  component={RouterLink}
                  to={detailLink}
                  sx={showButtonCss}
                  aria-label={`Dettaglio ${resource}`}
                >
                  Dettaglio
                </Button>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

// Modifica il componente LatestRecordsList per passare il resource
const LatestRecordsList = ({ resource, fields }) => (
  <List
    title={<></>}
    resource={resource}
    perPage={10}
    sort={{ field: 'id', order: 'DESC' }}
    pagination={false}
    actions={null}
  >
    <CustomLatestRecordsList fields={fields} resource={resource} />
  </List>
);

export const Dashboard = () => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  const dataProvider = useDataProvider();
  const [serviceCounts, setServiceCounts] = useState({});
  const [serviceErrors, setServiceErrors] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchServiceData = async () => {
      setLoading(true);
      const promises = services.map(async (service) => {
        try {
          const result = await dataProvider.getList(service.service, {
            pagination: { page: 1, perPage: 1 },
            sort: { field: 'id', order: 'ASC' },
            filter: {}
          });
          return { service: service.service, count: result.total };
        } catch (error) {
          console.error(`Error fetching data for ${service.name}:`, error);
          return { service: service.service, error: true };
        }
      });

      const results = await Promise.all(promises);
      const counts = {};
      const errors = {};

      results.forEach((result) => {
        if (result.error) {
          errors[result.service] = true;
        } else {
          counts[result.service] = result.count;
        }
      });

      setServiceCounts(counts);
      setServiceErrors(errors);
      setLoading(false);
    };

    fetchServiceData();
  }, [dataProvider]);

  if (loading) return <Loading />;

  return (
    <div className={classes.root}>
      <Title title="Dashboard" />
      <Grid container spacing={3}>
        {permissions === 'admin' && (
          <><Grid item xs={12}>
            <Card>
              <CardHeader className={classes.paper} title="Dashboard amministrativa" />
              <CardContent>
                <Grid container spacing={2}>
                  {services.map((service) => (
                    <Grid item xs={12} sm={6} md={4} key={service.service}>
                      <ServiceCard
                        service={service}
                        count={serviceCounts[service.service]}
                        error={serviceErrors[service.service]}
                      />
                    </Grid>
                  ))}
                  <Card >
                    <CardContent >
                      <StatusPieChartBooking0Status></StatusPieChartBooking0Status>
                    </CardContent>
                  </Card>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardHeader
                  className={classes.paper}
                  title="Ultimi 10 record Anagrafiche"
                  action={
                    <Button
                      sx={createButtonCss}
                      variant="contained"
                      color="primary"
                      size="small"
                      component={RouterLink}
                      to="/anagrafiche/crea"
                      aria-label="Crea Anagrafica"
                    >
                      Crea
                    </Button>
                  }
                />
                <CardContent>
                  <LatestRecordsList
                    resource="anagen"
                    fields={[
                      { source: 'id', label: 'ID' },
                      { source: 'gnfind', label: 'Codice' },
                      { source: 'gnragnom', label: 'Ragione Sociale' },
                      // { source: 'createdAt', type: 'date', label: 'Data Creazione', showTime: true }
                    ]}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardHeader className={classes.paper} title="Ultimi 10 record Fornitori Servizi" />
                <CardContent>
                  <LatestRecordsList
                    resource="view-fornitori-servpax"
                    fields={[
                      { source: 'id', label: 'ID' },
                      { source: 'gncfind', label: 'Codice Fornitore' },
                      { source: 'descrizione', label: 'Descrizione' },
                      // { source: 'createdAt', type: 'date', label: 'Data Creazione', showTime: true }
                    ]}
                  />
                </CardContent>
              </Card>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default Dashboard;
