import * as React from 'react';
import { useState } from 'react';
import { useGetList, useNotify } from 'react-admin';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress,
    Button as MuiButton,
    Alert,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import {
    DirectionsBike,
    Add as AddIcon,
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import axios from 'axios';
import config from '../../config/config.json';

const host = config.production.host;

const AddVehicleDialog = ({
    open,
    onClose,
    booking0Id,
    bk0_paxad,
    bk0_paxch,
    bk0_paxin,
    onSuccess
}) => {
    const [selectedGroup, setSelectedGroup] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [filterEnabled, setFilterEnabled] = useState(false);
    const jwt = localStorage.getItem("feathers-jwt");
    const totalePax = bk0_paxad + bk0_paxch + bk0_paxin;
    const msgTotPax = "Mostra solo gruppi con PAX >= " + totalePax;
    const notify = useNotify();


    // Carica i gruppi dalla tabellealfa con filtro opzionale su tbmy >= 30
    const { data: groups, isLoading: isLoadingGroups, error: loadError } = useGetList(
        'tabellealfa',
        {
            filter: {
                tbpref: 'GRP',
                ...(filterEnabled && { tbmy: { $gte: totalePax.toString() } })
            },
            sort: { field: 'ordinamento', order: 'ASC' },
            pagination: { page: 1, perPage: 100 }
        }
    );

    const handleSubmit = async () => {
        if (!selectedGroup) {
            setError('Seleziona un gruppo');
            return;
        }

        setLoading(true);
        setError(null);

        try {
            await axios.post(
                `${host}/booking-0-automezzi`,
                {
                    booking0Id,
                    gruppoId: selectedGroup,
                    flAttivo: 1,
                    flVisibile: 1,
                    flAnnullato: 0
                },
                {
                    headers: {
                        'Authorization': `Bearer ${jwt}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            notify('Automezzo aggiunto con successo', { type: 'success' });
            onSuccess?.();
            handleClose();
        } catch (error) {
            console.error('Error adding vehicle:', error);
            setError('Errore durante l\'aggiunta dell\'automezzo. Riprova.');
            notify('Errore nell\'aggiunta dell\'automezzo', { type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setSelectedGroup('');
        setError(null);
        setFilterEnabled(false); // Reset filter state when closing
        onClose();
    };

    // Reset selected group when changing filter to avoid invalid selections
    const handleFilterChange = (event) => {
        setFilterEnabled(event.target.checked);
        setSelectedGroup('');
    };



    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    maxHeight: '90vh',
                    overflowY: 'auto'
                }
            }}
        >
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <DirectionsBike sx={{ mr: 1 }} />
                    Aggiungi Automezzo per {booking0Id}
                </Box>
            </DialogTitle>
            <DialogContent>
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}

                {loadError ? (
                    <Alert severity="error">
                        Errore nel caricamento dei gruppi. Riprova più tardi.
                    </Alert>
                ) : isLoadingGroups ? (
                    <Box display="flex" justifyContent="center" my={3}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box sx={{ mt: 2 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filterEnabled}
                                    onChange={handleFilterChange}
                                    name="filterTbmy"
                                    color="primary"
                                />
                            }
                            label={msgTotPax}
                            sx={{ mb: 2, display: 'block' }}
                        />

                        <FormControl fullWidth>
                            <InputLabel id="group-select-label">Seleziona Gruppo Automezzo</InputLabel>
                            <Select
                                labelId="group-select-label"
                                value={selectedGroup}
                                onChange={(e) => setSelectedGroup(e.target.value)}
                                label="Seleziona Gruppo Automezzo"
                                disabled={loading}
                            >
                                {groups?.map((group) => (
                                    <MenuItem key={group.id} value={group.id}>
                                        {group.tbdes} {group.tbmy && `(PAX MAX: ${group.tbmy})`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {groups?.length === 0 && (
                            <Typography color="textSecondary" sx={{ mt: 2 }}>
                                {filterEnabled
                                    ? 'Nessun gruppo automezzo disponibile con tbmy >= 30.'
                                    : 'Nessun gruppo automezzo disponibile.'}
                            </Typography>
                        )}
                    </Box>
                )}
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <MuiButton
                    onClick={handleClose}
                    disabled={loading}
                    color="inherit"
                >
                    Annulla
                </MuiButton>
                <MuiButton
                    onClick={handleSubmit}
                    disabled={loading || !selectedGroup || isLoadingGroups}
                    variant="contained"
                    color="primary"
                    startIcon={loading ? <CircularProgress size={20} /> : <AddIcon />}
                >
                    {loading ? 'Aggiunta in corso...' : 'Aggiungi Automezzo'}
                </MuiButton>
            </DialogActions>
        </Dialog>
    );
};

AddVehicleDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    booking0Id: PropTypes.number.isRequired,
    onSuccess: PropTypes.func
};

export default AddVehicleDialog;