// src/components/users/UserActivities.js
import React, { useState, useMemo, useEffect } from 'react';
import { debounce } from 'lodash';
import {
    useGetList,
    useRecordContext,
    DateField,
    useNotify,
    Pagination
} from 'react-admin';
import {
    Card,
    CardContent,
    Typography,
    Box,
    Chip,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Collapse,
    Alert,
    CircularProgress,
    Button
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FilterListIcon from '@mui/icons-material/FilterList';
import ClearIcon from '@mui/icons-material/Clear';

import axios from "axios";
import config from '../../config/config.json';
// import ActivityStats from './ActivityStats';

const host = config.production.host;

// Costanti per i tipi di azioni
const ACTION_TYPES = {
    FIND: {
        label: 'Ricerche',
        icon: <FindInPageIcon />,
        description: 'Richieste di ricerca dati',
        color: 'primary'
    },
    CREATE: {
        label: 'Creazione',
        icon: <AddCircleIcon />,
        description: 'Creazione nuovi record',
        color: 'success'
    },
    PATCH: {
        label: 'Modifica parziale',
        icon: <EditIcon />,
        description: 'Aggiornamento dati',
        color: 'info'
    },
    UPDATE: {
        label: 'Modifica completa',
        icon: <EditIcon />,
        description: 'Aggiornamento dati',
        color: 'info'
    },
    DELETE: {
        label: 'Eliminazione',
        icon: <DeleteIcon />,
        description: 'Cancellazione record',
        color: 'error'
    },
    ERROR: {
        label: 'Errori',
        icon: <ErrorIcon />,
        description: 'Operazioni fallite',
        color: 'error'
    }
};


// Funzione per formattare i dettagli in base al tipo di azione
const formatActionDetails = (details, action) => {
    if (!details) return null;

    // Per le ricerche (FIND)
    if (action.includes('FIND')) {
        const queryParams = details.query ? Object.entries(details.query)
            .filter(([key]) => !key.startsWith('$'))
            .map(([key, value]) => `${key}: ${JSON.stringify(value)}`)
            .join(', ') : '';

        return `Ricerca su ${details.service} ${queryParams ? `(${queryParams})` : ''}`;
    }

    // Per gli errori
    if (action === 'ERROR_OCCURRED') {
        return `${details.errorMessage} in ${details.service}`;
    }

    // Per azioni di modifica
    if (details.updatedData) {
        return `Modificati campi: ${Object.keys(details.updatedData).join(', ')}`;
    }

    return `${details.method} su ${details.service}`;
};

const useFilteredActivities = (userId) => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(50);
    const [searchConfig, setSearchConfig] = useState({
        searchText: '',
        action: 'all',
        status: 'all',
        service: 'all',
    });
    const [debouncedSearchText, setDebouncedSearchText] = useState('');
    const notify = useNotify();

    // Debounce della ricerca testuale
    useEffect(() => {
        const handler = debounce(() => {
            setDebouncedSearchText(searchConfig.searchText);
        }, 400);

        handler();
        return () => handler.cancel();
    }, [searchConfig.searchText]);

    // Preparazione dei filtri per react-admin
    const filters = useMemo(() => {
        const baseFilters = { usersId: userId };

        if (searchConfig.action !== 'all') {
            baseFilters.action = searchConfig.action;
        }
        if (searchConfig.status !== 'all') {
            baseFilters.status = searchConfig.status;
        }
        if (searchConfig.service !== 'all') {
            baseFilters['details.service'] = searchConfig.service;
        }
        if (debouncedSearchText) {
            baseFilters.q = debouncedSearchText;
        }

        return baseFilters;
    }, [userId, searchConfig.action, searchConfig.status, searchConfig.service, debouncedSearchText]);

    const { data, total, isLoading, error } = useGetList('user-activities', {
        pagination: { page, perPage },
        sort: { field: 'createdAt', order: 'DESC' },
        filter: filters,
    });

    useEffect(() => {
        if (error) {
            notify('Errore nel caricamento delle attività', { type: 'error' });
        }
    }, [error, notify]);

    const resetFilters = () => {
        setSearchConfig({
            searchText: '',
            action: 'all',
            status: 'all',
            service: 'all',
        });
        setPage(1);
    };

    return {
        data,
        total,
        isLoading,
        page,
        perPage,
        setPage,
        setPerPage,
        searchConfig,
        setSearchConfig,
        resetFilters,
    };
};

// Componente per i filtri di ricerca
const SearchFilters = ({ searchConfig, onSearchChange, onReset, services = [] }) => {
    const hasActiveFilters = searchConfig.searchText ||
        searchConfig.action !== 'all' ||
        searchConfig.status !== 'all' ||
        searchConfig.service !== 'all';

    return (
        <Card sx={{ mb: 3, p: 2 }}>
            <Box display="flex" alignItems="center" mb={2}>
                <FilterListIcon sx={{ mr: 1 }} />
                <Typography variant="h6">Filtri di ricerca</Typography>
                {hasActiveFilters && (
                    <Button
                        startIcon={<ClearIcon />}
                        onClick={onReset}
                        sx={{ ml: 'auto' }}
                        color="error"
                    >
                        Reset filtri
                    </Button>
                )}
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <TextField
                        fullWidth
                        label="Cerca"
                        value={searchConfig.searchText}
                        onChange={(e) => onSearchChange({ searchText: e.target.value })}
                        InputProps={{
                            startAdornment: <SearchIcon sx={{ color: 'action.active', mr: 1 }} />,
                        }}
                        helperText="Cerca in qualsiasi campo inclusi i dettagli"
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                        <InputLabel>Tipo Azione</InputLabel>
                        <Select
                            value={searchConfig.action}
                            onChange={(e) => onSearchChange({ action: e.target.value })}
                            label="Tipo Azione"
                        >
                            <MenuItem value="all">Tutte le azioni</MenuItem>
                            {Object.entries(ACTION_TYPES).map(([key, { label, icon, color }]) => (
                                <MenuItem value={key} key={key}>
                                    <Box display="flex" alignItems="center">
                                        <Box sx={{ mr: 1, color: `${color}.main` }}>{icon}</Box>
                                        {label}
                                    </Box>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                {<Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                        <InputLabel>Servizio</InputLabel>
                        <Select
                            value={searchConfig.service}
                            onChange={(e) => onSearchChange({ service: e.target.value })}
                            label="Servizio"
                        >
                            <MenuItem value="all">Tutti i servizi</MenuItem>
                            {services.map(service => (
                                <MenuItem value={service} key={service}>{service}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>}

                <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                        <InputLabel>Status</InputLabel>
                        <Select
                            value={searchConfig.status}
                            onChange={(e) => onSearchChange({ status: e.target.value })}
                            label="Status"
                        >
                            <MenuItem value="all">Tutti gli status</MenuItem>
                            <MenuItem value="SUCCESS">
                                <Chip label="Success" size="small" color="success" sx={{ mr: 1 }} />
                            </MenuItem>
                            <MenuItem value="ERROR">
                                <Chip label="Error" size="small" color="error" sx={{ mr: 1 }} />
                            </MenuItem>
                            <MenuItem value="WARNING">
                                <Chip label="Warning" size="small" color="warning" sx={{ mr: 1 }} />
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            {hasActiveFilters && (
                <Box mt={2}>
                    <Typography variant="caption" color="textSecondary">
                        Filtri attivi: {[
                            searchConfig.searchText && `Ricerca: "${searchConfig.searchText}"`,
                            searchConfig.action !== 'all' && `Azione: ${ACTION_TYPES[searchConfig.action]?.label}`,
                            searchConfig.status !== 'all' && `Status: ${searchConfig.status}`,
                            searchConfig.service !== 'all' && `Servizio: ${searchConfig.service}`,
                        ].filter(Boolean).join(' | ')}
                    </Typography>
                </Box>
            )}
        </Card>
    );
};

// Componente Riga Tabella
const TableRowExpanded = ({ activity }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const getActionIcon = () => {
        if (activity.action.includes('FIND')) return <FindInPageIcon color="primary" />;
        if (activity.action.includes('CREATE')) return <AddCircleIcon color="success" />;
        if (activity.action.includes('UPDATE')) return <EditIcon color="primary" />;
        if (activity.action.includes('DELETE')) return <DeleteIcon color="error" />;
        if (activity.action.includes('ERROR')) return <ErrorIcon color="error" />;
        return <InfoIcon />;
    };

    return (
        <>
            <TableRow
                sx={{
                    backgroundColor: activity.status === 'ERROR' ? '#fff4f4' : 'inherit'
                }}
            >
                <TableCell>
                    <IconButton size="small" onClick={() => setIsExpanded(!isExpanded)}>
                        {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <DateField record={activity} source="createdAt" showTime />
                </TableCell>
                <TableCell>
                    <Box display="flex" alignItems="center" gap={1}>
                        {getActionIcon()}
                        <Chip
                            label={activity.action.replace('_', ' ')}
                            size="small"
                            color={activity.status === 'ERROR' ? 'error' : 'default'}
                        />
                    </Box>
                </TableCell>
                <TableCell>
                    <Typography variant="body2">
                        {formatActionDetails(activity.details, activity.action)}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Chip
                        label={activity.status}
                        size="small"
                        color={
                            activity.status === 'ERROR' ? 'error' :
                                activity.status === 'WARNING' ? 'warning' :
                                    'success'
                        }
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        <Box sx={{ p: 2 }}>
                            {activity.status === 'ERROR' && (
                                <Alert severity="error" sx={{ mb: 2 }}>
                                    {activity.details.errorMessage}
                                </Alert>
                            )}
                            <pre style={{
                                overflow: 'auto',
                                backgroundColor: '#f5f5f5',
                                padding: '8px',
                                borderRadius: '4px',
                                maxHeight: '400px'
                            }}>
                                {JSON.stringify(activity.details, null, 2)}
                            </pre>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

// Componente principale
const UserActivities = () => {
    const record = useRecordContext();
    const {
        data,
        total,
        isLoading,
        page,
        perPage,
        setPage,
        setPerPage,
        searchConfig,
        setSearchConfig,
        resetFilters,
    } = useFilteredActivities(record.id);

    const [listaServizi, setListaServizi] = useState([]);
    const jwt = localStorage.getItem("feathers-jwt");

    // Ottieni la lista dei servizi per i filtri
    // TODO: serve un endpoint per ottenere tutta la lista dei servizi.
    const { data: globalData } = useGetList('user-activities', {
        pagination: { page: 1, perPage: 10 },
    });



    useEffect(() => {
        const fetchListaServizi = async () => {
            const token = jwt;
            try {
                const response = await axios.get(
                    `${host}/lista-servizi/`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token
                        }
                    }
                );

                setListaServizi(response.data.services);

                console.log("listaServizi:", response.data.services);

            } catch (error) {
                console.error("Errore nella chiamata API di listaServizi:", error);
            } finally {

            }
        };

        fetchListaServizi();
    }, [jwt]);



    const services = useMemo(() => {
        if (!globalData) return [];
        const uniqueServices = new Set();
        Object.values(globalData).forEach(activity => {
            if (activity.details?.service) {
                uniqueServices.add(activity.details.service);
            }
        });
        return Array.from(uniqueServices).sort();
    }, [globalData]);

    if (isLoading) return (
        <Box display="flex" justifyContent="center" m={3}>
            <CircularProgress />
        </Box>
    );

    const handleSearchChange = (updates) => {
        setSearchConfig(prev => ({ ...prev, ...updates }));
        setPage(1); // Reset page when filters change
    };

    return (
        <Card>
            <CardContent>
                {/* <ActivityStats /> */}

                <SearchFilters
                    searchConfig={searchConfig}
                    onSearchChange={handleSearchChange}
                    onReset={resetFilters}
                    // services={services}
                    services={listaServizi}
                />

                <Box mt={2} display="flex" justifyContent="center">
                    <Pagination
                        page={page}
                        perPage={perPage}
                        total={total}
                        setPage={setPage}
                        setPerPage={setPerPage}
                    />
                </Box>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Data/Ora</TableCell>
                                <TableCell>Azione</TableCell>
                                <TableCell>Dettagli</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((activity) => (
                                <TableRowExpanded
                                    key={activity.id}
                                    activity={activity}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box mt={2} display="flex" justifyContent="center">
                    <Pagination
                        page={page}
                        perPage={perPage}
                        total={total}
                        setPage={setPage}
                        setPerPage={setPerPage}
                    />
                </Box>
            </CardContent>
        </Card>
    );
};

export default UserActivities;