
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    TopToolbar,
    ExportButton,
    EditButton,
    ShowButton,
    // FunctionField,
    ReferenceField,
    FunctionField,
    // WithRecord
} from 'react-admin';
import DettaglioButton from './DettaglioButton';

// Icone
import PeopleIcon from '@mui/icons-material/People';
import Button from '@mui/material/Button';

// Importa CSS
import { listCss } from '../shared/Styles/listStyles';
import { editButtonCss, saveButtonCss, showButtonCss, exportButtonCss, columnsButtonCss, deleteButtonCss, filterButtonCss } from '../shared/Styles/buttonStyles';
import { ToBoolean } from '../shared/ToBoolean';

const ListActions = () => (
    <TopToolbar>
        <ExportButton label="Esporta" sx={exportButtonCss} />
    </TopToolbar>
);

export const TariffaServpaxList = props => (
    <List
        title={<>
            <Button>
                <PeopleIcon></PeopleIcon>
                &nbsp;Lista delle Tariffe Servpax
            </Button>
        </>}
        sx={listCss}

        actions={<ListActions />}
        filterDefaultValues={{ id: { $ne: 0 } }}
        {...props}>
        <Datagrid bulkActionButtons={false} >
            <NumberField source="id" />
            <>
                <ReferenceField link={false} source='anagenId' reference='anagen' label='Fornitore' ></ReferenceField>

                <FunctionField
                    source="anagenId" // used for sorting
                    label=""
                    render={record => record && <DettaglioButton codice={record.anagenId} />}
                />
            </>
            <ReferenceField link={false} source='servpaxId' reference='servpax' label='Servizio' />
            <DateField source="dataFrom" />
            <DateField source="dataTo" />
            <TextField source="costo" />
            <TextField source="costo2" />
            <TextField source="costo3" />
            <ToBoolean label="Attivo" source="flAttivo" />
            <ToBoolean label="Visibile" source="fiVisibile" />
            <ToBoolean label="Annullato" source="flAnnullato" />
            {/* <NumberField source="flPredefinito" /> */}
            {/* <NumberField source="ordinamento" /> */}
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
            <EditButton sx={editButtonCss} label="Modifica" />
            <ShowButton sx={showButtonCss} label="Dettaglio" />
        </Datagrid>
    </List>
);
