import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FunctionField } from 'react-admin';

const CustomTextFieldDataOra = ({ source, label }) => {
    // Funzione per convertire la data ISO in formato locale
    const formatDateToLocal = (isoDateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false };
        const date = new Date(isoDateString);
        return date.toLocaleString('it-IT', options).replace(/\//g, '-');
    };

    return (
        <div>
            <Box display="flex" alignItems="center" >
                <FunctionField render={record => {
                    // Utilizzo della funzione per formattare la data
                    const formattedDate = formatDateToLocal(record[source]);
                    return (
                        <Typography variant="body1" style={{ marginRight: 8 }}><strong>{label}</strong> : {formattedDate}</Typography>
                    );

                }} />
            </Box>
        </div>
    );
};

export default CustomTextFieldDataOra;
