import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Box,
    List,
    ListItem,
    ListItemText,
    Button,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import { Title, usePermissions } from 'react-admin';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import feathersClient from '../../feathersClient';
import { Link as RouterLink } from 'react-router-dom';

function NotesPage() {
    const { permissions } = usePermissions();
    const [notes, setNotes] = useState([]);
    const [tipoRecords, setTipoRecords] = useState([]);
    const [selectedNotes, setSelectedNotes] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const fetchData = useCallback(async () => {
        try {
            const [notesResult, tipoRecordsResult] = await Promise.all([
                feathersClient.service('note').find({
                    query: {
                        $sort: { createdAt: -1 },
                        $limit: 100, // Limita a 100 note, modifica secondo necessità
                    },
                }),
                feathersClient.service('tiporecord').find({
                    query: {
                        $limit: 100, // Limita a 100 tiporecord, modifica secondo necessità
                        $select: ['id', 'descrizione', 'archivio'], // Assicura che 'descrizione' sia incluso
                    },
                }),
            ]);

            setNotes(notesResult.data);
            setTipoRecords(tipoRecordsResult.data);
            setLoading(false);
            setErrorMessage("");
            setError(false);
        } catch (err) {
            console.log('Error fetching data:', err.message);
            setLoading(false);
            setErrorMessage(err.message);
            setError(true);
        }
    }, []);

    useEffect(() => {
        fetchData();
        const intervalId = setInterval(fetchData, 30000); // Aggiorna ogni 30 secondi

        return () => clearInterval(intervalId);
    }, [fetchData]);

    const tipoRecordMap = useMemo(() => {
        const map = {};
        tipoRecords.forEach((tipoRecord) => {
            map[tipoRecord.id] = tipoRecord;
        });
        return map;
    }, [tipoRecords]);

    const handleMarkAsRead = async (noteIds) => {
        try {
            await Promise.all(
                noteIds.map((noteId) =>
                    feathersClient.service('note').patch(noteId, { nonLetta: 0 })
                )
            );
            // Aggiorna lo stato locale per riflettere il cambiamento
            setNotes((prevNotes) =>
                prevNotes.map((note) =>
                    noteIds.includes(note.id) ? { ...note, nonLetta: 0 } : note
                )
            );
            setSelectedNotes([]);
            setSelectAll(false);
        } catch (err) {
            console.log('Error marking notes as read:', err);
        }
    };

    const handleMarkAsUnread = async (noteIds) => {
        try {
            await Promise.all(
                noteIds.map((noteId) =>
                    feathersClient.service('note').patch(noteId, { nonLetta: 1 })
                )
            );
            // Aggiorna lo stato locale per riflettere il cambiamento
            setNotes((prevNotes) =>
                prevNotes.map((note) =>
                    noteIds.includes(note.id) ? { ...note, nonLetta: 1 } : note
                )
            );
            setSelectedNotes([]);
            setSelectAll(false);
        } catch (err) {
            console.log('Error marking notes as unread:', err);
        }
    };

    const handleSelectNote = (noteId) => {
        setSelectedNotes((prevSelected) =>
            prevSelected.includes(noteId)
                ? prevSelected.filter((id) => id !== noteId)
                : [...prevSelected, noteId]
        );
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedNotes([]);
        } else {
            setSelectedNotes(notes.map((note) => note.id));
        }
        setSelectAll(!selectAll);
    };

    // Calcola il numero di messaggi letti e non letti
    const unreadCount = notes.filter((note) => note.nonLetta).length;
    const readCount = notes.length - unreadCount;

    if (loading) {
        return (
            <Box sx={{ flexGrow: 1, p: 3 }}>
                <Typography variant="h5">Caricamento delle note...</Typography>
            </Box>
        );
    }

    if (error) {
        /* Box che mostra il messaggio ricevuto dal Backend - vedi hook in notes  */
        return (
            <Box sx={{ flexGrow: 1, p: 3 }}>
                <Typography variant="h5" color="error">
                    {errorMessage}
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>
            <Title title="Note" />
            {permissions === 'admin' && (
                <Card sx={{ mb: 3, backgroundColor: '#fff3f3' }}>
                    <CardContent
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <ErrorOutlineIcon sx={{ color: 'red', mr: 2 }} />
                        <Typography variant="h5" color="error">
                            GRUPPO ADMIN - Visualizzazione Note
                        </Typography>
                    </CardContent>
                </Card>
            )}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <Typography variant="h4" sx={{ color: '#01b5d0', flexGrow: 1 }}>
                    Elenco Note
                </Typography>
                <Typography variant="h6" sx={{ mr: 2 }}>
                    Non Letti: {unreadCount}
                </Typography>
                <Typography variant="h6">Letti: {readCount}</Typography>
            </Box>
            {/* Azioni di massa */}
            <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectAll}
                            onChange={handleSelectAll}
                            color="primary"
                        />
                    }
                    label="Seleziona Tutto"
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleMarkAsRead(selectedNotes)}
                    disabled={selectedNotes.length === 0}
                    sx={{ ml: 2 }}
                >
                    Segna come Letto
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleMarkAsUnread(selectedNotes)}
                    disabled={selectedNotes.length === 0}
                    sx={{ ml: 2 }}
                >
                    Segna come Non Letto
                </Button>
            </Box>
            <Card>
                <CardContent>
                    <List>
                        {notes.map((note) => {
                            const tiporecord = tipoRecordMap[note.tiporecordId];
                            return (
                                <ListItem
                                    key={note.id}
                                    alignItems="flex-start"
                                    sx={{
                                        backgroundColor: note.nonLetta ? '#e3f2fd' : 'inherit',
                                        mb: 1,
                                        borderRadius: 1,
                                    }}
                                >
                                    <Checkbox
                                        edge="start"
                                        onChange={() => handleSelectNote(note.id)}
                                        checked={selectedNotes.includes(note.id)}
                                        color="primary"
                                        sx={{ mt: 0.5 }}
                                    />
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    fontWeight: note.nonLetta ? 'bold' : 'normal',
                                                    wordBreak: 'break-word',
                                                }}
                                            >
                                                {note.descrizione || 'Nessuna descrizione'}
                                            </Typography>
                                        }
                                        secondary={
                                            <>
                                                {/* Aggiungi un Box per allineare "Archivio" e "ID" */}
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        mt: 1,
                                                    }}
                                                >
                                                    {tiporecord && tiporecord.descrizione && (
                                                        <Typography
                                                            variant="subtitle1"
                                                            color="primary"
                                                            sx={{ fontWeight: 'bold', mr: 2 }}
                                                        >
                                                            {`Archivio: ${tiporecord.descrizione}`}
                                                        </Typography>
                                                    )}
                                                    <Typography variant="body2" color="textSecondary">
                                                        {`ID: ${note.id}`}
                                                    </Typography>
                                                </Box>
                                                <Typography variant="body2" color="textSecondary">
                                                    {`Creato il: ${new Date(note.createdAt).toLocaleString()}`}
                                                </Typography>
                                                {note.descrizione2 && (
                                                    <Typography variant="body2" color="textSecondary">
                                                        {`Descrizione Aggiuntiva: ${note.descrizione2}`}
                                                    </Typography>
                                                )}
                                            </>
                                        }
                                        sx={{ maxWidth: '70%' }}
                                    />
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        {note.nonLetta ? (
                                            <Button
                                                variant="text"
                                                color="primary"
                                                onClick={() => handleMarkAsRead([note.id])}
                                            >
                                                Letto
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="text"
                                                color="primary"
                                                onClick={() => handleMarkAsUnread([note.id])}
                                            >
                                                Da leggere
                                            </Button>
                                        )}
                                        {/* Aggiungi il link "Vedi contesto Nota" */}
                                        {tiporecord && tiporecord.archivio && (
                                            <Button
                                                variant="text"
                                                color="primary"
                                                component={RouterLink}
                                                to={`/${tiporecord.archivio}/${note.recordId}/show/annotazioni`}
                                                sx={{ mt: 1 }}
                                            >
                                                Vedi contesto Nota
                                            </Button>
                                        )}
                                    </Box>
                                </ListItem>
                            );
                        })}
                    </List>
                </CardContent>
            </Card>
        </Box>
    );
}

export default NotesPage;
