import React, { useEffect, useState } from 'react';
import { Loading, useGetList } from 'react-admin';
import { Typography, ListItem, ListItemAvatar, ListItemText, Avatar } from '@mui/material';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';

const GetCostoUfficialeAutomezzo = ({ amtarga, onItemClick, automezzo }) => {
    const { data, isLoading, error } = useGetList(
        'automezzi-costi',
        {
            pagination: false,
            filter: {
                amtarga: amtarga,
            }
        }
    );

    const [prezzoVenditaEff, setPrezzoVenditaEff] = useState(null);

    useEffect(() => {
        if (data && data.length > 0) {
            setPrezzoVenditaEff(data[0].prezzoVenditaEff);
        }
    }, [data]);

    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    console.log(data);

    return (
        <ListItem button onClick={() => onItemClick(automezzo, prezzoVenditaEff)}>
            <ListItemAvatar>
                <Avatar>
                    <DirectionsBusIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={automezzo.amtarga} secondary={prezzoVenditaEff ? `Prezzo Vendita Effettivo: €${parseFloat(prezzoVenditaEff).toFixed(2)}` : 'Nessun costo ufficiale presente'} />
        </ListItem>
    );
};

export default GetCostoUfficialeAutomezzo;
