import feathers from 'feathers-client';
import config from './config/config.json';

const host = config.production.host;

const feathersClient = feathers()
    .configure(feathers.hooks())
    .configure(feathers.rest(host).fetch(window.fetch.bind(window)))
    .configure(feathers.authentication({ jwtStrategy: 'jwt', storage: window.localStorage }));

console.log('Feathers client configured');

feathersClient.authenticate()
    .then(() => console.log('Client authenticated'))
    .catch(error => console.error('Error authenticate : Authentication error:', error));

export default feathersClient;