import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useGetList } from 'react-admin';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(3),
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        minWidth: 120,
        width: '100%',
    },
}));

const AddressSelectionComponent = ({ onStartPointChange, onEndPointChange, selectedPacchetto }) => {
    const classes = useStyles();
    const [startPoint, setStartPoint] = useState('');
    const [endPoint, setEndPoint] = useState('');
    const [partenze, setPartenze] = useState([]);
    const [arrivi, setArrivi] = useState([]);

    // Recupera i dati da pacchetti-partenze
    const { data: partenzeData, loading: partenzeLoading } = useGetList(
        'pacchetti-partenze',
        {
            pagination: { page: 1, perPage: 100 },
            filter: {
                flAttivo: 1,
                pacchettiId: selectedPacchetto
            }
        }
    );

    // Recupera i dati da pacchetti-arrivi
    const { data: arriviData, loading: arriviLoading } = useGetList(
        'pacchetti-arrivi',
        {
            pagination: { page: 1, perPage: 100 },
            filter: { pacchettiId: selectedPacchetto }
        }
    );

    useEffect(() => {
        if (!partenzeLoading && partenzeData) {
            setPartenze(partenzeData.map(item => item.nome));
        }
    }, [partenzeData, partenzeLoading]);

    useEffect(() => {
        if (!arriviLoading && arriviData) {
            setArrivi(arriviData.map(item => item.nome));
        }
    }, [arriviData, arriviLoading]);

    const handleStartPointChange = (event) => {
        const selectedName = event.target.value;
        setStartPoint(selectedName);
        onStartPointChange(selectedName);
    };

    const handleEndPointChange = (event) => {
        const selectedName = event.target.value;
        setEndPoint(selectedName);
        onEndPointChange(selectedName);
    };

    if (partenzeLoading || arriviLoading) {
        return <Typography>Caricamento in corso...</Typography>;
    }

    return (
        <div className={classes.root}>
            <Typography variant="h6" className={classes.title}>
                Seleziona punti di partenza e arrivo - ID Pacchetto: {selectedPacchetto}
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="start-point-label">Punto di partenza</InputLabel>
                        <Select
                            labelId="start-point-label"
                            value={startPoint}
                            onChange={handleStartPointChange}
                            label="Punto di partenza"
                        >
                            <MenuItem value="">
                                <em>Seleziona un punto di partenza</em>
                            </MenuItem>
                            {partenze.map((nome) => (
                                <MenuItem key={nome} value={nome}>
                                    {nome}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="end-point-label">Punto di arrivo</InputLabel>
                        <Select
                            labelId="end-point-label"
                            value={endPoint}
                            onChange={handleEndPointChange}
                            label="Punto di arrivo"
                        >
                            <MenuItem value="">
                                <em>Seleziona un punto di arrivo</em>
                            </MenuItem>
                            {arrivi.map((nome) => (
                                <MenuItem key={nome} value={nome}>
                                    {nome}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
};

export default AddressSelectionComponent;