import React from 'react';
import { Typography, Grid } from '@mui/material';

const SelectedTariffInfo = ({ tariff }) => {
    if (!tariff) return null;

    return (
        <Grid container spacing={4} alignItems="center">
            <Grid item xs={2}>
                <Typography variant="subtitle2" color="textSecondary">ID</Typography>
                <Typography variant="subtitle1" fontWeight="500">{tariff.id}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="subtitle2" color="textSecondary">Descrizione</Typography>
                <Typography variant="subtitle1" fontWeight="500">{tariff.tfa_des}</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="subtitle2" color="textSecondary">Budget KM</Typography>
                <Typography variant="subtitle1" fontWeight="500">{tariff.tfa_bdgkm}</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="subtitle2" color="textSecondary">Budget Ore</Typography>
                <Typography variant="subtitle1" fontWeight="500">{tariff.tfa_bdghh}</Typography>
            </Grid>
            <Grid item xs={2}>

            </Grid>
        </Grid>
    );
};

export default SelectedTariffInfo;