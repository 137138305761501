import { defaultTheme } from "react-admin";
import createMuiTheme from "@material-ui/core/styles/createMuiStrictModeTheme";
import merge from "lodash/merge";

export const myTheme = createMuiTheme(
  merge({}, defaultTheme, {
    palette: {
      /* primary: {
        main: '#01b5d0', // Colore principale, ad esempio è usato in automato da bottoni come EditButton
      }, */
      secondary: {
        main: "#01b5d0", // Colore principale
      },
      // Nota: verifica il valore di "width" poiché 11140 potrebbe essere un errore
      RaSidebar: {
        sidebar: {
          width: 11140,
          closedWidth: 50,
        },
        fixed: {
          position: 'inherit'
        }
      },
    },
  })
);