import React, { useState } from 'react';
import { useDelete, Confirm, useNotify } from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { deleteButtonCss,/*  cancelButtonCss, saveButtonCss */ } from '../shared/Styles/buttonStyles';

const CustomDeleteContatto = ({ recordId, record }) => {
    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const [deleteOne, { isLoading, error }] = useDelete();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        deleteOne(
            'anagen-contatti',
            { id: recordId, previousData: record },
            {
                onSuccess: () => {
                    notify('Contatto eliminato con successo', 'info');
                    setOpen(false);
                },
                onFailure: (error) => {
                    notify(`Errore durante l'eliminazione: ${error.message}`, 'warning');
                    setOpen(false);
                }
            }
        );
    };

    return (
        <>
            <Button
                sx={deleteButtonCss}
                startIcon={<DeleteIcon />}
                onClick={handleClickOpen}
                disabled={isLoading}
                color="error"
            >
                Cancella
            </Button>
            <Confirm
                isOpen={open}
                loading={isLoading}
                title="Conferma Eliminazione"
                content="Sei sicuro di voler eliminare questo contatto?"
                onConfirm={handleDelete}
                onClose={handleClose}
                confirm="Sì"
                cancel="No"
            />
            {error && <p>ERROR</p>} {/* Gestione dell'errore al di fuori del dialogo di conferma */}
        </>
    );
};

export default CustomDeleteContatto;
