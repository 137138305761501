// EditRouteDialog.js
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Alert,
    CircularProgress,
    Box,
    Typography,
    Card,
    CardContent,
    Divider,
    Button as MuiButton,
    TextField,
    Autocomplete
} from '@mui/material';
import {
    AccessTime as AccessTimeIcon,
    Edit as EditIcon
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import axios from 'axios';
import { debounce } from 'lodash';
import config from '../../config/config.json';

const host = config.production.host;

// Componente per l'autocompletamento degli indirizzi
const AddressAutocomplete = ({ value, onChange, label, placeholder, error }) => {
    const [options, setOptions] = useState([]);
    const jwt = localStorage.getItem("feathers-jwt");

    const fetchSuggestions = React.useCallback(
        debounce(async (input) => {
            if (input.length < 3) {
                setOptions([]);
                return;
            }

            try {
                const response = await axios.get(
                    `${host}/google-maps-proxy?path=/place/autocomplete/json&input=${encodeURIComponent(input)}&types=geocode`,
                    {
                        headers: { 'Authorization': `Bearer ${jwt}` }
                    }
                );
                setOptions(response.data.predictions);
            } catch (error) {
                console.error('Error fetching suggestions:', error);
                setOptions([]);
            }
        }, 300),
        [jwt]
    );

    return (
        <Autocomplete
            freeSolo
            options={options}
            getOptionLabel={(option) => option.description || ''}
            filterOptions={(x) => x}
            onInputChange={(event, newInputValue) => {
                onChange(newInputValue);
                fetchSuggestions(newInputValue);
            }}
            inputValue={value}
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    onChange(newValue);
                } else if (newValue && newValue.description) {
                    onChange(newValue.description);
                } else {
                    onChange('');
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    placeholder={placeholder}
                    variant="outlined"
                    error={!!error}
                    helperText={error}
                    fullWidth
                />
            )}
        />
    );
};

const EditRouteDialog = ({ open, onClose, routeData, booking0Id, booking0_automezziId, bk0_idnumber }) => {
    const [origin, setOrigin] = useState('');
    const [destination, setDestination] = useState('');
    const [loading, setLoading] = useState(false);
    const [calculatedRouteData, setCalculatedRouteData] = useState(null);
    const [errors, setErrors] = useState({});
    const jwt = localStorage.getItem("feathers-jwt");
    const refresh = useRefresh();
    const notify = useNotify();

    // Aggiorna origin e destination quando routeData cambia
    useEffect(() => {
        if (routeData) {
            setOrigin(routeData.bk2_partenza || '');
            setDestination(routeData.bk2_arrivo || '');
            setCalculatedRouteData(null); // Azzeriamo i dettagli del percorso
        }
    }, [routeData]);

    // Validazione degli input
    const validateInputs = () => {
        const newErrors = {};
        if (!origin) newErrors.origin = 'Origine richiesta';
        if (!destination) newErrors.destination = 'Destinazione richiesta';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Calcolo del percorso
    const calculateRoute = async () => {
        if (!validateInputs()) return;

        setLoading(true);
        setErrors({});

        try {
            console.log('Calculating route for:', { origin, destination });

            const response = await axios.get(
                `${host}/google-maps-proxy?path=/directions/json&origin=${encodeURIComponent(origin)}&destination=${encodeURIComponent(destination)}&mode=driving&language=it`,
                {
                    headers: { 'Authorization': `Bearer ${jwt}` }
                }
            );

            const routeDetails = response.data.routes[0].legs[0];
            const newRouteData = {
                distance: routeDetails.distance,
                duration: routeDetails.duration,
                startLocation: routeDetails.start_location,
                endLocation: routeDetails.end_location,
                startAddress: routeDetails.start_address,
                endAddress: routeDetails.end_address
            };

            console.log('Route calculated:', newRouteData);
            setCalculatedRouteData(newRouteData);
        } catch (error) {
            console.error('Error calculating route:', error);
            setErrors({
                calculation: 'Errore nel calcolo del percorso. Verifica gli indirizzi e riprova.'
            });
            notify('Errore nel calcolo del percorso', { type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    // Salvataggio della tratta modificata
    const saveRoute = async () => {
        console.log('Starting saveRoute with data:', {
            calculatedRouteData,
            booking0Id,
            booking0_automezziId,
            bk0_idnumber
        });

        if (!calculatedRouteData) {
            notify('Devi calcolare il percorso prima di salvare', { type: 'warning' });
            return;
        }

        // Verifica che tutti i dati necessari siano presenti e nel formato corretto
        const validatedBooking0Id = Number(booking0Id);
        const validatedAutomezziId = Number(booking0_automezziId);
        const validatedIdNumber = Number(bk0_idnumber);

        if (isNaN(validatedBooking0Id) || isNaN(validatedAutomezziId) || isNaN(validatedIdNumber)) {
            console.error('Data validation failed:', {
                booking0Id: validatedBooking0Id,
                booking0_automezziId: validatedAutomezziId,
                bk0_idnumber: validatedIdNumber
            });
            notify('Dati mancanti o non validi per il salvataggio', { type: 'error' });
            return;
        }

        setLoading(true);
        try {
            const distanza = parseFloat(calculatedRouteData.distance.text.replace(',', '.'));
            const tempoInSecondi = parseInt(calculatedRouteData.duration.value);

            const hours = Math.floor(tempoInSecondi / 3600);
            const minutes = Math.floor((tempoInSecondi % 3600) / 60);
            const seconds = tempoInSecondi % 60;

            const urlGooglemaps = `http://www.google.com/maps?f=d&source=s_d&saddr=${calculatedRouteData.startLocation.lat},${calculatedRouteData.startLocation.lng}&daddr=${calculatedRouteData.endLocation.lat},${calculatedRouteData.endLocation.lng}&hl=it&sensor=false&language=it`;

            const updatedData = {
                booking0Id: validatedBooking0Id,
                booking0_automezziId: validatedAutomezziId,
                bk2_partenza: calculatedRouteData.startAddress,
                bk2_arrivo: calculatedRouteData.endAddress,
                bk2_pkmt: distanza,
                bk2_ore: String(hours).padStart(2, '0'),
                bk2_minuti: String(minutes).padStart(2, '0'),
                bk2_secondi: String(seconds).padStart(2, '0'),
                bk2_tempoInSecondi: tempoInSecondi,
                bk2_purl: urlGooglemaps,
                flAttivo: 1,
                flVisibile: 1,
                flAnnullato: 0
            };

            console.log('Updating route with data:', updatedData);

            const updateResponse = await axios.patch(
                `${host}/booking-2/${routeData.id}`,
                updatedData,
                {
                    headers: {
                        'Authorization': `Bearer ${jwt}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            console.log('Update response in booking-2:', updateResponse.data);
            notify('Tratta modificata con successo', { type: 'success' });
            refresh();
            handleClose();
        } catch (error) {
            console.error('Error updating route:', error.response || error);
            setErrors({
                saving: `Errore durante il salvataggio della tratta: ${error.response?.data?.message || error.message}`
            });
            notify('Errore nel salvataggio della tratta', { type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    // Chiusura e reset del dialog
    const handleClose = () => {
        setOrigin('');
        setDestination('');
        setCalculatedRouteData(null);
        setErrors({});
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    maxHeight: '90vh',
                    overflowY: 'auto'
                }
            }}
        >
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <EditIcon sx={{ mr: 1 }} />
                    Modifica Tratta
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                    <AddressAutocomplete
                        value={origin}
                        onChange={setOrigin}
                        label="Origine"
                        placeholder="Inserisci indirizzo di partenza"
                        error={errors.origin}
                    />
                    <AddressAutocomplete
                        value={destination}
                        onChange={setDestination}
                        label="Destinazione"
                        placeholder="Inserisci indirizzo di arrivo"
                        error={errors.destination}
                    />

                    {Object.entries(errors).map(([key, error]) => (
                        error && (
                            <Alert key={key} severity="error" sx={{ mt: 2 }}>
                                {error}
                            </Alert>
                        )
                    ))}

                    {calculatedRouteData && (
                        <Card sx={{ mt: 2 }}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    <AccessTimeIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                                    Dettagli Percorso
                                </Typography>
                                <Divider sx={{ my: 1 }} />
                                <Box sx={{ display: 'grid', gap: 1 }}>
                                    <Typography>
                                        <strong>Distanza:</strong> {calculatedRouteData.distance.text}
                                    </Typography>
                                    <Typography>
                                        <strong>Durata stimata:</strong> {calculatedRouteData.duration.text}
                                    </Typography>
                                    <Divider sx={{ my: 1 }} />
                                    <Typography>
                                        <strong>Partenza:</strong> {calculatedRouteData.startAddress}
                                    </Typography>
                                    <Typography>
                                        <strong>Arrivo:</strong> {calculatedRouteData.endAddress}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    )}
                </Box>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <MuiButton
                    onClick={handleClose}
                    disabled={loading}
                    color="inherit"
                >
                    Annulla
                </MuiButton>
                <MuiButton
                    onClick={calculatedRouteData ? saveRoute : calculateRoute}
                    disabled={loading || (!origin || !destination)}
                    variant="contained"
                    color="primary"
                    startIcon={loading ? <CircularProgress size={20} /> : null}
                >
                    {loading ? 'Elaborazione...' : calculatedRouteData ? 'Salva Modifiche' : 'Ricalcola Percorso'}
                </MuiButton>
            </DialogActions>
        </Dialog>
    );
};

EditRouteDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    routeData: PropTypes.object.isRequired,
    booking0Id: PropTypes.number.isRequired,
    booking0_automezziId: PropTypes.number,
    bk0_idnumber: PropTypes.number.isRequired,
};

export default EditRouteDialog;
