import React, { useState } from 'react';
import { Loading, useGetList } from 'react-admin';
import { Typography, Box, List, ListItem, ListItemAvatar, ListItemText, Avatar, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import GetCostoUfficialeAutomezzo from './GetCostoUfficialeAutomezzo';

const GetListAutomezzi = ({ amtgruppo, onUpdateTfbCkm }) => {
    const { data, isLoading, error } = useGetList(
        'automezzi',
        {
            pagination: false,
            filter: {
                amtgruppo: amtgruppo,
            }
        }
    );

    const [selectedMezzo, setSelectedMezzo] = useState(null);

    const handleItemClick = (automezzo, prezzoVenditaEff) => {
        setSelectedMezzo({
            amtarga: automezzo.amtarga,
            prezzoVenditaEff: parseFloat(prezzoVenditaEff).toFixed(2)
        });
    };

    const handleClose = () => {
        if (selectedMezzo) {
            onUpdateTfbCkm(selectedMezzo.prezzoVenditaEff);
        }
        setSelectedMezzo(null);
    };

    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    // console.log(data);
    return (
        <Box sx={{ width: '100%', maxHeight: '100%', overflowY: 'auto', padding: 2, scrollbarWidth: 'thin', scrollbarColor: 'cyan transparent' }}>
            <style>
                {`
                    ::-webkit-scrollbar {
                        width: 12px;
                    }

                    ::-webkit-scrollbar-track {
                        background: transparent;
                    }

                    ::-webkit-scrollbar-thumb {
                        background-color: cyan;
                        border-radius: 20px;
                        border: 3px solid transparent;
                    }
                `}
            </style>
            <Typography variant="h6">Lista Automezzi</Typography>
            <List>
                {data.map((automezzo) => (
                    <GetCostoUfficialeAutomezzo key={automezzo.id} amtarga={automezzo.amtarga} onItemClick={handleItemClick} automezzo={automezzo} />
                ))}
            </List>

            <Dialog open={selectedMezzo !== null} onClose={handleClose}>
                <DialogTitle>Dettagli Automezzo Selezionato</DialogTitle>
                <DialogContent>
                    {selectedMezzo && (
                        <>
                            <Typography>
                                Hai selezionato il Mezzo con Targa: {selectedMezzo.amtarga}
                            </Typography>
                            <Typography>
                                Prezzo Vendita Effettivo: €{selectedMezzo.prezzoVenditaEff}
                            </Typography>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Ok</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default GetListAutomezzi;
