import * as React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    required,
    ShowButton,
    TopToolbar,
    SaveButton,
    Toolbar,
    BooleanInput,
    NumberInput,
    useRedirect,
} from 'react-admin';
import { styled } from '@mui/material/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button as MuiButton, Divider } from '@mui/material';
import { useState, } from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

// Importo il css per i Bottoni
import { saveButtonCss, showButtonCss } from '../shared/Styles/buttonStyles';
import { formCss } from "../shared/Styles/formCss";

/* STILI PER LA DIALOG DEL CREATEUSERVER */
// Stili personalizzati per la DialogTitle
const StyledDialogTitle = styled(DialogTitle)(({ theme, iserror }) => ({
    backgroundColor: iserror === 'true' ? theme.palette.error.main : theme.palette.success.main,
    color: theme.palette.common.white,
}));

// Stili personalizzati per la DialogContent
const StyledDialogContent = styled(DialogContent)(({ theme, iserror }) => ({
    backgroundColor: iserror === 'true' ? theme.palette.error.light : theme.palette.success.light,
    color: iserror === 'true' ? theme.palette.error.contrastText : theme.palette.success.contrastText,
}));

/* CSS - BASE - FORM - START */
const styles = {

    TextInput: {
        // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        // background: 'linear-gradient(45deg, #01b5d0 10%, #ffffff 90%)',
        border: 0,
        borderRadius: 1,
        // boxShadow: '0px 0px 3px 2px #01b5d0',
        color: 'green',
        height: 50,
        padding: '0 0px',
    },
};


const useStyles = makeStyles(styles);
/* CSS - BASE - FORM - END */


const listFiltersAnagen = {
    // title: textFilter(),
    'idx': {
        input: ({ source }) => <NumberInput source="id" />,
        operators: [
            { value: 'eq', label: 'Uguale' },
            { value: 'neq', label: 'Maggiore' },
        ],
    },
    'ragione sociale': {
        input: ({ source }) => <TextInput label="La ragione sociale" source={source} />,
        operators: [
            { value: 'eq', label: 'Uguale' },
            { value: 'in', label: 'Include' },
        ],
    },
};

/* CODICE PER LA GESTIONE DELLA VALIDAZIONE - inizio */

const requiredField = [required('Questo campo è obbligatorio')];

// Funzione di validazione per email
const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value) ? undefined : 'Email non valida';
};

// Funzione di validazione per la Provincia di nascita
const validateProvince = (value) => {
    const provinceRegex = /^[A-Z]{2}$/i; // Due lettere, case insensitive
    return provinceRegex.test(value) ? undefined : 'Provincia non valida, servono due lettere';
};

// Funzione di validazione per la Nazione di nascita
const validateNation = (value) => {
    const nationRegex = /^[A-Z]{2}$/i; // Due lettere, case insensitive
    return nationRegex.test(value) ? undefined : 'Nazione non valida, servono due lettere';
};


// Funzione di validazione per codice fiscale
const validateCodiceFiscale = (value) => {
    const cfRegex = /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/i;
    return cfRegex.test(value) ? undefined : 'Codice Fiscale non valido';
};

// Funzione di validazione per il numero di telefono
const validatePhoneNumber = (value) => {
    const phoneRegex = /^[0-9]+$/;
    const minLength = 8;
    const maxLength = 15;
    if (!phoneRegex.test(value)) {
        return 'Il numero di telefono deve contenere solo cifre';
    }
    if (value.length < minLength || value.length > maxLength) {
        return `Il numero di telefono deve essere tra ${minLength} e ${maxLength} cifre`;
    }
    return undefined;
};

// Validazione
const validateOperatoreCreation = (values) => {
    const errors = {};
    if (!values.nome) {
        errors.nome = 'Il nome è necessario';
    }
    if (!values.cognome) {
        errors.cognome = 'Il cognome è necessario';
    }
    return errors
};

const UserSaveButton = () => {
    const redirect = useRedirect();
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [isError, setIsError] = useState(false);

    const handleCloseDialog = () => {
        setOpenDialog(false);
        if (!isError) {
            redirect('/anagen');
        }
    };

    const onSuccess = (data) => {
        // console.log("Success callback triggered");
        setDialogContent(`Utente "${data.gnpfnome} ${data.gnpfcognome}" creato con successo!`);
        setIsError(false);
        setOpenDialog(true);
    };

    const onError = (error) => {
        let errorMessage = "Si è verificato un errore durante la creazione dell'utente. Motivo: " + error.message;
        if (error.errors && error.errors.length > 0) {
            const firstError = error.errors[0];
            errorMessage = `Non è stato possibile creare l'utente. Motivo dal ${firstError.origin}: ${firstError.message} per ${firstError.value}`;
        }

        setDialogContent(errorMessage);
        setIsError(true);
        setOpenDialog(true);
    };

    return (
        <>
            <SaveButton
                label="Crea nuovo utente"
                sx={saveButtonCss}
                type="button"
                mutationOptions={{ onSuccess, onError }}
            />
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <StyledDialogTitle iserror={isError.toString()}>
                    {isError ? 'Errore' : 'Successo'}
                </StyledDialogTitle>
                <StyledDialogContent iserror={isError.toString()}>
                    {dialogContent}
                </StyledDialogContent>
                <DialogActions>
                    <MuiButton onClick={handleCloseDialog} color={isError ? "error" : "success"}>
                        Chiudi
                    </MuiButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

/* CODICE PER LA GESTIONE DELLA VALIDAZIONE - fine */

const ShowTopToolbarDettaglio = () => (
    <TopToolbar>
        <ShowButton sx={showButtonCss} label="Dettaglio" />
    </TopToolbar>
);

const ShowActionsForEdit = () => (
    <ShowTopToolbarDettaglio></ShowTopToolbarDettaglio>
);

const MyToolbarEdit = () => (
    <Toolbar>
        <SaveButton sx={saveButtonCss}
            label="Salva parametri" alwaysEnable />
    </Toolbar>
);

export const AnagenEdit = ({ id, ...props }) => {

    const classes = useStyles();

    return (
        <Edit actions={<ShowActionsForEdit />} title="Modifica anagrafica cliente" /* title={<AnagenTitle />} name={<AnagenName />} */ {...props}>
            <SimpleForm sx={formCss} toolbar={<MyToolbarEdit />}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 2, width: '30ch' },
                    }}
                >
                    <div>
                        <Box display="flex" alignItems="center" >
                            <Typography variant="body1" style={{ marginRight: 8 }}>Id: </Typography>
                            {<TextInput nolabel className={classes.TextInput} variant="outlined" label="Anagen Id" source="id" validate={[required()]}
                                color="success"
                                InputProps={{
                                    readOnly: true,
                                }} />}

                            <Typography variant="body1" style={{ marginRight: 8 }}>Ricerca nome: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Ricerca" source="gnfind" validate={[required()]} />}
                        </Box>

                    </div>
                    <Divider variant="middle" />
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Nome: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Nome" source="gnpfnome" validate={[required()]} />}

                            <Typography variant="body1" style={{ marginRight: 8 }}>Cognome: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Cognome" source="gnragnom" validate={[required()]} />}
                        </Box>
                    </div>
                    <Divider variant="middle" />
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Partita Iva: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="P.Iva" source="gncdiva" validate={[required()]} />}

                            <Typography variant="body1" style={{ marginRight: 8 }}>Codice Fiscale: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Codice fiscale" source="gncdfisc" validate={[required()]} />}
                        </Box>
                    </div>
                    <Divider variant="middle" />
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Ragione sociale: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Rag.Soc." source="gnragnom" validate={[required()]} placeholder="Nome" />}
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Rag.Soc2" source="gnrag2" />}
                        </Box>
                    </div>
                    <Divider variant="middle" />
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Data di nascita: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Data di nascita" source="gnpfdtna" placeholder="esempio@dominio.com" />}
                            <Typography variant="body1" style={{ marginRight: 8 }}>Comune di nascita: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Comune di nascita" source="gnpfcuna" placeholder="http://..." />}
                        </Box>
                    </div>
                    <Divider variant="middle" />
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Indirizzo: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Indirizzo" source="gnind" placeholder="esempio@dominio.com" />}
                            <Typography variant="body1" style={{ marginRight: 8 }}>CAP: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="CAP" source="gncap" placeholder="http://..." />}
                        </Box>
                    </div>
                    <Divider variant="middle" />
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Comune: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Comune" source="gncomune" placeholder="esempio@dominio.com" />}
                            <Typography variant="body1" style={{ marginRight: 8 }}>Frazione: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Frazione" source="gnfrazione" placeholder="http://..." />}
                        </Box>
                    </div>
                    <Divider variant="middle" />
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Provincia: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Provincia" source="gnprov" placeholder="" />}
                            <Typography variant="body1" style={{ marginRight: 8 }}>Nazione: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Nazione" source="gnnaz" placeholder="" />}
                        </Box>
                    </div>
                    <Divider variant="middle" />
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Provincia di nascita: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Provincia di nascita" source="gnpfprna" placeholder="" />}
                            <Typography variant="body1" style={{ marginRight: 8 }}>Nazione di nascita: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Nazione di nascita" source="gnpfnzna" placeholder="" />}
                        </Box>
                    </div>
                    <Divider variant="middle" />
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Email: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Email" source="gnemail" placeholder="esempio@dominio.com" />}
                            <Typography variant="body1" style={{ marginRight: 8 }}>Website: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Url Website" source="gnhomep" placeholder="http://..." />}
                        </Box>
                    </div>
                    <Divider variant="middle" />
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Telefono 1: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Telefono" source="gntel" placeholder="" />}
                            <Typography variant="body1" style={{ marginRight: 8 }}>Telefono 2: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Cellulare" source="gncel" placeholder="" />}
                            <Typography variant="body1" style={{ marginRight: 8 }}>Fax: </Typography>
                            {<TextInput color="success" className={classes.TextInput} variant="outlined" label="Fax" source="gnfax" placeholder="" />}
                        </Box>
                    </div>
                    <Divider variant="middle" />
                    <div>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: 8 }}>Attivo: </Typography>
                            {<BooleanInput color="success" className={classes.TextInput} variant="outlined" label="Att" source="flAttivo" />}
                            <Typography variant="body1" style={{ marginRight: 8 }}>Visibile: </Typography>
                            {<BooleanInput color="success" className={classes.TextInput} variant="outlined" label="Vis" source="fiVisibile" />}
                            <Typography variant="body1" style={{ marginRight: 8 }}>Annullato: </Typography>
                            {<BooleanInput color="success" className={classes.TextInput} variant="outlined" label="Ann" source="flAnnullato" />}
                        </Box>
                    </div>

                </Box>
            </SimpleForm>
        </Edit>
    );
}
