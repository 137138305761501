
import { pacchettiList } from './pacchettiList';
import pacchettiCreate from './pacchettiCreate';
import pacchettiEdit from './pacchettiEdit';
import { pacchettiShow } from './pacchettiShow';

const pacchetti = {
    list: pacchettiList,
    create: pacchettiCreate,
    edit: pacchettiEdit,
    show: pacchettiShow,
};

export default pacchetti;
