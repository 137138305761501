
import { Create, SimpleForm, TextInput, DateInput, NumberInput } from 'react-admin';

export const ServpaxCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="descrizione" />
            <TextInput source="note" />
            <NumberInput source="flAttivo" />
            <NumberInput source="flVisibile" />
            <NumberInput source="flAnnullato" />
            <NumberInput source="ordinamento" />
            <DateInput source="createdAt" />
            <DateInput source="updatedAt" />
        </SimpleForm>
    </Create>
);
