// EditDatesDialog.js
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useUpdate, useNotify, useRefresh } from 'react-admin';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    TextField,
    Typography,
    Alert
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import { EventNote as EventNoteIcon } from '@mui/icons-material';

export const EditDatesDialog = ({ open, onClose, record }) => {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        bk0_datada: '',
        bk0_dataal: ''
    });
    const [error, setError] = useState('');
    const [update] = useUpdate();
    const notify = useNotify();
    const refresh = useRefresh();

    useEffect(() => {
        if (record) {
            setFormValues({
                bk0_datada: formatDateForInput(record.bk0_datada),
                bk0_dataal: formatDateForInput(record.bk0_dataal)
            });
        }
    }, [record]);

    function formatDateForInput(dateString) {
        if (!dateString) return '';
        try {
            const date = new Date(dateString);
            if (isNaN(date.getTime())) return ''; // Invalid date
            return date.toISOString().slice(0, 16); // Format: YYYY-MM-DDTHH:mm
        } catch (error) {
            console.error('Date formatting error:', error);
            return '';
        }
    }

    function formatDateForSave(dateString) {
        if (!dateString) return null;
        try {
            const date = new Date(dateString);
            if (isNaN(date.getTime())) return null;
            return date.toISOString();
        } catch (error) {
            console.error('Date save formatting error:', error);
            return null;
        }
    }

    const validateDates = () => {
        if (!formValues.bk0_datada || !formValues.bk0_dataal) {
            setError('Entrambe le date sono obbligatorie');
            return false;
        }

        const startDate = new Date(formValues.bk0_datada);
        const endDate = new Date(formValues.bk0_dataal);

        if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
            setError('Date non valide');
            return false;
        }

        if (startDate > endDate) {
            setError('La data di inizio deve essere precedente alla data di fine');
            return false;
        }

        setError('');
        return true;
    };

    const handleSubmit = async () => {
        if (!validateDates()) {
            notify(error, { type: 'warning' });
            return;
        }

        setLoading(true);
        try {
            await update(
                'booking-0',
                {
                    id: record.id,
                    data: {
                        bk0_datada: formatDateForSave(formValues.bk0_datada),
                        bk0_dataal: formatDateForSave(formValues.bk0_dataal)
                    },
                }
            );
            notify('Date aggiornate con successo', { type: 'success' });
            refresh();
            onClose();
        } catch (error) {
            console.error('Error updating dates:', error);
            notify('Errore durante l\'aggiornamento delle date', { type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (field) => (event) => {
        setFormValues(prev => ({
            ...prev,
            [field]: event.target.value
        }));
        setError(''); // Clear error when user makes changes
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: { maxHeight: '90vh', overflowY: 'auto' }
            }}
        >
            <DialogTitle sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                backgroundColor: '#f5f5f5',
                borderBottom: '1px solid #ddd'
            }}>
                <EventNoteIcon color="primary" />
                Modifica Date Servizio
            </DialogTitle>
            <DialogContent sx={{ pt: 2 }}>
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2" gutterBottom>
                            Data e Ora Inizio
                        </Typography>
                        <TextField
                            type="datetime-local"
                            value={formValues.bk0_datada}
                            onChange={handleChange('bk0_datada')}
                            fullWidth
                            required
                            error={!!error && !formValues.bk0_datada}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                                step: 300 // 5 minutes step
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2" gutterBottom>
                            Data e Ora Fine
                        </Typography>
                        <TextField
                            type="datetime-local"
                            value={formValues.bk0_dataal}
                            onChange={handleChange('bk0_dataal')}
                            fullWidth
                            required
                            error={!!error && !formValues.bk0_dataal}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                                step: 300, // 5 minutes step
                                min: formValues.bk0_datada // Non permette date precedenti alla data di inizio
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{
                padding: 2,
                borderTop: '1px solid #ddd',
                backgroundColor: '#f5f5f5'
            }}>
                <Button
                    onClick={onClose}
                    disabled={loading}
                >
                    Annulla
                </Button>
                <LoadingButton
                    loading={loading}
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                >
                    Salva
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default EditDatesDialog;