import * as React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Title } from 'react-admin';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import GetEditPassword from './GetEditPassword';
import RequestResetPassword from './RequestResetPassword';

function ResetPasswordPage() {
    const location = useLocation();
    const token = new URLSearchParams(location.search).get('token');

    return (
        <Card sx={{ mt: 10, textAlign: 'center', backgroundColor: '#f3f6f9' }}>
            <Title title="Pagina per reset password" />

            <CardContent>
                <ErrorOutlineIcon sx={{ fontSize: 60, color: 'e85a2c' }} />
                <Typography variant="h4" sx={{ margin: '20px 0', color: 'blue' }}>
                    App Trasporti Tuvoni - Reset password
                </Typography>
                <Typography variant="subtitle1">
                    <hr />
                </Typography>
                {token ? <GetEditPassword token={token} /> : <RequestResetPassword />}
            </CardContent>
        </Card>
    );
}

export default ResetPasswordPage;