import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';


import SimulatoreOperatoreStepper from './SimulatoreOperatoreStepper';

function SimulatoreOperatore() {
    return (
        <div>
            <Container component="main" maxWidth="md" sx={{ mt: 10 }}>
                <Box sx={{ my: 4 }}>
                    <Paper elevation={3} sx={{ p: 3 }}>
                        <SimulatoreOperatoreStepper />
                    </Paper>
                </Box>
            </Container>
        </div>
    );
}

export default SimulatoreOperatore;
