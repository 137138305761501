import * as React from 'react';
import {
    useInput
} from 'react-admin';
import {
    Box,
    Typography,
    TextField,
    InputAdornment,
    LinearProgress,
    IconButton
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (password.match(/[a-z]/) && password.match(/[A-Z]/)) strength++;
    if (password.match(/\d/)) strength++;
    if (password.match(/[^a-zA-Z\d]/)) strength++;
    return strength;
};

const PasswordInputCustom = props => {
    const {
        field,
        fieldState: { isTouched, invalid, error },
        formState: { isSubmitted }
    } = useInput(props);
    const [strength, setStrength] = React.useState(0);
    const [value, setValue] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setValue(newValue);
        field.onChange(newValue);
        setStrength(calculatePasswordStrength(newValue));
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    React.useEffect(() => {
        if (field.value && field.value !== value) {
            setValue(field.value);
            setStrength(calculatePasswordStrength(field.value));
        }
    }, [field.value]);

    const errorMessage = React.useMemo(() => {
        if (isTouched && invalid && error) {
            return error.message || 'Errore di validazione';
        }
        return '';
    }, [isTouched, invalid, error]);

    return (
        <Box>
            <TextField
                {...field}
                type={showPassword ? "text" : "password"}
                label="Password"
                error={isTouched && invalid}
                helperText={errorMessage}
                onChange={handleChange}
                value={value}
                fullWidth
                variant="outlined"
                autoComplete="new-password"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <LockIcon />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <LinearProgress
                variant="determinate"
                value={strength * 25}
                sx={{
                    marginTop: 1,
                    '& .MuiLinearProgress-bar': {
                        backgroundColor:
                            strength <= 1 ? '#f44336' :
                                strength === 2 ? '#ff9800' :
                                    strength === 3 ? '#4caf50' : '#2196f3'
                    }
                }}
            />
            <Typography variant="caption">
                Forza password: {
                    strength <= 1 ? 'Debole' :
                        strength === 2 ? 'Media' :
                            strength === 3 ? 'Forte' : 'Molto forte'
                }
            </Typography>
        </Box>
    );
};

export default PasswordInputCustom;