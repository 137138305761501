import React, { useState, useEffect } from 'react';
import { useNotify, useDataProvider, useUpdate } from 'react-admin';
import { TextField, Button } from '@mui/material';
import { Select, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import axios from 'axios';
import { uploadButtonCss } from '../shared/Styles/buttonStyles';
import config from '../../config/config.json';

const CustomCreateDocumenti = ({ tiporecordId, recordId, userId, refresh }) => {
    const [tipodocumentoId, setTipodocumentoId] = useState(0);
    const [descrizione, setDescrizione] = useState('');
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [update, { isLoading, error }] = useUpdate();
    const [file, setFile] = useState(null); // Nuovo stato per il file
    const [tipoDocumentoOptions, setTipoDocumentoOptions] = useState([]); // Stato per memorizzare le opzioni della dropdown

    const fetchTipoDocumento = async () => {
        try {
            const { data } = await dataProvider.getList('tipodocumento', {
                pagination: { page: 1, perPage: 10 },
                sort: { field: 'id', order: 'ASC' },
            });
            setTipoDocumentoOptions(data);
        } catch (error) {
            console.error("Errore nel recupero dei tipi documento:", error);
            notify("Errore nel recupero dei tipi documento", 'warning');
        }
    };

    useEffect(() => {
        fetchTipoDocumento();
    }, []);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const uploadFile = async (documentoId) => {
        let formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios({
                method: 'post',
                /* '/uploads/documenti/:tiporecordId/:recordId/:documentoId */
                /* `/uploads/${tiporecordId}/${recordId}/${documentoId}` */
                url: `${config.production.hostUploads}/uploads/documenti/${tiporecordId}/${recordId}/${documentoId}`,
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            return response.data;
        } catch (error) {
            console.error("Errore nell'upload del file:", error);
            throw error;
        }
    };

    const handleClick = async () => {
        try {

            const data = {
                tiporecordId: tiporecordId,
                tipodocumentoId: tipodocumentoId,
                recordId: recordId,
                descrizione: descrizione,
                userId: userId
            };

            dataProvider.create('documenti', { data })
                .then(response => {
                    console.log("Risposta della create:", response.data);
                    notify('Riferimento file su DB e upload completati con successo');
                    setDescrizione(''); // Reset della descrizione dopo la creazione con successo

                    uploadFile(response.data.id).then((rispostaDopoUpload) => {
                        console.log(rispostaDopoUpload);

                        const data = {
                            nomeFile: rispostaDopoUpload.metadati.nomeFile,
                            metadati: JSON.stringify(rispostaDopoUpload.metadati),
                            estensioneFile: rispostaDopoUpload.metadati.tipoMIME,
                            dimensione: rispostaDopoUpload.metadati.dimensione
                        };

                        update(
                            'documenti',
                            { id: rispostaDopoUpload.documentiId, data: data },
                            {
                                onSuccess: ({ data }) => {
                                    // refresh();
                                    notify('Descrizione file aggiornata con successo');
                                    //setEditText(''); // Reset della descrizione se necessario
                                },
                                onFailure: (error) => {
                                    notify(error.message, 'warning');
                                }
                            }
                        );
                    });
                    refresh();
                })
                .catch(error => {
                    notify(error.message, 'warning');
                });

        } catch (error) {
            notify(error.message, 'warning');
        }
    };

    return (
        <Box>
            <TextField
                id="descrizione"
                label="Descrizione"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={descrizione}
                onChange={(e) => setDescrizione(e.target.value)}
            />

            {/* Input per selezionare il file */}
            {<input
                type="file"
                onChange={handleFileChange}
                style={{
                    display: 'block', marginTop: '10px', backgroundColor: 'green',
                    color: 'white',
                }}
            />}
            {/*             <div style={uploadButtonCss} onClick={() => document.getElementById('hidden-file-input').click()}>
                Seleziona file
                <input
                    type="file"
                    id="hidden-file-input"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />
            </div> */}

            <Select
                labelId="dropdown-tipo-documento-label"
                id="dropdown-tipo-documento"
                value={tipodocumentoId}
                onChange={(e) => setTipodocumentoId(e.target.value)}
                fullWidth
                displayEmpty
                style={{ marginTop: '10px' }}
            >
                <MenuItem value="" disabled>
                    Seleziona un tipo documento
                </MenuItem>
                {tipoDocumentoOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.descrizione} {/* Assumendo che l'oggetto abbia una proprietà 'nome' */}
                    </MenuItem>
                ))}
            </Select>

            <Button
                onClick={handleClick}
                sx={uploadButtonCss}
                variant="contained"
                color="primary"
                style={{ marginTop: '10px' }}
                disabled={!descrizione || !file} // Disabilita se non c'è descrizione o file
            >
                Esegui upload
            </Button>
        </Box>
    );
};

export default CustomCreateDocumenti;
