import { useRecordContext } from 'react-admin';
import AnagenSediList from "../CustomAnagenSedi/CustomAnagenSedi";
import CustomCreateSedi from "../CustomAnagenSedi/CustomCreateSede";

const GestioneSediAnagen = ({ /* tiporecordId, */ refresh }) => {
    const record = useRecordContext();
    // const userId = localStorage.getItem("RaStore.profile.idUser");
    const emailUser = localStorage.getItem("RaStore.profile.email");
    if (!record) return null;
    return record ? (
        <>

            {<CustomCreateSedi angenId={record.id} label="Voci Contatto " />}
            {<AnagenSediList recordId={record.id} emailUser={emailUser}></AnagenSediList>} </>
    ) : null;
};

export default GestioneSediAnagen;
