import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    IconButton,
    Paper,
    Grid,
    Snackbar,
    Alert
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MySimpleMap from "../shared/OpenStreet/MySimpleMap";
import axios from "axios";
import config from '../../config/config.json';

const host = config.production.host;

const GeoDetails = ({ propValueMask }) => {
    const [geoData, setGeoData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const jwt = localStorage.getItem("feathers-jwt");

    useEffect(() => {
        const fetchGeoData = async () => {
            const token = jwt;
            const data = {
                spec: {
                    itemsType: "avl_unit",
                    propName: "sys_name",
                    propValueMask: "*" + propValueMask + "*" || "*",
                    sortType: "sys_name"
                },
                force: 1,
                flags: 1,
                from: 0,
                to: 0
            };

            try {
                const response = await axios.post(
                    `${host}/wialon/core/search_items/`,
                    data,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token
                        }
                    }
                );

                const dataForGeo = {
                    id: response.data.items[0].id,
                    flags: 1025
                };

                const responseForGeo = await axios.post(
                    `${host}/wialon/core/search_item/`,
                    dataForGeo,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token
                        }
                    }
                );

                setGeoData(responseForGeo.data);
            } catch (error) {
                console.error("Errore nella chiamata API:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchGeoData();
    }, [propValueMask, jwt]);

    const handleCopyJson = () => {
        navigator.clipboard.writeText(JSON.stringify(geoData, null, 2))
            .then(() => {
                setOpenSnackbar(true);
            })
            .catch((error) => {
                console.error('Errore durante la copia:', error);
            });
    };

    if (loading) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography>Caricamento dati geolocalizzati...</Typography>
            </Box>
        );
    }

    if (!geoData || !geoData.item || !geoData.item.pos) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography>Nessun dato disponibile per la geolocalizzazione.</Typography>
            </Box>
        );
    }

    const latitude = geoData.item.pos?.y || 40.7095;
    const longitude = geoData.item.pos?.x || 9.69923;

    const vehicleInfo = {
        nome: geoData.item.nm,
        targa: geoData.item.lmsg?.p?.can_reg_number,
        vin: geoData.item.lmsg?.p?.pwr_ext + `V`,
        velocita: `${geoData.item.lmsg?.p?.can_speed || 0} km/h`,
        chilometraggio: `${((geoData.item.lmsg?.p?.io_16 || 0) / 1000).toFixed(2)} km`,
        carburante: `${(geoData.item.lmsg?.p?.can_total_fuel || 0).toFixed(2)} L`,
        temperatura: `${geoData.item.lmsg?.p?.can_eng_cool_temp || 0} °C`,
        ultimoAggiornamento: new Date(geoData.item.pos.t * 1000).toLocaleString('it-IT', {
            dateStyle: 'medium',
            timeStyle: 'medium'
        })
    };

    return (
        <Box sx={{ width: '100%', p: 2 }}>
            {/* Box Informazioni */}
            <Paper
                elevation={3}
                sx={{
                    p: 3,
                    mb: 3,
                    borderRadius: 2,
                    backgroundColor: 'background.paper'
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
                            Posizione Attuale
                        </Typography>
                        <Box sx={{ pl: 1 }}>
                            <Typography sx={{ mb: 1 }}>
                                <strong>Latitudine:</strong> {latitude}
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <strong>Longitudine:</strong> {longitude}
                            </Typography>
                            <Typography>
                                <strong>Ultimo aggiornamento:</strong> {vehicleInfo.ultimoAggiornamento}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
                            Informazioni Veicolo
                        </Typography>
                        <Box sx={{ pl: 1 }}>
                            <Typography sx={{ mb: 1 }}>
                                <strong>Nome:</strong> {vehicleInfo.nome}
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <strong>Targa:</strong> {vehicleInfo.targa}
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <strong>VIN:</strong> {vehicleInfo.vin}
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <strong>Velocità:</strong> {vehicleInfo.velocita}
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <strong>Chilometraggio:</strong> {vehicleInfo.chilometraggio}
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <strong>Carburante consumato:</strong> {vehicleInfo.carburante}
                            </Typography>
                            <Typography>
                                <strong>Temperatura motore:</strong> {vehicleInfo.temperatura}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>

            {/* Mappa */}
            <Box sx={{ mb: 3 }}>
                <MySimpleMap latitude={latitude} longitude={longitude} />
            </Box>

            {/* Accordion JSON */}
            <Accordion
                sx={{
                    borderRadius: '8px !important',
                    '&:before': {
                        display: 'none',
                    }
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                        backgroundColor: 'background.paper',
                        borderRadius: '8px',
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%'
                    }}>
                        <Typography>Dati JSON Completi</Typography>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                handleCopyJson();
                            }}
                            size="small"
                            sx={{ mr: 2 }}
                        >
                            <ContentCopyIcon />
                        </IconButton>
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ bgcolor: 'background.default' }}>
                    <pre style={{
                        overflow: 'auto',
                        maxHeight: '400px',
                        backgroundColor: '#f5f5f5',
                        padding: '16px',
                        borderRadius: '4px',
                        margin: 0
                    }}>
                        {JSON.stringify(geoData, null, 2)}
                    </pre>
                </AccordionDetails>
            </Accordion>

            {/* Snackbar per notifica copia */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="success"
                    sx={{ width: '100%' }}
                >
                    JSON copiato negli appunti!
                </Alert>
            </Snackbar>
        </Box>
    );
};

GeoDetails.propTypes = {
    propValueMask: PropTypes.string.isRequired
};

export default GeoDetails;