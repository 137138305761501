import * as React from "react";
import {
    List,
    TextField,
    Show,
    EditButton,
    TabbedShowLayout,
    ShowButton,
    TopToolbar,
    useRefresh,
    NumberInput,
    DateInput,
    DateField,
    SelectInput,
    ReferenceInput,
    FunctionField,
    ReferenceManyField,
    useRecordContext,
    ReferenceField,
    NumberField,
    TextInput,
    Datagrid,
    ReferenceOneField
} from 'react-admin';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import { CardHeader } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PeopleIcon from '@mui/icons-material/People';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";
import { Link } from 'react-router-dom';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

// Importo il css per i Bottoni
import { editButtonCss, showButtonCss } from '../shared/Styles/buttonStyles';
import { formCss } from "../shared/Styles/formCss";
import { ToBoolean } from '../shared/ToBoolean';

// Gestione lista note
import GestioneNote from "../note/GestioneNote";

// Gestione lista documenti
import GestioneDocumenti from "../documenti/GestioneDocumenti";

// Gestione lista anagen_contatti - n contatti per singolo rigo
import GestioneContattiAnagen from "../CustomAnagenContatti/GestioneContattiAnagen";

// Gestione lista anagen_contatti - n contatti per singolo rigo
import GestioneSediAnagen from "../CustomAnagenSedi/GestioneSediAnagen";

import CustomTextFieldDataOra from "../shared/FormFields/CustomTextFieldDataOra";

const ShowActions = () => (
    <TopToolbar>
        <EditButton sx={editButtonCss} label="Modifica" />
    </TopToolbar>
);

/* const ShowTopToolbarDettaglio = () => (
    <TopToolbar>
        <ShowButton sx={showButtonCss} label="Dettaglio" />
    </TopToolbar>
); */

export const AnagenShow = (props) => {

    const refresh = useRefresh();

    return (
        <Show title={<>
            <Button>
                <PeopleIcon></PeopleIcon>
                &nbsp;Dettaglio Cliente
            </Button>
        </>} actions={<ShowActions />}>
            <Card sx={{ minWidth: 275 }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: '#01b5d0' }}>
                            <AssignmentIcon />
                        </Avatar>
                    }
                    title={<><strong>ID Anagrafica :</strong>< TextField label="Anagen Id" source="id" /></>}
                    subheader={
                        <>
                            <strong>Testo per ricerca :</strong> {<TextField label="Ricerca" source="gnfind" />}
                            <br />
                            <strong>Ragione sociale :</strong> {<TextField label="Rag.Soc." source="gnragnom" />}
                            <br />
                            {<TextField label="Rag.Soc2" source="gnrag2" />}
                            <br />
                            <strong>Partita IVA :</strong>{<TextField label="Partita IVA" source="gncdiva" />}
                            <br />
                            <strong>Codice fiscale :</strong>{<TextField label="Codice fiscale" source="gncdfisc" />}
                            <br />
                            <strong>Dati indirizzo :</strong><br></br>
                            {<TextField source="gnind" label="Indirizzo" />}
                            <br />
                            {<TextField source="gncomune" label="Comune" />}
                            <br />
                            {<TextField source="gnprov" label="Provincia" />}
                            <br></br>
                            {<TextField source="gncap" label="CAP" />}
                            <br></br>
                            <ReferenceOneField reference="users" target="clientiId" label="">
                                {<FunctionField
                                    label="Dettaglio Fornitore"
                                    render={record => record && (
                                        <Button
                                            component={Link}
                                            to={`/users/${record.id}/show/anagraficaavanzata`}
                                            size="small"
                                            sx={{
                                                backgroundColor: '#2196f3',
                                                color: 'white',
                                                '&:hover': {
                                                    backgroundColor: '#1976d2',
                                                },
                                            }}
                                        >
                                            User di appartenenza: {record.email}
                                        </Button>
                                    )}
                                />}

                            </ReferenceOneField>

                        </>}
                />
            </Card>
            <Divider sx={{ padding: 1 }}></Divider>
            <ResponsiveTabbedShowLayout sx={formCss}>
                <TabbedShowLayout.Tab label="Anagrafica Base"
                    sx={{
                        '&.Mui-selected': {
                            borderBottom: '3px solid #01b5d0',
                            backgroundColor: '#e8e8e8'
                        }
                    }}
                >
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={6}>
                            <List pagination="" actions={false}>

                                <ListItem>
                                    <ListItemText
                                        primary="Recapito telefonico"
                                        secondary='Recapito telefonico'
                                    />
                                    {< TextField label="Telefono" source="gntel" />}
                                </ListItem>

                                <ListItem>
                                    <ListItemText
                                        primary="FAX"
                                        secondary='FAX'
                                    />
                                    {<TextField label="FAX" source="gnfax" />}
                                </ListItem>

                                <ListItem>
                                    <ListItemText
                                        primary="Cellulare"
                                        secondary='Numero di cellulare'
                                    />
                                    {<TextField label="Cellulare" source="gncel" />}
                                </ListItem>

                                <ListItem>
                                    <ListItemText
                                        primary="Email"
                                        secondary='Contatto email'
                                    />
                                    {<TextField label="Email" source="gnemail" />}
                                </ListItem>

                                <ListItem>
                                    <ListItemText
                                        primary="Sito"
                                        secondary='Sito Internet'
                                    />
                                    {<TextField label="URL Sito" source="gnhomep" />}
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Proprieta" path="proprieta"
                    sx={{
                        '&.Mui-selected': {
                            borderBottom: '3px solid #01b5d0',
                            backgroundColor: '#e8e8e8'
                        }
                    }}
                >
                    <div>
                        <div>
                            {<CustomTextFieldDataOra label="Data creazione" source="createdAt" />}
                            <br></br>
                            {<CustomTextFieldDataOra label="Data ultima modifica" source="updatedAt" />}
                        </div>
                    </div>
                    <div>
                        {<ToBoolean label="Attivo" source="flAttivo" labelOn={true}></ToBoolean>}
                        {<ToBoolean label="Visibile" source="fiVisibile" labelOn={true}></ToBoolean>}
                        {<ToBoolean label="Annullato" source="flAnnullato" labelOn={true}></ToBoolean>}
                    </div>
                    <Divider variant="middle" />
                    <ConfirmDialogDeleteButtonCustom
                        titolo={"Cancella Autista"}
                        messaggio={"Sei sicuro di vole cancellare questo Autista ?"}
                        servizio={"autisti"} />
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Annotazioni" path="annotazioni"
                    sx={{
                        '&.Mui-selected': {
                            borderBottom: '3px solid #01b5d0',
                            backgroundColor: '#e8e8e8'
                        }
                    }}
                >
                    <p><strong>Annotazioni</strong></p>
                    <GestioneNote tiporecordId="1" refresh={refresh} ></GestioneNote>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Documenti" path="documenti"
                    sx={{
                        '&.Mui-selected': {
                            borderBottom: '3px solid #01b5d0',
                            backgroundColor: '#e8e8e8'
                        }
                    }}
                >
                    <p><strong>Documenti</strong></p>
                    <GestioneDocumenti tiporecordId="1" refresh={refresh} ></GestioneDocumenti>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Contatti" path="contatti"
                    sx={{
                        '&.Mui-selected': {
                            borderBottom: '3px solid #01b5d0',
                            backgroundColor: '#e8e8e8'
                        }
                    }}
                >
                    <p><strong>Contatti</strong></p>
                    <GestioneContattiAnagen /* tiporecordId="1" */ refresh={refresh} ></GestioneContattiAnagen>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Indirizzi / Sedi" path="indirizziSedi"
                    sx={{
                        '&.Mui-selected': {
                            borderBottom: '3px solid #01b5d0',
                            backgroundColor: '#e8e8e8'
                        }
                    }}
                >
                    <p><strong>Indirizzi / Sedi</strong></p>
                    <GestioneSediAnagen /* tiporecordId="1" */ refresh={refresh} ></GestioneSediAnagen>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Servizi Pax Abilitati" path="serviziPax"
                    sx={{
                        '&.Mui-selected': {
                            borderBottom: '3px solid #01b5d0',
                            backgroundColor: '#e8e8e8'
                        }
                    }}
                >
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <GetListFornitoriServpax></GetListFornitoriServpax>
                        </Grid>
                    </Grid>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Tariffe Servizi Pax" path="tariffeServiziPax"
                    sx={{
                        '&.Mui-selected': {
                            borderBottom: '3px solid #01b5d0',
                            backgroundColor: '#e8e8e8'
                        }
                    }}
                >
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <GetListTariffaServpax></GetListTariffaServpax>
                        </Grid>
                    </Grid>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Preventivi" path="preventivi"
                    sx={{
                        '&.Mui-selected': {
                            borderBottom: '3px solid #01b5d0',
                            backgroundColor: '#e8e8e8'
                        }
                    }}
                >
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <GetListBooking0></GetListBooking0>
                        </Grid>
                    </Grid>
                </TabbedShowLayout.Tab>
            </ResponsiveTabbedShowLayout>

        </Show>
    );
}

/* Blocco di codice per la gestione delle tariffe - inizio */

const GetListTariffaServpax = (props) => {
    const record = useRecordContext();
    const gnfind = record.gnfind;
    if (!record) return null;

    return (
        <ReferenceManyField
            reference="tariffa-servpax"
            target="anagenId"
        >

            <EditableDatagrid
                bulkActionButtons={false}
                editForm={<TariffaServpaxRowForm isCreate={false} />}
                createForm={<TariffaServpaxRowForm isCreate={true} id={record.id} />}
            >
                <FunctionField label="Fornitore" render={record => record && `${gnfind}`} />
                <ReferenceField link={false} label="Servizio" source="servpaxId" reference="servpax">
                    <TextField source="descrizione" />
                    <br></br>
                    <FunctionField
                        label="Dettaglio Servizio"
                        render={record => record && (
                            <Button
                                component={Link}
                                to={`/servpax/${record.id}/show`}
                                size="small"
                                sx={{
                                    backgroundColor: '#2196f3',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: '#1976d2',
                                    },
                                }}
                            >
                                Dettaglio
                            </Button>
                        )}
                    />
                </ReferenceField>
                <DateField fullWidth label="Data Dal" source="dataFrom" />
                <DateField fullWidth label="Data Al" source="dataTo" />
                <NumberField source="costo" label="Costo" options={{ style: 'currency', currency: 'EUR' }} />

            </EditableDatagrid>

        </ReferenceManyField>
    );
}

const TariffaServpaxRowForm = ({ isCreate, ...props }) => (
    <RowForm {...props}>
        {/* {isCreate && <TextInput source="servpaxId" defaultValue={parseInt(props.id)} disabled style={{ display: 'none' }} />} */}
        <TextInput source="anagenId" defaultValue={parseInt(props.id)} style={{ display: 'none' }} />
        <ReferenceInput fullWidth
            sx={{ mb: 2 }}
            label="Servizi Abilitati"
            source="servpaxId"
            reference="view-fornitori-servpax"
            filter={{ anagenId: props.id }}
            perPage={null}
        >
            <SelectInput optionText="descrizione" />
        </ReferenceInput>

        <DateInput fullWidth label="Data Dal" source="dataFrom" />
        <DateInput fullWidth label="Data Al" source="dataTo" />
        <NumberInput min={0.000} fullWidth label="Costo" source="costo" options={{ style: "currency", currency: "EUR" }} />
    </RowForm>
);

/* Blocco di codice per la gestione delle tariffe - fine */

/* Blocco di codice per la gestione dei servizi abilitati - inizio */

const GetListFornitoriServpax = (props) => {
    const record = useRecordContext();
    const gnfind = record.gnfind;
    if (!record) return null;

    return (
        <ReferenceManyField
            reference="fornitori-servpax"
            target="anagenId"
        >

            <EditableDatagrid
                noDelete
                bulkActionButtons={false}
                editForm={<FornitoriServpaxRowForm isCreate={false} />}
                createForm={<FornitoriServpaxRowForm isCreate={true} id={record.id} />}
            >
                <FunctionField label="Fornitore" render={record => record && `${gnfind}`} />
                <ReferenceField link={false} label="Servizio" source="servpaxId" reference="servpax">
                    <TextField source="descrizione" />
                    <FunctionField
                        label="Dettaglio Servizio"
                        render={record => record && (
                            <Button
                                component={Link}
                                to={`/servpax/${record.id}/show`}
                                size="small"
                                sx={{
                                    backgroundColor: '#2196f3',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: '#1976d2',
                                    },
                                }}
                            >
                                Dettaglio
                            </Button>
                        )}
                    />
                </ReferenceField>
                <ConfirmDialogDeleteButtonCustom
                    titolo={"Togli Abilitazione"}
                    messaggio={"Sei sicuro di voler togliere l'abilitazione ?"}
                    servizio={"fornitori-servpax"}
                    redirectPath={"/anagen/" + record.id + "/show/"}
                />
            </EditableDatagrid>

        </ReferenceManyField>
    );
}

const FornitoriServpaxRowForm = ({ isCreate, ...props }) => (
    <RowForm {...props}>
        {/* {isCreate && <TextInput source="servpaxId" defaultValue={parseInt(props.id)} disabled style={{ display: 'none' }} />} */}
        <TextInput source="anagenId" defaultValue={parseInt(props.id)} style={{ display: 'none' }} />
        <ReferenceInput fullWidth
            sx={{ mb: 2 }}
            label="Servizi Abilitati"
            source="servpaxId"
            reference="servpax"
            // perPage={null}
            // page={1}
            perPage={null}
            sort={{ field: 'descrizione', order: 'ASC' }}
        >
            <SelectInput optionText="descrizione" />
        </ReferenceInput>
    </RowForm>
);

/* Blocco di codice per la gestione dei servizi abilitati - fine */

/* Blocco di codice dei prevenditi su booking-0 - inizio */

const GetListBooking0 = (props) => {
    const record = useRecordContext();
    if (!record) return null;

    return (
        <ReferenceManyField
            reference="booking-0"
            target="bk0_clienteId"
        >

            <Datagrid
                bulkActionButtons={false}
            >
                <TextField source="bk0_idnumber" label="Numero" />
                <ReferenceField source="bk0_idstatus" reference="booking-0-status" label="Status"></ReferenceField>
                <ReferenceField
                    link={null}
                    label="Cliente"
                    source="bk0_clienteId"
                    reference="anagen"
                    sortable={true}
                    emptyText="-" // Testo da mostrare quando il valore è vuoto
                >
                    <TextField source="gnfind" />
                </ReferenceField>
                <DateField
                    source="bk0_datada"
                    label="Dal"
                    showTime={false} // Mostra solo la data senza ora
                />
                <DateField
                    source="bk0_dataal"
                    label="Al"
                    showTime={false}
                />
                <ReferenceField
                    link={null}
                    label="Tipo Servizio"
                    source="bk0_servizio"
                    reference="servizi"
                    sortable={true}
                    emptyText="-"
                />
                <ReferenceField
                    link={null}
                    label="Tipo Richiesta"
                    source="bk0_richiestatipo"
                    reference="tabellealfa"
                    sortable={true}
                    emptyText="-"
                />
                <TextField label="Note per Booking" source="bk0_ntmemo" emptyText="-" />
                <TextField label="Nota per Cliente" source="bk0_ntcliente" emptyText="-" />
                <TextField label="Nota per Autista" source="bk0_ntautista" emptyText="-" />
                <TextField label="Nota per Responsabile" source="bk0_ntresp" emptyText="-" />
                <TextField label="Adulti" source="bk0_paxin" emptyText="0" />
                <TextField label="Bambini" source="bk0_paxch" emptyText="0" />
                <TextField label="Infanti" source="bk0_paxin" emptyText="0" />
                <DateField
                    source="createdAt"
                    label="Data Creazione"
                    showTime={true} // Mostra data e ora per la creazione
                />
                <FunctionField
                    label="Tratte"
                    render={record => record && (
                        <Button
                            component={Link}
                            to={`/booking-0/${record.id}/show/tratte`}
                            size="small"
                            startIcon={<DirectionsCarIcon />}
                            sx={{
                                backgroundColor: '#2196f3',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#1976d2',
                                },
                            }}
                        >
                            Tratte
                        </Button>
                    )}
                />
                <ShowButton
                    sx={showButtonCss}
                    label="Dettaglio"
                />
            </Datagrid>

        </ReferenceManyField>
    );
}

/* Blocco di codice dei prevenditi su booking-0  - fine */


// Stile personalizzato per il container dei tab
const ResponsiveTabContainer = styled(Box)(({ theme }) => ({
    '& .MuiTabs-root': {
        [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',
            '& .MuiTabs-scroller': {
                overflow: 'visible !important',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                    display: 'none'
                }
            }
        }
    },
    '& .MuiTabs-flexContainer': {
        [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',
            gap: '4px'
        }
    },
    '& .MuiTab-root': {
        minWidth: 'auto',
        padding: '6px 12px',
        fontSize: '0.875rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.75rem',
            maxWidth: 'none'
        }
    }
}));


// TabbedShowLayout personalizzato e responsive
const ResponsiveTabbedShowLayout = ({ children, ...props }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <ResponsiveTabContainer>
            <TabbedShowLayout
                {...props}
                sx={{
                    '& .RaTabbedShowLayout-content': {
                        padding: isMobile ? '16px 8px' : '24px 16px'
                    }
                }}
            >
                {children}
            </TabbedShowLayout>
        </ResponsiveTabContainer>
    );
};
