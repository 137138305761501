
import { usersList } from './usersList';
import usersCreate from './usersCreate';
import usersEdit from './usersEdit';
import { usersShow } from './usersShow';

const users = {
    list: usersList,
    create: usersCreate,
    edit: usersEdit,
    show: usersShow,
};

export default users;
