import {
    ClientiList,
    ClientiCreate,
    ClientiEdit,
    ClientiShow,
} from '../clienti/clienti';

export default {
    list: ClientiList,
    create: ClientiCreate,
    edit: ClientiEdit,
    show: ClientiShow,
};