import * as React from 'react';
import { Card, CardContent, Button, Grid } from '@mui/material';
import { Title, localStorageStore } from 'react-admin';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';

// Inizializza lo store
const storeGenerale = localStorageStore();

function ChoicePage() {
    const navigate = useNavigate();

    const createButton = React.useCallback((label, path, urlParam, tabellealfaId) => {
        // Stile per le icone
        const iconStyle = {
            backgroundColor: '#01b5d0'
        };

        // Funzione per gestire il click del pulsante
        const handleClick = () => {
            storeGenerale.setItem('profile.urlParam', urlParam);
            storeGenerale.setItem('profile.urlParam.id', tabellealfaId);
            navigate(`${path}/${encodeURIComponent(urlParam)}`);
        };

        return (
            <Grid item key={label}>
                <Button
                    style={iconStyle}
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                >
                    {label}
                </Button>
            </Grid>
        );
    }, [navigate]);

    return (
        <Card sx={{ mt: 10, textAlign: 'center', backgroundColor: '#f3f6f9' }}>
            <Title title="Crea Anagrafica" />
            <CardContent>
                <AssignmentIcon sx={{ fontSize: 60, color: blue[500] }} />
                <Typography variant="h4" sx={{ margin: '20px 0', color: '#01b5d0' }}>
                    Crea Anagrafica
                </Typography>
                <Typography variant="subtitle1" sx={{ marginBottom: '20px' }}>
                    Seleziona la tipologia di anagrafica che desideri creare.
                </Typography>
                <Grid container direction="column" alignItems="center" spacing={2}>
                    {createButton('Clienti', '/anagen/create', 'cliente', '1135')}
                    {createButton('Fornitori', '/anagen/create', 'fornitore', '1136')}
                    {createButton('Cliente Fornitore', '/anagen/create', 'cliente fornitore', '1137')}
                    {createButton('Dipendenti e coll.', '/anagen/create', 'dipendente', '1148')}
                    {createButton('Parco veicoli', '/automezzi/create', 'veicolo', '')}
                    {createButton('Autisti', '/autisti/create', 'autista', '')}
                    {createButton('Sedi aziendali', '/x', 'sede', '')}
                    {createButton('Anagrafica Gen.', '/x', 'generale', '')}
                </Grid>
            </CardContent>
        </Card>
    );
}

export default ChoicePage;