import * as React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Title } from 'react-admin';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { usePermissions } from 'react-admin';

function NotFoundPage() {

    const { permissions } = usePermissions();
    console.log(permissions);

    return (
        <Card sx={{ mt: 10, textAlign: 'center', backgroundColor: '#f3f6f9' }}>
            <Title title="404 Pagina non trovata" />
            {permissions === "admin" && (
                <CardContent>
                    <ErrorOutlineIcon sx={{ fontSize: 60, color: 'red' }} />
                    <Typography variant="h4" sx={{ margin: '20px 0', color: 'red' }}>
                        GRUPPO ADMIN
                    </Typography>
                    <Typography variant="subtitle1">
                        Pagina di default.
                    </Typography>
                </CardContent>
            )}
            <CardContent>
                <ErrorOutlineIcon sx={{ fontSize: 60, color: blue[500] }} />
                <Typography variant="h4" sx={{ margin: '20px 0', color: '#01b5d0' }}>
                    404: Pagina non trovata
                </Typography>
                <Typography variant="subtitle1">
                    Siamo spiacenti, la pagina che stai cercando non esiste.
                </Typography>
            </CardContent>
        </Card>
    );
}

export default NotFoundPage;
