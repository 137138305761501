export const fieldBaseForShow = {
    margin: '10px', // Definisce i margini intorno al campo
    color: 'grey', // Imposta il colore del testo
    bgcolor: 'white', // Imposta il colore dello sfondo
    padding: '5px', // Imposta il padding interno
    borderRadius: '4px', // Aggiunge bordi arrotondati
};

export const fieldBaseDialogSimulatorForShow = {
    // margin: '10px', // Definisce i margini intorno al campo
    color: 'green', // Imposta il colore del testo
    bgcolor: 'white', // Imposta il colore dello sfondo
    padding: '5px', // Imposta il padding interno
    borderRadius: '4px', // Aggiunge bordi arrotondati
};


export const fieldRisultatiDialogSimulatorForShow = {
    // margin: '10px', // Definisce i margini intorno al campo
    color: 'green', // Imposta il colore del testo
    bgcolor: 'white', // Imposta il colore dello sfondo
    padding: '5px', // Imposta il padding interno
    borderRadius: '4px', // Aggiunge bordi arrotondati
    size: 10
};

