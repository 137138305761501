// TariffBSelectionDialog.js
import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    Box,
    Typography,
} from '@mui/material';
import {
    useGetList,
    ListBase,
    Datagrid,
    TextField,
    NumberField,
    FunctionField,
    useUpdate,
    useNotify,
    useRefresh,
} from 'react-admin';

const TariffBSelectionDialog = ({ open, onClose, record, gruppoId, tariffeAId }) => {
    const [selectedTariff, setSelectedTariff] = useState(null);
    const [update] = useUpdate();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleConfirm = async () => {
        if (!selectedTariff) return;

        try {
            await update(
                'booking-0-automezzi',
                {
                    id: record.id,         // Ora questo è l'ID dell'automezzo specifico
                    data: {
                        tariffeBId: selectedTariff.id,
                    },
                },
                {
                    onSuccess: () => {
                        notify('Tariffa B aggiornata con successo', { type: 'success' });
                        refresh();
                        onClose();
                        setSelectedTariff(null); // Reset della selezione
                    },
                    onError: (error) => {
                        console.error('Errore aggiornamento:', error);
                        notify('Errore durante l\'aggiornamento della tariffa', { type: 'error' });
                    },
                }
            );
        } catch (error) {
            console.error('Errore durante l\'aggiornamento:', error);
            notify('Errore durante l\'aggiornamento della tariffa', { type: 'error' });
        }
    };

    const ActionButtons = ({ record }) => (
        <Button
            variant="contained"
            size="small"
            onClick={(e) => {
                e.stopPropagation();
                setSelectedTariff(record);
            }}
            sx={{ mx: 1 }}
        >
            Seleziona
        </Button>
    );

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>
                Seleziona Tariffa B - Gruppo: {gruppoId} - Tariffa A: {tariffeAId}
            </DialogTitle>
            <DialogContent>
                <ListBase
                    resource="tariffe-b"
                    filter={{
                        tfa_id: tariffeAId,
                        tfb_gruppo: gruppoId,
                    }}
                >
                    <Datagrid
                        bulkActionButtons={false}
                        rowStyle={(record) => ({
                            backgroundColor: record.id === selectedTariff?.id ? '#eef' : undefined,
                            cursor: 'pointer',
                        })}
                    >
                        <TextField source="id" />
                        <TextField source="tfb_des" label="Descrizione" />
                        <NumberField source="tfb_ckm" label="€/Km" />
                        <NumberField source="tfb_chh" label="€/Ora" />
                        <NumberField source="tfb_snotturno" label="Suppl.Nott." />
                        <NumberField source="tfb_vkm" label="€/Km Vuoto" />
                        <NumberField source="tfb_vhh" label="€/Ora Vuoto" />
                        <FunctionField
                            label="Azioni"
                            render={(record) => <ActionButtons record={record} />}
                        />
                    </Datagrid>
                </ListBase>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button onClick={onClose}>Annulla</Button>
                    <Button
                        variant="contained"
                        onClick={handleConfirm}
                        disabled={!selectedTariff}
                        sx={{ ml: 1 }}
                    >
                        Conferma
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default TariffBSelectionDialog;