import React, { useState, useEffect, useCallback } from 'react';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import { styled } from '@mui/material/styles';
import feathersClient from '../../../feathersClient';

// Stile personalizzato per il Badge
const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: 'red',
        color: 'white',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const MessageBadge = () => {
    const [messageCount, setMessageCount] = useState(0);

    const fetchNewMessages = useCallback(async () => {
        try {
            const result = await feathersClient.service('note').find({
                query: {
                    nonLetta: 1, // Filtro per note non lette
                    $sort: { createdAt: -1 },
                    $limit: 0 // Nessun record, solo il conteggio
                }
            });

            setMessageCount(result.total); // Aggiorna il conteggio con il totale delle note non lette
        } catch (error) {
            console.error('Error fetching messages:', error.message);
        }
    }, []); // Nessuna dipendenza necessaria

    useEffect(() => {
        console.log('MessageBadge component mounted');

        // Fetch messages immediately on mount
        fetchNewMessages();

        const intervalId = setInterval(fetchNewMessages, 10000); // Poll every 10 seconds

        // Cleanup function
        return () => {
            console.log('MessageBadge component unmounting, clearing interval');
            clearInterval(intervalId);
        };
    }, [fetchNewMessages]);

    console.log('Rendering MessageBadge with count:', messageCount);

    return (
        <StyledBadge badgeContent={messageCount} color="error">
            <MailIcon color="action" />
        </StyledBadge>
    );
};

export default MessageBadge;
