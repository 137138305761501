import { Layout } from 'react-admin';
import { MyMenu } from '../MyMenu/MyMenu';
// import { MyMenu2 } from './MyMenu2';
import { MyAppBar } from '../MyAppBar/MyAppBar';
import {
    AppLocationContext,
    Breadcrumb,
    ResourceBreadcrumbItems,
} from "@react-admin/ra-navigation";
// import { ReactQueryDevtools } from 'react-query/devtools'
import { MyBreadcrumb } from './MyBreadcrumb';

export const MyLayout = ({ children, ...props }) => (
    <AppLocationContext>
        <Layout {...props} menu={MyMenu} appBar={MyAppBar}>
            <MyBreadcrumb />
            {children}
            {/* <ReactQueryDevtools /> */}
        </Layout>
    </AppLocationContext>
);

